<template>

    <div class="">

      <div class="container-fluid" v-bind:class="loading">

        <div class="row">

          <div class="col-md-4">

            <div  class="card custom-card-wrapper" style="margin-bottom: 10px;">

              <div class="card-header shadow-sm p-2 d-flex justify-content-between shadow-sm">
                <a data-bs-toggle="modal" data-bs-target="#create-payment-group" class="primary-btn " ><i class="bi bi-plus-circle-fill"></i> Create Payment Group</a>
              </div>

              <div class="card-body p-0">

                <div class="card-body-header shadow-sm p-2 d-flex justify-content-between shadow-sm">
                  Active Payment Group <span class="badge rounded-pill bg-primary" v-text="active_payroll"></span>
                </div>

                <table v-if="parseInt(active_payroll) > 0" class="table table-striped">
                  <thead class=" ">
                    <tr>
                      <th>Name</th>
                      <th>Members</th>
                      <th>Status</th>
                      <th class="text-right">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(group,index) in activegroups" v-bind:key="getKey(index)"  @click="setPaymentGroup(group,index)">
                      <td v-text="group.name"></td>
                      <td v-text="group.members"></td>
                      <td v-html="getGroupStatus(group.status)"></td>
                      <td class="text-right ">
                        <div class="btn-group-sm">

                          <button type="button" class="btn btn-warning btn-sm" @click="deleteGroup(group)">
                            <i class="bi bi-pencil"></i>
                          </button>

                          <button type="button" class="btn btn-danger btn-sm" @click="deleteGroup(group)">
                            <i class="bi bi-x"></i>
                          </button>

                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>

            </div>

            <div v-if="parseInt(pending_payroll) > 0" class="card custom-card-wrapper"  style="margin-bottom: 10px;">

              <div class="card-header">
                Pending Approvals <span class="badge rounded-pill bg-secondary" v-text="pending_payroll"></span>
              </div>
              <div class="card-body p-0">

                <table class="table table-striped">
                  <thead class=" ">
                  <tr>
                    <th>Name</th>
                    <th>Members</th>
                    <th>Status</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(group,index) in pendinggroups" v-bind:key="getKey(index)"  @click="setPaymentGroup(group,index)">
                    <td v-text="group.name"></td>
                    <td v-text="group.members"></td>
                    <td v-html="getGroupStatus(group.status)"></td>
                  </tr>
                  </tbody>
                </table>

              </div>
            </div>

            <div v-if="parseInt(pending_payment) > 0" class="card custom-card-wrapper"  style="margin-bottom: 10px;">

              <div class="card-header">
                Pending Payment <span class="badge rounded-pill bg-warning" v-text="pending_payment"></span>
              </div>

              <div class="card-body p-0">

                <table class="table table-striped">
                  <thead class=" ">
                  <tr>
                    <th>Name</th>
                    <th>Members</th>
                    <th>Status</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(group,index) in pendingpaymentgroups" v-bind:key="getKey(index)"  @click="setPaymentGroup(group,index)">
                    <td v-text="group.name"></td>
                    <td v-text="group.members"></td>
                    <td v-html="getGroupStatus(group.status)"></td>
                  </tr>
                  </tbody>
                </table>

              </div>

            </div>

            <div v-if="parseInt(close_payroll) > 0" class="card custom-card-wrapper"  style="margin-bottom: 10px;">

              <div class="card-header">
                Completed Payment <span class="badge rounded-pill bg-success" v-text="close_payroll"></span>
              </div>

              <div class="card-body p-0">

                <table class="table table-striped">
                  <thead class=" ">
                  <tr>
                    <th>Name</th>
                    <th>Members</th>
                    <th>Status</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(group,index) in closedgroups" v-bind:key="getKey(index)"  @click="setPaymentGroup(group,index)">
                    <td v-text="group.name"></td>
                    <td v-text="group.members"></td>
                    <td v-html="getGroupStatus(group.status)"></td>
                  </tr>
                  </tbody>
                </table>

              </div>

            </div>

          </div>

          <div class="col-md-8">

            <div class="card custom-card-wrapper">

              <div class="card-header line-height-1">
                <span v-text="paymentgroup.name"></span> <br> <small class="text-success text-small font-weight-500"><span v-text="paymentgroup.members"></span> members</small>
              </div>

              <div class="card-body p-0">

                <div class="card-body-header-lg p-2 d-flex justify-content-between shadow-sm">

                  <div class="input-group mb-0">
                    <input type="text" class="form-control" placeholder="Search" aria-label="Recipient's Name" aria-describedby="button-addon2" v-model="search">
                    <button @click="searchMember"  class="btn btn-outline-secondary btn-sm" type="button" id="button-addon2"><i class="bi bi-search"></i></button>
                  </div>

                  <a v-if="parseInt(paymentgroup.payment_status) === 0 " class="btn btn-outline-primary btn-sm" @click="createMember" ><i class="bi bi-plus-circle-fill"></i> Add Member(s)</a>

                </div>

                <div class="card-body-subheader p-2 d-flex justify-content-between border-bottom">
                  <div class="font-weight-500 pt-1">

                    <button v-if="parseInt(paymentgroup.status) === 0" type="button" class="btn btn-primary btn-sm m-1" data-bs-toggle="modal" data-bs-target="#update-payment-group">
                      <i class="bi bi-pencil"></i> Edit
                    </button>

                    <button v-if="parseInt(paymentgroup.status) === 0" @click="deleteGroup(paymentgroup)" type="button" class="btn btn-danger btn-sm m-1">
                      <i class="bi bi-x"></i> Delete
                    </button>

                    <button v-if="parseInt(paymentgroup.status) === -2" type="button" class="btn btn-warning btn-sm m-1">
                      <i class="bi bi-recycle"></i> Resend
                    </button>

                    <button type="button" class="btn btn-secondary btn-sm m-1">
                      <i class="bi bi-download"></i> Export
                    </button>

                    <button v-if="parseInt(paymentgroup.payment_status) === 0 " type="button" class="btn bg-green btn-sm" @click="makePayment(paymentgroup)">
                      <i class="bi bi-check-lg"></i> Initiate Payment
                    </button>

                  </div>
                  <span class="text-success"> Total Amount: <span class="font-weight-500 text-success">KES. <span v-text="formatStake(paymentgroup.amount)"></span> </span></span>
                </div>

                <vuetable
                    ref="vuetable"
                    api-url="request/table"
                    :api-mode="apiMode"
                    :fields="fields"
                    :sort-order="sortOrder"
                    :css="css.table"
                    pagination-path=""
                    :per-page="perPage"
                    :append-params="moreParams"
                    :http-fetch="fetchMembers"
                    :load-on-start="loadOnStart"
                    @vuetable:row-clicked="onRowClicked"
                    @vuetable:pagination-data="onPaginationData"
                    @vuetable:loading="onLoading"
                    @vuetable:loaded="onLoaded">

                  <div slot="actions-slot" slot-scope="props">

                    <div class="btn-group" v-if="parseInt(paymentgroup.status) === 0 ">

                      <button type="button" class="btn btn-primary btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        More
                      </button>

                      <ul class="dropdown-menu">

                        <li>
                          <a class="dropdown-item" @click="deleteMember(props.rowData)">Remove Member</a>
                        </li>
                        <li>
                          <a class="dropdown-item" @click="changeAmount(props.rowData)">Update Amount</a>
                        </li>

                        <li>
                          <a class="dropdown-item" @click="editMember(props.rowData)" data-bs-toggle="modal" data-bs-target="#update-member-details">Update Details</a>
                        </li>

                      </ul>

                    </div>
                    <div class="btn-group" v-if="parseInt(paymentgroup.status) === -2 ">

                      <button type="button" class="btn btn-primary btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        More
                      </button>

                      <ul class="dropdown-menu">

                        <li>
                          <a class="dropdown-item" @click="deleteMember(props.rowData)">Remove Member</a>
                        </li>

                        <li>
                          <a class="dropdown-item">Send Money</a>
                        </li>

                        <li>
                          <a class="dropdown-item" @click="changeAmount(props.rowData)">Update Amount</a>
                        </li>

                        <li>
                          <a class="dropdown-item" @click="editMember(props.rowData)" data-bs-toggle="modal" data-bs-target="#update-member-details">Update Details</a>
                        </li>

                      </ul>

                    </div>

                  </div>

                  <div slot="sequence-slot" slot-scope="props">
                    {{ props.rowIndex + 1}}
                  </div>

                  <div slot="payment-method-slot" slot-scope="props">

                    <div v-if="parseInt(props.rowData.recipient_type) === 1">
                      <span>MPESA</span>
                    </div>

                    <div v-else-if="parseInt(props.rowData.recipient_type) === 2">
                      <span v-text="getBankName(props.rowData.paybill)"></span>
                    </div>

                    <div v-else-if="parseInt(props.rowData.recipient_type) === 2">
                      Paybil - <span v-text="props.rowData.paybill"></span>
                    </div>

                    <div v-else-if="parseInt(props.rowData.recipient_type) === 3">
                      Till - <span v-text="props.rowData.paybill"></span>
                    </div>

                  </div>

                  <div slot="status-slot" slot-scope="props">
                    <span v-html="getStatus(props.rowData.status)"></span>
                  </div>

                  <div slot="amount-slot" slot-scope="props">
                    {{ formatCurrency(props.rowData.amount) }} Ksh
                  </div>

                  <div slot="payment-reference-slot" slot-scope="props">

                    <div v-if="parseInt(props.rowData.status) === -1">
                      <button type="button" class="btn btn-warning btn-sm">
                        <i class="bi bi-recycle"></i> Resend
                      </button>
                    </div>

                    <div v-else>
                      <span v-show="parseInt(props.rowData.recipient_type) === 1">MPESA <span v-text="props.rowData.reference"></span></span>
                      <span v-show="parseInt(props.rowData.recipient_type) === 2">BANK <span v-text="props.rowData.reference"></span></span>
                    </div>

                  </div>


                </vuetable>

                <vuetable-pagination
                    ref="pagination"
                    :css="css.pagination"
                    @vuetable-pagination:change-page="onChangePage">
                </vuetable-pagination>

              </div>
            </div>

          </div>

        </div>

      </div>

      <div class="modal fade" id="create-payment-group" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Create Payment Group</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

            <div class="modal-body" v-bind:class="loading">
              <form class="custom-form">

                <div class="input-wrapper mb-2">

                  <div class="mb-3">
                    <label for="group-name" class="form-label">Payment Group Name</label>
                    <input type="text" class="form-control" id="group-name" v-model="name">
                  </div>

                  <div class="mb-3">
                    <label for="project-name" class="form-label">Description</label>
                    <input type="text" class="form-control" id="project-name">
                  </div>
                </div>


                <div class="modal-footer-copy">
                  <button type="button" class="btn btn-primary btn-sm shadow mr-2" @click="createPaymentGroup">Create</button>
                  <button type="button" class="btn btn-outline-secondary btn-sm" id="close-create-payment-group" data-bs-dismiss="modal">Cancel</button>
                </div>

              </form>
            </div>

          </div>
        </div>
      </div>

      <div class="modal fade" id="update-member-details" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered">
          <div class="modal-content">

            <div class="modal-header">
              <h5 class="modal-title">Update <span v-text="member.recipient_name"></span> Details</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

            <div class="modal-body" v-bind:class="loading">

              <form class="custom-form">

                <div class="input-wrapper mb-2">

                <div class="font-weight-500">
                  Personal Details
                </div>

                <div class="row">

                  <div class="col-md-3">
                    <div class="mb-3">
                      <input type="text" class="form-control" placeholder="First Name" v-model="member.first_name">
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="mb-3">
                      <input type="text" class="form-control" placeholder="Middle Name" v-model="member.middle_name">
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="mb-3">
                      <input type="text" class="form-control" placeholder="Last Name" v-model="member.last_name">
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="mb-3">
                      <input type="text" class="form-control" placeholder=" Id Number" v-model="member.id_number">
                    </div>
                  </div>

                </div>

                <div class="font-weight-500">
                  Payment Details
                </div>

                <div class="row">

                  <div class="col-md-3">
                    <div class="mb-3">
                      <label>Select Payment Method</label>
                      <select class="form-select form-select-sm" aria-label=".form-select-sm example" v-model="member.recipient_type">
                        <option value="1">M-PESA</option>
                        <option value="2">BANK</option>
                        <option value="3">Paybill Number</option>
                        <option value="4">Till Number</option>
                      </select>
                    </div>
                  </div>

                  <div v-show="parseInt(member.recipient_type) === 1" class="col-md-3">
                    <div class="mb-3">
                      <label>M-PESA Number</label>
                      <input type="text" class="form-control" placeholder="M-Pesa No" v-model="member.account">
                    </div>
                  </div>

                  <div v-show="parseInt(member.recipient_type) === 3" class="col-md-3">
                    <div class="mb-3">
                      <label>PayBill Number</label>
                      <input type="text" class="form-control" placeholder="PayBill No" v-model="member.paybill">
                    </div>
                  </div>

                  <div v-show="parseInt(member.recipient_type) === 4" class="col-md-3">
                    <div class="mb-3">
                      <label>Till Number</label>
                      <input type="text" class="form-control" placeholder="Till No" v-model="member.paybill">
                    </div>
                  </div>

                  <div v-show="parseInt(member.recipient_type) === 2" class="col-md-3">
                    <div class="mb-3">
                      <label>Select Bank Name</label>
                      <select class="form-select form-select-sm" aria-label=".form-select-sm example" v-model="member.paybill">
                        <option v-for="(b,i) in banks" v-bind:key="getKey(i)" v-bind:value="b.bank_code" v-text="b.bank_name"></option>
                      </select>
                    </div>
                  </div>

                  <div v-show="parseInt(member.recipient_type) > 1" class="col-md-3">
                    <div class="mb-3">
                      <label>Account Number</label>
                      <input type="text" class="form-control" placeholder="Account Number" v-model="member.account">
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="mb-3">
                      <label>Amount</label>
                      <input type="number" min="100" max="70000" class="form-control" placeholder="Enter any amount from 100" v-model="member.amount">
                    </div>
                  </div>
                </div>

                <div class="modal-footer-copy">
                  <button type="button" class="btn btn-primary btn-sm shadow mr-2" @click="updateMember">Update Details</button>
                  <button type="button" class="btn btn-outline-secondary btn-sm" id="close-update-member-details" data-bs-dismiss="modal">Cancel</button>
                </div>

                </div>

              </form>

            </div>

          </div>
        </div>
      </div>

      <div class="modal fade" id="update-payment-group" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" >Update Group</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

            <div class="modal-body" v-bind:class="loading">
              <form class="custom-form">

                <div class="input-wrapper mb-2">

                  <div class="mb-3">
                    <label for="group-name" class="form-label">Group Name</label>
                    <input type="text" class="form-control" v-model="paymentgroup.name">
                  </div>

                </div>


                <div class="modal-footer-copy">
                  <button type="button" class="btn btn-primary btn-sm shadow mr-2" @click="updatePaymentGroup">Update</button>
                  <button type="button" class="btn btn-outline-secondary btn-sm" id="close-update-payment-group" data-bs-dismiss="modal">Cancel</button>
                </div>

              </form>
            </div>

          </div>
        </div>
      </div>

    </div>

</template>

<script>

import Vue from 'vue'
import axios from "@/services/api";
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldSequence from "vuetable-2/src/components/VuetableFieldSequence";
Vue.use(Vuetable);

var moreParams = {};
moreParams.route = 'payment/table';

var activeGroupfields = [
      {
        name: VuetableFieldSequence,
        title: '#',
      },
      {
        name: 'recipient_name',
        title: '<span class="orange glyphicon glyphicon-credit-card"></span> Name',
        sortField: 'payment_group_member.recipient_name',
      },
      {
        name: 'id_number',
        title: '<span class="orange glyphicon glyphicon-bookmark"></span> ID No.',
        sortField: 'payment_group_member.id_number'
      },
      {
        name: "payment-method-slot",
        title: 'Method',
        sortField: 'payment_group_member.recipient_type',
      },
      {
        name: 'account',
        title: 'Acc No.',
        sortField: 'payment_group_member.account'
      },
      {
        name: 'amount-slot',
        title: 'Amount',
        sortField: 'payment_group_member.amount',
      },
      {
        name: "status-slot",
        title: 'Status',
        sortField: 'payment_group_member.status',
      },
      {
        name: "actions-slot",
        title: 'Actions',
        titleClass: "center aligned",
        dataClass: "text-right"
      }
    ];

var pendingApprovalGroupfields = [
  {
    name: VuetableFieldSequence,
    title: '#',
  },
  {
    name: 'recipient_name',
    title: '<span class="orange glyphicon glyphicon-credit-card"></span> Name',
    sortField: 'payment_group_member.recipient_name',
  },
  {
    name: 'id_number',
    title: '<span class="orange glyphicon glyphicon-bookmark"></span> ID No.',
    sortField: 'payment_group_member.id_number'
  },
  {
    name: "payment-method-slot",
    title: 'Method',
    sortField: 'payment_group_member.recipient_type',
  },
  {
    name: 'account',
    title: 'Acc No.',
    sortField: 'payment_group_member.account'
  },
  {
    name: 'amount-slot',
    title: 'Amount',
    sortField: 'payment_group_member.amount',
  },
  {
    name: "status-slot",
    title: 'Status',
    sortField: 'payment_group_member.status',
  }
];

var paymentGroupfields = [
  {
    name: VuetableFieldSequence,
    title: '#',
  },
  {
    name: 'recipient_name',
    title: '<span class="orange glyphicon glyphicon-credit-card"></span> Name',
    sortField: 'payment_group_member.recipient_name',
  },
  {
    name: 'id_number',
    title: '<span class="orange glyphicon glyphicon-bookmark"></span> ID No.',
    sortField: 'payment_group_member.id_number'
  },
  {
    name: 'account',
    title: 'Acc No.',
    sortField: 'payment_group_member.account'
  },
  {
    name: 'amount-slot',
    title: 'Amount',
    sortField: 'payment_group_member.amount',
  },
  {
    name: "status-slot",
    title: 'Status',
    sortField: 'payment_group_member.status',
  },
  {
    name: "payment-reference-slot",
    title: 'Reference',
  }
];

  export default {
  name: 'paymentgroup',
  components: {
    Vuetable,
    'vuetable-pagination': VuetablePagination
  },
  data: function (){
    return {
      row_index: 0,
      active_tab: 'account',
      paymentgroups: [],
      paymentgroup: {},
      member: {
        account: '',
        id_number: 0,
        payment_type: 1,
        first_name: '',
        middle_name: '',
        last_name: '',
        amount: 0,
        paybill: 0,
      },

      // vuetable
      search: '',
      fields: [
        {
          name: 'sequence-slot',
          title: '#',
        },
        {
          name: 'recipient_name',
          title: '<span class="orange glyphicon glyphicon-credit-card"></span> Name',
          sortField: 'payment_group_member.recipient_name',
        },
        {
          name: 'id_number',
          title: '<span class="orange glyphicon glyphicon-bookmark"></span> ID No.',
          sortField: 'payment_group_member.id_number'
        },
        {
          name: "payment-method-slot",
          title: 'Method',
          sortField: 'payment_group_member.recipient_type',
        },
        {
          name: 'account',
          title: 'Acc No.',
          sortField: 'payment_group_member.account'
        },
        {
          name: 'amount-slot',
          title: 'Amount',
          sortField: 'payment_group_member.amount',
        },
        {
          name: "status-slot",
          title: 'Status',
          sortField: 'payment_group_member.status',
        },
        {
          name: "actions-slot",
          title: 'Actions',
          titleClass: "center aligned",
          dataClass: "text-right"
        }
      ],
      moreParams: moreParams,
      css: {
        table: {
          tableClass: 'table table-striped table-bordered table-hovered',
          loadingClass: 'loading',
          ascendingIcon: 'glyphicon glyphicon-chevron-up',
          descendingIcon: 'glyphicon glyphicon-chevron-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger',
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination pull-right',
          activeClass: 'bg-green',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: '',
          },
        }
      },
      sortOrder: [
        { field: 'payment_group_member.id', direction: 'desc' }
      ],
      perPage: parseInt(5),
      loadOnStart: false,
      apiMode: true,
      name: '',
      loading: '',
      banks: [
        {
          "bank_name": "Absa Bank",
          "bank_code": 101
        },
        {
          "bank_name": "Co-operative Bank",
          "bank_code": 102
        },
        {
          "bank_name": "Equity Bank(K)",
          "bank_code": 103
        },
        {
          "bank_name": "Family Bank",
          "bank_code": 104
        },
        {
          "bank_name": "KCB Bank Kenya (K)",
          "bank_code": 105
        },
        {
          "bank_name": "Standard Chartered Bank (K)",
          "bank_code": 106
        },
        {
          "bank_name": "ABC Bank",
          "bank_code": 107
        },
        {
          "bank_name": "I & M Bank (K)",
          "bank_code": 108
        },
        {
          "bank_name": "HF Group",
          "bank_code": 109
        },
        {
          "bank_name": "Ecobank (K)",
          "bank_code": 110
        },
        {
          "bank_name": "Habib Bank A.G. Zurich",
          "bank_code": 111
        },
        {
          "bank_name": "NCBA Bank(K)",
          "bank_code": 112
        },
        {
          "bank_name": "Stanbic Bank",
          "bank_code": 113
        },
        {
          "bank_name": "Sidian Bank",
          "bank_code": 114
        },
        {
          "bank_name": "Rafiki Microfinance Bank",
          "bank_code": 115
        },
        {
          "bank_name": "Spire Bank",
          "bank_code": 116
        },
        {
          "bank_name": "First Community Bank",
          "bank_code": 117
        },
        {
          "bank_name": "Access Bank (K)",
          "bank_code": 118
        },
        {
          "bank_name": "Diamond Trust Bank (K)",
          "bank_code": 119
        },
        {
          "bank_name": "DIB Kenya Bank",
          "bank_code": 120
        },
        {
          "bank_name": "Development Bank",
          "bank_code": 121
        },
        {
          "bank_name": "Citibank N.A.",
          "bank_code": 122
        },
        {
          "bank_name": "Bank of Baroda (K)",
          "bank_code": 123
        },
        {
          "bank_name": "Bank of Africa (K)",
          "bank_code": 124
        },
        {
          "bank_name": "Faulu Micro-Finance Bank",
          "bank_code": 125
        },
        {
          "bank_name": "Credit Bank",
          "bank_code": 126
        },
        {
          "bank_name": "Choice Microfinance Bank",
          "bank_code": 127
        },
        {
          "bank_name": "Consolidated Bank",
          "bank_code": 128
        },
        {
          "bank_name": "Caritas Microfinance Bank",
          "bank_code": 129
        },
        {
          "bank_name": "Kenya Women Microfinance Bank",
          "bank_code": 130
        },
        {
          "bank_name": "Mayfair Bank",
          "bank_code": 131
        },
        {
          "bank_name": "Kingdom Bank",
          "bank_code": 132
        },
        {
          "bank_name": "National Bank of Kenya",
          "bank_code": 133
        },
        {
          "bank_name": "Middle East Bank (K)",
          "bank_code": 134
        },
        {
          "bank_name": "Paramount Universal Bank",
          "bank_code": 135
        },
        {
          "bank_name": "Prime Bank",
          "bank_code": 136
        },
        {
          "bank_name": "Postbank",
          "bank_code": 137
        },
        {
          "bank_name": "SBM Bank (K)",
          "bank_code": 138
        },
        {
          "bank_name": "Victoria Commercial bank",
          "bank_code": 139
        },
        {
          "bank_name": "UBA Kenya Bank",
          "bank_code": 140
        },
        {
          "bank_name": "Salaam Microfinance Bank",
          "bank_code": 141
        },
        {
          "bank_name": "M Oriental Bank (K)",
          "bank_code": 142
        },
        {
          "bank_name": "Gulf African Bank",
          "bank_code": 143
        },
        {
          "bank_name": "Guaranty Trust Bank (K)",
          "bank_code": 144
        },
        {
          "bank_name": "Guardian Bank",
          "bank_code": 145
        },
        {
          "bank_name": "Bank of India(K)",
          "bank_code": 146
        }
      ]

    }
  },
  mounted: function() {

    this.$store.dispatch("setCurrentPage","groups");
    this.$store.dispatch("setCurrentPageName","Payroll");
    this.$store.dispatch("setPreviousPage","dashbord");
    this.getPaymentGroup();


  },
  computed: {

    active_payroll: function (){

      var count = 0;

      this.jQuery.each(this.paymentgroups,function (k,v){

        if(parseInt(v.payment_status) === 0 ) {

          count++
        }
      })

      return count;

    },

    pending_payroll: function (){

      var count = 0;

      this.jQuery.each(this.paymentgroups,function (k,v){

        if(parseInt(v.payment_status) === 1 ) {

          count++
        }
      })

      return count;

    },

    pending_payment: function (){

      var count = 0;

      this.jQuery.each(this.paymentgroups,function (k,v){

        if(parseInt(v.payment_status) === 2 ) {

          count++
        }
      })

      return count;

    },

    close_payroll: function (){

      var count = 0;

      this.jQuery.each(this.paymentgroups,function (k,v){

        if(parseInt(v.payment_status) > 2 ) {

          count++
        }
      })

      return count;

    },

    activegroups: function (){

      var count = [];

      this.jQuery.each(this.paymentgroups,function (k,v){

        if(parseInt(v.payment_status) === 0 ) {

          count.push(v)
        }
      })

      return count;

    },

    pendinggroups: function (){

      var count = [];

      this.jQuery.each(this.paymentgroups,function (k,v){

        if(parseInt(v.payment_status) === 1 ) {

          count.push(v)
        }
      })

      return count;

    },

    pendingpaymentgroups: function (){

      var count = [];

      this.jQuery.each(this.paymentgroups,function (k,v){

        if(parseInt(v.payment_status) === 2 ) {

          count.push(v)
        }
      })

      return count;

    },

    closedgroups: function (){

      var count = [];

      this.jQuery.each(this.paymentgroups,function (k,v){

        if(parseInt(v.payment_status) > 2 ) {

          count.push(v)
        }
      })

      return count;

    },

  },
  methods: {

    getBankName: function(bankCode){

      var bankName = "";

      this.jQuery.each(this.banks,function (k,v){

        if(parseInt(v.bank_code) === parseInt(bankCode)) {

          bankName = v.bank_name;

        }

      })

      return bankName;
    },
    getGroupStatus: function (status){

      if(parseInt(status) === 0) {

        return '<span class="fw-bold text-secondary">Pending</span>'
      }

      if(parseInt(status) === 1) {

        return '<span class="fw-bold text-secondary">Pending Approval</span>'
      }

      if(parseInt(status) === 2) {

        return '<span class="fw-bold text-warning">Payment Initiated</span>'
      }

      if(parseInt(status) === -1) {

        return '<span class="fw-bold text-danger">Payment Failed</span>'
      }

      if(parseInt(status) === -2) {

        return '<span class="fw-bold text-danger">Insufficient Balance</span>'

      }

      return '<span class="fw-bold text-success">Payment Completed</span>'

    },

    getStatus: function (status){

      if(parseInt(status) === 0) {

        return '<span class="fw-bold text-secondary">Waiting Approval</span>'
      }

      if(parseInt(status) === 1) {

        return '<span class="fw-bold text-warning">Payment Initiated</span>'
      }

      if(parseInt(status) === -1) {

        return '<span class="fw-bold text-danger">Payment Failed</span>'

      }

      return '<span class="fw-bold text-success">Payment Successful</span>'

    },

    searchMember: function (){

      if(this.search.length < 2 ) {

        this.setError("invalid fields","please type atleast 3 characters to search")
        return
      }

      this.moreParams.search = this.search;
      this.$refs.vuetable.refresh();

    },

    reload: function() {

      this.moreParams.search = '';
      this.$refs.vuetable.refresh();
    },

    onPaginationData: function(paginationData) {

      this.$refs.pagination.setPaginationData(paginationData)
    },

    onChangePage: function(page) {

      this.$refs.vuetable.changePage(page)

    },

    onRowClicked: function (rowData){

      console.log('event onRowClicked ==> '+JSON.stringify(rowData))

    },

    delete: function(rowData) {

      console.log('TO delete '+JSON.stringify(rowData))

    },

    editRow: function(rowData){

      alert("You clicked edit on"+ JSON.stringify(rowData))
    },

    deleteRow: function(rowData){

      alert("You clicked delete on"+ JSON.stringify(rowData))

    },

    onLoading: function() {

      console.log('loading... show your spinner here')

    },

    onLoaded: function() {

      this.loading = '';
      console.log('loaded! .. hide your spinner here')

    },

    fetchMembers: function(apiUrl, httpOptions){

      var vm = this;
      var endpoint = process.env.VUE_APP_URL_PAYMENT_GROUP_MEMBERS;
      var path = endpoint.replace(":id",parseInt(this.paymentgroup.id));
      vm.loading = 'loading';

      var auth = vm.getAuth();
      console.log('got auth '+auth);

      return axios.post(path, JSON.stringify(httpOptions.params), {
        headers: {
          'api-key': auth
        },
      })
    },

    getPaymentMethod: function (paymentMethod){

      if(parseInt(paymentMethod) === 1 ) {

        return 'MPESA';

      }
      return  'BANK';
    },

    formatAmount: function (amount){

      return this.formatStake(amount);

    },

    setPaymentGroup: function(group,index){

      this.paymentgroup = group;
      this.row_index = index;

      if(parseInt(this.paymentgroup.status) === 0) {

        this.fields = activeGroupfields

      }
      else if(parseInt(this.paymentgroup.status) === 1) {

        this.fields = pendingApprovalGroupfields

      }
      else if(parseInt(this.paymentgroup.status) === 2) {

        this.fields = paymentGroupfields

      }
      else if(parseInt(this.paymentgroup.status) === 3) {

        this.fields = paymentGroupfields

      }
      else if(parseInt(this.paymentgroup.status) === 4) {

        this.fields = paymentGroupfields

      }

      this.reload();

    },

    isCurrentRow: function(index) {

      return parseInt(index) === this.row_index ? 'bg-green text-light' : '';

    },

    getPaymentGroup: function(){

      var vm = this;

      var path = process.env.VUE_APP_URL_PAYMENT_GROUP_LIST;

      var auth = vm.getAuth();
      console.log('got auth '+auth);

      axios.post(path, JSON.stringify({}),{
        headers: {
          'api-key': auth
        },
      })
          .then(res => {

            var msg = res.data.data;
            vm.paymentgroups = msg;
          })
          .catch(err => {

            vm.loading = '';

            console.log('FAILED response '+JSON.stringify(err.response.data.data))

            if (err.response) {

              vm.setError("Failed",err.response.data.data)

            }
            else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")

            }
            else {

              vm.setError("Network Error", "Check your network connection and try again")

            }

          })

    },

    createPaymentGroup: function (){

      if (!this.name) {

        this.setError("Invalid name", "Please enter a valid name");
        return;
      }



      var vm = this;

      var auth = vm.getAuth();
      console.log('got auth '+auth);

      var path = "/client/paymentgroup/create";
      this.loading = 'loading';

      axios.post(path, JSON.stringify({name: vm.name}),{
        headers: {
          'api-key': auth
        },
      })
          .then(res => {

            vm.loading = '';
            vm.closeModal('create-payment-group');
            var msg = res.data.data.message;
            vm.setSuccess("Success", msg);
            vm.getPaymentGroup();

          })
          .catch(err => {

            vm.loading = '';

            console.log('FAILED response '+JSON.stringify(err))

            if (err.response) {
              vm.setError("Failed",err.response.data.data)
            }
            else if (err.request) {
              vm.setError("Network Error", "Check your network connection and try again")
            }
            else {
              vm.setError("Network Error", "Check your network connection and try again")
            }
          })

    },

    createMember: function (){

      this.$router.push({ name: 'creategroup', params: {id: this.paymentgroup.id, name:this.paymentgroup.name} });

    },

    deleteMember: function(data) {

      var vm = this;

      vm.$swal.fire({
        title: "Do you want to delete. "+data.recipient_name+' from the list?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete'

      }).then((result) => {

        if (result.isConfirmed) {

          var path = process.env.VUE_APP_URL_PAYMENT_GROUP_MEMBER_DELETE.replace(":id", data.id);
          vm.loading = 'loading';

          axios.post(path, JSON.stringify({}),{
            headers: {
              'api-key': vm.getAuth()
            },
          })
              .then(res => {

                vm.loading = '';
                vm.reload();

                var msg = res.data.data.message;

                vm.$swal.fire(
                    'Submitted!',
                    msg,
                    'success'
                )

              })
              .catch(err => {

                vm.loading = '';

                vm.$swal.fire(
                    'Failed!',
                    err.response.data.data,
                    'error'
                )

                if (err.response) {

                  var message = "";

                  if(parseInt(err.response.status) === 428 ) {

                    message = err.response.data.message;
                    vm.setError("Failed", message)
                    return;
                  }

                  if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                    vm.setError("Session Expired", "Your session on this device has expired");
                    vm.logout();
                    return;

                  } else {

                    message =  err.response.data.message;
                    vm.setError("Failed", message)

                  }

                } else if (err.request) {

                  vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err.request));

                } else {

                  //vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err));

                }
              })
        } else {

          vm.cashoutLoading = '';

        }

      })

    },

    changeAmount: async function(data){

      var vm = this;

      const { value: amount } = await vm.$swal.fire({
        title: 'Enter new amount for '+data.recipient_name,
        input: 'number',
        inputLabel: 'Amount',
        inputPlaceholder: 'Enter new amount',
        inputAttributes: {
          min: 100,
          max: 70000
        }
      })

      if (amount) {

        var path = process.env.VUE_APP_URL_PAYMENT_GROUP_MEMBER_UPDATE_AMOUNT.replace(":id", data.id);
        vm.loading = 'loading';

        axios.post(path, JSON.stringify({amount: parseInt(amount)}),{
          headers: {
            'api-key': vm.getAuth()
          },
        })
            .then(res => {

              vm.loading = '';
              vm.reload();

              var msg = res.data.data.message;

              vm.$swal.fire(
                  'Submitted!',
                  msg,
                  'success'
              )

            })
            .catch(err => {

              vm.loading = '';

              vm.$swal.fire(
                  'Failed!',
                  err.response.data.data,
                  'error'
              )

              if (err.response) {

                var message = "";

                if(parseInt(err.response.status) === 428 ) {

                  message = err.response.data.message;
                  vm.setError("Failed", message)
                  return;
                }

                if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                  vm.setError("Session Expired", "Your session on this device has expired");
                  vm.logout();
                  return;

                } else {

                  message =  err.response.data.message;
                  vm.setError("Failed", message)

                }

              } else if (err.request) {

                vm.setError("Failed", "Please check your network")
                console.log(JSON.stringify(err.request));

              } else {

                //vm.setError("Failed", "Please check your network")
                console.log(JSON.stringify(err));

              }
            })

      }

    },

    editMember: function (data) {

      this.member = data;

      var names = [];

      if(data.recipient_name){

        names = data.recipient_name.split(' ');

      }

      if(names.length > 0) {

        this.member.first_name = names[0];
      }

      if(names.length > 1) {

        this.member.middle_name = names[1];
      }

      if(names.length > 2) {

        this.member.last_name = names.slice(2).join(" ");
      }

    },

    updateMember: function (){

      if (!this.member.account) {

        this.setError("Invalid Account", "Please enter a account");
        return;
      }

      if (parseInt(this.member.amount) < 100 ) {

        this.setError("Invalid Amount", "Please enter a amount atleast 100");
        return;
      }

      if (!this.member.first_name) {

        this.setError("Invalid First Name", "Please enter First Name");
        return;
      }

      if (!this.member.middle_name) {

        this.setError("Invalid Middle Name", "Please enter Middle Name");
        return;
      }

      if (!this.member.last_name) {

        this.setError("Invalid Last Name", "Please enter Last Name");
        return;
      }

      if (parseInt(this.member.id_number) < 1000000 ) {

        this.setError("Invalid ID Number", "Please enter a valid ID Number");
        return;
      }

      var paybill = 3016903;

      if (parseInt(this.member.recipient_type) > 1 ) {

        paybill = this.member.paybill;

      }

      var vm = this;

      var data = {
        id_number: parseInt(this.member.id_number),
        payment_type: parseInt(this.member.recipient_type),
        first_name: this.member.first_name,
        middle_name: this.member.middle_name,
        last_name: this.member.last_name,
        amount: parseInt(this.member.amount),
        paybill: parseInt(paybill),
        account_number: this.member.account
      };

      var path = process.env.VUE_APP_URL_PAYMENT_GROUP_MEMBER_UPDATE.replace(":id", this.member.id);
      vm.loading = 'loading';

      axios.post(path, JSON.stringify(data),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            vm.reload();
            vm.closeModal('update-member-details');

            var msg = res.data.data.message;

            vm.$swal.fire(
                'Submitted!',
                msg,
                'success'
            )

          })
          .catch(err => {

            vm.loading = '';

            vm.$swal.fire(
                'Failed!',
                err.response.data.data,
                'error'
            )

            if (err.response) {

              var message = "";

              if(parseInt(err.response.status) === 428 ) {

                message = err.response.data.message;
                vm.setError("Failed", message)
                return;
              }

              if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();
                return;

              } else {

                message =  err.response.data.message;
                vm.setError("Failed", message)

              }

            } else if (err.request) {

              vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err.request));

            } else {

              //vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err));

            }
          })
    },

    deleteGroup: function(data) {

      var vm = this;

      vm.$swal.fire({
        title: "Do you want to delete. "+data.name+'?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete'

      }).then((result) => {

        if (result.isConfirmed) {

          var path = process.env.VUE_APP_URL_PAYMENT_GROUP_DELETE.replace(":id", data.id);
          vm.loading = 'loading';

          axios.post(path, JSON.stringify({}),{
            headers: {
              'api-key': vm.getAuth()
            },
          })
              .then(res => {

                vm.loading = '';
                vm.getPaymentGroup();

                var msg = res.data.data.message;

                vm.$swal.fire(
                    'Submitted!',
                    msg,
                    'success'
                )

              })
              .catch(err => {

                vm.loading = '';

                vm.$swal.fire(
                    'Failed!',
                    err.response.data.data,
                    'error'
                )

                if (err.response) {

                  var message = "";

                  if(parseInt(err.response.status) === 428 ) {

                    message = err.response.data.message;
                    vm.setError("Failed", message)
                    return;
                  }

                  if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                    vm.setError("Session Expired", "Your session on this device has expired");
                    vm.logout();
                    return;

                  } else {

                    message =  err.response.data.message;
                    vm.setError("Failed", message)

                  }

                } else if (err.request) {

                  vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err.request));

                } else {

                  //vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err));

                }
              })
        } else {

          vm.cashoutLoading = '';

        }

      })

    },

    updatePaymentGroup: function (){

      if (!this.paymentgroup.name) {

        this.setError("Invalid name", "Please enter a valid name");
        return;
      }

      var vm = this;

      var path = process.env.VUE_APP_URL_PAYMENT_GROUP_UPDATE.replace(":id", this.paymentgroup.id);
      vm.loading = 'loading';

      axios.post(path, JSON.stringify(this.paymentgroup),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            vm.closeModal('update-payment-group');
            var msg = res.data.data.message;
            vm.$swal.fire(
                'Submitted!',
                msg,
                'success'
            )

            vm.getPaymentGroup();

          })
          .catch(err => {

            vm.loading = '';

            vm.$swal.fire(
                'Failed!',
                err.response.data.data,
                'error'
            )

            if (err.response) {

              var message = "";

              if(parseInt(err.response.status) === 428 ) {

                message = err.response.data.message;
                vm.setError("Failed", message)
                return;
              }

              if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();
                return;

              } else {

                message =  err.response.data.message;
                vm.setError("Failed", message)

              }

            } else if (err.request) {

              vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err.request));

            } else {

              //vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err));

            }

          })
    },

    makePayment: function(data) {

      var vm = this;

      vm.$swal.fire({
        title: 'Confirm',
        text: "Do you want to initiate payment for "+data.name+' a total of '+vm.formatCurrency(data.amount)+' Ksh?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Initiate Payment'

      }).then((result) => {

        if (result.isConfirmed) {

          var path = process.env.VUE_APP_URL_PAYMENT_GROUP_APPROVAL_INITIATE.replace(":id",data.id);
          vm.loading = 'loading';

          axios.post(path, JSON.stringify({}),{
            headers: {
              'api-key': vm.getAuth()
            },
          })
              .then(res => {

                vm.loading = '';
                vm.reload();

                var msg = res.data.data.message;

                vm.$swal.fire(
                    'Submitted!',
                    msg,
                    'success'
                )

              })
              .catch(err => {

                vm.loading = '';

                vm.$swal.fire(
                    'Failed!',
                    err.response.data.data,
                    'error'
                )

                if (err.response) {

                  var message = "";

                  if(parseInt(err.response.status) === 428 ) {

                    message = err.response.data.message;
                    vm.setError("Failed", message)
                    return;
                  }

                  if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                    vm.setError("Session Expired", "Your session on this device has expired");
                    vm.logout();
                    return;

                  } else {

                    message =  err.response.data.message;
                    vm.setError("Failed", message)

                  }

                } else if (err.request) {

                  vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err.request));

                } else {

                  //vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err));

                }
              })
        } else {

          vm.cashoutLoading = '';

        }

      })

    },

  },

}

</script>