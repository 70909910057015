<template>

  <div class="login-page">

    <img class="mb-1 mt-4" src="/img/logo.png?x" alt="Logo" height="50">
    <div class="registration-prompt">
      <span>Dont have an account yet? <router-link to="/join">Register</router-link>  </span>
    </div>

    <main class="form-signin card" v-bind:class="loading">

          <div v-show="isLogin" class="card-body mr-2 ml-2 ">

            <h1 class="h3 mb-3 fw-normal float-start">Login in</h1>

            <hr class="border" style="margin: 35px 0px 35px"/>

            <form>

              <div v-show="error_message.length > 0" class="alert alert-danger alert-dismissible fade show" role="alert" style="font-size: 0.9em;text-align: left;">
                <span v-text="error_message"></span>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>

              <div v-show="success_message.length > 0" class="alert alert-success alert-dismissible fade show" role="alert" style="font-size: 0.9em;text-align: left;">
                <span v-text="success_message"></span>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>

              <div class="form-floating mb-3">
                <input v-model="email" type="email" class="form-control form-control-lg" id="email1" aria-describedby="emailHelp" placeholder="Input your email address">
                <label for="email1" class="form-label">Email address</label>
              </div>

              <div class="form-floating mb-3">
                <input v-model="password" type="password" class="form-control form-control-lg" id="exampleInputPassword1" placeholder="Input your password">
                <label for="exampleInputPassword1" class="form-label">Password</label>
              </div>

              <div class="w-100 btn btn-primary" @click="signin">Submit</div>

              <div class="mb-3 form-check float-start">
                <div class="registration-prompt">
                  <span>Forgot Password? <a @click="showReset">Click Here to Reset</a>  </span>
                </div>
              </div>

            </form>

          </div>

          <div v-show="isForgot" class="card-body mr-2 ml-2 ">

            <h1 class="h3 mb-3 fw-normal float-start">Forgot Password</h1>

            <hr class="border" style="margin: 35px 0px 35px"/>

            <form>

              <div v-show="error_message.length > 0" class="alert alert-danger alert-dismissible fade show" role="alert" style="font-size: 0.9em;text-align: left;">
                <span v-text="error_message"></span>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>

              <div v-show="success_message.length > 0" class="alert alert-success alert-dismissible fade show" role="alert" style="font-size: 0.9em;text-align: left;">
                <span v-text="success_message"></span>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>

              <div class="form-floating mb-3">
                <input v-model="email" type="email" class="form-control form-control-lg" id="email2" aria-describedby="emailHelp" placeholder="Input your email address">
                <label for="email2" class="form-label">Email address</label>
              </div>

              <div class="w-100 btn btn-primary" @click="forgot">Submit</div>

            </form>

          </div>

          <div v-show="isVerification" class="card-body mr-2 ml-2 ">

            <h1 class="h3 mb-3 fw-normal float-start">Verification Code</h1>

            <hr class="border" style="margin: 35px 0px 35px"/>

            <form>

              <div v-show="error_message.length > 0" class="alert alert-danger alert-dismissible fade show" role="alert" style="font-size: 0.9em;text-align: left;">
                <span v-text="error_message"></span>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>

              <div v-show="success_message.length > 0" class="alert alert-success alert-dismissible fade show" role="alert" style="font-size: 0.9em;text-align: left;">
                <span v-text="success_message"></span>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>

              <div class="form-floating mb-3">
                <input v-model="code" type="number" class="form-control form-control-lg" id="verificationCode" placeholder="Enter Verification Code Sent to your phone">
                <label for="verificationCode" class="form-label">Verification Code</label>
              </div>

              <div class="w-100 btn btn-primary" @click="verify">Verify Account</div>

            </form>

          </div>

          <div v-show="isReset" class="card-body mr-2 ml-2 ">

            <h1 class="h3 mb-3 fw-normal float-start">Password Verification Code</h1>

            <hr class="border" style="margin: 35px 0px 35px"/>

            <form>

              <div v-show="error_message.length > 0" class="alert alert-danger alert-dismissible fade show" role="alert" style="font-size: 0.9em;text-align: left;">
                <span v-text="error_message"></span>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>

              <div v-show="success_message.length > 0" class="alert alert-success alert-dismissible fade show" role="alert" style="font-size: 0.9em;text-align: left;">
                <span v-text="success_message"></span>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>

              <div class="form-floating mb-3">
                <input v-model="code" type="number" class="form-control form-control-lg" id="verificationCode1" placeholder="Enter Verification Code Sent to your phone">
                <label for="verificationCode1" class="form-label">Verification Code</label>
              </div>

              <div class="form-floating mb-3">
                <input v-model="password" type="password" class="form-control form-control-lg" id="password1" placeholder="Input your password">
                <label for="password1" class="form-label">Password</label>
              </div>

              <div class="form-floating mb-3">
                <input v-model="password1" type="password" class="form-control form-control-lg" id="password2" placeholder="Confirm your password">
                <label for="password2" class="form-label">Repeat Password</label>
              </div>

              <div class="w-100 btn btn-primary" @click="reset">Change Password</div>

            </form>

          </div>

    </main>

    <div class="disclaimer">

      <span>We're committed to your privacy. WagePay uses the information you provide to us to contact you about our relevant content, products, and services. You may unsubscribe from these communications at any time. For more information, check out our <a href="#">Privacy policy</a> or  <a href="#">Contact Us</a>.</span>
    </div>

    </div>

</template>

<script>
import axios from "@/services/api";

export default {
  name: 'Login',
  components: {

  },
  data: function (){
    return {
      email: '',
      password:'',
      password1: '',
      loading: '',
      isLogin: true,
      isVerification: false,
      isReset: false,
      isForgot: false,
      message: '',
      code: '',
      success_message: '',
      error_message: ''
    }
  },
  mounted() {

    this.$store.dispatch("setCurrentPage","login");

  },
  computed: {

  },
  methods: {

    showReset: function(){

      this.isLogin = false;
      this.isReset = false;
      this.isForgot = true;
      this.isVerification = false;
    },

    signin: function () {

      this.error_message = '';
      this.success_message = '';

      if (!this.validEmail(this.email)) {

          this.error_message = "Please enter a valid email address";
          return;
      }

      if (this.password.length < 4) {

        this.error_message = "Please enter a password of more than 4 characters";
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_LOGIN;

      var currentDate = new Date();
      var login_tag = parseInt(currentDate.getTime()+''+Math.floor(Math.random() * (9 * (Math.pow(10, 2)))) + (Math.pow(10, 2)));
      //this.setValue("login_tag",login_tag);
      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var referrer = this.getValue("referrer");

      axios.post(path, JSON.stringify({
        username: this.email,
        password: this.password,
        login_tag: login_tag,
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: referrer
      }))
          .then(res => {

            vm.loading = '';
            var msg = res.data.data;
            vm.error_message = '';
            vm.success_message = msg;
            vm.isReset = false;
            vm.isLogin = false;
            vm.isVerification = true;
            vm.message = msg;

          })
          .catch(err => {

            vm.loading = '';

            if (err.response) {

              vm.error_message = err.response.data.data;

            }
            else if (err.request) {

              vm.error_message = "Check your network connection and try again";

            }
            else {

              vm.error_message = "Check your network connection and try again";

            }

          })

    },

    verify: function () {

      this.error_message = '';
      this.success_message = '';

      if (this.code.length < 4) {

        this.error_message =  "Please enter a verification code of more than 4 characters";
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_VERIFICATION_CODE;
      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var referrer = this.getValue("referrer");

      axios.post(path, JSON.stringify({
        username: this.email,
        code: parseInt(this.code),
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: referrer
      }))
          .then(res => {

            vm.loading = '';
            var profile = res.data.data;
            vm.success_message = "Login successful";
            vm.isLogin = true;
            vm.isVerification = false;
            vm.isReset = false;
            vm.setProfile(profile);
            vm.goTo('home')

          })
          .catch(err => {

            vm.loading = '';

            if (err.response) {

              vm.error_message = err.response.data.data;

            }
            else if (err.request) {

              vm.error_message = "Check your network connection and try again";

            }
            else {

              vm.error_message = "Check your network connection and try again";

            }

          })

    },

    forgot: function () {

      this.error_message = '';
      this.success_message = '';

      if (!this.validEmail(this.email)) {

        this.error_message = "Please enter a valid email address";
        return;
      }

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_FORGOT_PASSWORD;

      axios.post(path, JSON.stringify({
        email: this.email,
        code: parseInt(this.code)
      }))
          .then(res => {

            vm.loading = '';
            var msg = res.data.data;
            vm.success_message = msg;
            vm.isLogin = false;
            vm.isForgot = false;
            vm.isVerification = false;
            vm.isReset = true;

          })
          .catch(err => {

            vm.loading = '';

            if (err.response) {

              vm.error_message = err.response.data.data;

            }
            else if (err.request) {

              vm.error_message = "Check your network connection and try again";

            }
            else {

              vm.error_message = "Check your network connection and try again";

            }

          })

    },

    reset: function () {

      this.error_message = '';
      this.success_message = '';

      if (this.code.length < 4) {

        this.error_message =  "Please enter a verification code of more than 4 characters";
        return;

      }

      if (!this.validEmail(this.email)) {

        this.error_message = "Please enter a valid email address";
        return;
      }

      if (this.password.length < 0) {

        this.error_message = "Please enter a password of more than 4 characters";
        return;

      }

      if (this.password !== this.password1) {

        this.error_message = "Password does not match";
        return;
      }

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_RESET_PASSWORD;

      axios.post(path, JSON.stringify({
        email: this.email,
        password: this.password,
        code: parseInt(this.code),
      }))
          .then(res => {

            vm.loading = '';
            var msg = res.data.data;
            vm.success_message = msg;
            vm.password = '';
            vm.code = '';
            vm.isLogin = true;
            vm.isVerification = false;
            vm.isReset = false;
            vm.isForgot = false;

          })
          .catch(err => {

            vm.loading = '';

            if (err.response) {

              vm.error_message = err.response.data.data;

            }
            else if (err.request) {

              vm.error_message = "Check your network connection and try again";

            }
            else {

              vm.error_message = "Check your network connection and try again";

            }

          })

    },

  },
}

</script>