<template>

    <div class="">

      <div class="container-fluid" v-bind:class="loading">

        <div class="card custom-card-wrapper">

          <div class="card-header card-body-header-lg p-2 pb-0 d-flex justify-content-between shadow-sm">

            <div class=" d-flex justify-content-left ">
              <span class="font-weight-500 men-item underline-item mr-2 "><router-link to="/worksheets" class="">Worksheets</router-link></span>
              <span class="font-weight-500 men-item underline-item active mr-2">Mark Attendance <span v-text="name"></span></span>
            </div>

            <div class="input-group blue-input-group mb-2">

              <button class="btn blue-input-group-btn-left btn-outline-primary dropdown-toggle font-size-12" type="button" data-bs-toggle="dropdown" aria-expanded="false">Filter By Status</button>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">Action before</a></li>
                <li><a class="dropdown-item" href="#">Another action before</a></li>
                <li><a class="dropdown-item" href="#">Something else here</a></li>
                <li><hr class="dropdown-divider"></li>
                <li><a class="dropdown-item" href="#">Separated link</a></li>
              </ul>
              <input type="text" class="form-control" aria-label="Text input with 2 dropdown buttons">
              <button class="btn blue-input-group-btn-right btn-outline-primary  font-size-12" type="button" ><i class="bi bi-search"></i></button>
            </div>

          </div>

          <div class="card-body p-0">

            <div class="card-body-subheader p-2 d-flex dd-flex justify-content-between border-bottom">

              <div class="font-weight-500 pt-1">
                <div class="btn-toolbar mb-3" role="toolbar" aria-label="Toolbar with button groups">
                  <div class="input-group">
                    <div class="input-group-text primary-btn font-size-12" id="btnGroupAddon" v-text="formatDate(date)"></div>
                    <input id="date-picker" class="form-control" placeholder="Click to Pick a date" aria-label="Click to Pick a date" aria-describedby="btnGroupAddon">
                  </div>
                </div>

              </div>

              <div class="font-weight-500 pt-1">
                <div class="btn-group" role="group">
                  <button type="button" class="btn btn-primary btn-sm" @click="searchStatus(-1)">Show All</button>
                  <button type="button" class="btn bg-green btn-sm" @click="searchStatus(1)">Show Present Only</button>
                  <button type="button" class="btn btn-warning btn-sm" @click="searchStatus(2)">Show Absent Only</button>
                  <button type="button" class="btn btn-secondary btn-sm" @click="searchStatus(-2)">Show Unmarked Only</button>

                </div>
              </div>

            </div>

            <vuetable
                ref="vuetable"
                api-url="request/table"
                :api-mode="apiMode"
                :fields="fields"
                :sort-order="sortOrder"
                :css="css.table"
                pagination-path=""
                :per-page="perPage"
                :append-params="moreParams"
                :http-fetch="fetchMembers"
                :load-on-start="loadOnStart"
                @vuetable:pagination-data="onPaginationData"
                @vuetable:loading="onLoading"
                @vuetable:loaded="onLoaded">

              <div slot="sequence-slot" slot-scope="props">
                {{ props.rowIndex + 1}}
              </div>

              <div slot="date-slot" slot-scope="props">
                {{ formatDate(props.rowData.attendance_date) }}
              </div>

              <div slot="markattendance-slot" slot-scope="props">
                <markattendance v-bind:key="getKey(props.rowData.id)" v-bind:row-data="props.rowData" v-bind:row-index="props.rowIndex"></markattendance>
              </div>

            </vuetable>

            <vuetable-pagination
                ref="pagination"
                :css="css.pagination"
                @vuetable-pagination:change-page="onChangePage">
            </vuetable-pagination>

            <div class="modal-footer-copy p-2">
              <button type="button" class="btn btn-outline-secondary btn-sm mr-2" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn bg-green btn-sm shadow" @click="markRegisterAllPresent">Mark All Present</button>
              <button type="button" class="btn bg-danger btn-sm shadow" @click="markRegisterAllAbsent">Mark All Absent</button>
              <button type="button" class="btn btn-primary btn-sm shadow" @click="markRegister">Save</button>
            </div>

          </div>

        </div>

      </div>

    </div>

</template>

<script>

import Vue from 'vue'
import axios from "@/services/api";
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import markattendance from '@/components/worksheet/mark.vue'
import Lightpick from 'lightpick'
import moment from 'moment';

Vue.use(Vuetable);

var moreParams = {};
moreParams.route = 'payment/table';

export default {
  name: 'attendance',
  components: {
    Vuetable,
    'vuetable-pagination': VuetablePagination,
     markattendance
  },
  data: function (){
    return {
      mqttClient:'',
      active_tab: 'account',
      worksheet_id: {},
      attendance: [],
      date: '2022-04-25',
      reglist: [],

      // vuetable
      search: '',
      fields: [
        {
          name: 'sequence-slot',
          title: '#',
        },

        {
          name: 'recipient_name',
          title: '<span class="orange glyphicon glyphicon-credit-card"></span> Name',
          sortField: 'worksheet_member.recipient_name',
        },

        {
          name: 'id_number',
          title: '<span class="orange glyphicon glyphicon-bookmark"></span> ID No.',
          sortField: 'worksheet_member.id_number'
        },

        {
          name: "job_title",
          title: '<span>#</span> Title',
          sortField: 'worksheet_member.job_title_id',
        },

        {
          name: 'date-slot',
          title: 'Date',
        },

        {
          name: 'markattendance-slot',
          title: 'Attendance',
        }

      ],
      moreParams: moreParams,
      css: {
        table: {
          tableClass: 'table table-striped table-bordered table-hovered',
          loadingClass: 'loading',
          ascendingIcon: 'glyphicon glyphicon-chevron-up',
          descendingIcon: 'glyphicon glyphicon-chevron-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger',
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination pull-right',
          activeClass: 'bg-green',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: '',
          },
        }
      },
      sortOrder: [
        { field: 'worksheet_member.id', direction: 'desc' }
      ],
      perPage: parseInt(5),
      loadOnStart: false,
      apiMode: true,
      name: '',
      loading: ''
    }
  },
  mounted: function() {

    this.$store.dispatch("setCurrentPage","worksheet");
    var vm = this;
    vm.EventBus.$on('event:mark:attendance',function(updates){

      var obj = JSON.parse(updates);
      obj.date = vm.date;

      var exist = false;

      vm.jQuery.each(vm.attendance, function (k, v) {

        if (parseInt(v.id) === parseInt(obj.id)) {
          exist = true;
          vm.attendance[k] = obj;
        }
      });

      if(!exist) {

        vm.attendance.push(obj)
      }

    });

    var fm = 'YYYY-MM-DD';
    vm.date = moment().format(fm);
    vm.searchMember();

    new Lightpick({
      field: document.getElementById('date-picker'),
      singleDate: true,
      minDate: moment(vm.worksheet.start,fm),
      maxDate: moment(),
      onSelect: function(start){

        vm.date = start.format(fm);
        vm.searchMember();

      }
    });

  },
  computed: {
    worksheet: function () {

      return this.$store.state.worksheet;

    },
  },
  methods: {

    searchStatus: function(status){

      this.moreParams.search = this.search;
      this.moreParams.start_date = this.date;
      this.moreParams.status = status;
      this.$refs.vuetable.refresh();
    },

    searchMember: function (){

      this.moreParams.search = this.search;
      this.moreParams.start_date = this.date;
      this.$refs.vuetable.refresh();

    },

    reload: function() {

      this.$refs.vuetable.refresh();
    },

    onPaginationData: function(paginationData) {

      this.$refs.pagination.setPaginationData(paginationData)
    },

    onChangePage: function(page) {

      this.$refs.vuetable.changePage(page)

    },

    delete: function(rowData) {

      console.log('TO delete '+JSON.stringify(rowData))

    },

    editRow: function(rowData){

      alert("You clicked edit on"+ JSON.stringify(rowData))
    },

    deleteRow: function(rowData){

      alert("You clicked delete on"+ JSON.stringify(rowData))

    },

    onLoading: function() {

      this.loading = 'loading';
      console.log('loading... show your spinner here')

    },

    onLoaded: function() {

      this.loading = '';
      console.log('loaded! .. hide your spinner here')

    },

    fetchMembers: function(apiUrl, httpOptions){

      var vm = this;
      var endpoint = process.env.VUE_APP_URL_WORKSHEET_ATTENDANCE_VIEW_TABLE;
      var path = endpoint.replace(":id",parseInt(this.worksheet_id));

      return axios.post(path, JSON.stringify(httpOptions.params), {
        headers: {
          'api-key': vm.getAuth()
        },
      })
    },

    getPaymentMethod: function (paymentMethod){

      if(parseInt(paymentMethod) === 1 ) {

        return 'MPESA';

      }
      return  'BANK';
    },

    formatAmount: function (amount){

      return this.formatStake(amount);

    },

    setPaymentGroup: function(group){

      this.paymentgroup = group;
      this.reload();

    },

    getTableClass: function(index) {

      return index % 2 === 0 ? 'bg-green text-light' : '';

    },

    getPaymentGroup: function(){

      var vm = this;

      var path = process.env.VUE_APP_URL_PAYMENT_GROUP_LIST;

      axios.post(path, JSON.stringify({}),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            var msg = res.data.data;
            vm.paymentgroups = msg;
            vm.setPaymentGroup(vm.paymentgroups[0])
          })
          .catch(err => {

            vm.loading = '';

            console.log('FAILED response '+JSON.stringify(err.response.data.data))

            if (err.response) {

              vm.setError("Failed",err.response.data.data)

            }
            else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")

            }
            else {

              vm.setError("Network Error", "Check your network connection and try again")

            }

          })

    },

    showTab: function(tabName) {

      this.active_tab = tabName;
    },

    isActiveTab: function(tabName) {

      return this.active_tab === tabName;
    },

    getTabClass: function(tabName) {

      return this.active_tab === tabName ? 'active-tab' : '';
    },

    getTabTitleClass: function(tabName) {

      return this.active_tab === tabName ? 'active' : '';

    },

    createPaymentGroup: function (){

      if (!this.name) {
        this.setError("Invalid name", "Please enter a valid name");
        return;
      }

      var vm = this;

      var path = process.env.VUE_APP_URL_PAYMENT_GROUP_CREATE;
      this.loading = 'loading';

      axios.post(path, JSON.stringify({name: this.name}),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            vm.closeModal('create-payment-group');
            var msg = res.data.data.message;
            vm.setSuccess("Success", msg);
            vm.getPaymentGroup();

          })
          .catch(err => {

            vm.loading = '';

            console.log('FAILED response '+JSON.stringify(err.response.data.data))

            if (err.response) {
              vm.setError("Failed",err.response.data.data)
            }
            else if (err.request) {
              vm.setError("Network Error", "Check your network connection and try again")
            }
            else {
              vm.setError("Network Error", "Check your network connection and try again")
            }
          })

    },

    createMember: function (){

      this.$router.push({ name: 'creategroup', params: {id: this.paymentgroup.id, name:this.paymentgroup.name} });

    },

    deleteMember: function(data) {

      var vm = this;

      vm.$swal.fire({
        title: "Do you want to delete. "+data.recipient_name+' from the list?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete'

      }).then((result) => {

        if (result.isConfirmed) {

          var path = process.env.VUE_APP_URL_PAYMENT_GROUP_MEMBER_DELETE.replace(":id", data.id);
          vm.loading = 'loading';

          axios.post(path, JSON.stringify({}),{
            headers: {
              'api-key': vm.getAuth()
            },
          })
              .then(res => {

                vm.loading = '';
                vm.reload();

                var msg = res.data.data.message;

                vm.$swal.fire(
                    'Submitted!',
                    msg,
                    'success'
                )

              })
              .catch(err => {

                vm.loading = '';

                vm.$swal.fire(
                    'Failed!',
                    err.response.data.data,
                    'error'
                )

                if (err.response) {

                  var message = "";

                  if(parseInt(err.response.status) === 428 ) {

                    message = err.response.data.message;
                    vm.setError("Failed", message)
                    return;
                  }

                  if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                    vm.setError("Session Expired", "Your session on this device has expired");
                    vm.logout();
                    return;

                  } else {

                    message =  err.response.data.message;
                    vm.setError("Failed", message)

                  }

                } else if (err.request) {

                  vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err.request));

                } else {

                  //vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err));

                }
              })
        } else {

          vm.cashoutLoading = '';

        }

      })

    },

    changeAmount: async function(data){

      var vm = this;

      const { value: amount } = await vm.$swal.fire({
        title: 'Enter new amount for '+data.recipient_name,
        input: 'number',
        inputLabel: 'Amount',
        inputPlaceholder: 'Enter new amount',
        inputAttributes: {
          min: 100,
          max: 70000
        }
      })

      if (amount) {

        var path = process.env.VUE_APP_URL_PAYMENT_GROUP_MEMBER_UPDATE_AMOUNT.replace(":id", data.id);
        vm.loading = 'loading';

        axios.post(path, JSON.stringify({amount: parseInt(amount)}),{
          headers: {
            'api-key': vm.getAuth()
          },
        })
            .then(res => {

              vm.loading = '';
              vm.reload();

              var msg = res.data.data.message;

              vm.$swal.fire(
                  'Submitted!',
                  msg,
                  'success'
              )

            })
            .catch(err => {

              vm.loading = '';

              vm.$swal.fire(
                  'Failed!',
                  err.response.data.data,
                  'error'
              )

              if (err.response) {

                var message = "";

                if(parseInt(err.response.status) === 428 ) {

                  message = err.response.data.message;
                  vm.setError("Failed", message)
                  return;
                }

                if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                  vm.setError("Session Expired", "Your session on this device has expired");
                  vm.logout();
                  return;

                } else {

                  message =  err.response.data.message;
                  vm.setError("Failed", message)

                }

              } else if (err.request) {

                vm.setError("Failed", "Please check your network")
                console.log(JSON.stringify(err.request));

              } else {

                //vm.setError("Failed", "Please check your network")
                console.log(JSON.stringify(err));

              }
            })

      }

    },

    editMember: function (data) {

      this.member = data;

      var names = [];

      if(data.recipient_name){

        names = data.recipient_name.split(' ');

      }

      if(names.length > 0) {

        this.member.first_name = names[0];
      }

      if(names.length > 1) {

        this.member.middle_name = names[1];
      }

      if(names.length > 2) {

        this.member.last_name = names.slice(2).join(" ");
      }

    },

    updateMember: function (){

      if (!this.member.account) {

        this.setError("Invalid Account", "Please enter a account");
        return;
      }

      if (parseInt(this.member.amount) < 100 ) {

        this.setError("Invalid Amount", "Please enter a amount atleast 100");
        return;
      }

      if (!this.member.first_name) {

        this.setError("Invalid First Name", "Please enter First Name");
        return;
      }

      if (!this.member.middle_name) {

        this.setError("Invalid Middle Name", "Please enter Middle Name");
        return;
      }

      if (!this.member.last_name) {

        this.setError("Invalid Last Name", "Please enter Last Name");
        return;
      }

      if (parseInt(this.member.id_number) < 1000000 ) {

        this.setError("Invalid ID Number", "Please enter a valid ID Number");
        return;
      }

      var paybill = 3016903;

      if (this.member.recipient_type === 2 ) {

        paybill = this.bank_paybill;

      }

      var vm = this;

      var data = {
        id_number: parseInt(this.member.id_number),
        payment_type: parseInt(this.member.payment_method),
        first_name: this.member.first_name,
        middle_name: this.member.middle_name,
        last_name: this.member.last_name,
        amount: parseInt(this.member.amount),
        paybill: parseInt(paybill),
        account_number: this.member.account
      };

      var path = process.env.VUE_APP_URL_PAYMENT_GROUP_MEMBER_UPDATE.replace(":id", this.member.id);
      vm.loading = 'loading';

      axios.post(path, JSON.stringify(data),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            vm.reload();
            vm.closeModal('update-member-details');

            var msg = res.data.data.message;

            vm.$swal.fire(
                'Submitted!',
                msg,
                'success'
            )

          })
          .catch(err => {

            vm.loading = '';

            vm.$swal.fire(
                'Failed!',
                err.response.data.data,
                'error'
            )

            if (err.response) {

              var message = "";

              if(parseInt(err.response.status) === 428 ) {

                message = err.response.data.message;
                vm.setError("Failed", message)
                return;
              }

              if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();
                return;

              } else {

                message =  err.response.data.message;
                vm.setError("Failed", message)

              }

            } else if (err.request) {

              vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err.request));

            } else {

              //vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err));

            }
          })
    },

    deleteGroup: function(data) {

      var vm = this;

      vm.$swal.fire({
        title: "Do you want to delete. "+data.name+'?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete'

      }).then((result) => {

        if (result.isConfirmed) {

          var path = process.env.VUE_APP_URL_PAYMENT_GROUP_DELETE.replace(":id", data.id);
          vm.loading = 'loading';

          axios.post(path, JSON.stringify({}),{
            headers: {
              'api-key': vm.getAuth()
            },
          })
              .then(res => {

                vm.loading = '';
                vm.getPaymentGroup();

                var msg = res.data.data.message;

                vm.$swal.fire(
                    'Submitted!',
                    msg,
                    'success'
                )

              })
              .catch(err => {

                vm.loading = '';

                vm.$swal.fire(
                    'Failed!',
                    err.response.data.data,
                    'error'
                )

                if (err.response) {

                  var message = "";

                  if(parseInt(err.response.status) === 428 ) {

                    message = err.response.data.message;
                    vm.setError("Failed", message)
                    return;
                  }

                  if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                    vm.setError("Session Expired", "Your session on this device has expired");
                    vm.logout();
                    return;

                  } else {

                    message =  err.response.data.message;
                    vm.setError("Failed", message)

                  }

                } else if (err.request) {

                  vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err.request));

                } else {

                  //vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err));

                }
              })
        } else {

          vm.cashoutLoading = '';

        }

      })

    },

    updatePaymentGroup: function (){

      if (!this.paymentgroup.name) {

        this.setError("Invalid name", "Please enter a valid name");
        return;
      }

      var vm = this;

      var path = process.env.VUE_APP_URL_PAYMENT_GROUP_UPDATE.replace(":id", this.paymentgroup.id);
      vm.loading = 'loading';

      axios.post(path, JSON.stringify(this.paymentgroup),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            vm.closeModal('update-payment-group');
            var msg = res.data.data.message;
            vm.$swal.fire(
                'Submitted!',
                msg,
                'success'
            )

            vm.getPaymentGroup();

          })
          .catch(err => {

            vm.loading = '';

            vm.$swal.fire(
                'Failed!',
                err.response.data.data,
                'error'
            )

            if (err.response) {

              var message = "";

              if(parseInt(err.response.status) === 428 ) {

                message = err.response.data.message;
                vm.setError("Failed", message)
                return;
              }

              if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();
                return;

              } else {

                message =  err.response.data.message;
                vm.setError("Failed", message)

              }

            } else if (err.request) {

              vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err.request));

            } else {

              //vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err));

            }

          })
    },

    getID: function (prefix,id){

      return prefix+'-row-data-'+id;
    },

    getIDWithHash: function (prefix,id){

      return '#'+prefix+'-row-data-'+id;
    },

    markRegister: function (){

      var vm = this;

      if(this.attendance.length === 0){

        this.setError("Invalid name", "Mark attendance to proceed");
        return;
      }

      this.loading = 'loading';

      var path = process.env.VUE_APP_URL_WORKSHEET_ATTENDANCE_MARK.replace(":id", this.worksheet_id);

      axios.post(path, JSON.stringify({attendance:vm.attendance,date: vm.date}),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            var msg = res.data.data.message;
            vm.$swal.fire(
                'Updated!',
                msg,
                'success'
            )

            vm.reload();

          })
          .catch(err => {

            vm.loading = '';

            vm.$swal.fire(
                'Failed!',
                err.response.data.data,
                'error'
            )

            if (err.response) {

              var message = "";

              if(parseInt(err.response.status) === 428 ) {

                message = err.response.data.message;
                vm.setError("Failed", message)
                return;
              }

              if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();
                return;

              } else {

                message =  err.response.data.message;
                vm.setError("Failed", message)

              }

            } else if (err.request) {

              vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err.request));

            } else {

              //vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err));

            }

          })

    },

    markRegisterAllPresent: function (){

      var vm = this;

      this.loading = 'loading';

      var path = process.env.VUE_APP_URL_WORKSHEET_ATTENDANCE_MARK_ALL.replace(":id", this.worksheet_id);

      axios.post(path, JSON.stringify({date: vm.date,status: 1}),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            var msg = res.data.data.message;
            vm.$swal.fire(
                'Updated!',
                msg,
                'success'
            )

            vm.reload();

          })
          .catch(err => {

            vm.loading = '';

            vm.$swal.fire(
                'Failed!',
                err.response.data.data,
                'error'
            )

            if (err.response) {

              var message = "";

              if(parseInt(err.response.status) === 428 ) {

                message = err.response.data.message;
                vm.setError("Failed", message)
                return;
              }

              if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();
                return;

              } else {

                message =  err.response.data.message;
                vm.setError("Failed", message)

              }

            } else if (err.request) {

              vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err.request));

            } else {

              //vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err));

            }

          })
    },

    markRegisterAllAbsent: function (){

      var vm = this;

      this.loading = 'loading';

      var path = process.env.VUE_APP_URL_WORKSHEET_ATTENDANCE_MARK_ALL.replace(":id", this.worksheet_id);

      axios.post(path, JSON.stringify({date: vm.date,status: 0}),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            var msg = res.data.data.message;
            vm.$swal.fire(
                'Updated!',
                msg,
                'success'
            )

            vm.reload();

          })
          .catch(err => {

            vm.loading = '';

            vm.$swal.fire(
                'Failed!',
                err.response.data.data,
                'error'
            )

            if (err.response) {

              var message = "";

              if(parseInt(err.response.status) === 428 ) {

                message = err.response.data.message;
                vm.setError("Failed", message)
                return;
              }

              if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();
                return;

              } else {

                message =  err.response.data.message;
                vm.setError("Failed", message)

              }

            } else if (err.request) {

              vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err.request));

            } else {

              //vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err));

            }

          })

    },

    getRowID: function (id,suffix){

      return suffix + '-' + id;

    },

  },
  watch: {

    $route: {

      immediate: true,
      handler(to) {

        // react to route changes...
        this.worksheet_id = to.params.id;
        this.name = to.params.name;
      }
    },
  },

}

</script>