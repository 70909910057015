<template>

    <div class="">

      <div class="container-fluid">
        <div class="card custom-card-wrapper">

          <div class="card-header card-body-header-lg p-2 pb-0 d-flex justify-content-between shadow-sm">

            <div class=" d-flex justify-content-left ">

            <span class="font-weight-500 men-item underline-item mr-2">
                 <a @click="goBack" class="">Timesheet Payment</a>
            </span>

              <span class="font-weight-500 men-item underline-item mr-2 active">
                 Timesheet Payment Members
              </span>
            </div>

            <div class="input-group blue-input-group mb-2">
              <button class="btn blue-input-group-btn-left btn-outline-primary dropdown-toggle font-size-12" type="button" data-bs-toggle="dropdown" aria-expanded="false">Filter By Status</button>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">Action before</a></li>
                <li><a class="dropdown-item" href="#">Another action before</a></li>
                <li><a class="dropdown-item" href="#">Something else here</a></li>
                <li><hr class="dropdown-divider"></li>
                <li><a class="dropdown-item" href="#">Separated link</a></li>
              </ul>
              <input type="text" class="form-control" aria-label="Text input with 2 dropdown buttons">
              <button class="btn blue-input-group-btn-right btn-outline-primary  font-size-12" type="button" ><i class="bi bi-search"></i></button>
            </div>
          </div>

          <div class="card-body p-0">

            <vuetable
                ref="vuetable"
                api-url="request/table"
                :api-mode="apiMode"
                :fields="fields"
                :sort-order="sortOrder"
                :css="css.table"
                pagination-path=""
                :per-page="perPage"
                :append-params="moreParams"
                :http-fetch="fetchMembers"
                :load-on-start="loadOnStart"
                @vuetable:pagination-data="onPaginationData"
                @vuetable:loading="onLoading"
                @vuetable:loaded="onLoaded">

              <div slot="actions-slot" slot-scope="props">
                <div v-show="parseInt(props.rowData.status) === 0" class="btn-group">
                  <button type="button" class="btn bg-danger btn-sm" style="color: white" @click="deleteMember(props.rowData)">
                    Remove Member
                  </button>

                </div>
              </div>

              <div slot="sequence-slot" slot-scope="props">
                {{ props.rowIndex + 1}}
              </div>

              <div slot="amount-slot" slot-scope="props">
                {{ formatCurrency(props.rowData.amount) }} Ksh
              </div>

              <div slot="created-slot" slot-scope="props">
                {{ formatDateTime(props.rowData.created) }}
              </div>

              <div slot="payment-method-slot" slot-scope="props">
                <span v-text="getPaymentMethod(props.rowData.payment_method)"></span>
              </div>

              <div slot="status-slot" slot-scope="props">
                <span v-html="getStatus(props.rowData.status)"></span>
              </div>

            </vuetable>

            <vuetable-pagination
                ref="pagination"
                :css="css.pagination"
                @vuetable-pagination:change-page="onChangePage">
            </vuetable-pagination>

          </div>
        </div>
      </div>

    </div>

</template>

<script>

import Vue from 'vue'
import axios from "@/services/api";
import Vuetable from 'vuetable-2'
import VuetableFieldSequence from "vuetable-2/src/components/VuetableFieldSequence";

import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
Vue.use(Vuetable);

var moreParams = {};

export default {
  name: 'worksheetpaymentschedulemembers',
  components: {
    Vuetable,
    'vuetable-pagination': VuetablePagination
  },
  data: function (){
    return {
      mqttClient:'',
      active_tab: 'account',
      name: '',
      start_date: '',
      end_date: '',
      action: 'Create',
      payment: {},

      // vuetable
      search: '',
      fields: [
        {
          name: VuetableFieldSequence,
          title: '#',
        },
        {
          name: "recipient_name",
          title: 'Name',
          sortField: 'worksheet_member.recipient_name',
        },
        {
          name: 'id_number',
          title: '<span class="orange glyphicon glyphicon-bookmark"></span> ID No.',
          sortField: 'worksheet_member.id_number'
        },
        {
          name: "payment-method-slot",
          title: '<span>#</span> Payment Method',
          sortField: 'worksheet_member.payment_method',
        },
        {
          name: 'account',
          title: 'Acc No.',
          sortField: 'worksheet_member.account'
        },
        {
          name: 'status-slot',
          title: 'Status',
          sortField: 'worksheet_payment_schedule_member.status'
        },
        {
          name: 'amount-slot',
          title: '<span class="orange glyphicon glyphicon-bookmark"></span> Amount',
          sortField: 'worksheet_payment_schedule_member.amount'
        },
        {
          name: "created-slot",
          title: '<span class="orange glyphicon glyphicon-calendar"></span> Created',
          sortField: 'worksheet_payment_schedule_member.created',
        },
        {
          name: "actions-slot",
          title: 'Actions',
          titleClass: "center aligned",
          dataClass: "text-right"
        }
      ],
      moreParams: moreParams,
      css: {
        table: {
          tableClass: 'table table-striped table-bordered table-hovered',
          loadingClass: 'loading',
          ascendingIcon: 'glyphicon glyphicon-chevron-up',
          descendingIcon: 'glyphicon glyphicon-chevron-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger',
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination pull-right',
          activeClass: 'bg-green',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: '',
          },
        }
      },
      sortOrder: [
        { field: 'worksheet_payment_schedule_member.id', direction: 'desc' }
      ],
      perPage: parseInt(5),
      loadOnStart: true,
      apiMode: true,
      loading: ''
    }
  },
  mounted() {

    this.$store.dispatch("setCurrentPage","worksheetpaymentsmembers");
    this.$store.dispatch("setCurrentPageName","Timesheet Payments Members");
    this.$store.dispatch("setPreviousPage","worksheetpayments");

  },
  computed: {

    worksheetpaymentschedule: function () {

      return this.$store.state.worksheetpaymentschedule;

    },
    worksheet: function () {

      return this.$store.state.worksheet;

    },

  },
  methods: {

    getStatus: function (status){

      if(parseInt(status) === 0) {

        return '<span class="fw-bold text-secondary">Waiting Approval</span>'
      }

      if(parseInt(status) === 1) {

        return '<span class="fw-bold text-warning">Payment Initiated</span>'
      }

      if(parseInt(status) === -1) {

        return '<span class="fw-bold text-danger">Payment Failed</span>'
      }

      return '<span class="fw-bold text-success">Payment Successful</span>'

    },

    dateFormat: function (date){

      return this.dateFormat(date)

    },

    searchMember: function (){

      if(this.search.length < 2 ) {

        this.setError("invalid fields","please type atleast 3 characters to search")
        return
      }

      this.moreParams.search = this.search;
      this.$refs.vuetable.refresh();

    },

    reload: function() {

      this.moreParams.search = '';
      this.$refs.vuetable.refresh();
    },

    onPaginationData: function(paginationData) {

      this.$refs.pagination.setPaginationData(paginationData)
    },

    onChangePage: function(page) {

      this.$refs.vuetable.changePage(page)

    },

    delete: function(rowData) {

      console.log('TO delete '+JSON.stringify(rowData))

    },

    editRow: function(rowData){

      alert("You clicked edit on"+ JSON.stringify(rowData))
    },

    deleteRow: function(rowData){

      alert("You clicked delete on"+ JSON.stringify(rowData))

    },

    onLoading: function() {

      this.loading = 'loading'
      console.log('loading... show your spinner here')

    },

    onLoaded: function() {

      this.loading = ''
      console.log('loaded! .. hide your spinner here')

    },

    fetchMembers: function(apiUrl, httpOptions){

      var vm = this;
      var path = process.env.VUE_APP_URL_WORKSHEET_PAYMENT_SCHEDULE_MEMBERS_TABLE_VIEW.replace(":id",vm.worksheet.id).replace(":payment_id",vm.worksheetpaymentschedule.id);

      return axios.post(path, JSON.stringify(httpOptions.params), {
        headers: {
          'api-key': vm.getAuth()
        },
      })
    },

    getPaymentMethod: function (paymentMethod){

      if(parseInt(paymentMethod) === 1 ) {

        return 'MPESA';

      }
      return  'BANK';

    },

    formatAmount: function (amount){

      return this.formatStake(amount);

    },

    deleteMember: function(data) {

      var vm = this;

      vm.$swal.fire({
        title: 'Confirm',
        text: "Do you want to remove "+data.recipient_name+' from payment list?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Remove Member'

      }).then((result) => {

        if (result.isConfirmed) {

          var path = process.env.VUE_APP_URL_WORKSHEET_PAYMENT_SCHEDULE_MEMBERS_DELETE.replace(":member_id",data.id).replace(":id",vm.worksheet.id).replace(":payment_id",vm.worksheetpaymentschedule.id);
          vm.loading = 'loading';

          axios.post(path, JSON.stringify({}),{
            headers: {
              'api-key': vm.getAuth()
            },
          })
              .then(res => {

                vm.loading = '';
                vm.reload();

                var msg = res.data.data.message;

                vm.$swal.fire(
                    'Submitted!',
                    msg,
                    'success'
                )

              })
              .catch(err => {

                vm.loading = '';

                vm.$swal.fire(
                    'Failed!',
                    err.response.data.data,
                    'error'
                )

                if (err.response) {

                  var message = "";

                  if(parseInt(err.response.status) === 428 ) {

                    message = err.response.data.message;
                    vm.setError("Failed", message)
                    return;
                  }

                  if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                    vm.setError("Session Expired", "Your session on this device has expired");
                    vm.logout();
                    return;

                  } else {

                    message =  err.response.data.message;
                    vm.setError("Failed", message)

                  }

                } else if (err.request) {

                  vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err.request));

                } else {

                  //vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err));

                }
              })
        } else {

          vm.cashoutLoading = '';

        }

      })

    },

  },
}

</script>