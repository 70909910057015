<template>

    <div class="">

      <div class="container-fluid">
        <div class="row">
          <div class="col-md-4">
            <ul class="list-group">

              <li class="list-group-item">
                <div class="image text-center">
                  <img src="/img/no_image.png" class="no-image"> <br>
                  <span v-text="client.client_name"></span>
                </div>
              </li>

              <li class="list-group-item" style="border: 1px solid rgba(0,0,0,.125);">
                <div class="d-flex justify-content-between">
                  <span class="font-weight-bold">Phone No</span>
                  <span class="text-primary" v-text="client.contact_msisdn"></span>
                </div>
              </li>

              <li class="list-group-item" style="border: 1px solid rgba(0,0,0,.125);">
                <div class="d-flex justify-content-between">
                  <span class="font-weight-bold">Email</span>
                  <span class="text-primary" v-text="client.contact_email"></span>
                </div>
              </li>

              <li class="list-group-item" style="border: 1px solid rgba(0,0,0,.125);">
                <div class="d-flex justify-content-between">
                  <span class="font-weight-bold">Office Location</span>
                  <span class="text-primary">Nairobi</span>
                </div>
              </li>

            </ul>
          </div>

          <div class="col-md-8 ">
            <div class="card custom-card-wrapper shadow-sm">
              <div class="card-header">
                Account Summary
              </div>
              <div class="card-body">
                <!-- Tab links -->

                <ul class="nav nav-tabs">

                  <li class="nav-item">
                    <a class="nav-link tablinks text-dark" v-bind:class="getTabTitleClass('account')" @click="showTab('account')" aria-current="page" >Account Statement</a>
                  </li>

                  <li class="nav-item">
                    <a class="nav-link tablinks text-dark" v-bind:class="getTabTitleClass('paybill')" @click="showTab('paybill')" >Topup History</a>
                  </li>
                </ul>

                <!-- Tab content -->
                <div v-show="isActiveTab('account')" class="tabcontent p-0" v-bind:class="getTabClass('account')">

                  <div class="table-section">

                    <vuetable
                        ref="vuetable"
                        api-url="request/table"
                        :api-mode="apiMode"
                        :fields="fields"
                        :sort-order="sortOrder"
                        :css="css.table"
                        pagination-path=""
                        :per-page="perPage"
                        :append-params="moreParams"
                        :http-fetch="fetchMembers"
                        :load-on-start="loadOnStart"
                        @vuetable:pagination-data="onPaginationData"
                        @vuetable:loading="onLoading"
                        @vuetable:loaded="onLoaded">

                      <div slot="actions-slot" slot-scope="props">
                        <div class="btn-group">

                          <button type="button" class="btn btn-primary btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            More
                          </button>

                          <ul class="dropdown-menu">

                            <li><a class="dropdown-item" @click="createMember(props.rowData)">Add Members</a></li>

                            <li><a class="dropdown-item" @click="viewMembers(props.rowData)">View</a></li>

                            <li><a class="dropdown-item" @click="viewMembersAttendance(props.rowData)">Attendance</a></li>

                            <li><a class="dropdown-item" @click="updateWorksheet(props.rowData)" data-bs-toggle="modal" data-bs-target="#create-worksheet" >Update Details</a></li>

                            <li><a class="dropdown-item" @click="deleteSheet(props.rowData)">Delete Sheet</a></li>

                          </ul>

                        </div>
                      </div>

                      <div slot="sequence-slot" slot-scope="props">
                        {{ props.rowIndex + 1}}
                      </div>

                      <div slot="format-created" slot-scope="props">
                        {{ formatDate(props.rowData.created) }}
                      </div>

                      <div slot="format-currency-slot" slot-scope="props">
                        {{ formatCurrency(props.rowData.amount) }} Ksh
                      </div>

                      <div slot="format-balance-slot" slot-scope="props">
                        {{ formatCurrency(props.rowData.client_balance) }} Ksh
                      </div>

                      <div slot="format-end-date-slot" slot-scope="props">
                        {{ formatDate(props.rowData.end_date) }}
                      </div>

                      <div slot="transaction-type" slot-scope="props">

                        <div class="" v-if="parseInt(props.rowData.transaction_type) === 2" style="color: green">
                          <i class="bi bi-arrow-up-circle-fill"></i> Credit
                        </div>

                        <div class="" v-if="parseInt(props.rowData.transaction_type) === 1" style="color: red">
                          <i class="bi bi-arrow-down-circle-fill"></i> Debit
                        </div>

                      </div>


                    </vuetable>

                    <vuetable-pagination
                        ref="pagination"
                        :css="css.pagination"
                        @vuetable-pagination:change-page="onChangePage">
                    </vuetable-pagination>

                  </div>

                </div>

                <div  v-show="isActiveTab('paybill')" class="tabcontent p-0"  v-bind:class="getTabClass('paybill')">

                  <div class="table-section">
                    <vuetable
                        ref="topupvuetable"
                        api-url="request/table"
                        :api-mode="apiMode"
                        :fields="topupFields"
                        :sort-order="topupSortOrder"
                        :css="css.table"
                        pagination-path=""
                        :per-page="perPage"
                        :append-params="moreParams"
                        :http-fetch="fetchTransactions"
                        :load-on-start="loadOnStart"
                        @vuetable:pagination-data="onPaginationData"
                        @vuetable:loading="onLoading"
                        @vuetable:loaded="onLoaded">

                      <div slot="format-created" slot-scope="props">
                        {{ formatDate(props.rowData.created) }}
                      </div>

                      <div slot="format-currency-slot" slot-scope="props">
                        {{ formatCurrency(props.rowData.amount) }} Ksh
                      </div>

                    </vuetable>

                    <vuetable-pagination
                        ref="topuppagination"
                        :css="css.pagination"
                        @vuetable-pagination:change-page="onTopupChangePage">
                    </vuetable-pagination>

                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

</template>

<script>

import Vue from 'vue'
import axios from "@/services/api";
import Vuetable from 'vuetable-2'

import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldSequence from "vuetable-2/src/components/VuetableFieldSequence";
Vue.use(Vuetable);
var moreParams = {};

export default {
  name: 'Details',
  components: {
    Vuetable,
    'vuetable-pagination': VuetablePagination

  },
  data: function (){
    return {
      active_tab: 'account',
      // vuetable
      search: '',
      fields: [
        {
          name: VuetableFieldSequence,
          title: '#',
        },
        {
          name: "format-created",
          title: '<i class="bi bi-calendar"></i> Date',
          sortField: 'transactions.created',
        },
        {
          name: 'format-currency-slot',
          title: '<i class="bi bi-cash"></i> Amount',
          sortField: 'transactions.amount',
        },
        {
          name: "transaction-type",
          title: '<i class="bi bi-arrow-down-up"></i> Type',
          sortField: 'transactions.transaction_type',
        },
        {
          name: "description",
          title: '<i class="bi bi-info"></i> Description',
          sortField: 'transactions.description',
        },
        {
          name: 'format-balance-slot',
          title: '<i class="bi bi-bank2"></i> Balance',
          sortField: 'transactions.client_balance',
        },
      ],
      topupFields: [
        {
          name: VuetableFieldSequence,
          title: '#',
        },
        {
          name: "format-created",
          title: '<i class="bi bi-calendar"></i> Date',
          sortField: 'mpesa_transaction.created',
        },
        {
          name: 'format-currency-slot',
          title: '<i class="bi bi-cash"></i> Amount',
          sortField: 'mpesa_transaction.amount',
        },
        {
          name: "reference",
          title: 'Reference',
          sortField: 'mpesa_transaction.reference',
        },
        {
          name: "account",
          title: '<i class="bi bi-info"></i> Account',
          sortField: 'mpesa_transaction.account',
        },
        {
          name: 'sender',
          title: '<i class="bi bi-user"></i> Sender',
          sortField: 'mpesa_transaction.sender',
        },
      ],
      moreParams: moreParams,
      css: {
        table: {
          tableClass: 'table table-striped table-bordered table-hovered',
          loadingClass: 'loading',
          ascendingIcon: 'glyphicon glyphicon-chevron-up',
          descendingIcon: 'glyphicon glyphicon-chevron-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger',
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination pull-right',
          activeClass: 'bg-green',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: '',
          },
        }
      },
      sortOrder: [
        { field: 'transactions.id', direction: 'desc' }
      ],
      topupSortOrder: [
        { field: 'mpesa_transaction.id', direction: 'desc' }
      ],
      perPage: parseInt(10),
      loadOnStart: true,
      apiMode: true,
      loading: ''
    }
  },
  mounted() {

    this.$store.dispatch("setCurrentPage","details");


  },
  computed: {
    client: function (){

      return this.getClient();
    },
  },
  methods: {

    showTab: function(tabName) {

      this.active_tab = tabName;
    },

    isActiveTab: function(tabName) {

      return this.active_tab === tabName;
    },

    getTabClass: function(tabName) {

      return this.active_tab === tabName ? 'active-tab' : '';
    },

    getTabTitleClass: function(tabName) {

      return this.active_tab === tabName ? 'active' : '';

    },
    dateFormat: function (date){

      return this.dateFormat(date)

    },

    reload: function() {

      this.moreParams.search = '';
      this.$refs.vuetable.refresh();
      this.$refs.topupvuetable.refresh();
    },

    onPaginationData: function(paginationData) {

      this.$refs.pagination.setPaginationData(paginationData)
    },

    onChangePage: function(page) {

      this.$refs.vuetable.changePage(page)

    },

    onTopupChangePage: function(page) {

      this.$refs.topupvuetable.changePage(page)

    },

    delete: function(rowData) {

      console.log('TO delete '+JSON.stringify(rowData))

    },

    editRow: function(rowData){

      alert("You clicked edit on"+ JSON.stringify(rowData))
    },

    deleteRow: function(rowData){

      alert("You clicked delete on"+ JSON.stringify(rowData))

    },

    onLoading: function() {

      console.log('loading... show your spinner here')

    },

    onLoaded: function() {

      console.log('loaded! .. hide your spinner here')

    },

    fetchMembers: function(apiUrl, httpOptions){

      var vm = this;
      var path = process.env.VUE_APP_URL_TRANSACTIONS_VIEW_TABLE;

      return axios.post(path, JSON.stringify(httpOptions.params), {
        headers: {
          'api-key': vm.getAuth()
        },
      })
    },

    fetchTransactions: function(apiUrl, httpOptions){

      var vm = this;
      var path = process.env.VUE_APP_URL_TOPUPS_VIEW_TABLE;

      return axios.post(path, JSON.stringify(httpOptions.params), {
        headers: {
          'api-key': vm.getAuth()
        },
      })
    },

    formatAmount: function (amount){

      return this.formatStake(amount);

    },

  },
}

</script>