<template>

  <div class="">

    <div class="container-fluid" v-bind:class="loading">

      <div class="row">

        <div class="col-md-5">
          <div class="card custom-card-wrapper">

            <div class="card-header">
              <div class="font-weight-500 pt-1">
                Pending Approvals (<span v-text="approval_count"></span>)
              </div>
            </div>

            <div class="card-body p-0">

              <table class="table table-striped">

                <thead class=" ">
                  <tr>
                    <th>Name</th>
                    <th>Initiator</th>
                    <th>Members</th>
                    <th>Amount</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(group,index) in approvals" v-bind:key="getKey(index)" @click="setApproval(group,index)">
                    <td>
                      <span v-text="group.group_name"></span>
                    </td>

                    <td v-text="group.fullname"></td>
                    <td v-text="group.total_members"></td>
                    <td><span v-text="formatCurrency(group.total_amount)"></span> Ksh</td>
                  </tr>
                </tbody>

              </table>

            </div>

          </div>

        </div>

        <div class="col-md-7">

          <div class="card custom-card-wrapper">

            <div class="card-header line-height-1">
              <span v-text="paymentgroup.group_name"></span> <br> <small class="text-success text-small font-weight-500"><span v-text="paymentgroup.total_members"></span> members</small>
            </div>

            <div class="card-body p-0">

              <div class="card-body-subheader p-2 d-flex justify-content-between border-bottom">
                <div class="font-weight-500 pt-1">
                  <div class="btn-group" v-show="parseInt(paymentgroup.can_approve) === 1">
                    <button type="button" class="btn btn-success btn-sm" @click="accept">
                      Approve
                    </button>

                    <button type="button" class="btn btn-danger btn-sm" @click="reject">
                      Return
                    </button>
                  </div>
                </div>
                <span class="text-success"> Total Amount: <span class="font-weight-500 text-success">KES. <span v-text="formatStake(paymentgroup.total_amount)"></span> </span></span>
              </div>

              <table class="table table-striped">

                <thead class=" ">
                <tr>
                  <th>Date</th>
                  <th>Approver</th>
                  <th>Status</th>
                  <th>Level</th>
                  <th>Description</th>
                </tr>
                </thead>

                <tbody>
                <tr v-for="(status,index) in approvals_status" v-bind:key="getKey(index)">
                  <td v-text="formatDateTime(status.created)"></td>
                  <td v-text="status.first_name"></td>
                  <td v-html="getStatus(status.status)"></td>
                  <td v-text="getApproverLevel(status.approval_level)"></td>
                  <td v-text="status.description"></td>
                </tr>
                </tbody>

              </table>

            </div>

          </div>

        </div>

      </div>

    </div>

  </div>

</template>

<script>

import axios from "@/services/api";

export default {
  name: 'approvals',
  components: {},
  data: function (){
    return {
      row_index: 0,
      active_tab: 'account',
      approvals: [],
      paymentgroup: {},
      member: {
        account: '',
        id_number: 0,
        payment_type: 1,
        first_name: '',
        middle_name: '',
        last_name: '',
        amount: 0,
        paybill: 0,
      },
      approvals_status: [],
      loading: ''
    }
  },
  mounted: function() {

    this.$store.dispatch("setCurrentPage","approvals");
    this.$store.dispatch("setCurrentPageName","Approvals");
    this.$store.dispatch("setPreviousPage","dashboard");
    this.getApprovals();

  },
  computed: {

    approval_count: function (){

      if(this.approvals) {

        return this.approvals.length
      }

      return 0;
    }

  },
  methods: {

    getStatus: function (status){

      if(parseInt(status) === 1) {

        return '<span class="fw-bold text-success">Approved</span>'
      }

      return '<span class="fw-bold text-danger">Returned</span>'

    },

    getApproverLevel: function (level){

      if(parseInt(level) === 1) {

        return '1st Approver';
      }

      if(parseInt(level) === 2) {

        return '2nd Approver';
      }

      if(parseInt(level) === 3) {

        return '3rd Approver';
      }

    },

    getGroupType: function (payment_type){

      if(parseInt(payment_type) === 1 ) {

        return 'PayRoll';

      }

      return  'Timesheet';

    },

    formatAmount: function (amount){

      return this.formatStake(amount);

    },

    setApproval: function(group,index){

      this.paymentgroup = group;
      this.row_index = index;
      this.getApprovalStatus();

    },

    getApprovalStatus: function (){

      var path = "";
      var vm = this;

      if(parseInt(this.paymentgroup.payment_type)  === 1 ) { // payment group

        path = process.env.VUE_APP_URL_PAYMENT_GROUP_APPROVAL_STATUS.replace(":id",this.paymentgroup.reference_id);

      }
      else if(parseInt(this.paymentgroup.payment_type)  === 2 ) { // worksheet

        path = process.env.VUE_APP_URL_WORKSHEET_APPROVAL_STATUS.replace(":id",this.paymentgroup.group_id).replace(":payment_id",this.paymentgroup.reference_id);

      }

      vm.loading = '';

      axios.post(path, JSON.stringify({}),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            var msg = res.data.data;
            vm.approvals_status = msg;
          })
          .catch(err => {

            vm.loading = '';

            console.log('FAILED response '+JSON.stringify(err.response.data.data))

            if (err.response) {

              vm.setError("Failed",err.response.data.data)

            }
            else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")

            }
            else {

              vm.setError("Network Error", "Check your network connection and try again")

            }

          })


    },

    isCurrentRow: function(index) {

      return parseInt(index) === this.row_index ? 'bg-green text-light' : '';

    },

    getApprovals: function(){

      var vm = this;

      var path = process.env.VUE_APP_URL_APPROVAL_LIST;

      axios.post(path, JSON.stringify({}),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            var msg = res.data.data;
            vm.approvals = msg;

            if(vm.approvals.length > 0 ) {

              vm.setApproval(vm.approvals[0], 0)
            }

          })
          .catch(err => {

            vm.loading = '';

            console.log('FAILED response '+JSON.stringify(err.response.data.data))

            if(parseInt(err.response.status) === 428 || parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

              vm.setError("Session Expired", "Your session on this device has expired");
              vm.logout();
              return;

            }

            if (err.response) {

              vm.setError("Failed",err.response.data.data)

            }
            else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")

            }
            else {

              vm.setError("Network Error", "Check your network connection and try again")

            }

          })

    },

    reject: async function(){

      var vm = this;

      const { value: description } = await vm.$swal.fire({
        title: 'Enter Return/Rejection reason',
        input: 'textarea',
        inputLabel: 'Enter Return/Rejection reason',
        inputPlaceholder: 'Type Return/Rejection reason here... ',
        inputAttributes: {
          'aria-label': 'Enter Return/Rejection reason'
        },
      })

      if (description) {

        var path = ""

        if(parseInt(vm.paymentgroup.payment_type) === 1 ) {

          path = process.env.VUE_APP_URL_PAYMENT_GROUP_APPROVAL_RETURN.replace(":id", vm.paymentgroup.reference_id);
        }
        else if(parseInt(vm.paymentgroup.payment_type) === 2 ) {

          path = process.env.VUE_APP_URL_WORKSHEET_APPROVAL_RETURN.replace(":payment_id", vm.paymentgroup.reference_id).replace(":id", vm.paymentgroup.group_id);
        }

        vm.loading = 'loading';

        axios.post(path, JSON.stringify({description: description}),{
          headers: {
            'api-key': vm.getAuth()
          },
        })
            .then(res => {

              vm.loading = '';
              vm.getApprovals();

              var msg = res.data.data.message;

              vm.$swal.fire(
                  'Submitted!',
                  msg,
                  'success'
              )

            })
            .catch(err => {

              vm.loading = '';

              vm.$swal.fire(
                  'Failed!',
                  err.response.data.data,
                  'error'
              )

              if (err.response) {

                var message = "";

                if(parseInt(err.response.status) === 428 ) {

                  message = err.response.data.message;
                  vm.setError("Failed", message)
                  return;
                }

                if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                  vm.setError("Session Expired", "Your session on this device has expired");
                  vm.logout();
                  return;

                } else {

                  message =  err.response.data.message;
                  vm.setError("Failed", message)

                }

              } else if (err.request) {

                vm.setError("Failed", "Please check your network")
                console.log(JSON.stringify(err.request));

              } else {

                //vm.setError("Failed", "Please check your network")
                console.log(JSON.stringify(err));

              }
            })

      }

    },

    accept: function() {

      var vm = this;

      vm.$swal.fire({
        title: 'Confirm',
        text: "Do you want to approve payment for "+vm.paymentgroup.group_name+' a total of '+vm.formatCurrency(vm.paymentgroup.total_amount)+' Ksh?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Approve Payment'

      }).then((result) => {

        if (result.isConfirmed) {

          var path = ""

          if(parseInt(vm.paymentgroup.payment_type) === 1 ) {

             path = process.env.VUE_APP_URL_PAYMENT_GROUP_APPROVAL_ACCEPT.replace(":id", vm.paymentgroup.reference_id);
          }
          else if(parseInt(vm.paymentgroup.payment_type) === 2 ) {

            path = process.env.VUE_APP_URL_WORKSHEET_APPROVAL_ACCEPT.replace(":payment_id", vm.paymentgroup.reference_id).replace(":id", vm.paymentgroup.group_id);
          }

          vm.loading = 'loading';

          axios.post(path, JSON.stringify({description: 'approved'}),{
            headers: {
              'api-key': vm.getAuth()
            },
          })
              .then(res => {

                vm.loading = '';
                vm.getApprovals();

                var msg = res.data.data.message;

                vm.$swal.fire(
                    'Submitted!',
                    msg,
                    'success'
                )

              })
              .catch(err => {

                vm.loading = '';

                vm.$swal.fire(
                    'Failed!',
                    err.response.data.data,
                    'error'
                )

                if (err.response) {

                  var message = "";

                  if(parseInt(err.response.status) === 428 ) {

                    message = err.response.data.message;
                    vm.setError("Failed", message)
                    return;
                  }

                  if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                    vm.setError("Session Expired", "Your session on this device has expired");
                    vm.logout();
                    return;

                  } else {

                    message =  err.response.data.message;
                    vm.setError("Failed", message)

                  }

                } else if (err.request) {

                  vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err.request));

                } else {

                  //vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err));

                }
              })
        } else {

          vm.cashoutLoading = '';

        }

      })

    },

  },

}

</script>