<template>

  <div class="login-page">

    <img class="mb-1 mt-4" src="/img/logo.png?x"  alt="Logo" height="50">
    <div class="registration-prompt">
      <span>Have an account? <router-link to="/">Login</router-link>  </span>
    </div>

    <main class="form-signin card" v-bind:class="loading">

        <div v-show="isSignup" class="card-body mr-2 ml-2 ">

          <h1 class="h3 mb-3 fw-normal float-start">Sign Up</h1>

          <hr class="border" style="margin: 35px 0px 35px"/>

          <form>

            <div v-show="error_message.length > 0" class="alert alert-danger alert-dismissible fade show" role="alert" style="font-size: 0.9em;text-align: left;">
              <span v-text="error_message"></span>
              <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>

            <div v-show="success_message.length > 0" class="alert alert-success alert-dismissible fade show" role="alert" style="font-size: 0.9em;text-align: left;">
              <span v-text="success_message"></span>
              <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>

            <div class="form-floating mb-3">
              <input v-model="company_name" type="email" class="form-control form-control-lg" id="company_name" aria-describedby="emailHelp" placeholder="Company Name">
              <label for="company_name" class="form-label">Company Name</label>
            </div>

            <div class="row mb-3">

              <div class="col-sm-12 col-md-6 mt-3">
                <div class="form-floating">
                  <input v-model="first_name" type="text" class="form-control form-control-lg" id="first_name" placeholder="Contact Person First Name">
                  <label for="first_name" class="form-label">Contact Person First Name</label>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 mt-3 mt-3">
                <div class="form-floating">
                  <input v-model="last_name" type="text" class="form-control form-control-lg" id="last_name" placeholder="Contact Person Last Name">
                  <label for="last_name" class="form-label">Contact Person Last Name</label>
                </div>
              </div>

            </div>


            <div class="row mb-3">

              <div class="col-sm-12 col-md-6 mt-3">
                <div class="form-floating">
                  <input v-model="email" type="text" class="form-control form-control-lg" id="email" placeholder="Company Email">
                  <label for="email" class="form-label">Company Email</label>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 d-flex bd-highlight mt-3 form-floating">
                <VueCountryCode @onSelect="setCountry" :preferredCountries="['ke', 'ug', 'tz']" style="border-radius: 5px 0px 0px 5px"></VueCountryCode>
                <input v-model="msisdn" type="text" class="form-control form-control-lg" id="msisdn" placeholder="Company Contact Number" style="border-radius: 0px 5px 5px 0px">
                <label for="msisdn" class="form-label" style="margin-left: 60px;">Company Contact Number</label>
              </div>

            </div>


            <div class="row mb-3" style="display: none">

              <div class="col-sm-12 col-md-6 mt-3">
                <div class="form-floating">
                  <input v-model="password" type="password" class="form-control form-control-lg" id="password" placeholder="Password">
                  <label for="password" class="form-label">Password</label>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 mt-3 ">
                <div class="form-floating">
                  <input v-model="password1" type="password" class="form-control form-control-lg" id="password1" placeholder="Repeat Password">
                  <label for="password1" class="form-label">Repeat Password</label>
                </div>
              </div>

            </div>

            <div class="w-100 btn btn-primary" @click="join">Create WagePay Account</div>

          </form>

        </div>

        <div v-show="isLogin" class="card-body mr-2 ml-2 ">

          <h1 class="h3 mb-3 fw-normal float-start">Login in</h1>

          <hr class="border" style="margin: 35px 0px 35px"/>

          <form>

            <div v-show="error_message.length > 0" class="alert alert-danger alert-dismissible fade show" role="alert" style="font-size: 0.9em;text-align: left;">
              <span v-text="error_message"></span>
              <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>

            <div v-show="success_message.length > 0" class="alert alert-success alert-dismissible fade show" role="alert" style="font-size: 0.9em;text-align: left;">
              <span v-text="success_message"></span>
              <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>

            <div class="form-floating mb-3">
              <input v-model="email" type="email" class="form-control form-control-lg" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Input your email address">
              <label for="exampleInputEmail1" class="form-label">Email address</label>
            </div>

            <div class="form-floating mb-3">
              <input v-model="password" type="password" class="form-control form-control-lg" id="exampleInputPassword1" placeholder="Input your password">
              <label for="exampleInputPassword1" class="form-label">Password</label>
            </div>

            <div class="mb-3 form-check float-start">
              <input type="checkbox" class="form-check-input" id="exampleCheck1">
              <label class="form-check-label" for="exampleCheck1">Check me out</label>
            </div>

            <div class="w-100 btn btn-primary" @click="signin">Submit</div>

          </form>

        </div>

      <div v-show="isVerification" class="card-body mr-2 ml-2 ">

        <h1 class="h3 mb-3 fw-normal float-start">Verification Code</h1>

        <hr class="border" style="margin: 35px 0px 35px"/>

        <form>

          <div v-show="error_message.length > 0" class="alert alert-danger alert-dismissible fade show" role="alert" style="font-size: 0.9em;text-align: left;">
            <span v-text="error_message"></span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>

          <div v-show="success_message.length > 0" class="alert alert-success alert-dismissible fade show" role="alert" style="font-size: 0.9em;text-align: left;">
            <span v-text="success_message"></span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>

          <div class="form-floating mb-3">
            <input v-model="code" type="number" class="form-control form-control-lg" id="verificationCode" placeholder="Enter Verification Code Sent to your phone">
            <label for="verificationCode" class="form-label">Verification Code</label>
          </div>

          <div class="w-100 btn btn-primary" @click="verify">Verify Account</div>

        </form>

      </div>

    </main>

    <div class="disclaimer">

      <span>We're committed to your privacy. WagePay uses the information you provide to us to contact you about our relevant content, products, and services. You may unsubscribe from these communications at any time. For more information, check out our <a href="#">Privacy policy</a> or  <a href="#">Contact Us</a>.</span>
    </div>

    </div>

</template>

<script>
import axios from "@/services/api";
import VueCountryCode from "vue-country-code";

export default {
  name: 'Signup',
  components: {
    VueCountryCode
  },
  data: function (){
    return {
      email: '',
      password:'',
      loading: '',
      isLogin: false,
      isSignup: true,
      isVerification: false,
      message: '',
      code: '',
      success_message: '',
      error_message: '',
      company_name: '',
      first_name: '',
      last_name:'',
      password1: '',
      msisdn: '',
      country: {
        "name": "Kenya",
        "iso2": "KE",
        "dialCode": "254"
      }
    }
  },
  mounted() {

    this.$store.dispatch("setCurrentPage","login");

  },
  computed: {

  },
  methods: {

    setCountry: function (country){

      this.country = country;

    },

    join: function () {

      this.error_message = '';
      this.success_message = '';

      if (!this.validEmail(this.email)) {

          this.error_message = "Please enter a valid email address";
          return;
      }

      /*
      if (this.password.length < 6) {

        this.error_message = "Please enter a password of more than 6 characters";
        return;
      }
      */

      if (this.first_name.length < 2) {

        this.error_message = "Missing first name";
        return;
      }

      if (this.last_name.length < 2) {

        this.error_message = "Missing Last name";
        return;
      }

      if (this.company_name.length < 2) {

        this.error_message = "Missing company name";
        return;
      }

      /*
      if (this.password !== this.password1) {

        this.error_message = "Password does not match";
        return;
      }
      */

      if (!this.msisdn || this.msisdn.length < 5) {

        this.error_message = "Invalid Mobile Number";
        return;
      }
      // format mobile number

      // remove any non numeric character
      var phone = this.msisdn.replace(/[^\d.-]/g, '')

      // if phone starts with country code remove it

      var phoneWithoutDialingCode = phone;
      var len = this.country.dialCode.length;
      var firstN = phone.slice(0, len);
      if(parseInt(firstN) === parseInt(this.country.dialCode)) {

        //phone number contains country code, remove it
        phoneWithoutDialingCode =  phone.slice(-1 * (phone.length - len));
      }

      var formattedMsisdn = this.country.dialCode + '' + parseInt(phoneWithoutDialingCode);

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_SIGNUP;

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var referrer = this.getValue("referrer");

      axios.post(path, JSON.stringify({
        name: this.company_name,
        first_name: this.first_name,
        last_name: this.last_name,
        contact_msisdn: parseInt(formattedMsisdn),
        contact_email: this.email,
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: referrer
      }))
          .then(res => {

            vm.loading = '';
            this.password = '';

            vm.error_message = '';
            vm.success_message = res.data.data.message;

            vm.isSignup = false;
            vm.isLogin = true;
            vm.isVerification = false;

          })
          .catch(err => {

            vm.loading = '';

            if (err.response.data.data) {

              vm.error_message = err.response.data.data;

            }
            else if (err.request) {

              vm.error_message = "Check your network connection and try again";

            }
            else {

              vm.error_message = "Failed, error occurred";

            }

          })

    },

    signin: function () {

      this.error_message = '';
      this.success_message = '';

      if (!this.validEmail(this.email)) {

        this.error_message = "Please enter a valid email address";
        return;
      }

      if (this.password.length < 4) {

        this.error_message = "Please enter a password of more than 4 characters";
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_LOGIN;

      var currentDate = new Date();
      var login_tag = parseInt(currentDate.getTime()+''+Math.floor(Math.random() * (9 * (Math.pow(10, 2)))) + (Math.pow(10, 2)));
      //this.setValue("login_tag",login_tag);
      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var referrer = this.getValue("referrer");

      axios.post(path, JSON.stringify({
        username: this.email,
        password: this.password,
        login_tag: login_tag,
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: referrer
      }))
          .then(res => {

            vm.loading = '';
            var msg = res.data.data;
            vm.error_message = '';
            vm.success_message = msg;

            vm.isLogin = false;
            vm.isVerification = true;
            vm.isSignup = false;
            vm.message = msg;

          })
          .catch(err => {

            vm.loading = '';

            if (err.response) {

              vm.error_message = err.response.data.data;

            }
            else if (err.request) {

              vm.error_message = "Check your network connection and try again";

            }
            else {

              vm.error_message = "Check your network connection and try again";

            }

          })

    },

    verify: function () {

      this.error_message = '';
      this.success_message = '';

      if (this.code.length < 4) {

        this.error_message =  "Please enter a verification code of more than 4 characters";
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_VERIFICATION_CODE;
      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var referrer = this.getValue("referrer");

      axios.post(path, JSON.stringify({
        username: this.email,
        code: parseInt(this.code),
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: referrer
      }))
          .then(res => {

            vm.loading = '';
            var profile = res.data.data;
            vm.success_message = "Login successful";
            vm.isLogin = true;
            vm.isVerification = false;
            vm.setProfile(profile);
            vm.goTo('home')

          })
          .catch(err => {

            vm.loading = '';

            if (err.response) {

              vm.error_message = err.response.data.data;

            }
            else if (err.request) {

              vm.error_message = "Check your network connection and try again";

            }
            else {

              vm.error_message = "Check your network connection and try again";

            }

          })

    },

  },
}

</script>