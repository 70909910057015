<template>

    <div class="">

      <div class="container-fluid">
        <div class="row">

          <div class="col-md-12">
            <div class="card custom-card-wrapper shadow-sm">

              <div class="card-body">

                <div class="font-weight-500">
                  Name of Payment Group
                </div>

                <form>
                  <div class="mb-3">
                    <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Enter name of payment group">
                  </div>
                </form>
                <!-- Tab links -->

                <ul class="nav nav-tabs">
                  <li class="nav-item">
                    <a class="nav-link tablinks text-dark" v-bind:class="getTabTitleClass('form')" @click="showTab('form')" aria-current="page" >Quick Add</a>
                  </li>
                  <li class="nav-item">
                    <a id="js-upload-file" class="nav-link tablinks text-dark" v-bind:class="getTabTitleClass('upload')" @click="showTab('upload')" >Upload Members</a>
                  </li>
                </ul>
                <!-- Tab content -->
                <div v-show="isActiveTab('form')" class="tabcontent p-0">

                  <div class="font-weight-500">
                    Business Details
                  </div>
                  <div class="row">
                    <div class="col-md-3">
                      <div class="mb-3">
                        <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Business Name">
                      </div>
                    </div>

                  </div>

                  <div class="font-weight-500">
                    Payment Details
                  </div>

                  <select class="form-select mb-2" aria-label="Default select example" id="selector">
                    <option  value="1">Bank</option>
                    <option value="2">Paybill</option>
                  </select>
                  <div class="row toggle-items" id="item1">

                    <div class="col-md-3">
                      <div class="mb-3">
                        <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Bank Name">
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mb-3">
                        <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Bank Acc Name">
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mb-3">
                        <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Bank Acc No">
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mb-3">
                        <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Bank Branch">
                      </div>
                    </div>

                  </div>
                  <div class="row toggle-items d-none" id="item2">

                    <div class="col-md-3">
                      <div class="mb-3">
                        <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Paybill Name">
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mb-3">
                        <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Paybill Number">
                      </div>
                    </div>
                  </div>

                </div>

                <div v-show="isActiveTab('upload')" class="tabcontent pt-3">
                  <div class="file-container">
                    <div class="file-overlay"></div>
                    <div class="file-wrapper">
                      <input class="file-input" id="js-file-input" type="file"/>
                      <div class="file-content">
                        <div class="file-infos">
                          <p class="file-icon"><i class="bi bi-upload" style="font-size: 64px; font-weight: bolds"></i><span class="icon-shadow"></span><span>DRAG & DROP HERE OR CLICK TO UPLOAD FILE</span></p>
                        </div>
                        <p class="file-name" id="js-file-name">No file selected</p>
                      </div>
                    </div>
                  </div>

                  <div style="width: 70vw; margin: auto">
                    <strong>Instructions</strong> Format your file exactly as the attached template. If you are using excel. make sure you import your data as CSV and confirm the formatting<br>
                    <span class="font-weight-500 text-danger"> Click here to download template</span>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

</template>

<script>

export default {
  name: 'business',
  components: {

  },
  data: function (){
    return {
      highlights: true,
      upcoming: false,
      today: false,
      sub_page: '',
      mqttClient:'',
      active_tab: 'form'
    }
  },
  mounted() {

    this.$store.dispatch("setCurrentPage","home");


    var supportDrag = (function () {

      let div = document.createElement("div");
      return (
          ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
          "FormData" in window &&
          "FileReader" in window
      );
    })();

    let input = document.getElementById("js-file-input");

    if (!supportDrag) {

      document.querySelectorAll(".has-drag")[0].classList.remove("has-drag");
    }

    input.addEventListener(
        "change",
        function () {

          document.getElementById("js-file-name").innerHTML = this.files[0].name;
          document
              .querySelectorAll(".file-input")[0]
              .classList.remove("file-input--active");
        },
        false
    );

    if (supportDrag) {
      input.addEventListener("dragenter", function () {
        document
            .querySelectorAll(".file-input")[0]
            .classList.add("file-input--active");
      });

      input.addEventListener("dragleave", function () {
        document
            .querySelectorAll(".file-input")[0]
            .classList.remove("file-input--active");
      });
    }



    // reset game filter
    //this.$store.dispatch("resetAllGames");
    //this.reloadProfile();

  },
  computed: {
    betslip_count: function (){

      return this.$store.state.betslip.total

    },
    current_sub_page: function () {

      return this.$store.state.current_sub_page;
    },
    sport_id: function () {

      return this.$store.state.sport_id;

    }
  },
  methods: {

    showTab: function(tabName) {

      this.active_tab = tabName;
    },

    isActiveTab: function(tabName) {

      return this.active_tab === tabName;
    },

    getTabClass: function(tabName) {

      return this.active_tab === tabName ? 'active-tab' : '';
    },

    getTabTitleClass: function(tabName) {

      return this.active_tab === tabName ? 'active' : '';

    },

    setUpcoming: function(){

      this.highlights = false;
      this.today = false;
      this.upcoming = true;
    },
    setToday: function(){

      this.highlights = false;
      this.today = true;
      this.upcoming = false;
    },
    setHighlight: function(){

      this.highlights = true;
      this.today = false;
      this.upcoming = false;
    },
    setSubPage: function (page){

      this.$store.dispatch("setCurrentSubPage",page);
      this.sub_page = page;

    },
    isHighlight: function(){

      return this.highlights
    },
    isToday: function(){

      return this.today
    },
    isUpcoming: function(){

      return this.upcoming
    },

    geSubPageActiveClass: function (subpage) {

      return subpage === this.sub_page ? 'active' : "";

    },
    setSport: function(sportID) {

      this.$store.dispatch("setSportID",sportID);

    }
  },
}

</script>

<style>
/*Image upload*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --overlay-color: rgba(0, 0, 0, .7);
  --dialog-color: #e9ecef;
  --dialog-border-radius: 5px;
  --icon-color: rgba(73, 80, 87, .6);
  --dialog-padding: 5px;
  --drag-over-background: #e3e5e8;
}

.file-container {
  font-family: sans-serif;
  font-size: 13pt;
  color: #4d4d4d;
}
.file-container .file-overlay {
  /*position: fixed;*/
  /*width: 100vw;*/
  /*height: 100vh;*/
  /*top: 0;*/
  /*right: 0;*/
  /*bottom: 0;*/
  /*left: 0;*/
  /*z-index: 10;*/
  /*background-color: var(--overlay-color);*/
}
.file-container .file-wrapper {
  /*position: fixed;*/
  display: block;
  width: 70vw;
  height: 300px;
  max-height: 500px;
  min-height: 300px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-color: var(--dialog-color);
  z-index: 20;
  border-radius: var(--dialog-border-radius);
  padding: var(--dialog-padding);
}
.file-container .file-wrapper .file-input {
  position: absolute;
  /*width: 100%;*/
  /*height: 100%;*/
  /*top: 0;*/
  /*right: 0;*/
  /*bottom: 0;*/
  /*left: 0;*/
  /*background-color: #000;*/
  /*z-index: 10;*/
  /*cursor: pointer;*/
  opacity: 0;
}
.file-container .file-wrapper .file-input--active + .file-content {
  background: var(--drag-over-background);
}
.file-container .file-wrapper .file-input--active + .file-content .file-icon {
  opacity: 0.5;
}
.file-container .file-wrapper .file-input--active + .file-content .file-icon i {
  animation-name: bounce;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
}
.file-container .file-wrapper .file-input--active + .file-content .file-icon .icon-shadow {
  animation-name: shrink;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
}
.file-container .file-wrapper .file-content {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  border: 2px dashed var(--icon-color);
  border-radius: var(--dialog-border-radius);
  transition: 0.2s;
}
.file-container .file-wrapper .file-content .file-infos {
  position: absolute;
  display: flex;
  width: 50%;
  height: 50%;
  min-width: 400px;
  min-height: 202px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  flex-direction: column;
  justify-content: center;
}
.file-container .file-wrapper .file-content .file-infos .file-icon {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  color: var(--icon-color);
  /*background-color: #f1f1f1;*/
  border-radius: var(--dialog-border-radius);
  padding: var(--dialog-padding);
  /*box-shadow: inset 0px 0px 6px 0px #ccc;*/
  transition: 0.2s;
}
.file-container .file-wrapper .file-content .file-infos .file-icon i {
  margin-bottom: 20px;
  width: 100%;
}
.file-container .file-wrapper .file-content .file-infos .file-icon .icon-shadow {
  position: relative;
  display: block;
  width: 95px;
  height: 7px;
  border-radius: 100%;
  background-color: var(--drag-over-background);
  top: -17px;
  margin-left: auto;
  margin-right: auto;
}
.file-container .file-wrapper .file-content .file-infos .file-icon > span {
  position: absolute;
  bottom: var(--dialog-padding);
  width: calc(100% - var(--dialog-padding) * 2);
}
.file-container .file-wrapper .file-content .file-infos .file-icon > span span {
  display: none;
}
.file-container .file-wrapper .file-content .file-infos .file-icon > span .has-drag {
  display: inline;
}
.file-container .file-wrapper .file-content .file-infos .file-icon i, .file-container .file-wrapper .file-content .file-infos .file-icon span {
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}
.file-container .file-wrapper .file-content .file-name {
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0;
  bottom: var(--dialog-padding);
  right: 0;
  font-weight: bold;
  font-size: 15pt;
  margin: auto;
  text-align: center;
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes shrink {
  0% {
    width: 95px;
  }
  50% {
    width: 75px;
  }
  100% {
    width: 95px;
  }
}
</style>