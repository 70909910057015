import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import {routes} from './routes'
import store from './store/store'
import jQuery from 'jquery'
import VueToast from  'vue-toast-notification';
import moment from 'moment';

import 'vue-toast-notification/dist/theme-sugar.css';
import SMESPAY from './smespay-v1';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import "bootstrap/dist/css/bootstrap.min.css"

import Cookies from 'js-cookie'
import './registerServiceWorker'
import axios from "@/services/api";

// adds pragmatic play games websockets to vue instance
Vue.prototype.SMESPAY = SMESPAY;

const $ = jQuery;
Vue.prototype.jQuery = $;
const EventBus = new Vue();
Vue.prototype.EventBus = EventBus;
//Vue.prototype.Swal = VueSweetalert2;

Vue.use(VueRouter);
Vue.config.productionTip = true
Vue.use(VueToast);
Vue.use(VueSweetalert2);

function notif(message,type) {

    $.notify({
        icon: "add_alert",
        message: message

    }, {
        type: type,
        timer: 3000,
        placement: {
            from: 'top',
            align: 'center'
        }
    });
}

Vue.mixin({
    methods: {
        /**
         * gets a random value from 0 to the supplied max
         * @param max
         * @returns {number}
         */
        getRandomInt:function (max) {

            return Math.floor(Math.random() * max);
        },
        randomString: function (length) {
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for (var i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() *
                    charactersLength));
            }
            return result;
        },
        getUIValue: function (text) {

            var len = text.length;
            if (len <= parseInt(process.env.VUE_APP_BASE_LENGTH)) {

                return 0;
            }
            return parseInt(text.slice(parseInt(process.env.VUE_APP_BASE_LENGTH), len), parseInt(process.env.VUE_APP_BASE_RADIX));
        },
        getBackendValue: function (text) {

            text = parseInt(text);
            return  this.randomString(parseInt(process.env.VUE_APP_BASE_LENGTH)) +''+ text.toString(parseInt(process.env.VUE_APP_BASE_RADIX));
        },

        getClientID: function () {

            var prof = this.getProfile();
            var n = 10;

            if (!prof) {

                return 'anonymous-' + Math.floor(Math.random() * (9 * (Math.pow(10, n)))) + (Math.pow(10, n))
            }

            n = 5;
            return "profile-" + prof.id + '-' + Math.floor(Math.random() * (9 * (Math.pow(10, n)))) + (Math.pow(10, n))

        },
        async oddsUpdate(payload) {

            var vm = this;

            if (Array.isArray(payload)) {

                this.jQuery.each(payload, function (k, v) {

                    switch (v.type) {

                        case 'ODDS':
                            var message = v.message;
                            var match_id = parseInt(message.match_id);
                            var market_id = parseInt(message.market_id);
                            var outcome_id = message.outcome_id;
                            var producer_id = parseInt(message.producer_id);
                            var producer_status = message.producer_status;
                            var specifier = message.specifier;
                            var status = parseInt(message.status);
                            var status_name = message.status_name;
                            var btimestamp = message.timestamp;
                            var odd_value = message.odd;
                            var active = parseInt(message.active);

                            var oddID = vm.getOddID(match_id, market_id, outcome_id, specifier, 'odd');
                            vm.EventBus.$emit('odds:' + oddID, {
                                status: status,
                                active: active,
                                odd: odd_value
                            });

                            var delays = 0;
                            if (message.processing_delays) {

                                delays = message.processing_delays;

                            } else {

                                var currentDate = new Date();
                                var timestamp = currentDate.getTime();
                                delays = (parseInt(timestamp) - parseInt(btimestamp))

                                //console.log(payload.event+" ==> "+match_id+"#"+market_id+" outcome "+outcome_id+" status "+status_name+" Delays "+(parseInt(timestamp) - parseInt(btimestamp))+"ms");
                            }

                            console.log(v.event + " ==> market " + market_id + " outcome " + outcome_id + " status " + status_name + " Delays " + delays + "ms");
                            break;

                        case 'PRODUCER-STATUS':
                            producer_id = parseInt(message.producer_id);
                            producer_status = parseInt(message.producer_status);

                            if (producer_id === 3) { // ignore prematch producer status events

                                break;
                            }

                            //producer_status = producer_status === 0 ? 1 : 0;

                            vm.EventBus.$emit('producer:status', {
                                producer_status: producer_status,
                                producer_id: producer_id
                            });

                            // currentDate = new Date();
                            //timestamp = currentDate.getTime();
                            //console.log("Processing producer status time is "+(parseInt(timestamp) - parseInt(btimestamp))+"ms");
                            break;

                        case 'PROFILE':
                            var notification = message.notification;
                            if (notification) {
                                var type = notification.type;
                                var title = notification.title;
                                var msg = notification.message;

                                if (type === "error") {

                                    this.setError(title, msg)

                                } else if (type === "success") {

                                    this.setSuccess(title, msg)

                                } else if (type === "warning") {

                                    this.setWarning(title, msg)

                                }

                            }
                            break;

                        case 'BET_STOP':
                            match_id = parseInt(v.match_id);
                            btimestamp = parseInt(v.timestamp);
                            var timestamp_sent = parseInt(v.timestamp_sent);
                            currentDate = new Date();
                            timestamp = currentDate.getTime();
                            vm.EventBus.$emit('betstop:match-' + match_id);
                            console.log(v.type + " ==> matchID " + match_id + " Delays " + (parseInt(timestamp) - parseInt(btimestamp)) + "ms Waiting time " + (parseInt(timestamp) - parseInt(timestamp_sent)) + "ms");
                            break;

                        case 'MARKET_STATUS':
                            match_id = parseInt(v.match_id);
                            market_id = parseInt(v.market_id);
                            btimestamp = parseInt(v.timestamp);
                            status = parseInt(v.status);
                            status_name = v.status_name;
                            timestamp_sent = parseInt(v.timestamp_sent);
                            currentDate = new Date();
                            timestamp = currentDate.getTime();
                            vm.EventBus.$emit('status:match-' + match_id + ':market-' + market_id, {
                                status: status
                            });
                            console.log(v.type + " ==> matchID " + match_id + " marketID " + market_id + " ==> " + status_name + " Delays " + (parseInt(timestamp) - parseInt(btimestamp)) + "ms Waiting time " + (parseInt(timestamp) - parseInt(timestamp_sent)) + "ms");
                            break;

                        case 'BETSLIP':
                            message = v.message;
                            match_id = parseInt(message.match_id);
                            market_id = parseInt(message.market_id);
                            outcome_id = message.outcome_id;
                            producer_id = parseInt(message.producer_id);
                            producer_status = message.producer_status;
                            specifier = message.specifier;
                            status = parseInt(message.status);
                            status_name = message.status_name;
                            btimestamp = message.timestamp;
                            odd_value = message.odd;
                            active = parseInt(message.active);

                            oddID = this.getOddID(match_id, market_id, outcome_id, specifier, 'odd');

                            var oddsChangedPayload = {};

                            oddsChangedPayload.id = oddID;
                            oddsChangedPayload.odds = odd_value;
                            oddsChangedPayload.status = status;
                            oddsChangedPayload.active = active;
                            oddsChangedPayload.previous_odds = odd_value;

                            this.EventBus.$emit('odds:changed', oddsChangedPayload);

                            delays = 0;
                            if (message.processing_delays) {

                                delays = message.processing_delays;

                            } else {

                                currentDate = new Date();
                                timestamp = currentDate.getTime();
                                delays = (parseInt(timestamp) - parseInt(btimestamp))
                                //console.log(payload.event+" ==> "+match_id+"#"+market_id+" outcome "+outcome_id+" status "+status_name+" Delays "+(parseInt(timestamp) - parseInt(btimestamp))+"ms");
                            }

                            if (market_id == 45 && outcome_id == 274)
                                console.log(match_id + " ==> market " + market_id + " outcome " + outcome_id + " status " + status_name + " Delays " + delays + "ms ==> odds " + odd_value);

                            //console.log(payload.event+" ==> market "+market_id+" outcome "+outcome_id+" status "+status_name+" Delays "+delays+"ms");
                            break;

                    }

                })

                return;
            }

            switch (payload.type) {

                case 'ODDS':
                    var message = payload.message;
                    var match_id = parseInt(message.match_id);
                    var market_id = parseInt(message.market_id);
                    var outcome_id = message.outcome_id;
                    var producer_id = parseInt(message.producer_id);
                    var producer_status = message.producer_status;
                    var specifier = message.specifier;
                    var status = parseInt(message.status);
                    var status_name = message.status_name;
                    var btimestamp = message.timestamp;
                    var odd_value = message.odd;
                    var active = parseInt(message.active);

                    var oddID = this.getOddID(match_id, market_id, outcome_id, specifier, 'odd');
                    this.EventBus.$emit('odds:' + oddID, {
                        status: status,
                        active: active,
                        odd: odd_value
                    });

                    var delays = 0;
                    if (message.processing_delays) {

                        delays = message.processing_delays;

                    } else {

                        var currentDate = new Date();
                        var timestamp = currentDate.getTime();
                        delays = (parseInt(timestamp) - parseInt(btimestamp))
                        //console.log(payload.event+" ==> "+match_id+"#"+market_id+" outcome "+outcome_id+" status "+status_name+" Delays "+(parseInt(timestamp) - parseInt(btimestamp))+"ms");
                    }

                    if (market_id == 45 && outcome_id == 274)
                        console.log(match_id + " ==> market " + market_id + " outcome " + outcome_id + " status " + status_name + " Delays " + delays + "ms ==> odds " + odd_value);

                    //console.log(payload.event+" ==> market "+market_id+" outcome "+outcome_id+" status "+status_name+" Delays "+delays+"ms");
                    break;

                case 'BETSLIP':
                    message = payload.message;
                    match_id = parseInt(message.match_id);
                    market_id = parseInt(message.market_id);
                    outcome_id = message.outcome_id;
                    producer_id = parseInt(message.producer_id);
                    producer_status = message.producer_status;
                    specifier = message.specifier;
                    status = parseInt(message.status);
                    status_name = message.status_name;
                    btimestamp = message.timestamp;
                    odd_value = message.odd;
                    active = parseInt(message.active);

                    oddID = this.getOddID(match_id, market_id, outcome_id, specifier, 'odd');

                    var oddsChangedPayload = {};

                    oddsChangedPayload.id = oddID;
                    oddsChangedPayload.odds = odd_value;
                    oddsChangedPayload.status = status;
                    oddsChangedPayload.active = active;
                    oddsChangedPayload.previous_odds = odd_value;

                    this.EventBus.$emit('odds:changed', oddsChangedPayload);

                    delays = 0;
                    if (message.processing_delays) {

                        delays = message.processing_delays;

                    } else {

                        currentDate = new Date();
                        timestamp = currentDate.getTime();
                        delays = (parseInt(timestamp) - parseInt(btimestamp))
                        //console.log(payload.event+" ==> "+match_id+"#"+market_id+" outcome "+outcome_id+" status "+status_name+" Delays "+(parseInt(timestamp) - parseInt(btimestamp))+"ms");
                    }

                    if (market_id == 45 && outcome_id == 274)
                        console.log(match_id + " ==> market " + market_id + " outcome " + outcome_id + " status " + status_name + " Delays " + delays + "ms ==> odds " + odd_value);

                    //console.log(payload.event+" ==> market "+market_id+" outcome "+outcome_id+" status "+status_name+" Delays "+delays+"ms");
                    break;

                case 'PRODUCER-STATUS':
                    producer_id = parseInt(message.producer_id);
                    producer_status = parseInt(message.producer_status);

                    if (producer_id === 3) { // ignore prematch producer status events

                        break;
                    }

                    this.EventBus.$emit('producer:status', {
                        producer_status: producer_status,
                        producer_id: producer_id
                    });

                    // currentDate = new Date();
                    //timestamp = currentDate.getTime();
                    //console.log("Processing producer status time is "+(parseInt(timestamp) - parseInt(btimestamp))+"ms");
                    break;

                case 'PROFILE':
                    var notification = payload.message.notification;
                    if (notification) {

                        var type = notification.type;
                        var title = notification.title;
                        var msg = notification.message;

                        if (type === "error") {

                            this.setError(title, msg)

                        } else if (type === "success") {

                            this.setSuccess(title, msg)

                        } else if (type === "warning") {

                            this.setWarning(title, msg)

                        }

                    }
                    break;

                case 'LOGIN':

                    var login_tag = this.getValue("login_tag");
                    var current_login_tag = payload.message.login_tag;

                    console.log('got login_tag ' + login_tag + ' and current_login_tag ' + current_login_tag);

                    if (parseInt(login_tag) !== parseInt(current_login_tag)) {

                        this.setError("Session Expired", "Your session on this device has expired");
                        this.logout();
                    }

                    break;

                case 'BET_STOP':
                    match_id = parseInt(payload.match_id);
                    //btimestamp = parseInt(payload.timestamp);
                    //var timestamp_sent = parseInt(payload.timestamp_sent);
                    //currentDate = new Date();
                    //timestamp = currentDate.getTime();
                    this.EventBus.$emit('betstop:match-' + match_id);
                    //console.log(payload.type+" ==> matchID "+match_id+" Delays "+(parseInt(timestamp) - parseInt(btimestamp))+"ms Waiting time "+(parseInt(timestamp) - parseInt(timestamp_sent))+"ms");
                    break;

                case 'MARKET_STATUS':
                    match_id = parseInt(payload.match_id);
                    market_id = parseInt(payload.market_id);
                    //btimestamp = parseInt(payload.timestamp);
                    status = parseInt(payload.status);
                    //status_name = payload.status_name;
                    //timestamp_sent = parseInt(payload.timestamp_sent);
                    //currentDate = new Date();
                    //timestamp = currentDate.getTime();
                    this.EventBus.$emit('status:match-' + match_id + ':market-' + market_id, {
                        status: status
                    });
                //console.log(payload.type+" ==> matchID "+match_id+" marketID "+market_id+" ==> "+status_name+" Delays "+(parseInt(timestamp) - parseInt(btimestamp))+"ms Waiting time "+(parseInt(timestamp) - parseInt(timestamp_sent))+"ms");

            }

        },
        async uxUpdate(payload) {

            var vm = this;

            var procesed = false;

            switch (payload.event) {

                case "producer_status":

                    procesed = true;
                    var producer_id = parseInt(payload.message.producer_id);
                    var producer_status = parseInt(payload.message.producer_status);

                    console.log('Got producerID ' + producer_id + ' status ' + producer_status)

                    this.EventBus.$emit('producer:status', {
                        producer_status: producer_status,
                        producer_id: producer_id
                    });
                    break;

                case "odds_change":
                    procesed = true;
                    var message = payload;
                    var match_id = parseInt(message.match_id);
                    var btimestamp = parseInt(payload.timestamp);

                    var markets = message.market;

                    vm.jQuery.each(markets, function (k, v) {

                        var market_id = parseInt(v.market_id);
                        var status = v.status;
                        var specifier = v.specifier === undefined || v.specifier === null ? '' : v.specifier ;

                        var outcome = v.outcome;

                        if(outcome.length === 0 ) {

                            var topic = 'status:match-'+match_id+':market-'+market_id;
                            vm.EventBus.$emit(topic,{
                                status: status
                            });

                        } else {

                            vm.jQuery.each(outcome, function (key, val) {

                                var outcome_id = val.outcome_id;
                                var active = parseInt(val.active);
                                var odd_value = val.odd;
                                var previous_odds = val.previous_odds;
                                var odd_id = val.odd_id;
                                status = parseInt(val.status);

                                var ux = {
                                    status: status,
                                    active:active,
                                    odd: odd_value,
                                    previous_odds: previous_odds,
                                    odd_id: odd_id
                                }

                                var oddID = vm.getOddID(match_id,market_id,outcome_id,specifier,'odd');
                                var topic = 'odds:'+oddID;
                                vm.EventBus.$emit(topic,ux);

                                topic = 'odds:'+odd_id;
                                vm.EventBus.$emit(topic,ux);

                            });
                        }

                    });

                    vm.EventBus.$emit('match:reload:'+match_id, payload);
                    var currentDate = new Date();
                    var timestamp = parseInt(currentDate.getTime());
                    console.log(payload.event + " ==> " + match_id + " Delays " + (timestamp - btimestamp) + "ms");
                    break;

                case 'bet_stop':
                    procesed = true;
                    message = payload;
                    match_id = parseInt(payload.match_id);
                    btimestamp = parseInt(payload.timestamp);
                    currentDate = new Date();
                    timestamp = parseInt(currentDate.getTime());
                    vm.EventBus.$emit('betstop:match-'+match_id);
                    vm.EventBus.$emit('match:reload:'+match_id,payload);

                    console.log(payload.event + " ==> " + match_id + " Delays " + (timestamp - btimestamp) + "ms");

                    break;

                default:

                    match_id = parseInt(payload.match_id);
                    btimestamp = parseInt(payload.timestamp);
                    vm.EventBus.$emit('match:reload:' + match_id, payload);
                    currentDate = new Date();
                    timestamp = parseInt(currentDate.getTime());
                    console.log(payload.event + " ==> " + match_id + " Delays " + (timestamp - btimestamp) + "ms");
            }
            /*
            switch (payload.event) {

                case "producer_status":

                    var producer_id = parseInt(payload.message.producer_id);
                    var producer_status = parseInt(payload.message.producer_status);

                    console.log('Got producerID ' + producer_id + ' status ' + producer_status)

                    this.EventBus.$emit('producer:status', {
                        producer_status: producer_status,
                        producer_id: producer_id
                    });

                    break;

                case 'odds_change':
                    var message = payload;
                    var match_id = parseInt(message.match_id);
                    //var producer_id = parseInt(message.producer_id);
                    //var producer_status = message.producer_status;
                    var btimestamp = message.betradar_timestamp;

                    var markets = message.markets;
                    $.each(markets, function (k, v) {

                        var market_id = parseInt(v.market_id);
                        var status = parseInt(v.status);
                        var status_name = v.status_name;
                        var specifier = v.specifiers;

                        var outcome = v.outcome;

                        if(outcome.length === 0 ) {

                            vm.EventBus.$emit('status:match-'+match_id+':market-'+market_id,{
                                status: status
                            });

                            var currentDate = new Date();
                            var timestamp = currentDate.getTime();
                            if(market_id === 1)
                                console.log(payload.event+" ==> "+match_id+"#"+market_id+" status "+status_name+" Delays "+(parseInt(timestamp) - parseInt(btimestamp))+"ms");

                        } else {

                            $.each(outcome, function (key, val) {

                                var outcome_id = val.outcome_id;
                                var active = parseInt(val.active);
                                var odd_value = val.odd;
                                var ux = {
                                    status: status,
                                    active:active,
                                    odd: odd_value
                                }

                                var oddID = vm.getOddID(match_id,market_id,outcome_id,specifier,'odd');
                                vm.EventBus.$emit('odds:'+oddID,ux);

                                var currentDate = new Date();
                                var timestamp = currentDate.getTime();
                                if(market_id === 1) {
                                    console.log(payload.event + " ==> " + match_id + "#" + market_id + " outcome " + outcome_id + " status " + status_name + " odds ==> " + odd_value + " Delays " + (parseInt(timestamp) - parseInt(btimestamp)) + "ms");
                                    console.log('ODD ID odds:'+oddID+' payload '+JSON.stringify(ux,undefined,2))
                                }

                            });
                        }

                    });
                    vm.EventBus.$emit('match:reload:'+match_id);

                    break;

                case 'bet_stop':
                    message = payload;
                    match_id = parseInt(payload.match_id);
                    btimestamp = parseInt(payload.betradar_timestamp);
                    var currentDate = new Date();
                    var timestamp = currentDate.getTime();
                    vm.EventBus.$emit('betstop:match-'+match_id);
                    vm.EventBus.$emit('match:reload:'+match_id);

                    console.log(payload.event+" ==> matchID "+match_id+" Delays "+(parseInt(timestamp) - parseInt(btimestamp)));
                    break;
            }
            */

            if(!procesed) {

                console.log(JSON.stringify(payload));

                switch (payload.type) {

                    case 'PROFILE':

                        var notification = payload.message.notification;

                        if (notification) {

                            var type = notification.type;
                            var title = notification.title;
                            var msg = notification.message;

                            if (type === "error") {

                                this.setError(title, msg)

                            } else if (type === "success") {

                                this.setSuccess(title, msg)

                            } else if (type === "warning") {

                                this.setWarning(title, msg)

                            }

                            this.reloadProfile();

                        }
                        break;

                    case 'LOGIN':

                        var login_tag = this.getValue("login_tag");
                        var current_login_tag = payload.message.login_tag;
                        if (parseInt(login_tag) !== parseInt(current_login_tag)) {

                            this.setError("Session Expired", "Your session on this device has expired");
                            this.logout();
                        }

                        break;

                }
            }

        },
        async systemNotification(payload) {

            switch (payload.type) {

                case 'PROFILE':

                    var notification = payload.message.notification;

                    if (notification) {

                        var type = notification.type;
                        var title = notification.title;
                        var msg = notification.message;
                        var delivery = notification.delivery === undefined ? '' : notification.delivery;

                        if(delivery !== 'background') {

                            if (type === "error") {

                                this.setError(title, msg)

                            } else if (type === "success") {

                                this.setSuccess(title, msg)

                            } else if (type === "warning") {

                                this.setWarning(title, msg)

                            }
                        }

                        this.reloadProfile();

                    }
                    break;

                case 'LOGIN':

                    var login_tag = this.getValue("login_tag");
                    var current_login_tag = payload.message.login_tag;
                    if (parseInt(login_tag) !== parseInt(current_login_tag)) {

                        this.setError("Session Expired", "Your session on this device has expired");
                        this.logout();
                    }

                    break;

            }
        },

        formatOdds: function (x) {

            if (x === undefined) {

                return 1;

            }

            return parseFloat(x).toFixed(2);
        },

        getProfile: function () {

            var p = Cookies.get("i")

            if (p) {

                if (p === "undefined") {

                    return false;
                }

                try {

                    var data = JSON.parse(p)
                    return data;

                } catch (err) {

                    console.log(err.message)
                    return false;
                }
            }

            return false;
        },

        setProfile: function (profile) {

            console.log('Set profile '+JSON.stringify(profile))

            Cookies.set("i", JSON.stringify(profile), {expires: 2, secure: false});
            //this.EventBus.$emit('profile:balance', profile);
            this.setClient(profile.clients[0])
        },

        setClient: function (client) {

            Cookies.set("c", JSON.stringify(client), {expires: 2, secure: false});
            this.setAuth(client.token)
        },

        getClient: function () {

            var p = Cookies.get("c")

            if (p) {

                if (p === "undefined") {

                    return false;
                }

                try {

                    var data = JSON.parse(p)
                    return data;

                } catch (err) {

                    console.log(err.message)
                    return false;
                }
            }

            return false;
        },

        setAuth: function (auth) {

            Cookies.set("a", auth, {expires: 2, secure: false});
        },

        getAuth: function () {

            var p = Cookies.get("c")

            if (p) {

                if (p === "undefined") {

                    return false;
                }

                try {

                    var data = JSON.parse(p);
                    return data.token;

                } catch (err) {

                    console.log(err.message)
                    return false;
                }
            }

            return false;
        },

        removeProfile: function () {

            Cookies.remove("i");
            Cookies.remove("a")
            Cookies.remove("c")

        },

        removeAuth: function () {

            Cookies.remove("a")
        },

        reloadProfile: function () {

            var profile = this.getProfile();
            var vm = this;

            if (profile === false || profile === undefined) {

                return
            }

            var data = {};
            var path = process.env.VUE_APP_URL_BALANCE.replace("{profile_id}", profile.d);

            axios.post(path, JSON.stringify(data),{
                headers: {
                    'api-key': vm.getAuth()
                },
            })
                .then(res => {

                    const profile = res.data.message;

                    var login_tag = this.getValue("login_tag");
                    var current_login_tag = profile.l;

                    console.log('got login_tag ' + login_tag + ' and current_login_tag ' + current_login_tag);

                    if (parseInt(login_tag) !== parseInt(current_login_tag)) {

                        vm.setError("Session Expired", "Your session on this device has expired");
                        vm.logout();
                    } else {

                        vm.setProfile(profile);
                    }

                })
                .catch(err => {

                    if (err.response) {

                        if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 || parseInt(err.response.status) === 428 ) {

                            vm.setError("Session Expired", "Your session on this device has expired");
                            vm.logout();
                            return;

                        } else {

                            vm.setError("Failed",err.response.data.message)

                        }

                    } else if (err.request) {

                        console.log(JSON.stringify(err.request));

                    } else {

                        console.log(JSON.stringify(err));

                    }
                })
        },

        isMobile: function () {

            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

        },
        isDesktop: function () {

            return !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

        },
        mysqlDate: function (now) {

            //YYYY-MM-DD
            var yyyy = now.getFullYear();
            var months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
            var mm = months[now.getMonth()];
            var dt = now.getDate();
            var dd = dt > 9 ? dt : '0' + dt;
            return yyyy + '-' + mm + '-' + dd;
        },
        getToday: function () {

            return this.mysqlDate(new Date());
        },
        daysFromNow: function (days) {

            var date = new Date();
            date.setDate(date.getDate() + days);
            return this.mysqlDate(date);
        },
        getDayOfWeek: function (date) {

            var now = new Date(date);
            console.log('GOT date as ' + now);

            var weeks = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
            return weeks[now.getDay()];
        },
        getLongDayOfWeek: function (date) {

            var now = new Date(date);
            console.log('GOT date as ' + now);

            var weeks = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
            return weeks[now.getDay()];
        },
        formatMatchDate: function (date) {

            if (date === false || date === undefined || date === "" || date === null) {

                return ""
            }

            var res = date.replace(" ", "T");
            var d = new Date(res);
            var months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
            var mon = months[d.getMonth()];
            var dt = d.getDate();
            var day = dt > 9 ? dt : '0' + dt;
            //var hr = d.getHours();
            //var hour = hr > 9 ? hr : '0' + hr;
            //var min = d.getMinutes();
            //var minutes = min > 9 ? min : '0' + min;
            var formated = day + '/' + mon;// + ', ' + hour + ':' + minutes;
            return formated;
        },
        timeToStart: function (date) {

            if (date === false || date === undefined || date === "" || date === null) {

                return ""
            }

            var res = date.replace(" ", "T");
            var d = new Date(res);
            var now = new Date();

            if (now > d) {

                return '';
            }

            var seconds = (d - now) / 1000;

            if (seconds < 60) {

                console.log('GOT difference between ' + date + ' and now ' + now + ' is ' + seconds + 's ')
                return seconds + ' Sec';
            }

            var minutes = parseInt(seconds / 60);
            console.log('GOT difference between ' + date + ' and now ' + now + ' is ' + minutes + 'min')

            return minutes + ' Min';

        },
        formatCurrency: function (num) {

            if (num === false || isNaN(parseFloat(num)) || isNaN(num) || num === undefined || num === "" || num === null) {

                return 0
            }

            num = parseFloat(num).toFixed(2)
            var num_parts = num.toString().split(".");
            num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return num_parts.join(".");
        },
        showMenu: function () {

            document.getElementById("menu-init").click();

            //this.bestlip_visible = true;
        },
        copyToClipboard: function (text) {

            if (window.clipboardData && window.clipboardData.setData) {
                // IE specific code path to prevent textarea being shown while dialog is visible.
                return window.clipboardData.setData("Text", text);

            } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {

                var textarea = document.createElement("textarea");
                textarea.textContent = text;
                textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in MS Edge.
                document.body.appendChild(textarea);
                textarea.select();
                try {
                    return document.execCommand("copy");  // Security exception may be thrown by some browsers.
                } catch (ex) {
                    console.warn("Copy to clipboard failed.", ex);
                    return false;
                } finally {
                    document.body.removeChild(textarea);
                }
            }
        },
        toTop: function () {

            window.scrollTo(0, 0);

        },
        getURL: function (path) {

            if (path.length > 0) {

                if (path.substring(0, 1) == "/") {

                    // remove the first /
                    path = path.substring(1);
                }
            }


            return process.env.VUE_APP_ASSETS_BASE_URL + path;

        },
        saveObject: function (key, value) {

            value = JSON.stringify(value);

            if (typeof (Storage) !== "undefined") {

                localStorage.setItem(key, value);

            } else {

                document.cookie = key + "=" + value;
            }
        },
        setValue: function (key, value) {

            if (typeof (Storage) !== "undefined") {

                localStorage.setItem(key, value);

            } else {

                document.cookie = key + "=" + value;
            }
        },
        getObject: function (key) {

            if (typeof (Storage) !== "undefined") {

                var post = localStorage.getItem(key);

                if (post === undefined ) {

                    return false;
                }

                return JSON.parse(post);

            } else {

                return JSON.parse(this.getCookie(key));

            }
        },

        shortenName: function (name, characters) {

            if (name == undefined) {

                return name
            }

            name = name.trim();

            if (name.length <= characters) {

                return name;
            }

            return name.substring(0, characters) + '...';

        },

        getValue: function (key) {

            if (typeof (Storage) !== "undefined") {

                var post = localStorage.getItem(key);
                if (post == "undefined") {
                    return '';
                }

                return post;
            } else {
                return this.getCookie(key);
            }
        },

        removeObject: function (key) {

            if (typeof (Storage) !== "undefined") {
                localStorage.removeItem(key);
            } else {
                document.cookie = key + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            }

        },

        getCookie: function (ckey) {

            var key = ckey + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {

                    c = c.substring(1);

                }
                if (c.indexOf(key) == 0) {

                    return c.substring(key.length, c.length);
                }
            }
            return '';
        },

        goBack: function () {

            this.$router.back()
        },

        goHome: function () {

            this.goTo('home');
        },

        setError: function (title, body) {

            console.log('set error '+body);

            Vue.$toast.open({
                message: body,
                type: 'warning',
                duration: 4000,
                dismissible: true,
                position: 'bottom'
                // all of other options may go here
            });
        },

        setWarning: function (title, body) {

            Vue.$toast.open({
                message: body,
                type: 'warning',
                duration: 4000,
                dismissible: true,
                position: 'bottom'
                // all of other options may go here
            });
        },

        setSuccess: function (title, body) {

            Vue.$toast.open({
                message: body,
                type: 'success',
                duration: 4000,
                dismissible: true,
                position: 'bottom'
                // all of other options may go here
            });

        },

        reset: function () {

            this.$store.dispatch("resetAlerts");

        },

        logout: function () {

            this.closeMenu();
            // set profile to false
            this.removeProfile();
            // reset alerts
            this.reset();
            this.$router.push({name: 'login', params: {}});

        },

        poll1: function (tt) {

            console.log(tt)
        },

        getURLParameters: function() {

            var query = window.location.search.substring(1);

            var vars = query.split("&");

            var query_string = {};

            for (var i = 0; i < vars.length; i++) {

                var pair = vars[i].split("=");
                // If first entry with this name
                if (typeof query_string[pair[0]] === "undefined") {

                    query_string[pair[0]] = decodeURIComponent(pair[1]);
                    // If second entry with this name

                } else if (typeof query_string[pair[0]] === "string") {

                    var arr = [query_string[pair[0]], decodeURIComponent(pair[1])];
                    query_string[pair[0]] = arr;
                    // If third or later entry with this name

                } else {

                    query_string[pair[0]].push(decodeURIComponent(pair[1]));

                }
            }

            return query_string;
        },
        goTo: function (Name) {

            try {

                //this.dismiss();
                this.closeMenu();

                Name = Name.toLowerCase();

                if (this.$store.state.current_page === Name)
                    return

                this.$router.push({name: Name});
            }
            catch (e) {

                console.log(e.message)
            }
        },
        sortObject: function(obj) {

            return Object.keys(obj).sort(function(a, b) {
                return parseFloat(a) - parseFloat(b);
            }).reduce(function (result, key) {
                result[key] = obj[key];
                return result;
            }, {});
        },
        notify: function(message, type) {

            //no-undef
            notif(message,type);
        },
        closeMenu: function(){

            var el = document.getElementById("menu-close");
            if(el){

                el.click();
            }

        },
        formatStake: function (num) {

            if (num === false || isNaN(parseFloat(num)) || isNaN(num) || num === undefined || num === "" || num === null) {

                return 0
            }

            num = parseFloat(num).toFixed(0)
            var num_parts = num.toString().split(".");
            num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return num_parts.join(".");
        },
        validEmail: function(email){

            var re = /\S+@\S+\.\S+/;
            return re.test(email);
        },
        getKey: function (index) {

            var prefix = "random-key-"+index;
            return Math.random().toString(10).replace('0.', prefix + '-');
        },
        closeModal: function (modalID){

            var id = "close-"+modalID;
            document.getElementById(id).click();
        },
        openModal: function (modalID){

            var id = "close-"+modalID;
            document.getElementById(id).click();
        },
        formatDate: function (date) {

            //2022-04-25 16:25:24
            var fm = 'YYYY-MM-DD';
            return moment(date,fm).format('Do MMM YYYY');
        },

        formatDateShort: function (date) {

            //2022-04-25 16:25:24
            var fm = 'YYYY-MM-DD';
            return moment(date,fm).format('Do MMM YYYY');
        },

        formatDateWithoutYear: function (date) {

            //2022-04-25 16:25:24
            var fm = 'YYYY-MM-DD';
            return moment(date,fm).format('Do MMM');
        },

        formatDateTime: function (date) {

            //2022-04-25 16:25:24
            var fm = 'YYYY-MM-DD hh:mm:ss';
            return moment(date,fm).format('Do MMM YY, h:mm a');
        },

    }
})

const router = new VueRouter({
    mode: 'hash',
    routes
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {

    window.scrollTo(0, 0);

    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));

    next();
});

var v = new Vue({
    router,
    store,
    render: h => h(App),
    mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app');

if (!v) {

    window.location.href = 'https://lite.omoka.bet';
}
import "bootstrap/dist/js/bootstrap.min.js"
