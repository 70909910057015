<template>

    <div class="">

      <div class="container-fluid" v-bind:class="loading">

        <div class="row">

          <div class="col-md-4">

            <div  class="card custom-card-wrapper" style="margin-bottom: 10px;">

              <div class="card-header shadow-sm p-2 d-flex justify-content-between shadow-sm">
                <a data-bs-toggle="modal" data-bs-target="#create-worksheet" class="primary-btn " ><i class="bi bi-plus-circle-fill"></i> Create Timesheet</a>
              </div>

              <div class="card-body p-0">

                <div class="card-body-header shadow-sm p-2 d-flex justify-content-between shadow-sm">
                  Active Timesheets <span class="badge rounded-pill bg-primary" v-text="active_timesheets"></span>
                </div>

                <table v-if="parseInt(active_timesheets) > 0" class="table table-striped">
                  <thead class=" ">
                    <tr>
                      <th>Name</th>
                      <th>Start</th>
                      <th>End</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(group,index) in timesheets" v-bind:key="getKey(index)"  @click="setTimesheet(group,index)">
                      <td v-text="group.name"></td>
                      <td v-text="formatDate(group.start_date)"></td>
                      <td v-text="formatDate(group.end_date)"></td>
                    </tr>
                  </tbody>
                </table>

              </div>

            </div>

          </div>

          <div class="col-md-8" v-show="current_view == 'about'">

            <div class="card custom-card-wrapper">

              <div class="card-header line-height-1 p-2 d-flex shadow-sm justify-content-between">
                <div>
                  <span v-text="timesheet.name"></span>
                  <br>
                  <small class="text-success text-small font-weight-500"><span v-text="timesheet.members"></span> members</small>
                </div>

                <div>
                  <button type="button" class="btn bg-green btn-sm mr-1" @click="setCurrentView('members')">
                    <i class="bi bi-plus-circle-fill"></i> Add Member(s)
                  </button>
                </div>
              </div>

              <div class="card-body p-0">

                <div class="card-body-header-lg p-2 d-flex shadow-sm justify-content-between">

                  <div class="input-group mb-0" style="height: 100%;">
                    <input type="text" class="form-control" placeholder="Search" aria-label="Recipient's Name" aria-describedby="button-addon2" v-model="search">
                    <button @click="searchMember"  class="btn btn-outline-secondary btn-sm" type="button" id="button-addon2"><i class="bi bi-search"></i></button>
                  </div>

                  <div class="">
                    <button type="button" class="btn btn-primary btn-sm m-1" data-bs-toggle="modal" data-bs-target="#update-payment-group">
                      <i class="bi bi-pencil"></i> Edit
                    </button>

                    <button  @click="deleteTimesheet(timesheet)" type="button" class="btn btn-danger btn-sm m-1">
                      <i class="bi bi-x"></i> Delete
                    </button>

                    <button type="button" class="btn btn-secondary btn-sm m-1">
                      <i class="bi bi-download"></i> Export
                    </button>
                  </div>

                </div>

                <div class="card-body-subheader p-2 d-flex justify-content-between border-bottom">

                  <div class="row" style="width: 100%">

                    <div class="col-sm-7 pt-1">

                      <button type="button" class="btn btn-primary btn-sm mr-1"  @click="setCurrentView('attendance')">
                        <i class="bi bi-card-checklist"></i> Mark Attendance
                      </button>

                      <button type="button" class="btn bg-custom-primary btn-sm" @click="viewPayments">
                        <i class="bi bi-cash-stack"></i> Payments
                      </button>

                    </div>

                    <div class="col-sm-5">
                      <div class="row">
                        <div class="col-12">
                          <span class="font-weight-500 float-end" style="color: var(--primaryColor) !important">Total Amount: KES. {{ formatStake(timesheet.amount) }}</span>
                        </div>
                        <div class="col-12" v-html="getGroupStatus(timesheet.status)"></div>
                      </div>
                    </div>

                  </div>

                </div>

                <vuetable
                    ref="vuetable"
                    api-url="request/table"
                    :api-mode="apiMode"
                    :fields="fields"
                    :sort-order="sortOrder"
                    :css="css.table"
                    pagination-path=""
                    :per-page="perPage"
                    :append-params="moreParams"
                    :http-fetch="fetchMembers"
                    :load-on-start="loadOnStart"
                    @vuetable:pagination-data="onPaginationData"
                    @vuetable:loading="onLoading"
                    @vuetable:loaded="onLoaded">

                  <div slot="sequence-slot" slot-scope="props">
                    {{ props.rowIndex + 1}}
                  </div>

                  <div slot="actions-slot" slot-scope="props">
                    <div class="btn-group">
                      <button type="button" class="btn btn-primary btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        More
                      </button>
                      <ul class="dropdown-menu">

                        <li>
                          <a class="dropdown-item" @click="deleteMember(props.rowData)">Remove Member</a>
                        </li>

                        <li>
                          <a class="dropdown-item" @click="changeAmount(props.rowData)">Update Amount</a>
                        </li>

                        <li>
                          <a class="dropdown-item" @click="editMember(props.rowData)" data-bs-toggle="modal" data-bs-target="#update-member-details">Update Details</a>
                        </li>

                      </ul>

                    </div>
                  </div>

                  <div slot="payment-method-slot" slot-scope="props">
                    <div class="row">
                      <div class="col-12">
                        <span style="font-weight: 700;color: var(--primaryColor)" v-show="parseInt(props.rowData.payment_method) === 1">MPESA</span>
                        <span style="font-weight: 700;color: var(--primaryColor)" v-show="parseInt(props.rowData.payment_method) === 3">PayBill {{props.rowData.paybill}}</span>
                        <span style="font-weight: 700;color: var(--primaryColor)" v-show="parseInt(props.rowData.payment_method) === 4">Till {{props.rowData.paybill}}</span>
                        <span style="font-weight: 700;color: var(--primaryColor)" v-show="parseInt(props.rowData.payment_method) === 2" v-text="getBankName(props.rowData.paybill)"></span>
                      </div>

                      <div class="col-12" v-show="parseInt(props.rowData.payment_method) === 1">
                        <span v-text="props.rowData.account"></span>
                      </div>
                      <div class="col-12" v-show="parseInt(props.rowData.payment_method) === 2">
                        <span v-text="props.rowData.account"></span>
                      </div>
                      <div class="col-12" v-show="parseInt(props.rowData.payment_method) === 3">
                        Ac.  <span v-text="props.rowData.account"></span>
                      </div>
                      <div class="col-12" v-show="parseInt(props.rowData.payment_method) === 4">
                        Ac.  <span v-text="props.rowData.account"></span>
                      </div>

                    </div>
                  </div>

                  <div slot="recipient-slot" slot-scope="props">
                    <div style="color: var(--primaryColor)" v-text="props.rowData.recipient_name"></div>
                    <div v-text="props.rowData.msisdn"></div>
                  </div>

                  <div slot="payment-slot" slot-scope="props">

                    <div style="color: var(--primaryColor)">KES. {{ formatStake(props.rowData.daily_rate) }} / Day </div>

                    <div v-show="parseInt(props.rowData.payment_due) > 0" style="color: var(--dangerColor)">
                     Due KES. {{ formatStake(props.rowData.payment_due) }}
                    </div>

                    <div v-show="parseInt(props.rowData.payment_paid) > 0" style="color: var(--secondaryColor)">
                      Paid KES. {{ formatStake(props.rowData.payment_paid) }}
                    </div>

                  </div>

                  <div slot="attendance-slot" slot-scope="props">
                    <div>
                      <span style="color: var(--primaryColor)">Worked {{ props.rowData.days_worked }} Days</span>
                    </div>
                    <div v-show="parseInt(props.rowData.days_absent) > 0">
                      <span  style="color: var(--dangerColor)">Absent {{ props.rowData.days_absent }} Days</span>
                    </div>

                    <div class="progress" style="display: none">
                      <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 75%"></div>
                    </div>
                  </div>

                </vuetable>

                <vuetable-pagination
                    ref="pagination"
                    :css="css.pagination"
                    @vuetable-pagination:change-page="onChangePage">
                </vuetable-pagination>

              </div>
            </div>

          </div>

          <div class="col-md-8" v-show="current_view == 'attendance'">

            <div class="card custom-card-wrapper">

              <div class="card-header line-height-1">
                <span v-text="timesheet.name"></span> <br> <small class="text-success text-small font-weight-500">Attendance Register</small>
              </div>

              <div class="card-body p-0">

                <div class="card-body-header-lg p-2 d-flex justify-content-between shadow-sm">

                  <button type="button" class="btn btn-secondary btn-sm" @click="setCurrentView('about')">
                    <i class="bi bi-arrow-left"></i> Go Back
                  </button>

                  <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                    <div class="input-group flex-nowrap" style="max-width: 100% !important;">
                      <span id="date-picker-txt" class="input-group-text font-weight-500 " style="color: var(--primaryColor) !important">Select Date</span>
                      <span class="input-group-text primary-btn font-size-12" id="date-picker-btn" v-text="formatDate(date)" style="height: 100%;"></span>
                      <input id="date-picker" class="form-control" placeholder="Click to Pick a date" aria-label="Click to Pick a date" aria-describedby="btnGroupAddon" style="height: 100%;">
                    </div>

                  </div>

                </div>

                <div class="card-body-subheader p-2 d-flex justify-content-end border-bottom">

                  <div class="btn-group" role="group">
                    <button type="button" class="btn btn-primary btn-sm" @click="searchStatus(-1)">Show All</button>
                    <button type="button" class="btn bg-green btn-sm" @click="searchStatus(1)">Show Present Only</button>
                    <button type="button" class="btn btn-warning btn-sm" @click="searchStatus(2)">Show Absent Only</button>
                    <button type="button" class="btn btn-secondary btn-sm" @click="searchStatus(-2)">Show Unmarked Only</button>
                  </div>

                </div>

                <vuetable
                    ref="vuetable_attendance"
                    api-url="request/table"
                    :api-mode="apiMode"
                    :fields="attendanceFields"
                    :sort-order="attendanceSortOrder"
                    :css="css.table"
                    pagination-path=""
                    :per-page="perPage"
                    :append-params="attendanceMoreParams"
                    :http-fetch="attendanceFetchMembers"
                    :load-on-start="loadOnStart"
                    @vuetable:pagination-data="onAttendancePaginationData"
                    @vuetable:loading="onLoading"
                    @vuetable:loaded="onLoaded">

                  <div slot="date-slot" slot-scope="props">
                    {{ formatDate(props.rowData.attendance_date) }}
                  </div>

                  <div slot="markattendance-slot" slot-scope="props">
                    <markattendance v-bind:key="getKey(props.rowData.id)" v-bind:row-data="props.rowData" v-bind:row-index="props.rowIndex"></markattendance>
                  </div>

                  <div slot="recipient-slot" slot-scope="props">
                    <div style="color: var(--primaryColor)" v-text="props.rowData.recipient_name"></div>
                    <div v-text="props.rowData.msisdn"></div>
                  </div>

                </vuetable>

                <vuetable-pagination
                    ref="pagination_attendance"
                    :css="css.pagination"
                    @vuetable-pagination:change-page="onAttendanceChangePage">
                </vuetable-pagination>

                <div class="modal-footer-copy p-2">
                  <button type="button" class="btn btn-outline-secondary btn-sm mr-2" data-bs-dismiss="modal">Close</button>
                  <button type="button" class="btn bg-green btn-sm shadow" @click="markRegisterAllPresent">Mark All Present</button>
                  <button type="button" class="btn bg-danger btn-sm shadow" @click="markRegisterAllAbsent">Mark All Absent</button>
                  <button type="button" class="btn btn-primary btn-sm shadow" @click="markRegister">Save</button>
                </div>

              </div>

            </div>

          </div>

          <div class="col-md-8" v-show="current_view == 'members'">

            <div class="card custom-card-wrapper shadow-sm">

              <div class="card-header line-height-1 p-2 d-flex shadow-sm justify-content-between">
                <div>
                  <span v-text="timesheet.name"></span>
                  <br>
                  <small class="text-success text-small font-weight-500"><span v-text="timesheet.members"></span> Add Members</small>
                </div>
                <div>
                  <button type="button" class="btn btn-secondary btn-sm" @click="setCurrentView('about')">
                    <i class="bi bi-arrow-left"></i> Go Back
                  </button>
                </div>
              </div>

              <div class="card-body" v-bind:class="loading">

                <div class="font-weight-500" style="margin-bottom: 20px">
                  Add Members to <span v-text="timesheet.name"></span>
                </div>
                <!-- Tab links -->

                <ul class="nav nav-tabs" style="margin-bottom: 20px">

                  <li class="nav-item">
                    <a class="nav-link tablinks text-dark" v-bind:class="getTabTitleClass('form')" @click="showTab('form')" aria-current="page" >Quick Add</a>
                  </li>

                  <li class="nav-item">
                    <a id="js-upload-file" class="nav-link tablinks text-dark" v-bind:class="getTabTitleClass('upload')" @click="showTab('upload')" >Upload Members</a>
                  </li>

                </ul>

                <!-- Tab content -->
                <div v-bind:class="getTabClass('form')" class="tabcontent p-0" style="padding-bottom: 50px !important;">

                  <div v-for="(member,idex) in members" v-bind:key="getKey(idex)">

                    <div class="row">
                      <div class="col-12">
                        <div class="float-end"><button @click="remove(idex)" class="btn btn-outline-danger btn-sm"><i class="bi bi-x"></i> </button></div>
                      </div>
                    </div>

                    <div class="font-weight-500">
                      Personal Details
                    </div>

                    <div class="row">

                      <div class="col-md-4">
                        <div class="mb-3">
                          <label>First Name</label>
                          <input type="text" class="form-control" placeholder="First Name" v-model="member.first_name">
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="mb-3">
                          <label>Other Name</label>
                          <input type="text" class="form-control" placeholder="Other Name" v-model="member.last_name">
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="mb-3">
                          <label>Mobile Number</label>
                          <div class="input-group">
                            <VueCountryCode class="input-group-text" @onSelect="setCountry" :preferredCountries="['ke', 'ug', 'tz']" style="height: 32px;padding: 0"></VueCountryCode>
                            <input v-model="member.msisdn" type="number" class="form-control" id="phonenumber" placeholder="Mobile Number">
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3" style="display: none">
                        <div class="mb-3">
                          <input type="text" class="form-control" placeholder=" Id Number" v-model="member.id_number">
                        </div>
                      </div>

                    </div>

                    <div class="font-weight-500">
                      Payment Details
                    </div>

                    <div class="row">

                      <div v-bind:class="paymentDetailsClass(member.payment_type)">
                        <div class="mb-3">
                          <label>Select Payment Method</label>
                          <select class="form-select form-select-sm" aria-label=".form-select-sm example" v-model="member.payment_type">
                            <option value="1">M-PESA</option>
                            <option value="2">BANK</option>
                            <option value="3">Paybill Number</option>
                            <option value="4">Till Number</option>
                          </select>
                        </div>
                      </div>

                      <div v-show="parseInt(member.payment_type) === 1" v-bind:class="paymentDetailsClass(member.payment_type)">
                        <div class="mb-3">
                          <label>M-PESA Number</label>
                          <input type="text" class="form-control" placeholder="M-Pesa No" v-model="member.account_number">
                        </div>
                      </div>

                      <div v-show="parseInt(member.payment_type) === 2" v-bind:class="paymentDetailsClass(member.payment_type)">
                        <div class="mb-3">
                          <label>Select Bank Name</label>
                          <select class="form-select form-select-sm" aria-label=".form-select-sm example" v-model="bank_paybill">
                            <option v-for="(b,i) in banks" v-bind:key="getKey(i)" v-bind:value="b.bank_code" v-text="b.bank_name"></option>
                          </select>
                        </div>
                      </div>

                      <div v-show="parseInt(member.payment_type) === 3" v-bind:class="paymentDetailsClass(member.payment_type)">
                        <div class="mb-3">
                          <label>PayBill Number</label>
                          <input type="text" class="form-control" placeholder="PayBill No" v-model="member.bank_paybill">
                        </div>
                      </div>

                      <div v-show="parseInt(member.payment_type) === 4" v-bind:class="paymentDetailsClass(member.payment_type)">
                        <div class="mb-3">
                          <label>Till Number</label>
                          <input type="text" class="form-control" placeholder="Till Number" v-model="member.bank_paybill">
                        </div>
                      </div>

                      <div v-show="parseInt(member.payment_type) > 1" v-bind:class="paymentDetailsClass(member.payment_type)">
                        <div class="mb-3">
                          <label>Account Number</label>
                          <input type="text" class="form-control" placeholder="Bank Acc No" v-model="member.account_number">
                        </div>
                      </div>

                    </div>

                    <div class="font-weight-500">
                      Payment Rate
                    </div>
                    <div class="row">

                      <div class="col-md-6">
                        <div class="mb-3">
                          <label>Select Rate Type</label>
                          <select class="form-select form-select-sm" aria-label=".form-select-sm example" v-model="rate_type">
                            <option value="1">Fixed Amount</option>
                            <option value="2">Job Group</option>
                          </select>
                        </div>
                      </div>

                      <div class="col-md-6" v-show="parseInt(rate_type) === 2">
                        <div class="mb-3">
                          <label>Select Job Title</label>
                          <select class="form-select form-select-sm" aria-label=".form-select-sm example" v-model="member.job_title_id">
                            <option v-for="(j,index) in jobs" v-bind:key="getKey(index)" v-bind:value="j.id" v-text="j.name"></option>
                          </select>
                        </div>
                      </div>

                      <div class="col-md-6" v-show="parseInt(rate_type) === 1">
                        <div class="mb-3">
                          <label>Amount</label>
                          <input type="number" class="form-control" placeholder="Amount" v-model="member.amount">
                        </div>
                      </div>

                    </div>

                    <hr class="bg-success border-2 border-top border-success">

                  </div>

                  <div>

                    <div class="font-weight-500">
                      Personal Details
                    </div>

                    <div class="row">

                      <div class="col-md-3">
                        <div class="mb-3">
                          <label>First Name</label>
                          <input type="text" class="form-control" placeholder="First Name" v-model="first_name">
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="mb-3">
                          <label>Other Name</label>
                          <input type="text" class="form-control" placeholder="Other Name" v-model="last_name">
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="mb-3">
                          <label>Mobile Number</label>
                          <div class="input-group">
                            <VueCountryCode class="input-group-text" @onSelect="setCountry" :preferredCountries="['ke', 'ug', 'tz']" style="height: 32px;padding: 0"></VueCountryCode>
                            <input v-model="msisdn" type="number" class="form-control" id="msisdn" placeholder="Mobile Number">
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3" style="display: none">
                        <div class="mb-3">
                          <input type="text" class="form-control" placeholder=" Id Number" v-model="id_number">
                        </div>
                      </div>

                    </div>

                    <div class="font-weight-500">
                      Payment Details
                    </div>

                    <div class="row">

                      <div v-bind:class="paymentDetailsClass(payment_method)">
                        <div class="mb-3">
                          <label>Select Payment Method</label>
                          <select class="form-select form-select-sm" aria-label=".form-select-sm example" v-model="payment_method">
                            <option value="1">M-PESA</option>
                            <option value="2">BANK</option>
                            <option value="3">Paybill Number</option>
                            <option value="4">Till Number</option>
                          </select>
                        </div>
                      </div>

                      <div v-show="parseInt(payment_method) === 1" v-bind:class="paymentDetailsClass(payment_method)">
                        <div class="mb-3">
                          <label>M-PESA Number</label>
                          <input type="text" class="form-control" placeholder="M-Pesa No" v-model="account">
                        </div>
                      </div>

                      <div v-show="parseInt(payment_method) === 2" v-bind:class="paymentDetailsClass(payment_method)">
                        <div class="mb-3">
                          <label>Select Bank Name</label>
                          <select class="form-select form-select-sm" aria-label=".form-select-sm example" v-model="bank_paybill">
                            <option v-for="(b,i) in banks" v-bind:key="getKey(i)" v-bind:value="b.bank_code" v-text="b.bank_name"></option>
                          </select>
                        </div>
                      </div>

                      <div v-show="parseInt(payment_method) === 3" v-bind:class="paymentDetailsClass(payment_method)">
                        <div class="mb-3">
                          <label>PayBill Number</label>
                          <input type="text" class="form-control" placeholder="M-Pesa No" v-model="bank_paybill">
                        </div>
                      </div>

                      <div v-show="parseInt(payment_method) === 4" v-bind:class="paymentDetailsClass(payment_method)">
                        <div class="mb-3">
                          <label>Till Number</label>
                          <input type="text" class="form-control" placeholder="M-Pesa No" v-model="bank_paybill">
                        </div>
                      </div>

                      <div v-show="parseInt(payment_method) > 1" v-bind:class="paymentDetailsClass(payment_method)">
                        <div class="mb-3">
                          <label>Account Number</label>
                          <input type="text" class="form-control" placeholder="Bank Acc No" v-model="account">
                        </div>
                      </div>

                    </div>

                    <div class="font-weight-500">
                      Payment Rate
                    </div>

                    <div class="row">

                      <div class="col-md-6">
                        <div class="mb-3">
                          <label>Select Rate Type</label>
                          <select class="form-select form-select-sm" aria-label=".form-select-sm example" v-model="rate_type">
                            <option value="1">Fixed Amount</option>
                            <option value="2">Job Group</option>
                          </select>
                        </div>
                      </div>

                      <div class="col-md-6" v-show="parseInt(rate_type) === 2">
                        <div class="mb-3">
                          <label>Select Job Title</label>
                          <select class="form-select form-select-sm" aria-label=".form-select-sm example" v-model="job_title_id">
                            <option v-for="(j,index) in jobs" v-bind:key="getKey(index)" v-bind:value="j.id" v-text="j.name"></option>
                          </select>
                        </div>
                      </div>

                      <div class="col-md-6" v-show="parseInt(rate_type) === 1">
                        <div class="mb-3">
                          <label>Amount</label>
                          <input type="number" class="form-control" placeholder="Amount" v-model="amount">
                        </div>
                      </div>

                    </div>

                    <div class="row">
                      <div class="col">
                        <button class="btn btn-secondary btn-sm" @click="addMember" v-text="buttonName"></button>
                      </div>
                    </div>

                  </div>

                  <hr class="bg-green border-2 border-top ">

                  <div class="row">
                    <div class="col-12">
                      <div class="float-end">
                        <button class="btn btn-success" @click="createMember">Submit</button>
                      </div>
                    </div>
                  </div>

                </div>

                <div v-bind:class="getTabClass('upload')" class="tabcontent pt-3">

                  <form class="dropzone dz-clickable" id="my-dropzone" style="background-color: var(--dialog-color) !important; border: 2px dotted rgba(0,0,0,.3); border-radius: 5px">
                    <div class="dz-message d-flex flex-column">
                      <div class="file-content">
                        <div class="file-infos">
                          <p class="file-icon">
                            <i class="bi bi-upload" style="font-size: 64px; font-weight: bold"></i>
                            <span class="icon-shadow"></span>
                          </p>
                        </div>
                      </div>
                      Drag &amp; Drop here or click to upload file
                    </div>
                  </form>

                  <div>
                    <strong>Instructions</strong> Format your file exactly as the attached template. If you are using excel. make sure you import your data as CSV and confirm the formatting<br>
                    <span class="font-weight-500 text-danger"> Click here to download template</span>
                  </div>

                  <hr class="bg-green border-2 border-top ">

                  <div class="row">
                    <div class="col-12">
                      <div class="float-end">
                        <button class="btn btn-success" id="upload-members">Upload File</button>
                      </div>
                    </div>
                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>

      </div>

      <!-- Modal -->
      <div class="modal fade" id="create-worksheet" tabindex="-1" aria-labelledby="create-worksheet" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">

            <div class="modal-header">
              <h5 class="modal-title" id="create-timesheet"><span v-text="action"></span> Timesheet</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

            <div class="modal-body">
              <form class="custom-form">
                <div class="input-wrapper mb-2">

                  <div class="mb-3">
                    <label for="worksheet-name" class="form-label">Timesheet Name</label>
                    <input type="text" class="form-control" id="worksheet-name" v-model="name">
                  </div>

                  <div class="mb-3" style="display: none">
                    <label for="exampleInputEmail1" class="form-label">Payment Group </label>
                    <select class="form-select" aria-label="Default select example">
                      <option value="1">ABC Bank</option>
                    </select>
                  </div>

                  <div class="row">

                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="start-date" class="form-label">Start Time</label>
                        <input type="date" class="form-control" id="start-date" v-model="start_date">
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="end-date" class="form-label">End Time</label>
                        <input type="date" class="form-control" id="end-date" v-model="end_date">
                      </div>
                    </div>

                  </div>

                </div>


                <div class="modal-footer-copy">
                  <button type="button" class="btn btn-outline-secondary btn-sm mr-2" data-bs-dismiss="modal" id="close-create-worksheet">Close</button>
                  <button type="button" class="btn btn-primary btn-sm shadow" @click="createWorksheet">Save</button>
                </div>
              </form>
            </div>

          </div>
        </div>
      </div>

    </div>

</template>

<script>

import Vue from 'vue'
import axios from "@/services/api";
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldSequence from "vuetable-2/src/components/VuetableFieldSequence";

import markattendance from '@/components/worksheet/mark.vue'
import Lightpick from 'lightpick'
import moment from 'moment';
import Dropzone from "dropzone";
import VueCountryCode from "vue-country-code";

Vue.use(Vuetable);

var moreParams = {};

var membersFields =  [
  {
    name: VuetableFieldSequence,
    title: '#',
  },
  {
    name: 'recipient-slot',
    title: '<span class="orange glyphicon glyphicon-credit-card"></span> Name',
    sortField: 'worksheet_member.recipient_name',
  },
  {
    name: "payment-method-slot",
    title: 'Acc No.',
    dataClass: "text-left  w-20"
  },
  {
    name: "attendance-slot",
    title: 'Attendance',
    dataClass: "text-left w-20"
  },
  {
    name: "payment-slot",
    titleClass: "center aligned",
    title: 'Payments',
    dataClass: "text-left w-20"
  },
  {
    name: "actions-slot",
    title: 'Actions',
    titleClass: "center aligned",
    dataClass: "text-right"
  }
];

export default {
  name: 'timesheet',
  components: {
    Vuetable,
    'vuetable-pagination': VuetablePagination,
    markattendance,
    VueCountryCode
  },
  data: function (){
    return {
      row_index: 0,
      active_tab: 'upload',
      timesheets: [],
      timesheet: {},
      member: {
        account: '',
        id_number: 0,
        payment_type: 1,
        first_name: '',
        middle_name: '',
        last_name: '',
        amount: 0,
        paybill: 0,
      },
      action: 'Create',
      name: '',
      start_date: '',
      end_date: '',
      current_view: 'about',

      // vuetable
      search: '',
      fields: membersFields,
      moreParams: moreParams,
      css: {
        table: {
          tableClass: 'table table-striped table-bordered table-hovered',
          loadingClass: 'loading',
          ascendingIcon: 'glyphicon glyphicon-chevron-up',
          descendingIcon: 'glyphicon glyphicon-chevron-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger',
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination pull-right',
          activeClass: 'bg-green',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: '',
          },
        }
      },
      sortOrder: [
        { field: 'worksheet_member.id', direction: 'desc' }
      ],
      perPage: parseInt(10),
      loadOnStart: false,
      apiMode: true,
      loading: '',
      banks: [
        {
          "bank_name": "Absa Bank",
          "bank_code": 101
        },
        {
          "bank_name": "Co-operative Bank",
          "bank_code": 102
        },
        {
          "bank_name": "Equity Bank(K)",
          "bank_code": 103
        },
        {
          "bank_name": "Family Bank",
          "bank_code": 104
        },
        {
          "bank_name": "KCB Bank Kenya (K)",
          "bank_code": 105
        },
        {
          "bank_name": "Standard Chartered Bank (K)",
          "bank_code": 106
        },
        {
          "bank_name": "ABC Bank",
          "bank_code": 107
        },
        {
          "bank_name": "I & M Bank (K)",
          "bank_code": 108
        },
        {
          "bank_name": "HF Group",
          "bank_code": 109
        },
        {
          "bank_name": "Ecobank (K)",
          "bank_code": 110
        },
        {
          "bank_name": "Habib Bank A.G. Zurich",
          "bank_code": 111
        },
        {
          "bank_name": "NCBA Bank(K)",
          "bank_code": 112
        },
        {
          "bank_name": "Stanbic Bank",
          "bank_code": 113
        },
        {
          "bank_name": "Sidian Bank",
          "bank_code": 114
        },
        {
          "bank_name": "Rafiki Microfinance Bank",
          "bank_code": 115
        },
        {
          "bank_name": "Spire Bank",
          "bank_code": 116
        },
        {
          "bank_name": "First Community Bank",
          "bank_code": 117
        },
        {
          "bank_name": "Access Bank (K)",
          "bank_code": 118
        },
        {
          "bank_name": "Diamond Trust Bank (K)",
          "bank_code": 119
        },
        {
          "bank_name": "DIB Kenya Bank",
          "bank_code": 120
        },
        {
          "bank_name": "Development Bank",
          "bank_code": 121
        },
        {
          "bank_name": "Citibank N.A.",
          "bank_code": 122
        },
        {
          "bank_name": "Bank of Baroda (K)",
          "bank_code": 123
        },
        {
          "bank_name": "Bank of Africa (K)",
          "bank_code": 124
        },
        {
          "bank_name": "Faulu Micro-Finance Bank",
          "bank_code": 125
        },
        {
          "bank_name": "Credit Bank",
          "bank_code": 126
        },
        {
          "bank_name": "Choice Microfinance Bank",
          "bank_code": 127
        },
        {
          "bank_name": "Consolidated Bank",
          "bank_code": 128
        },
        {
          "bank_name": "Caritas Microfinance Bank",
          "bank_code": 129
        },
        {
          "bank_name": "Kenya Women Microfinance Bank",
          "bank_code": 130
        },
        {
          "bank_name": "Mayfair Bank",
          "bank_code": 131
        },
        {
          "bank_name": "Kingdom Bank",
          "bank_code": 132
        },
        {
          "bank_name": "National Bank of Kenya",
          "bank_code": 133
        },
        {
          "bank_name": "Middle East Bank (K)",
          "bank_code": 134
        },
        {
          "bank_name": "Paramount Universal Bank",
          "bank_code": 135
        },
        {
          "bank_name": "Prime Bank",
          "bank_code": 136
        },
        {
          "bank_name": "Postbank",
          "bank_code": 137
        },
        {
          "bank_name": "SBM Bank (K)",
          "bank_code": 138
        },
        {
          "bank_name": "Victoria Commercial bank",
          "bank_code": 139
        },
        {
          "bank_name": "UBA Kenya Bank",
          "bank_code": 140
        },
        {
          "bank_name": "Salaam Microfinance Bank",
          "bank_code": 141
        },
        {
          "bank_name": "M Oriental Bank (K)",
          "bank_code": 142
        },
        {
          "bank_name": "Gulf African Bank",
          "bank_code": 143
        },
        {
          "bank_name": "Guaranty Trust Bank (K)",
          "bank_code": 144
        },
        {
          "bank_name": "Guardian Bank",
          "bank_code": 145
        },
        {
          "bank_name": "Bank of India(K)",
          "bank_code": 146
        }
      ],

      attendance: [],
      date: '2022-04-25',
      reglist: [],
      attendanceFields: [
        {
          name: VuetableFieldSequence,
          title: '#',
        },
        {
          name: 'recipient-slot',
          title: '<span class="orange glyphicon glyphicon-credit-card"></span> Name',
          sortField: 'worksheet_member.recipient_name',
        },
        {
          name: 'date-slot',
          title: 'Date',
        },

        {
          name: 'markattendance-slot',
          title: 'Attendance',
        }

      ],
      attendanceMoreParams: moreParams,
      attendanceSortOrder: [
        { field: 'worksheet_member.id', direction: 'desc' }
      ],

      jobs:[],
      payment_method: 1,
      bank_paybill: '',
      account: '',
      amount: 0,
      first_name: '',
      middle_name: '',
      last_name: '',
      id_number: '',
      job_title_id: '',
      members: [],
      rate_type: 2,
      msisdn: '',
      country: {
        "name": "Kenya",
        "iso2": "KE",
        "dialCode": "254"
      },

    }

  },
  mounted: function() {

    this.$store.dispatch("setCurrentPage","groups");
    this.$store.dispatch("setCurrentPageName","Payroll");
    this.$store.dispatch("setPreviousPage","dashbord");
    this.getTimesheets();

    var vm = this;
    vm.EventBus.$on('event:mark:attendance',function(updates){

      var obj = JSON.parse(updates);
      obj.date = vm.date;

      var exist = false;

      vm.jQuery.each(vm.attendance, function (k, v) {

        if (parseInt(v.id) === parseInt(obj.id)) {
          exist = true;
          vm.attendance[k] = obj;
        }
      });

      if(!exist) {

        vm.attendance.push(obj)
      }

    });

    document.getElementById('date-picker-btn').onclick = function () {

      document.getElementById('date-picker').click();

    }

    document.getElementById('date-picker-txt').onclick = function () {

      document.getElementById('date-picker').click();

    }

    this.initFileUpload();
    this.getJobs();

  },
  computed: {

    active_timesheets: function (){

      return this.timesheets.length;

    },

    pending_payroll: function (){

      var count = 0;

      this.jQuery.each(this.paymentgroups,function (k,v){

        if(parseInt(v.payment_status) === 1 ) {

          count++
        }
      })

      return count;

    },

    pending_payment: function (){

      var count = 0;

      this.jQuery.each(this.paymentgroups,function (k,v){

        if(parseInt(v.payment_status) === 2 ) {

          count++
        }
      })

      return count;

    },

    close_payroll: function (){

      var count = 0;

      this.jQuery.each(this.paymentgroups,function (k,v){

        if(parseInt(v.payment_status) > 2 ) {

          count++
        }
      })

      return count;

    },

    activegroups: function (){

      var count = [];

      this.jQuery.each(this.paymentgroups,function (k,v){

        if(parseInt(v.payment_status) === 0 ) {

          count.push(v)
        }
      })

      return count;

    },

    pendinggroups: function (){

      var count = [];

      this.jQuery.each(this.paymentgroups,function (k,v){

        if(parseInt(v.payment_status) === 1 ) {

          count.push(v)
        }
      })

      return count;

    },

    pendingpaymentgroups: function (){

      var count = [];

      this.jQuery.each(this.paymentgroups,function (k,v){

        if(parseInt(v.payment_status) === 2 ) {

          count.push(v)
        }
      })

      return count;

    },

    closedgroups: function (){

      var count = [];

      this.jQuery.each(this.paymentgroups,function (k,v){

        if(parseInt(v.payment_status) > 2 ) {

          count.push(v)
        }
      })

      return count;

    },

    buttonName: function (){

      return this.members.length === 0 ? 'Add Member' : 'Add Another Member';

    },

  },
  methods: {

    paymentDetailsClass: function (paymentType){

      switch (parseInt(paymentType)) {

        case 1:
          return 'col-md-6';

        case 2:
          return 'col-md-4';

        case 3:
          return 'col-md-4';

        case 4:
          return 'col-md-4';

      }

      return 'col-md-3';

    },

    setCountry: function (country){

      this.country = country;
      console.log('setCountry ==> '+JSON.stringify(country));

    },
    setCurrentView: function (viewName){

      var vm = this;

      this.current_view = viewName;

      if(viewName === 'attendance') {

        var fm = 'YYYY-MM-DD';
        vm.date = moment().format(fm);
        //vm.searchAttendanceMember();

        new Lightpick({
          field: document.getElementById('date-picker'),
          singleDate: true,
          minDate: moment(vm.timesheet.start,fm),
          maxDate: moment(),
          onSelect: function(start){

            vm.date = start.format(fm);
            vm.searchAttendanceMember();

          }
        });

        this.reloadAttendance();

      }

      if(viewName === 'about') {

        this.reload();

      }

    },

    createWorksheet: function (){

      var vm = this;

      if (!this.name) {

        this.setError("Invalid name", "Please enter a Timesheet valid name");
        vm.$swal.fire(
            'Error!',
            "Please enter a Timesheet valid name",
            'error'
        )
        return;
      }

      if (!this.start_date) {

        this.setError("Invalid start date", "Please enter a Timesheet start date");
        vm.$swal.fire(
            'Error!',
            "Please enter a Timesheet valid start date",
            'error'
        )
        return;
      }

      if (!this.end_date) {

        this.setError("Invalid end date", "Please enter a Timesheet end date");
        vm.$swal.fire(
            'Error!',
            "Please enter a Timesheet valid end date",
            'error'
        )
        return;
      }

      var path = process.env.VUE_APP_URL_WORKSHEET_CREATE;

      if(this.action ==='Update') {

        path = process.env.VUE_APP_URL_WORKSHEET_UPDATE.replace(":id", this.timesheet.id);

      }

      this.loading = 'loading';

      var data = {
        name: this.name,
        start: this.start_date,
        end: this.end_date,
      };

      axios.post(path, JSON.stringify(data),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            vm.closeModal('create-worksheet');
            var msg = res.data.data.message;
            vm.setSuccess("Success", msg);
            vm.action = 'Create';

            vm.$swal.fire(
                'Submitted!',
                msg,
                'success'
            )
            vm.getTimesheets();
          })
          .catch(err => {

            vm.loading = '';

            console.log('FAILED response '+JSON.stringify(err.response.data.data))

            if (err.response) {
              vm.setError("Failed",err.response.data.data)
            }
            else if (err.request) {
              vm.setError("Network Error", "Check your network connection and try again")
            }
            else {
              vm.setError("Network Error", "Check your network connection and try again")
            }
          })

    },

    getBankName: function(bankCode){

      var bankName = "";

      this.jQuery.each(this.banks,function (k,v){

        if(parseInt(v.bank_code) === parseInt(bankCode)) {

          bankName = v.bank_name;

        }

      })

      return bankName;
    },

    getGroupStatus: function (status){

      if(parseInt(status) === 0) {

        return '<span class="fw-bold text-secondary float-end">Payment Status: Pending</span>'
      }

      if(parseInt(status) === 1) {

        return '<span class="fw-bold text-secondary float-end">Payment Status: Pending Approval</span>'
      }

      if(parseInt(status) === 2) {

        return '<span class="fw-bold text-warning float-end">Payment Status: Payment Initiated</span>'
      }

      if(parseInt(status) === -1) {

        return '<span class="fw-bold text-danger float-end">Payment Status: Payment Failed</span>'
      }

      if(parseInt(status) === -2) {

        return '<span class="fw-bold text-danger float-end">Payment Status: Insufficient Balance</span>'

      }

      return '<span class="fw-bold text-success float-end">Payment Status: Payment Completed</span>'

    },

    getStatus: function (status){

      if(parseInt(status) === 0) {

        return '<span class="fw-bold text-secondary">Waiting Approval</span>'
      }

      if(parseInt(status) === 1) {

        return '<span class="fw-bold text-warning">Payment Initiated</span>'
      }

      if(parseInt(status) === -1) {

        return '<span class="fw-bold text-danger">Payment Failed</span>'

      }

      return '<span class="fw-bold text-success">Payment Successful</span>'

    },

    searchMember: function (){

      if(this.search.length < 2 ) {

        this.setError("invalid fields","please type atleast 3 characters to search")
        return
      }

      this.moreParams.search = this.search;
      this.$refs.vuetable.refresh();

    },

    reload: function() {

      this.moreParams.search = '';
      this.$refs.vuetable.refresh();
    },

    onPaginationData: function(paginationData) {

      this.$refs.pagination.setPaginationData(paginationData)
    },

    onChangePage: function(page) {

      this.$refs.vuetable.changePage(page)

    },

    onRowClicked: function (rowData){

      console.log('event onRowClicked ==> '+JSON.stringify(rowData))

    },

    delete: function(rowData) {

      console.log('TO delete '+JSON.stringify(rowData))

    },

    editRow: function(rowData){

      alert("You clicked edit on"+ JSON.stringify(rowData))
    },

    deleteRow: function(rowData){

      alert("You clicked delete on"+ JSON.stringify(rowData))

    },

    onLoading: function() {

      console.log('loading... show your spinner here')

    },

    onLoaded: function() {

      this.loading = '';
      console.log('loaded! .. hide your spinner here')

    },

    fetchMembers: function(apiUrl, httpOptions){

      var vm = this;
      var endpoint = process.env.VUE_APP_URL_WORKSHEET_MEMBERS_VIEW_TABLE;
      var path = endpoint.replace(":id",parseInt(this.timesheet.id));

      vm.loading = 'loading';

      return axios.post(path, JSON.stringify(httpOptions.params), {
        headers: {
          'api-key': vm.getAuth()
        },
      })
    },

    getPaymentMethod: function (paymentMethod){

      if(parseInt(paymentMethod) === 1 ) {

        return 'MPESA';

      }
      return  'BANK';
    },

    formatAmount: function (amount){

      return this.formatStake(amount);

    },

    setTimesheet: function(group,index){

      this.timesheet = group;
      this.row_index = index;
      this.reload();

    },

    isCurrentRow: function(index) {

      return parseInt(index) === this.row_index ? 'bg-green text-light' : '';

    },

    getPaymentGroup: function(){

      var vm = this;

      var path = process.env.VUE_APP_URL_PAYMENT_GROUP_LIST;

      axios.post(path, JSON.stringify({}),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            var msg = res.data.data;
            vm.paymentgroups = msg;
          })
          .catch(err => {

            vm.loading = '';

            console.log('FAILED response '+JSON.stringify(err.response.data.data))

            if (err.response) {

              vm.setError("Failed",err.response.data.data)

            }
            else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")

            }
            else {

              vm.setError("Network Error", "Check your network connection and try again")

            }

          })

    },

    getTimesheets: function(){

      var vm = this;

      var path = process.env.VUE_APP_URL_WORKSHEET_VIEW_LIST;

      axios.post(path, JSON.stringify({}),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            var msg = res.data.data;
            vm.timesheets = msg;
            vm.setTimesheet(vm.timesheets[0],0);

          })
          .catch(err => {

            vm.loading = '';

            console.log('FAILED response '+JSON.stringify(err.response.data.data))

            if (err.response) {

              vm.setError("Failed",err.response.data.data)

            }
            else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")

            }
            else {

              vm.setError("Network Error", "Check your network connection and try again")

            }

          })

    },

    createTimesheets: function (){

      if (!this.name) {
        this.setError("Invalid name", "Please enter a valid name");
        return;
      }

      var vm = this;

      var path = process.env.VUE_APP_URL_PAYMENT_GROUP_CREATE;
      this.loading = 'loading';

      axios.post(path, JSON.stringify({name: this.name}),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            vm.closeModal('create-payment-group');
            var msg = res.data.data.message;
            vm.setSuccess("Success", msg);
            vm.getPaymentGroup();

          })
          .catch(err => {

            vm.loading = '';

            console.log('FAILED response '+JSON.stringify(err.response.data.data))

            if (err.response) {
              vm.setError("Failed",err.response.data.data)
            }
            else if (err.request) {
              vm.setError("Network Error", "Check your network connection and try again")
            }
            else {
              vm.setError("Network Error", "Check your network connection and try again")
            }
          })

    },

    deleteTimesheet: function(data) {

      var vm = this;

      vm.$swal.fire({
        title: "Do you want to delete. "+data.name+'?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete'

      }).then((result) => {

        if (result.isConfirmed) {

          var path = process.env.VUE_APP_URL_WORKSHEET_DELETE.replace(":id", data.id);
          vm.loading = 'loading';

          axios.post(path, JSON.stringify({}),{
            headers: {
              'api-key': vm.getAuth()
            },
          })
              .then(res => {

                vm.loading = '';
                vm.getPaymentGroup();

                var msg = res.data.data.message;

                vm.$swal.fire(
                    'Submitted!',
                    msg,
                    'success'
                )

              })
              .catch(err => {

                vm.loading = '';

                vm.$swal.fire(
                    'Failed!',
                    err.response.data.data,
                    'error'
                )

                if (err.response) {

                  var message = "";

                  if(parseInt(err.response.status) === 428 ) {

                    message = err.response.data.message;
                    vm.setError("Failed", message)
                    return;
                  }

                  if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                    vm.setError("Session Expired", "Your session on this device has expired");
                    vm.logout();
                    return;

                  } else {

                    message =  err.response.data.message;
                    vm.setError("Failed", message)

                  }

                } else if (err.request) {

                  vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err.request));

                } else {

                  //vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err));

                }
              })
        } else {

          vm.cashoutLoading = '';

        }

      })

    },

    updateTimesheet: function (){

      if (!this.timesheet.name) {

        this.setError("Invalid name", "Please enter a valid name");
        return;
      }

      var vm = this;

      var path = process.env.VUE_APP_URL_WORKSHEET_UPDATE.replace(":id", this.timesheet.id);
      vm.loading = 'loading';

      axios.post(path, JSON.stringify(this.timesheet),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            vm.closeModal('update-payment-group');
            var msg = res.data.data.message;
            vm.$swal.fire(
                'Submitted!',
                msg,
                'success'
            )

            vm.getTimesheets();

          })
          .catch(err => {

            vm.loading = '';

            vm.$swal.fire(
                'Failed!',
                err.response.data.data,
                'error'
            )

            if (err.response) {

              var message = "";

              if(parseInt(err.response.status) === 428 ) {

                message = err.response.data.message;
                vm.setError("Failed", message)
                return;
              }

              if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();
                return;

              } else {

                message =  err.response.data.message;
                vm.setError("Failed", message)

              }

            } else if (err.request) {

              vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err.request));

            } else {

              //vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err));

            }

          })
    },

    makePayment: function(data) {

      var vm = this;

      vm.$swal.fire({
        title: 'Confirm',
        text: "Do you want to initiate payment for "+data.name+' a total of '+vm.formatCurrency(data.amount)+' Ksh?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Initiate Payment'

      }).then((result) => {

        if (result.isConfirmed) {

          var path = process.env.VUE_APP_URL_PAYMENT_GROUP_APPROVAL_INITIATE.replace(":id",data.id);
          vm.loading = 'loading';

          axios.post(path, JSON.stringify({}),{
            headers: {
              'api-key': vm.getAuth()
            },
          })
              .then(res => {

                vm.loading = '';
                vm.reload();

                var msg = res.data.data.message;

                vm.$swal.fire(
                    'Submitted!',
                    msg,
                    'success'
                )

              })
              .catch(err => {

                vm.loading = '';

                vm.$swal.fire(
                    'Failed!',
                    err.response.data.data,
                    'error'
                )

                if (err.response) {

                  var message = "";

                  if(parseInt(err.response.status) === 428 ) {

                    message = err.response.data.message;
                    vm.setError("Failed", message)
                    return;
                  }

                  if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                    vm.setError("Session Expired", "Your session on this device has expired");
                    vm.logout();
                    return;

                  } else {

                    message =  err.response.data.message;
                    vm.setError("Failed", message)

                  }

                } else if (err.request) {

                  vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err.request));

                } else {

                  //vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err));

                }
              })
        } else {

          vm.cashoutLoading = '';

        }

      })

    },







    createPaymentGroup: function (){

      if (!this.name) {
        this.setError("Invalid name", "Please enter a valid name");
        return;
      }

      var vm = this;

      var path = process.env.VUE_APP_URL_PAYMENT_GROUP_CREATE;
      this.loading = 'loading';

      axios.post(path, JSON.stringify({name: this.name}),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            vm.closeModal('create-payment-group');
            var msg = res.data.data.message;
            vm.setSuccess("Success", msg);
            vm.getPaymentGroup();

          })
          .catch(err => {

            vm.loading = '';

            console.log('FAILED response '+JSON.stringify(err.response.data.data))

            if (err.response) {
              vm.setError("Failed",err.response.data.data)
            }
            else if (err.request) {
              vm.setError("Network Error", "Check your network connection and try again")
            }
            else {
              vm.setError("Network Error", "Check your network connection and try again")
            }
          })

    },

    deleteMember: function(data) {

      var vm = this;

      vm.$swal.fire({
        title: "Do you want to delete. "+data.recipient_name+' from the list?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete'

      }).then((result) => {

        if (result.isConfirmed) {

          var path = process.env.VUE_APP_URL_WORKSHEET_MEMBER_DELETE
              .replace(":member_id", data.id)
              .replace(":id",vm.timesheet.id);

          vm.loading = 'loading';

          axios.post(path, JSON.stringify({}),{
            headers: {
              'api-key': vm.getAuth()
            },
          })
              .then(res => {

                vm.loading = '';
                vm.reload();

                var msg = res.data.data.message;

                vm.$swal.fire(
                    'Submitted!',
                    msg,
                    'success'
                )

              })
              .catch(err => {

                vm.loading = '';

                vm.$swal.fire(
                    'Failed!',
                    err.response.data.data,
                    'error'
                )

                if (err.response) {

                  var message = "";

                  if(parseInt(err.response.status) === 428 ) {

                    message = err.response.data.message;
                    vm.setError("Failed", message)
                    return;
                  }

                  if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                    vm.setError("Session Expired", "Your session on this device has expired");
                    vm.logout();
                    return;

                  } else {

                    message =  err.response.data.message;
                    vm.setError("Failed", message)

                  }

                } else if (err.request) {

                  vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err.request));

                } else {

                  //vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err));

                }
              })
        } else {

          vm.cashoutLoading = '';

        }

      })

    },

    changeAmount: async function(data){

      var vm = this;

      const { value: amount } = await vm.$swal.fire({
        title: 'Enter new amount for '+data.recipient_name,
        input: 'number',
        inputLabel: 'Amount',
        inputPlaceholder: 'Enter new amount',
        inputAttributes: {
          min: 100,
          max: 70000
        }
      })

      if (amount) {

        var path = process.env.VUE_APP_URL_WORKSHEET_MEMBER_UPDATE_AMOUNT
            .replace(":member_id", data.id)
            .replace(":id",vm.timesheet.id);

        vm.loading = 'loading';

        axios.post(path, JSON.stringify({amount: parseInt(amount)}),{
          headers: {
            'api-key': vm.getAuth()
          },
        })
            .then(res => {

              vm.loading = '';
              vm.reload();

              var msg = res.data.data.message;

              vm.$swal.fire(
                  'Submitted!',
                  msg,
                  'success'
              )

            })
            .catch(err => {

              vm.loading = '';

              vm.$swal.fire(
                  'Failed!',
                  err.response.data.data,
                  'error'
              )

              if (err.response) {

                var message = "";

                if(parseInt(err.response.status) === 428 ) {

                  message = err.response.data.message;
                  vm.setError("Failed", message)
                  return;
                }

                if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                  vm.setError("Session Expired", "Your session on this device has expired");
                  vm.logout();
                  return;

                } else {

                  message =  err.response.data.message;
                  vm.setError("Failed", message)

                }

              } else if (err.request) {

                vm.setError("Failed", "Please check your network")
                console.log(JSON.stringify(err.request));

              } else {

                //vm.setError("Failed", "Please check your network")
                console.log(JSON.stringify(err));

              }
            })

      }

    },

    editMember: function (data) {

      this.member = data;

      var names = [];

      if(data.recipient_name){

        names = data.recipient_name.split(' ');

      }

      if(names.length > 0) {

        this.member.first_name = names[0];
      }

      if(names.length > 1) {

        this.member.middle_name = names[1];
      }

      if(names.length > 2) {

        this.member.last_name = names.slice(2).join(" ");
      }

    },

    updateMember: function (){

      if (!this.member.account) {

        this.setError("Invalid Account", "Please enter a account");
        return;
      }

      if (parseInt(this.member.amount) < 100 ) {

        this.setError("Invalid Amount", "Please enter a amount atleast 100");
        return;
      }

      if (!this.member.first_name) {

        this.setError("Invalid First Name", "Please enter First Name");
        return;
      }

      if (!this.member.middle_name) {

        this.setError("Invalid Middle Name", "Please enter Middle Name");
        return;
      }

      if (!this.member.last_name) {

        this.setError("Invalid Last Name", "Please enter Last Name");
        return;
      }

      if (parseInt(this.member.id_number) < 1000000 ) {

        this.setError("Invalid ID Number", "Please enter a valid ID Number");
        return;
      }

      var paybill = 3016903;

      if (this.member.recipient_type === 2 ) {

        paybill = this.bank_paybill;

      }

      var vm = this;

      var data = {
        id_number: parseInt(this.member.id_number),
        payment_type: parseInt(this.member.payment_method),
        first_name: this.member.first_name,
        middle_name: this.member.middle_name,
        last_name: this.member.last_name,
        amount: parseInt(this.member.amount),
        paybill: parseInt(paybill),
        account_number: this.member.account
      };

      var path = process.env.VUE_APP_URL_WORKSHEET_MEMBER_UPDATE
          .replace(":member_id", this.member.id)
          .replace(":id",this.timesheet.id);

      vm.loading = 'loading';

      axios.post(path, JSON.stringify(data),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            vm.reload();
            vm.closeModal('update-member-details');

            var msg = res.data.data.message;

            vm.$swal.fire(
                'Submitted!',
                msg,
                'success'
            )

          })
          .catch(err => {

            vm.loading = '';

            vm.$swal.fire(
                'Failed!',
                err.response.data.data,
                'error'
            )

            if (err.response) {

              var message = "";

              if(parseInt(err.response.status) === 428 ) {

                message = err.response.data.message;
                vm.setError("Failed", message)
                return;
              }

              if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();
                return;

              } else {

                message =  err.response.data.message;
                vm.setError("Failed", message)

              }

            } else if (err.request) {

              vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err.request));

            } else {

              //vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err));

            }
          })
    },

    deleteGroup: function(data) {

      var vm = this;

      vm.$swal.fire({
        title: "Do you want to delete. "+data.name+'?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete'

      }).then((result) => {

        if (result.isConfirmed) {

          var path = process.env.VUE_APP_URL_PAYMENT_GROUP_DELETE.replace(":id", data.id);
          vm.loading = 'loading';

          axios.post(path, JSON.stringify({}),{
            headers: {
              'api-key': vm.getAuth()
            },
          })
              .then(res => {

                vm.loading = '';
                vm.getPaymentGroup();

                var msg = res.data.data.message;

                vm.$swal.fire(
                    'Submitted!',
                    msg,
                    'success'
                )

              })
              .catch(err => {

                vm.loading = '';

                vm.$swal.fire(
                    'Failed!',
                    err.response.data.data,
                    'error'
                )

                if (err.response) {

                  var message = "";

                  if(parseInt(err.response.status) === 428 ) {

                    message = err.response.data.message;
                    vm.setError("Failed", message)
                    return;
                  }

                  if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                    vm.setError("Session Expired", "Your session on this device has expired");
                    vm.logout();
                    return;

                  } else {

                    message =  err.response.data.message;
                    vm.setError("Failed", message)

                  }

                } else if (err.request) {

                  vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err.request));

                } else {

                  //vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err));

                }
              })
        } else {

          vm.cashoutLoading = '';

        }

      })

    },

    updatePaymentGroup: function (){

      if (!this.paymentgroup.name) {

        this.setError("Invalid name", "Please enter a valid name");
        return;
      }

      var vm = this;

      var path = process.env.VUE_APP_URL_PAYMENT_GROUP_UPDATE.replace(":id", this.paymentgroup.id);
      vm.loading = 'loading';

      axios.post(path, JSON.stringify(this.paymentgroup),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            vm.closeModal('update-payment-group');
            var msg = res.data.data.message;
            vm.$swal.fire(
                'Submitted!',
                msg,
                'success'
            )

            vm.getPaymentGroup();

          })
          .catch(err => {

            vm.loading = '';

            vm.$swal.fire(
                'Failed!',
                err.response.data.data,
                'error'
            )

            if (err.response) {

              var message = "";

              if(parseInt(err.response.status) === 428 ) {

                message = err.response.data.message;
                vm.setError("Failed", message)
                return;
              }

              if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();
                return;

              } else {

                message =  err.response.data.message;
                vm.setError("Failed", message)

              }

            } else if (err.request) {

              vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err.request));

            } else {

              //vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err));

            }

          })
    },



    searchStatus: function(status){

      this.moreParams.search = this.search;
      this.moreParams.start_date = this.date;
      this.moreParams.status = status;
      this.$refs.vuetable.refresh();
    },

    searchAttendanceMember: function (){

      this.attendanceMoreParams.search = this.search;
      this.attendanceMoreParams.start_date = this.date;
      this.$refs.vuetable_attendance.refresh();

    },

    reloadAttendance: function() {

      this.$refs.vuetable_attendance.refresh();
    },

    onAttendancePaginationData: function(paginationData) {

      this.$refs.pagination_attendance.setPaginationData(paginationData)
    },

    onAttendanceChangePage: function(page) {

      this.$refs.vuetable_attendance.changePage(page)

    },

    attendanceFetchMembers: function(apiUrl, httpOptions){

      var vm = this;
      var endpoint = process.env.VUE_APP_URL_WORKSHEET_ATTENDANCE_VIEW_TABLE;
      var path = endpoint.replace(":id",parseInt(this.timesheet.id));

      return axios.post(path, JSON.stringify(httpOptions.params), {
        headers: {
          'api-key': vm.getAuth()
        },
      })
    },

    markRegister: function (){

      var vm = this;

      if(this.attendance.length === 0){

        this.setError("Invalid name", "Mark attendance to proceed");
        return;
      }

      this.loading = 'loading';

      var path = process.env.VUE_APP_URL_WORKSHEET_ATTENDANCE_MARK.replace(":id", this.timesheet.id);

      axios.post(path, JSON.stringify({attendance:vm.attendance,date: vm.date}),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            var msg = res.data.data.message;
            vm.$swal.fire(
                'Updated!',
                msg,
                'success'
            )

            vm.reloadAttendance();

          })
          .catch(err => {

            vm.loading = '';

            vm.$swal.fire(
                'Failed!',
                err.response.data.data,
                'error'
            )

            if (err.response) {

              var message = "";

              if(parseInt(err.response.status) === 428 ) {

                message = err.response.data.message;
                vm.setError("Failed", message)
                return;
              }

              if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();
                return;

              } else {

                message =  err.response.data.message;
                vm.setError("Failed", message)

              }

            } else if (err.request) {

              vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err.request));

            } else {

              //vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err));

            }

          })

    },

    markRegisterAllPresent: function (){

      var vm = this;

      this.loading = 'loading';

      var path = process.env.VUE_APP_URL_WORKSHEET_ATTENDANCE_MARK_ALL.replace(":id", this.timesheet.id);

      axios.post(path, JSON.stringify({date: vm.date,status: 1}),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            var msg = res.data.data.message;
            vm.$swal.fire(
                'Updated!',
                msg,
                'success'
            )

            vm.reloadAttendance();

          })
          .catch(err => {

            vm.loading = '';

            vm.$swal.fire(
                'Failed!',
                err.response.data.data,
                'error'
            )

            if (err.response) {

              var message = "";

              if(parseInt(err.response.status) === 428 ) {

                message = err.response.data.message;
                vm.setError("Failed", message)
                return;
              }

              if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();
                return;

              } else {

                message =  err.response.data.message;
                vm.setError("Failed", message)

              }

            } else if (err.request) {

              vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err.request));

            } else {

              //vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err));

            }

          })
    },

    markRegisterAllAbsent: function (){

      var vm = this;

      this.loading = 'loading';

      var path = process.env.VUE_APP_URL_WORKSHEET_ATTENDANCE_MARK_ALL.replace(":id", this.timesheet.id);

      axios.post(path, JSON.stringify({date: vm.date,status: 0}),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            var msg = res.data.data.message;
            vm.$swal.fire(
                'Updated!',
                msg,
                'success'
            )

            vm.reloadAttendance();

          })
          .catch(err => {

            vm.loading = '';

            vm.$swal.fire(
                'Failed!',
                err.response.data.data,
                'error'
            )

            if (err.response) {

              var message = "";

              if(parseInt(err.response.status) === 428 ) {

                message = err.response.data.message;
                vm.setError("Failed", message)
                return;
              }

              if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();
                return;

              } else {

                message =  err.response.data.message;
                vm.setError("Failed", message)

              }

            } else if (err.request) {

              vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err.request));

            } else {

              //vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err));

            }

          })

    },

    getRowID: function (id,suffix){

      return suffix + '-' + id;

    },

    getID: function (prefix,id){

      return prefix+'-row-data-'+id;
    },

    getIDWithHash: function (prefix,id){

      return '#'+prefix+'-row-data-'+id;
    },

    viewPayments: function (){

      this.$store.dispatch("setWorksheet",this.timesheet);
      this.$router.push({ name: 'worksheetpayments', params: {id: this.timesheet.id} });

    },




    getJobs: function(){

      var vm = this;

      var path = process.env.VUE_APP_URL_RATECARD_VIEW_LIST;

      axios.post(path, JSON.stringify({}),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            var msg = res.data.data;
            vm.jobs = msg;
          })
          .catch(err => {

            vm.loading = '';

            console.log('FAILED response '+JSON.stringify(err.response.data.data))

            if (err.response) {

              vm.setError("Failed",err.response.data.data)

            }
            else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")

            }
            else {

              vm.setError("Network Error", "Check your network connection and try again")

            }

          })

    },

    showTab: function(tabName) {

      console.log('showTab ==> '+tabName);
      this.active_tab = tabName;

    },

    isActiveTab: function(tabName) {

      console.log('isActiveTab ==> '+tabName);
      return this.active_tab === tabName;
    },

    getTabClass: function(tabName) {

      return this.active_tab === tabName ? 'active-tab' : 'inactive-tab';
    },

    getTabTitleClass: function(tabName) {

      return this.active_tab === tabName ? 'active' : '';

    },

    resetForm: function (){

      this.payment_method = 1;
      this.bank_paybill = '';
      this.account='';
      this.amount=0;
      this.first_name='';
      this.middle_name='';
      this.last_name='';
      this.id_number=0;
      this.loading='';
    },

    addMember: function (){

      if (!this.account) {

        this.setError("Invalid Account", "Please enter a account");
        return;
      }

      if (parseInt(this.job_title_id) === 0 ) {

        if (parseInt(this.amount) < 100 ) {

          this.setError("Invalid Amount", "Please enter a amount atleast 100");
          return;

        } else {

          this.setError("Invalid Job title", "Select Job Title");
          return;

        }
      }

      if (!this.first_name) {

        this.setError("Invalid First Name", "Please enter First Name");
        return;
      }

      if (!this.last_name) {

        this.setError("Invalid Last Name", "Please enter Last Name");
        return;
      }

      if (!this.msisdn || this.msisdn.length < 5) {

        this.setError("Invalid Mobile Number", "Please enter valid phone number");
        return;
      }
      // format mobile number

      // remove any non numeric character
      var phone = this.msisdn.replace(/[^\d.-]/g, '')

      // if phone starts with country code remove it
      var phoneWithoutDialingCode = phone;
      var len = this.country.dialCode.length;
      var firstN = phone.slice(0, len);
      if(parseInt(firstN) === parseInt(this.country.dialCode)) {

        //phone number contains country code, remove it
        phoneWithoutDialingCode =  phone.slice(-1 * (phone.length - len));
      }
      var formattedMsisdn = this.country.dialCode + '' + parseInt(phoneWithoutDialingCode);


      var paybill = 3016903;

      if (parseInt(this.payment_method) > 1 ) {

        paybill = this.bank_paybill;

      }

      this.members.push({
        id_number: parseInt(this.id_number),
        payment_type: parseInt(this.payment_method),
        first_name: this.first_name,
        middle_name: this.middle_name,
        last_name: this.last_name,
        job_title_id: parseInt(this.job_title_id),
        amount: parseInt(this.amount),
        paybill: parseInt(paybill),
        account_number: this.account,
        msisdn: parseInt(formattedMsisdn),
      });

      this.resetForm();

    },

    createMember: function (){

      var hasError = false
      var vm = this;

      if (!this.account) {

        this.setError("Invalid Account", "Please enter a account");
        hasError = true;
      }

      if (parseInt(this.job_title_id) === 0 ) {

        if (parseInt(this.amount) < 100 ) {

          this.setError("Invalid Amount", "Please enter a amount atleast 100");
          return;

        } else {

          this.setError("Invalid Job title", "Select Job Title");
          return;

        }
      }

      if (!this.first_name) {

        this.setError("Invalid First Name", "Please enter First Name");
        hasError = true;
      }

      if (!this.last_name) {

        this.setError("Invalid Last Name", "Please enter Last Name");
        hasError = true;
      }

      if (!this.msisdn || this.msisdn.length < 5) {

        this.setError("Invalid Mobile Number", "Please enter valid phone number");
        return;
      }
      // format mobile number

      // remove any non numeric character
      var phone = this.msisdn.replace(/[^\d.-]/g, '')

      // if phone starts with country code remove it
      var phoneWithoutDialingCode = phone;
      var len = this.country.dialCode.length;
      var firstN = phone.slice(0, len);
      if(parseInt(firstN) === parseInt(this.country.dialCode)) {

        //phone number contains country code, remove it
        phoneWithoutDialingCode =  phone.slice(-1 * (phone.length - len));
      }
      var formattedMsisdn = this.country.dialCode + '' + parseInt(phoneWithoutDialingCode);

      var paybill = 3016903;

      if (parseInt(this.payment_method) > 1 ) {

        paybill = this.bank_paybill;

      }

      if(!hasError) {

        this.members.push({
          id_number: parseInt(this.id_number),
          payment_type: parseInt(this.payment_method),
          first_name: this.first_name,
          middle_name: this.middle_name,
          last_name: this.last_name,
          job_title_id: parseInt(this.job_title_id),
          amount: parseInt(this.amount),
          msisdn: parseInt(formattedMsisdn),
          paybill: parseInt(paybill),
          account_number: this.account
        });

      } else {

        return
      }

      hasError = false;

      // let do validation again here
      vm.jQuery.each(vm.members, function (k, v) {

        if (!v.account) {

          hasError = true;
          console.log(k+' ==> Invalid Account')
          //vm.setError("Invalid Account", "Please enter a account");
          //return false
        }

        if (parseInt(v.job_title_id) === 0 ) {

          if (parseInt(v.amount) < 100 ) {

            hasError = true;
            //vm.setError("Invalid Amount", "Please enter a amount atleast 100");
            console.log(k+' ==> Invalid Amount')

            //return false;

          }
        }

        if (!v.first_name) {

          //this.setError("Invalid First Name", "Please enter First Name");
          hasError = true;
          console.log(k+' ==> Invalid First name')

        }

        if (!v.last_name) {

          //this.setError("Invalid Last Name", "Please enter Last Name");
          hasError = true;
          console.log(k+' ==> Invalid Last Name')

        }

        if (!v.msisdn || v.msisdn.length < 5) {

          hasError = true;
          //vm.setError("Invalid Mobile Number", "Please enter valid phone number");
          //return;
          console.log(k+' ==> Invalid Msisdn')

        }

        var paybill = 3016903;

        if (parseInt(v.payment_method) > 1 ) {

          paybill = v.bank_paybill;

        }


        var obj = {
          id_number: parseInt(v.id_number),
          payment_type: parseInt(v.payment_method),
          first_name: v.first_name,
          middle_name: v.middle_name,
          last_name: v.last_name,
          job_title_id: parseInt(v.job_title_id),
          amount: parseInt(v.amount),
          msisdn: parseInt(v.msisdn),
          paybill: parseInt(paybill),
          account_number: v.account
        };

        console.log(k+' ==> '+JSON.stringify(obj,undefined,2));

        //vm.members[k] = obj;

      });

      if (hasError) {

        //return ;
      }

      var endpoint = process.env.VUE_APP_URL_WORKSHEET_ADD_MEMBER;
      var path = endpoint.replace(":id",this.timesheet.id);

      this.loading = 'loading';

      axios.post(path, JSON.stringify(vm.members),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            var msg = res.data.data.message;
            vm.setSuccess("Success", msg);
            vm.members = [];
            vm.resetForm();
            vm.setCurrentView('about');

          })
          .catch(err => {

            vm.loading = '';

            console.log('FAILED response '+JSON.stringify(err.response.data.data))

            if (err.response) {
              vm.setError("Failed",err.response.data.data)
            }
            else if (err.request) {
              vm.setError("Network Error", "Check your network connection and try again")
            }
            else {
              vm.setError("Network Error", "Check your network connection and try again")
            }
          })
    },

    remove: function (index){

      this.members.splice(index, 1);

    },

    uploadMembers: function (){

      var formData = new FormData();
      var imagefile = document.querySelector('#js-file-input');
      formData.append("file", imagefile.files[0]);

      var vm = this;
      var endpoint = process.env.VUE_APP_URL_WORKSHEET_UPLOAD_MEMBERS;
      var path = endpoint.replace(":id",this.timesheet.id);

      this.loading = 'loading';

      axios.post(path, formData,{
        headers: {
          'api-key': vm.getAuth(),
          'Content-Type': 'multipart/form-data'
        },
      })
          .then(res => {

            vm.loading = '';
            var msg = res.data.data.message;
            vm.setSuccess("Success", msg);
            vm.members = [];
            vm.resetForm();

          })
          .catch(err => {

            vm.loading = '';

            console.log('FAILED response '+JSON.stringify(err.response.data.data))

            if (err.response) {
              vm.setError("Failed",err.response.data.data)
            }
            else if (err.request) {
              vm.setError("Network Error", "Check your network connection and try again")
            }
            else {
              vm.setError("Network Error", "Check your network connection and try again")
            }
          })
    },

    initFileUpload: function (){

      var vm = this;

      var endpoint = process.env.VUE_APP_BASE_URL + process.env.VUE_APP_URL_WORKSHEET_UPLOAD_MEMBERS;
      var path = endpoint.replace(":id",this.timesheet.id);

      new Dropzone("#my-dropzone",{
        url: path,
        autoProcessQueue: false,
        uploadMultiple: true,
        parallelUploads: 5,
        maxFiles: 1,
        maxFilesize: 1000,
        headers: {
          'api-key': vm.getAuth(),
        },
        acceptedFiles: 'text/csv',
        addRemoveLinks: true,
        init: function () {

          var dzClosure = this; // Makes sure that 'this' is understood inside the functions below.

          // for Dropzone to process the queue (instead of default form behavior):
          document.getElementById("upload-members").addEventListener("click", function (e) {
            // Make sure that the form isn't actually being sent.
            e.preventDefault();
            e.stopPropagation();
            dzClosure.processQueue();

          });

          //send all the form data along with the files:
          this.on("sendingmultiple", function (data, xhr, formData) {

            console.log(data);
            formData.append("file", data);

          });

          //send all the form data along with the files:
          this.on("sending", function (data, xhr, formData) {

            console.log(data);
            formData.append("file", data);

          });


          //send all the form data along with the files:
          this.on("success", function (res1, response) {

            var msg = "Contact uploaded successful. ";

            if (response.message) {

              msg = response.message
            }

            vm.setSuccess("Upload successful",msg)

          });

          //send all the form data along with the files:
          this.on("error", function (res1, response) {

            var error = response;

            if (response.message) {

              error = response.message
            }

            vm.setError("Upload Failed",error)

          });
        }

      });

    },

  },
}

</script>