
//import bootstrap from "bootstrap"
//var myModalEl = document.getElementById('myModal')
//var modal = bootstrap.Modal.getInstance(myModalEl) // Returns a Bootstrap modal instance

var SMESPay  = {
    _amount : '',
    _email : '',
    _firstName : '',
    _lastName : '',
    _address : '',
    _city : '',
    _url : '',
    _country : '',
    _reference : '',
    _apiKey : '',
    _transaction_type : 'sale',
    setApiKey : function (key) {
        
        var self = this;
        self._apiKey = key;
    },

    setReference : function (reference) {
        this._reference = reference;
    },

    setURL : function (url) {
        this._url = url;
    },

    setCountry : function (country) {
        this._country = country;
    },

    setCity : function (city) {
        this._city = city;
    },

    setAddress : function (address) {
        this._address = address;
    },

    setLastName : function (lastName) {
        this._lastName = lastName;
    },

    setFirstName : function (firstName) {
        this._firstName = firstName;
    },

    setEmail : function (email) {
        this._email = email;
    },

    setAmount : function (amount) {

        this._amount = amount;
    },

    setTransactionType : function (transaction_type) {

        this._transaction_type = transaction_type;
    },

    pay : function () {

        var _this = this;

        var payload = {
            amount: _this._amount,
            email: _this._email,
            reference: _this._reference,
            firstName: _this._firstName,
            lastName: _this._lastName,
            city: _this._city,
            address: _this._address,
            country: _this._country,
            transaction_type: _this._transaction_type,
            device_fingerprint_id: _this.device_fingerprint_id
        }

        // get iframe
        var iframe = document.getElementById('iframe');

        // create loader
        var loader = document.createElement('div');
        loader.setAttribute('style','position: absolute;left: 50%;top: 50%;');
        loader.innerHTML = '<svg width="64px" height="64px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" class="uil-ripple"><rect x="0" y="0" width="100" height="100" fill="none" class="bk"></rect><g><animate attributeName="opacity" dur="2s" repeatCount="indefinite" begin="0s" keyTimes="0;0.33;1" values="1;1;0"></animate><circle cx="50" cy="50" r="40" stroke="#298fca" fill="none" stroke-width="6" stroke-linecap="round"><animate attributeName="r" dur="2s" repeatCount="indefinite" begin="0s" keyTimes="0;0.33;1" values="0;22;44"></animate></circle></g><g><animate attributeName="opacity" dur="2s" repeatCount="indefinite" begin="1s" keyTimes="0;0.33;1" values="1;1;0"></animate><circle cx="50" cy="50" r="40" stroke="#333b9c" fill="none" stroke-width="6" stroke-linecap="round"><animate attributeName="r" dur="2s" repeatCount="indefinite" begin="1s" keyTimes="0;0.33;1" values="0;22;44"></animate></circle></g></svg>';

        // add to iframe
        iframe.contentWindow.document.body.appendChild(loader);

        // create XMLHttpRequest object
        const xhr = new XMLHttpRequest()
        // open a POST request
        xhr.open("POST", this._url)

        // set headers
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.setRequestHeader("api-key", this._apiKey);

        // send JSON data to the remote server
        xhr.send(JSON.stringify(payload))

        // triggered when the response is fully received
        xhr.onload = function() {

            if(xhr.status === 201 || xhr.status === 200 ) {

                // get body
                var response = JSON.parse(xhr.responseText)
                var device_fingerprint_id = response.device_fingerprint_id;
                var org_id = response.org_id;

                // create script
                var script = document.createElement('script');
                script.src = "https://h.online-metrix.net/fp/tags.js?org_id="+org_id+"&session_id="+device_fingerprint_id;

                // add to iframe
                iframe.contentWindow.document.head.appendChild(script); //or something of the likes

                // when scripts loads
                script.onload = function () {

                    // post data to cybersource
                    var endpoint = "https://secureacceptance.cybersource.com/pay";

                    const dform = document.createElement("form");
                    dform.setAttribute('method', "post");
                    dform.setAttribute('action', endpoint);
                    dform.setAttribute('role','form')
                    dform.style.display = 'none';

                    // attache form
                    //document.getElementById("dform").appendChild(dform);

                    delete response.org_id;

                    for (var key in response) {

                        var value = response[key];
                        const div = document.createElement('div');
                        div.setAttribute('class','form-group');

                        const label = document.createElement('label');
                        label.innerHTML = key;

                        const div1 = document.createElement('div');
                        div1.setAttribute('class','input-group');

                        const input = document.createElement("input");
                        input.setAttribute('name', key);
                        input.setAttribute('value', value);
                        input.setAttribute('class', 'form-control');

                        if(key === "submit") {

                            input.setAttribute('id', "smespay-submit-form");
                            input.setAttribute('type', "submit");

                        } else {

                            input.setAttribute('type', "text");
                        }

                        div1.appendChild(input);
                        div.appendChild(label);
                        div.appendChild(div1)
                        dform.appendChild(div);
                    }

                    iframe.contentWindow.document.body.appendChild(dform)

                    iframe.contentWindow.document.getElementById("smespay-submit-form").click();

                    //do stuff with the script
                    //var htmlString = iframe.innerHTML;
                };

                console.log('got src '+script.src);

            }
        }

        // triggered due to a network-level error
        xhr.onerror = function() {

            console.log("Network error occurred")
        }
    }


}

export default SMESPay;