<template>

    <div v-bind:class="loading">

      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" v-bind:name="getRowID('attendance')" v-bind:id="getRowID('present')" value="1" v-model="attendance">
        <label class="form-check-label" v-bind:for="getRowID('present')">
          Present
        </label>
      </div>

      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" v-bind:name="getRowID('attendance')" v-bind:id="getRowID('absent')" value="0" v-model="attendance">
        <label class="form-check-label" v-bind:for="getRowID('absent')">
          Absent
        </label>
      </div>

    </div>

</template>

<script>

import VuetableFieldMixin from "vuetable-2/src/components/VuetableFieldMixin.vue";
import axios from "@/services/api";
import moment from "moment";

export default {
  name: 'markattendance',
  components: {},
  data: function (){
    return {
      present: false,
      absent: true,
      attendance: -5,
      loading: '',
      row: {}
    }
  },
  mounted: function() {

    this.row = this.rowData
    this.attendance = parseInt(this.rowData.status);

  },
  computed: {

  },
  mixins: [VuetableFieldMixin],
  methods: {

    getRowID: function (suffix){

      return suffix + '-' + this.rowData.id;

    },

    markAttendance: function (status){

      var vm = this;

      var date =  moment(vm.row.attendance_date,'YYYY-MM-DD').format('YYYY-MM-DD');
      var dt = {
        id: vm.row.worksheet_member_id,
        status: status,
        date: date,
      };

      var dat = [];
      dat.push(dt);

      vm.submitAttendance(dat)
      //vm.EventBus.$emit('event:mark:attendance',JSON.stringify(dt));

    },

    submitAttendance: function (data){

      var vm = this;

      if(this.attendance.length === 0){

        this.setError("Invalid name", "Mark attendance to proceed");
        return;
      }

      this.loading = 'loading';

      var path = process.env.VUE_APP_URL_WORKSHEET_ATTENDANCE_MARK.replace(":id", vm.row.worksheet_id);

      axios.post(path, JSON.stringify(data),{
        headers: {
          'api-key': vm.getAuth()
        },
      }).then(res => {

            vm.loading = '';
            var msg = res.data.data.message;
            vm.setSuccess("success", msg)

          })
          .catch(err => {

              vm.loading = '';

              vm.setError("error", err.response.data.data)

              if (err.response) {

                var message = "";

                if(parseInt(err.response.status) === 428 ) {

                  message = err.response.data.message;
                  vm.setError("Failed", message)
                  return;
                }

                if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                  vm.setError("Session Expired", "Your session on this device has expired");
                  vm.logout();
                  return;

                } else {

                  message =  err.response.data.message;
                  vm.setError("Failed", message)

                }

              }
              else if (err.request) {

                vm.setError("Failed", "Please check your network")
                console.log(JSON.stringify(err.request));

              }
              else {

                console.log(JSON.stringify(err));

              }

          })
    },

  },
  watch: {

    attendance: function (newVal,oldVal){

      newVal = parseInt(newVal)
      console.log('attendance changed to '+newVal+' from '+oldVal)

      if(oldVal !== -5) {

        this.markAttendance(newVal)

      }

    },

  }
}

</script>