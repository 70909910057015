<template>

    <div class="">

      <div class="container-fluid">

        <div class="card mb-3">
          <div class="card-header card-body-header-lg p-2 pb-0 d-flex justify-content-between shadow-none bg-light ">
            <div class=" d-flex justify-content-left ">

              <span class="font-weight-500 men-item underline-item mr-2 px-3 active">
                  <router-link to="/settings" class="">Rate Card</router-link>
              </span>

              <span class="font-weight-500 men-item underline-item px-3 mr-2">
                 <router-link to="/settings/approvers" class="">Approvers</router-link>
              </span>

              <span class="font-weight-500 men-item underline-item px-3 mr-2" style="display: none">
                 <router-link to="/settings/payment" class="">Rate</router-link>
              </span>

              <span class="font-weight-500 men-item underline-item px-3 mr-2" style="display: none">
                 <router-link to="/settings/roles" class="">Roles</router-link>
              </span>

            </div>
          </div>
        </div>

        <div class="row">

          <div class="col-md-5">
            <div class="card custom-card-wrapper">
              <div class="card-header line-height-1">
                <span>Add New Rate Card</span>
              </div>
              <div class="card-body">
                <form class="custom-form">

                  <div class="input-wrapper mb-2">

                    <div class="mb-3">
                      <label for="name" class="form-label">Title</label>
                      <input type="text" class="form-control" id="name" v-model="name">
                    </div>

                    <div class="mb-3">
                      <label  class="form-label">Payment Type</label>
                      <select class="form-select" aria-label="Default select example" v-model="wage_type">
                        <option value="1" selected>Daily with Overtime</option>
                        <option value="2">Daily</option>
                      </select>
                    </div>

                    <div class="mb-3">
                      <label class="form-label">Daily Amount</label>
                      <input type="text" class="form-control" v-model="daily">
                    </div>

                    <div class="mb-3" v-show="parseInt(wage_type) === 1">
                      <label class="form-label">Overtime Amount</label>
                      <input type="text" class="form-control" v-model="overtime">
                    </div>

                  </div>

                  <div class="modal-footer-copy">
                    <button type="button" class="btn btn-primary btn-sm shadow mr-2" @click="createJob">Add</button>
                    <button type="button" class="btn btn-outline-secondary btn-sm " data-bs-dismiss="modal">Cancel</button>
                  </div>

                </form>
              </div>
            </div>
          </div>

          <div class="col-md-7">
            <div class="card custom-card-wrapper">
              <div class="card-header line-height-1">
                Rate Card List
              </div>
              <div class="card-body p-0">

                <table class="table table-striped">
                  <thead class=" ">
                  <tr>
                    <th>Job ID</th>
                    <th>Title</th>
                    <th>Daily Rate</th>
                    <th>Overtime</th>
                    <th class="text-right">Actions</th>
                  </tr>

                  </thead>
                  <tbody>
                  <tr v-for="(job,index) in jobs" v-bind:key="getKey(index)" class="">
                    <td v-text="job.id"></td>
                    <td v-text="job.name"></td>
                    <td v-text="formatCurrency(job.daily)"></td>
                    <td v-text="formatCurrency(job.overtime)"></td>
                    <td class="text-right">
                      <div class="btn-group">
                      <button type="button" class="btn btn-primary btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        More
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                          <a class="dropdown-item" @click="deleteJob(job)">Delete Job</a>
                        </li>

                        <li>
                          <a class="dropdown-item" @click="editJob(job)" data-bs-toggle="modal" data-bs-target="#update-job-title">Update Details</a>
                        </li>
                      </ul>
                    </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- Modal -->
      <div class="modal fade" id="update-job-title" tabindex="-1" aria-labelledby="update-job-title" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Update Rate Card</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form class="custom-form">
                <div class="input-wrapper mb-2">

                  <div class="mb-3">
                    <label for="u-name" class="form-label">Title</label>
                    <input type="text" class="form-control" id="u-name" v-model="currentjob.name">
                  </div>

                  <div class="mb-3">
                    <label for="u-keyword" class="form-label">Keyword</label>
                    <input type="text" class="form-control" id="u-keyword" v-model="currentjob.job_keyword">
                  </div>

                  <div class="mb-3">
                    <label for="u-daily-rate" class="form-label">Daily Rate</label>
                    <input type="number" class="form-control" id="u-daily-rate" v-model="currentjob.daily">
                  </div>

                  <div class="mb-3">
                    <label for="u-daily-rate" class="form-label">Overtime Rate</label>
                    <input type="number" class="form-control" id="u-overtime-rate" v-model="currentjob.overtime">
                  </div>

                </div>


                <div class="modal-footer-copy">
                  <button type="button" class="btn btn-outline-secondary btn-sm mr-2" data-bs-dismiss="modal" id="close-update-job-title">Close</button>
                  <button type="button" class="btn btn-primary btn-sm shadow" @click="updateJob">Update Job</button>
                </div>
              </form>
            </div>

          </div>
        </div>
      </div>

    </div>

</template>

<script>
import axios from "@/services/api";

export default {
  name: 'settings',
  components: {

  },
  data: function (){
    return {
      mqttClient:'',
      active_tab: 'account',
      jobs: [],
      id: 0,
      keyword: '',
      name:'',
      daily: 0,
      overtime:0,
      currentjob: {},
      wage_type: 2,
    }
  },
  mounted() {

    this.$store.dispatch("setCurrentPage","settings");
    this.$store.dispatch("setCurrentPageName","Settings");
    this.$store.dispatch("setPreviousPage","dashboard");
    this.getJobs();

  },
  computed: {

  },
  methods: {

    getJobs: function(){

      var vm = this;

      var path = process.env.VUE_APP_URL_RATECARD_VIEW_LIST;

      axios.post(path, JSON.stringify({}),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            var msg = res.data.data;
            vm.jobs = msg;
          })
          .catch(err => {

            vm.loading = '';

            console.log('FAILED response '+JSON.stringify(err.response.data.data))

            if (err.response) {

              vm.setError("Failed",err.response.data.data)

            }
            else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")

            }
            else {

              vm.setError("Network Error", "Check your network connection and try again")

            }

          })

    },

    showTab: function(tabName) {

      this.active_tab = tabName;
    },

    isActiveTab: function(tabName) {

      return this.active_tab === tabName;
    },

    getTabClass: function(tabName) {

      return this.active_tab === tabName ? 'active-tab' : '';
    },

    getTabTitleClass: function(tabName) {

      return this.active_tab === tabName ? 'active' : '';

    },

    geSubPageActiveClass: function (subpage) {

      return subpage === this.sub_page ? 'active' : "";

    },

    deleteJob: function(data) {

      var vm = this;

      vm.$swal.fire({
        title: "Do you want to delete. "+data.name+'?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete'

      }).then((result) => {

        if (result.isConfirmed) {

          var path = process.env.VUE_APP_URL_RATECARD_DELETE.replace(":id", data.id);
          vm.loading = 'loading';

          axios.post(path, JSON.stringify({}),{
            headers: {
              'api-key': vm.getAuth()
            },
          })
              .then(res => {

                vm.loading = '';
                vm.getJobs();

                var msg = res.data.data.message;

                vm.$swal.fire(
                    'Submitted!',
                    msg,
                    'success'
                )

              })
              .catch(err => {

                vm.loading = '';

                vm.$swal.fire(
                    'Failed!',
                    err.response.data.data,
                    'error'
                )

                if (err.response) {

                  var message = "";

                  if(parseInt(err.response.status) === 428 ) {

                    message = err.response.data.message;
                    vm.setError("Failed", message)
                    return;
                  }

                  if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                    vm.setError("Session Expired", "Your session on this device has expired");
                    vm.logout();
                    return;

                  } else {

                    message =  err.response.data.message;
                    vm.setError("Failed", message)

                  }

                } else if (err.request) {

                  vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err.request));

                } else {

                  //vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err));

                }
              })
        } else {

          vm.cashoutLoading = '';

        }

      })

    },

    editJob: function (data) {

      this.currentjob = data;

    },

    createJob: function (){

      var vm = this;

      if (!this.name) {

        this.setError("Invalid name", "Please enter a valid Rate Card name");
        vm.$swal.fire(
            'Error!',
            "Please enter a valid job valid name",
            'error'
        )
        return;
      }

      if (parseInt(this.daily) === 0 ) {

        this.setError("Invalid daily rate", "Please enter a daily rate");
        vm.$swal.fire(
            'Error!',
            "Please enter a worksheet valid start date",
            'error'
        )
        return;
      }

      var path = process.env.VUE_APP_URL_RATECARD_CREATE;

      this.loading = 'loading';

      var data = {
        name: this.name,
        keyword: this.keyword,
        daily: parseFloat(this.daily),
        overtime: parseFloat(this.overtime),
      };

      axios.post(path, JSON.stringify(data),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            var msg = res.data.data.message;
            vm.setSuccess("Success", msg);

            vm.$swal.fire(
                'Submitted!',
                msg,
                'success'
            )
            vm.getJobs();
          })
          .catch(err => {

            vm.loading = '';

            console.log('FAILED response '+JSON.stringify(err.response.data.data))

            if (err.response) {
              vm.setError("Failed",err.response.data.data)
            }
            else if (err.request) {
              vm.setError("Network Error", "Check your network connection and try again")
            }
            else {
              vm.setError("Network Error", "Check your network connection and try again")
            }
          })

    },

    updateJob: function (){

      var vm = this;

      if (!this.currentjob.name) {

        this.setError("Invalid name", "Please enter a valid Rate Card name");
        vm.$swal.fire(
            'Error!',
            "Please enter a valid job valid name",
            'error'
        )
        return;
      }

      if (parseInt(this.currentjob.daily) === 0 ) {

        this.setError("Invalid daily rate", "Please enter a daily rate");
        vm.$swal.fire(
            'Error!',
            "Please enter a worksheet valid start date",
            'error'
        )
        return;
      }

      var path = process.env.VUE_APP_URL_RATECARD_UPDATE.replace(":id", this.currentjob.id);

      this.loading = 'loading';

      var data = {
        name: this.currentjob.name,
        keyword: this.currentjob.job_keyword,
        daily: parseFloat(this.currentjob.daily),
        overtime: parseFloat(this.currentjob.overtime),
      };

      axios.post(path, JSON.stringify(data),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            var msg = res.data.data.message;
            vm.setSuccess("Success", msg);
            vm.$swal.fire(
                'Updated!',
                msg,
                'success'
            )
            vm.getJobs();
            vm.closeModal('create-job-title');

          })
          .catch(err => {

            vm.loading = '';

            console.log('FAILED response '+JSON.stringify(err.response.data.data))

            if (err.response) {
              vm.setError("Failed",err.response.data.data)
            }
            else if (err.request) {
              vm.setError("Network Error", "Check your network connection and try again")
            }
            else {
              vm.setError("Network Error", "Check your network connection and try again")
            }
          })

    },

  },
}

</script>