<template>

  <body id="app">

  <section v-if="current_page === 'login'" class="main-wrapper d-flex justify-content-center text-center" >

    <div id="body-wrapper-1">

      <router-view></router-view>

      <div id="footer-1" class="text-center">
        <a href="">Contact</a> <a href="">Customer Support </a> <span>&copy; <span v-text="year"></span> Bulk Payment. All Rights Reserved</span>
      </div>

    </div>

  </section>

  <section v-else class="main-wrapper d-flex justify-content-start">

      <div id="menu-wrapper">

        <div class="menu-header  text-light text-center font-weight-bold py-3 px-3 pr-1 d-flex justify-content-between">
          <span id="title">WagePay</span>
          <span @click="toggleMenu()" class="toggle-btn bg-transparent text-light">
              <i class="bi bi-arrow-right  d-none"></i> <i class="bi bi-arrow-left"></i>
            </span>
          <span @click="hideSideBar()" class="hide-btn bg-transparent text-light">
              <i class="bi bi-arrow-left"></i>
            </span>
        </div>

        <div class="menu-items">

          <router-link class="" to="/dashboard">
            <div class="menu-item px-3 py-2 " v-bind:class="activeClass('dashboard')">
              <img src="/img/dashboard_active.svg" class="menu-item-icon ">
              <span class="tohide">Dashboard</span>
            </div>
          </router-link>

          <router-link class="" to="/details">
            <div class="menu-item px-3 py-2 " v-bind:class="activeClass('details')">
              <img src="/img/business.svg" class="menu-item-icon ">
              <span class="tohide">Business Account</span>
            </div>
          </router-link>

          <router-link class="" to="/groups">
            <div class="menu-item px-3 py-2 "  v-bind:class="activeClass('groups')">
              <img src="/img/payment_groups.svg" class="menu-item-icon ">
              <span class="tohide">Payment Group</span>
            </div>
          </router-link>

          <router-link class="" to="/worksheets">
            <div class="menu-item px-3 py-2 "  v-bind:class="activeClass('worksheets')">
              <img src="/img/worksheet.svg" class="menu-item-icon ">
              <span class="tohide">Timesheet</span>
            </div>
          </router-link>

          <router-link class="" to="/payments">
            <div class="menu-item px-3 py-2 "  v-bind:class="activeClass('payments')">
              <img src="/img/payment_groups.svg" class="menu-item-icon ">
              <span class="tohide">Payments</span>
            </div>
          </router-link>

          <!--
          <a class="" href="/communication" style="">
            <div class="menu-item px-3 py-2 "  v-bind:class="activeClass('communications')">
              <img src="/img/communications.svg" class="menu-item-icon ">
              <span class="tohide">Communications</span>
            </div>
          </a>
          -->

          <router-link class="" to="/approvals">
            <div class="menu-item px-3 py-2 "  v-bind:class="activeClass('approvals')">
              <img src="/img/business.svg" class="menu-item-icon ">
              <span class="tohide">Approvals</span>
            </div>
          </router-link>

          <router-link class="" to="/users">
            <div class="menu-item px-3 py-2 "  v-bind:class="activeClass('users')">
              <img src="/img/user.svg" class="menu-item-icon ">
              <span class="tohide">User Management</span>
            </div>
          </router-link>

          <router-link class="" to="/settings">
            <div class="menu-item px-3 py-2 "  v-bind:class="activeClass('settings')">
              <img src="/img/settings.svg" class="menu-item-icon ">
              <span class="tohide">Settings</span>
            </div>
          </router-link>

        </div>

        <div class="px-3 log-out">

          <a @click="logout" class="logout-btn px-0 py-0 form-control bg-transparent">
            <div class="text-green px-2 p text-center py-1 ">
              <img src="/img/loguot.svg" class="menu-item-icon mr-0">
              <span class="tohide ml-2">Logout</span>
            </div>
          </a>

        </div>

      </div>

      <div id="body-wrapper">

        <div class="main-header d-flex justify-content-end line-height-1 p-2">
          <div class="balance-sec px-4">
                <span class="text-small">
                    Account balance
                </span><br>
            <span class="text-large font-weight-500">
              Ksh <span v-text="bal"></span>
            </span>
          </div>
          <a  class="primary-btn " data-bs-toggle="modal" data-bs-target="#make-payment">Top Up</a>
          <a href="" class="icon-btn px-2"><img class="icon-btn" src="/img/bell.svg"></a>
          <a href="" class="icon-btn" data-bs-toggle="dropdown" aria-expanded="false"><img class="icon-btn" src="/img/profile.svg"></a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#changepassword">Change Password</a></li>
            <li><a class="dropdown-item" @click="logout">Logout</a></li>
          </ul>
          <span class="menu-toggle" @click="toggleSideBar()">
                <i class="bi bi-list"></i>
            </span>
        </div>

        <div class="dashboard-header container-fluid py-2 mb-2 d-flex justify-content-between">

          <div class="lef">
<!--            <router-link v-show="previous_page.length > 0" v-bind:to="prev_page.route"><img v-bind:src="prev_page.img" class="dashboard-header-icon"><span v-text="prev_page.name"></span> / </router-link> <span v-text="current_page_name"></span>-->
          </div>

<!--          <div>-->
<!--            <div id="reportrange" style="">-->
<!--              <i class="bi bi-calendar"></i>&nbsp;-->
<!--              <span></span> <i class="bi bi-chevron-down"></i>-->
<!--            </div>-->
<!--          </div>-->

        </div>

        <router-view></router-view>

        <div id="footer" class="text-center">
          <a href="">Contact</a> <a href="">Customer Support </a> <span>&copy; <span v-text="year"></span> Bulk Payment. All Rights Reserved</span>
        </div>

      </div>

    </section>

    <div class="modal fade" id="changepassword" tabindex="-1" aria-labelledby="change-password" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" v-bind:class="loading">
        <div class="modal-content">

          <div class="modal-header">
            <h5 class="modal-title" id="change-password">Change Password</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>

          <div class="modal-body">

            <form>

              <div v-show="error_message.length > 0" class="alert alert-danger alert-dismissible fade show" role="alert" style="font-size: 0.9em;text-align: left;">
                <span v-text="error_message"></span>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>

              <div v-show="success_message.length > 0" class="alert alert-success alert-dismissible fade show" role="alert" style="font-size: 0.9em;text-align: left;">
                <span v-text="success_message"></span>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>

              <div class="form-floating mb-3">
                <input v-model="current_password" type="password" class="form-control form-control-lg" id="current_password" placeholder="Input your current password">
                <label for="current_password" class="form-label">Current Password</label>
              </div>

              <div class="form-floating mb-3">
                <input v-model="password" type="password" class="form-control form-control-lg" id="password" placeholder="Input your new password">
                <label for="password" class="form-label">Password</label>
              </div>

              <div class="form-floating mb-3">
                <input v-model="password1" type="password" class="form-control form-control-lg" id="password1" placeholder="Repeat your new password">
                <label for="password1" class="form-label">Repeat Password</label>
              </div>

              <div class="modal-footer-copy">
                <button type="button" class="btn btn-outline-secondary btn-sm mr-2" id="close-changepassword" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary btn-sm shadow" @click="changePassword">Change Password</button>
              </div>

            </form>

          </div>

        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Top Up Account</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form class="custom-form">
              <div class="input-wrapper mb-2">
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label">Select Payment Method</label>
                  <select class="form-select" aria-label="Default select example">
                    <option value="1">MPESA</option>
                  </select>
                </div>
                <div class="mb-3">
                  <label for="exampleInputPassword1" class="form-label">Amount to top up</label>
                  <input type="text" class="form-control" >
                </div>
                <div class="mb-3">
                  <label for="exampleInputPassword1" class="form-label">Your MPESA Phone No</label>
                  <input type="text" class="form-control" >
                </div>
              </div>


              <div class="modal-footer-copy">
                <button type="button" class="btn btn-outline-secondary btn-sm mr-2" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary btn-sm shadow">Topup Account</button>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>

    <div class="modal fade" id="make-payment" tabindex="-1" aria-labelledby="make-payment-label" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered"  v-bind:class="loading">
        <div class="modal-content">

          <div class="modal-header">
            <h5 class="modal-title" id="make-payment-label">Top Up Account</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>

          <div class="modal-body">

            <form class="custom-form">

              <div class="form-floating mb-3">

                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="topup_method" id="topup_method_mpesa" value="mpesa" v-model="topup_method" style="margin-top: 5px">
                  <label class="form-check-label" for="topup_method_mpesa">M-PESA</label>
                </div>

                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="topup_method" id="topup_method_card" value="card" v-model="topup_method" style="margin-top: 5px">
                  <label class="form-check-label" for="topup_method_card">Card</label>
                </div>

              </div>


              <div v-show="topup_method === 'mpesa'" class="card text-center" style="margin-bottom: 10px;">

                <div class="card-header">

                  <ul class="nav nav-tabs card-header-tabs">

                    <li class="nav-item">
                      <a class="nav-link " v-bind:class="isSelectedMpesaMethod('checkout')" aria-current="true" @click="setMpesaMethod('checkout')">M-PESA Checkout</a>
                    </li>

                    <li class="nav-item">
                      <a class="nav-link" v-bind:class="isSelectedMpesaMethod('lnm')"  @click="setMpesaMethod('lnm')">Lipa na M-PESA</a>
                    </li>
                  </ul>
                </div>

                <div v-show="mpesa_method === 'checkout'" class="card-body" >
                  <h6 class="card-title">M-PESA Express Checkout (STK Push)</h6>
                  <div class="form-floating mb-3">
                    <input v-model="msisdn" type="number" class="form-control form-control-lg" id="msisdn" placeholder="Mobile Number to Topup">
                    <label for="msisdn" class="form-label">Mobile Number to Topup</label>
                  </div>

                  <div class="form-floating mb-3">
                    <input v-model="topup_amount" type="number" class="form-control form-control-lg" id="topup_amount1" placeholder="Amount to Topup">
                    <label for="topup_amount1" class="form-label">Amount to Topup</label>
                  </div>

                  <a class="btn bg-green" @click="mpesaPayment">Make Payment</a>
                </div>

                <div v-show="mpesa_method === 'lnm'" class="card-body">

                  <ol class="list-group list-group-flush" style="text-align: left">
                    <li class="list-group-item" style="border-bottom: grey solid 2px;">Step 1: Go to M-PESA</li>
                    <li class="list-group-item" style="border-bottom: grey solid 2px;">Step 2: Select Lipa na M-PESA</li>
                    <li class="list-group-item" style="border-bottom: grey solid 2px;">Step 3: Select PayBill</li>
                    <li class="list-group-item" style="border-bottom: grey solid 2px;">Step 4: Enter <strong>892544 as Paybill</strong></li>
                    <li class="list-group-item" style="border-bottom: grey solid 2px;">Step 5: Enter <strong v-text="client.account"></strong> as account number</li>
                    <li class="list-group-item" style="border-bottom: grey solid 2px;">Step 6: Enter Amount to Topup</li>
                    <li class="list-group-item">Step 7: Enter M-PESA Pin</li>
                  </ol>
                </div>

              </div>

              <div v-show="topup_method === 'card'" class="form-floating mb-3">
                <input v-model="topup_amount" type="number" class="form-control form-control-lg" id="topup_amount" placeholder="Amount to Topup">
                <label for="topup_amount" class="form-label">Amount to Topup</label>
              </div>

              <div class="modal-footer-copy">
                <button type="button" class="btn btn-outline-secondary btn-sm mr-2" id="close-make-payment" data-bs-dismiss="modal">Close</button>
                <button v-show="topup_method === 'card'" type="button" class="btn bg-green btn-sm shadow" @click="cardPayment">Make Payment</button>
              </div>

            </form>
          </div>

        </div>
      </div>
    </div>

    <span data-bs-toggle="modal" data-bs-target="#card-iframe" id="show-card-iframe"></span>

    <div class="modal fade" id="card-iframe" tabindex="-1" aria-labelledby="card-iframe-label" aria-hidden="true">

      <div class="modal-dialog modal-lg" style="min-height: 700px">

        <div class="modal-content">

          <div class="modal-body">
            <iframe id="iframe" style="width: 100%;min-height: 700px;border: 0;"></iframe>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" id="cloe-card-iframe" data-bs-dismiss="modal">Close</button>
          </div>

        </div>

      </div>

    </div>

  </body>

</template>

<script>

import mqtt from "mqtt";
import axios from "@/services/api";

export default {
  name: 'app',
  components: {

  },
  mounted: function () {

    var vm = this;

    this.EventBus.$on('deposit:popup',function(payload){
      vm.amount = payload.amount;
      vm.message = payload.message;
      document.getElementById("deposit-init").click();
    });

    this.EventBus.$on('init:mqtt',function(){

      console.log('Wants Inititalize MQTT');

      if(vm.mqttClient !== false ) {

        vm.mqttClient.end();
        vm.mqttClient = false;
      }

      vm.myProfile = vm.getProfile();
      vm.initMqtt();

    });

    vm.initMqtt();

    var params = this.getURLParameters();
    var utm_source = params.utm_source ? params.utm_source : ''
    var utm_medium = params.utm_medium ? params.utm_medium : ''
    var utm_campaign = params.utm_campaign ? params.utm_campaign : ''
    var referrer = document.referrer
    if(utm_source.length > 0 ) {

      this.setValue('utm_source',utm_source)
    }

    if(utm_medium.length > 0 ) {

      this.setValue('utm_medium',utm_medium)
    }

    if(utm_campaign.length > 0 ) {

      this.setValue('utm_campaign',utm_campaign)
    }

    if(referrer.length > 0 ) {

      this.setValue('referrer',referrer)

   }

  },
  computed: {

    bal: function() {

      return this.formatCurrency(this.client.balance);

    },
    full_name: function () {

      if (this.profile === undefined) {

        return ""
      }

      if (this.profile.f1 === undefined || this.profile.f1.length === 0) {

        return ms;

      }

      var fn = this.profile.f1;
      var mn = this.profile.f2;
      var ln = this.profile.f3;
      var ms = this.getUIValue(this.profile.m);
      return fn + ' ' + mn + ' ' + ln + ' - ' + ms;

    },

    profile: function () {

      return this.getProfile();

    },

    current_page: function () {

      return this.$store.state.current_page;

    },
    current_page_name: function () {

      return this.$store.state.current_page_name;

    },
    previous_page: function () {

      return this.$store.state.previous_page;

    },
    client: function (){

      return this.getClient();
    },
  },
  watch: {

    current_page: function (){

      this.getPrevioudPage();
    }

  },
  methods: {

    getPrevioudPage: function (){

      var vm = this;
      var name = this.previous_page;
      var pg = vm.pages[0];
      vm.jQuery.each(vm.pages,function(k,v){

        if(v.route === name ) {

          pg = v;
        }
      });
      this.prev_page = pg;
    },

    activeClass: function (page){

      return page == this.current_page ? 'active' : '';

    },

    toggleMenu: function () {

      var menuElements = document.getElementsByClassName('tohide');
      var toggleIcon = document.getElementsByClassName('bi-arrow-left')[0];
      var toggleIcon2 = document.getElementsByClassName('bi-arrow-right')[0];

      for (let i = 0; i < menuElements.length; i++) {

        if(menuElements[i].classList.contains('d-none')){

          menuElements[i].classList.remove('d-none')

        }else{

          menuElements[i].classList.add('d-none')
        }
      }

      if(document.getElementById("title").innerHTML === 'BP'){

        document.getElementById("title").innerHTML = 'BULK PAYMENT'
        document.getElementById('menu-wrapper').style.width = '200px';

      } else {

        document.getElementById("title").innerHTML = 'BP'
        document.getElementById('menu-wrapper').style.width = 'auto';

      }

      if(toggleIcon.classList.contains('d-none')){

        toggleIcon.classList.remove('d-none');
        toggleIcon2.classList.add('d-none')

      } else {

        toggleIcon2.classList.remove('d-none');
        toggleIcon.classList.add('d-none');

      }

      // document.getElementById('menu-wrapper').style.width = '';
      // document.getElementById('body-wrapper').style.width = 'calc(100% - '+
    },

    toggleSideBar: function() {

      var sidebar = document.getElementById('menu-wrapper');
      sidebar.style.display = 'block';

      if(sidebar.classList.contains('d-none')){
        sidebar.classList.remove('d-none');
      }

    },

    hideSideBar: function() {

      var sidebar = document.getElementById('menu-wrapper');
      sidebar.style.display = 'none';

    },

    isActive: function (page){

      return this.current_page === page ? 'active' : '';

    },

    setActive: function(menu){

      this.current_menu = menu;

    },

    initMqtt: function(){

      var endpoint = process.env.VUE_APP_MQTT_HOST;
      var vm = this;

      if(this.mqttClient !== false ) {

        this.mqttClient.end();

      }

      // connection option

      const options = {
        clean: true, // retain session
        connectTimeout: 4000, // Timeout period
        // Authentication information
        clientId: this.getClientID(),
        username: process.env.VUE_APP_MQTT_USERNAME,
        password: process.env.VUE_APP_MQTT_PASSWORD,
      }

      var client  = mqtt.connect(endpoint,options);

      client.on('connect', function () {

        var profileTopic = 'topic/profile';

        client.subscribe(profileTopic, function (err) {
          if (!err) {

            console.log('subscribed to topic '+profileTopic);

          }

        });

      })

      client.on('message', function (topic, msg) {
        // message is Buffer
        var payload = JSON.parse(msg.toString());
        console.log("systemNotification ===> "+JSON.stringify(payload));
        vm.uxUpdate(payload);
      })

      this.mqttClient = client;

    },

    initMenuModal: function () {

      // Get the modal
      var modal = document.getElementById("menu-modal");

      // Get the button that opens the modal
      var btn = document.getElementById("menu-init");

      // Get the <span> element that closes the modal
      var span = document.getElementsByClassName("faso-menu-close")[0];

      // When the user clicks on <span> (x), close the modal
      span.onclick = function () {
        modal.style.display = "none";
      }

      // When the user clicks the button, open the modal
      btn.onclick = function () {
        modal.style.display = "block";
      }

      document.addEventListener("click", e => {

        if (e.target == document.querySelector("menu-modal")) {

          modal.style.display = "none";
        }

      });

      // When the user clicks anywhere outside of the modal, close it
      window.onclick = function (event) {

        if (event.target === modal) {

          modal.style.display = "none";

        }
      }

    },

    initDeposit: function () {

      this.dismiss();
      this.$router.push({name: 'deposit'});
    },

    dismiss: function () {

      console.log('DISMISS MENU');
      var modal = document.getElementById("menu-modal");
      modal.style.display = "none";

    },

    getKey: function (index) {

      return Math.random().toString(10).replace('0.', 'sport-' + index + '-');

    },

    setAmount: function (amount) {

      this.amount = amount
      this.deposit();

    },

    deposit: function () {

      this.reset();
      var p = this.getProfile();

      if(!p) {

        this.setError("Login","Please login to proceed");
        this.$router.push({ name: 'login', params: { } });
        return;

      }

      if(this.amount < 1 ) {

        this.setError("Invalid Amount","ENter amount atleast 1 KSH or above");
        return;

      }

      var vm = this;
      var path = process.env.VUE_APP_URL_DEPOSIT.replace("{profile_id}", p.d);

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var referrer = this.getValue("referrer");

      var data = {
        amount: parseInt(this.amount),
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: referrer,
      };

      vm.loading = 'loading';

      axios.post(path, JSON.stringify(data),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
              .then(res => {

                vm.loading = '';
                var msg = res.data.message;
                vm.setSuccess("Deposit Initiated",msg);
                vm.message = 'Please enter amount you want to deposit';

              })
              .catch(err => {

                vm.loading = '';
                if (err.response) {

                  if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 || parseInt(err.response.status) === 428 ) {

                    vm.setError("Session Expired", "Your session on this device has expired");
                    vm.logout();
                    return;

                  } else {

                    vm.setError("Failed", err.response.data.message)
                    console.log(JSON.stringify(err.response.data.message));
                  }

                } else if (err.request) {

                  vm.setError("Network Error", "Check your network connection and try again")
                  console.log(JSON.stringify(err.request));

                } else {

                  vm.setError("Network Error", "Check your network connection and try again")
                  console.log(JSON.stringify(err));

                }

              })
    },

    showDeposit: function() {

      document.getElementById("deposit-init").click();

    },

    changePassword: function () {

      this.error_message = '';
      this.success_message = '';

      if (this.current_password.length < 1) {

        this.error_message = "Please enter current password";
        return;

      }

      if (this.password.length < 4) {

        this.error_message = "Please enter a password of more than 4 characters";
        return;

      }

      if (this.password !== this.password1) {

        this.error_message = "Password does not match";
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_CHANGE_PASSWORD;

      axios.post(path, JSON.stringify({
        current_password: this.current_password,
        password: this.password
      }),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            var msg = res.data.data;
            vm.error_message = '';
            vm.success_message = msg;
            vm.closeModal('changepassword');
            vm.$swal.fire(
                'Updated',
                msg,
                'success'
            )

          })
          .catch(err => {

            vm.loading = '';

            if (err.response) {

              console.log(JSON.stringify(err.response,undefined,2))
              vm.error_message = err.response.data.data;

            }
            else if (err.request) {

              console.log(JSON.stringify(err.request,undefined,2))
              vm.error_message = "Check your network connection and try again";

            }
            else if (err.message) {

              console.log(JSON.stringify(err.message,undefined,2))
              vm.error_message = err.message;

            }
            else {

              vm.error_message = "Check your network connection and try again";

            }

          })

    },

    cardPayment: function (){

      var vm = this;
      vm.closeModal('make-payment');

      document.getElementById('show-card-iframe').click();

      var url = process.env.VUE_APP_BASE_URL + process.env.VUE_APP_URL_TOPUPS_INITIATE_CARD_PAYMENT

      this.process_card_payment = true;
      this.SMESPAY.setAddress('Wangige');
      this.SMESPAY.setCity('Nairobi');
      this.SMESPAY.setCountry('KE');
      this.SMESPAY.setAmount(this.topup_amount);
      this.SMESPAY.setEmail(vm.profile.email);
      this.SMESPAY.setReference(vm.client.account);
      this.SMESPAY.setFirstName(vm.profile.first_name);
      this.SMESPAY.setLastName(vm.profile.last_name);
      this.SMESPAY.setApiKey(vm.getAuth());
      this.SMESPAY.setTransactionType('sale');
      this.SMESPAY.setURL(url);
      this.SMESPAY.pay();

    },

    mpesaPayment: function (){

      var vm = this;
      this.error_message = '';
      this.success_message = '';

      if (parseInt(this.topup_amount) < 1) {

        this.error_message = "Invalid top up amount";
        return;

      }

      if (parseInt(this.msisdn) < 1) {

        this.error_message = "Invalid top up Mobile Number";
        return;

      }

      this.loading = 'loading';

      var path = process.env.VUE_APP_URL_TOPUPS_INITIATE_MPESA_PAYMENT;

      axios.post(path, JSON.stringify({
        amount: this.topup_amount,
        msisdn: this.msisdn
      }),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            var msg = res.data.data;
            vm.error_message = '';
            vm.success_message = msg;
            vm.closeModal('make-payment');
            vm.$swal.fire(
                'Updated',
                msg,
                'success'
            )

          })
          .catch(err => {

            vm.loading = '';

            if (err.response) {

              console.log(JSON.stringify(err.response,undefined,2))
              vm.error_message = err.response.data.data;

            }
            else if (err.request) {

              console.log(JSON.stringify(err.request,undefined,2))
              vm.error_message = "Check your network connection and try again";

            }
            else if (err.message) {

              console.log(JSON.stringify(err.message,undefined,2))
              vm.error_message = err.message;

            }
            else {

              vm.error_message = "Check your network connection and try again";

            }

          })

    },

    isSelectedMpesaMethod: function (name){

      return this.mpesa_method === name ? 'active' : '';

    },
    setMpesaMethod: function (name){

       this.mpesa_method = name;
    }

  },
  data: function () {
    return {
      current_menu: '',
      amount: 49,
      iconSize: 24,
      iconBigSize: 30,
      mqttClient: false,
      myProfile: this.getProfile(),
      loading: '',
      message:'Please enter amount you want to deposit',
      year: new Date().getFullYear(),
      prev_page: {},
      pages: [
        {
          route: 'dashboard',
          name: 'Dashboard',
          img:'/img/dashboard_active.svg'
        },
        {
          route: 'groups',
          name: 'Payroll',
          img:'/img/payment_groups.svg'
        },
        {
          route: 'worksheets',
          name: 'Timesheet',
          img:'/img/worksheet.svg'
        },
        {
          route: 'payments',
          name: 'Payment',
          img:'/img/payment_groups.svg'
        },
        {
          route: 'communication',
          name: 'Communications',
          img:'/img/communications.svg'
        },
        {
          route: 'approvals',
          name: 'Approvals',
          img:'/img/business.svg'
        },
        {
          route: 'user',
          name: 'User Management',
          img:'/img/user.svg'
        },
        {
          route: 'setting',
          name: 'Settings',
          img:'/img/settings.svg'
        }
      ],
      password:'',
      password1:'',
      current_password: '',
      code: '',
      success_message: '',
      error_message: '',
      process_card_payment: false,
      topup_method: 'mpesa',
      topup_amount: 0,
      msisdn: '',
      mpesa_method: 'checkout',
    }
  },
  beforeDestroy: function () {

    console.log("beforeDestroy")
    if(this.mqttClient !== false ) {

      this.mqttClient.end();
      this.mqttClient = false;

    }

  },
  destroyed: function () {

    console.log("destroyed")
    if(this.mqttClient !== false ) {

      this.mqttClient.end();
      this.mqttClient = false;

    }

  }
}
</script>