import Home from './components/Home'
import Details from '@/components/Details'
import Groups from '@/components/paymentgroup/index'
import CreateGroups from '@/components/paymentgroup/create'
import BusinessGroups from '@/components/paymentgroup/business'
import Worksheet from '@/components/worksheet/index'
import WorksheetAttendance from '@/components/worksheet/attendance'
import WorksheetCreateMembers from '@/components/worksheet/create'
import WorksheetViewMembers from '@/components/worksheet/view'
import WorksheetPayment from '@/components/worksheet/payment'
import WorksheetPayments from '@/components/worksheet/payments'
import WorksheetPaymentScheduleMembers from '@/components/worksheet/payment_schedule_members'

import Payments from '@/components/payments/index'
import PaymentsDue from '@/components/payments/due'
import PaymentsReversed from '@/components/payments/reversed'
import PaymentsCompleted from '@/components/payments/completed'
import PaymentsMake from '@/components/payments/make'
import Communication from '@/components/Communication'
import Approvals from '@/components/Approvals'
import Users from '@/components/Users'
import Settings from '@/components/settings/index'
import SettingsRoles from '@/components/settings/roles'
import SettingsPayment from '@/components/settings/payment'
import SettingsApprovers from '@/components/settings/approvers'
import Login from '@/components/Login'
import Signin from '@/components/Signin'

import Timesheet from '@/components/timesheet/index'

//const Login = () => import(/* webpackChunkName: "account" */ './components/Login')
//const Join = () => import(/* webpackChunkName: "account" */ './components/Join')

export const routes = [
    {
        path: '/',
        name: 'login',
        component: Login,
    },
    {
        path: '/join',
        name: 'signin',
        component: Signin,
    },
    {
        path: '/dashboard',
        name: 'home',
        component: Home,
        meta: {
            title: 'Wage Pay',
        }
    },
    {
        path: '/details',
        name: 'details',
        component: Details,
    },
    {
        path: '/groups',
        name: 'groups',
        component: Groups,
    },
    {
        path: '/groups/:id/create/:name',
        name: 'creategroup',
        component: CreateGroups,
    },
    {
        path: '/groups/business',
        name: 'businessgroup',
        component: BusinessGroups,
    },
    {
        path: '/timesheets',
        name: 'Timesheet',
        component: Timesheet,
    },
    {
        path: '/worksheets',
        name: 'worksheets',
        component: Worksheet,
    },
    {
        path: '/worksheets/:id/attendance/:name',
        name: 'attendance',
        component: WorksheetAttendance,
    },
    {
        path: '/worksheets/:id/payment',
        name: 'worksheetpayment',
        component: WorksheetPayment,
    },
    {
        path: '/worksheets/:id/payments',
        name: 'worksheetpayments',
        component: WorksheetPayments,
    },
    {
        path: '/worksheets/:id/payment/:payment_id/members',
        name: 'worksheetpaymentschedulemembers',
        component: WorksheetPaymentScheduleMembers,
    },
    {
        path: '/worksheets/:id/members/:name',
        name: 'worksheetcreatemembers',
        component: WorksheetCreateMembers,
    },
    {
        path: '/worksheets/:id/view/:name',
        name: 'worksheetviewmembers',
        component: WorksheetViewMembers,
    },
    {
        path: '/payments',
        name: 'payments',
        component: Payments,
    },
    {
        path: '/payments/due',
        name: 'paymentsdue',
        component: PaymentsDue,
    },
    {
        path: '/payments/completed',
        name: 'paymentscompleted',
        component: PaymentsCompleted,
    },
    {
        path: '/payments/reversed',
        name: 'paymentsreversed',
        component: PaymentsReversed,
    },
    {
        path: '/payments/make',
        name: 'paymentsmake',
        component: PaymentsMake,
    },
    {
        path: '/communication',
        name: 'communication',
        component: Communication,
    },
    {
        path: '/approvals',
        name: 'approvals',
        component: Approvals,
    },
    {
        path: '/users',
        name: 'users',
        component: Users,
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings,
    },
    {
        path: '/settings/approvers',
        name: 'settingsapprovers',
        component: SettingsApprovers,
    },
    {
        path: '/settings/roles',
        name: 'settingsroles',
        component: SettingsRoles,
    },
    {
        path: '/settings/payment',
        name: 'settingspayment',
        component: SettingsPayment,
    }
];