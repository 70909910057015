<template>

    <div class="">

      <div class="container-fluid">

        <div class="card mb-3">
          <div class="card-header card-body-header-lg p-2 pb-0 d-flex justify-content-between shadow-none bg-light ">
            <div class=" d-flex justify-content-left ">

              <span class="font-weight-500 men-item underline-item mr-2 px-3">
                  <router-link to="/settings" class="">Rate Card</router-link>
              </span>

              <span class="font-weight-500 men-item underline-item px-3 mr-2 active">
                 <router-link to="/settings/approvers" class="">Approvers</router-link>
              </span>

              <span class="font-weight-500 men-item underline-item px-3 mr-2" style="display: none">
                 <router-link to="/settings/payment" class="">Rate</router-link>
              </span>

              <span class="font-weight-500 men-item underline-item px-3 mr-2" style="display: none">
                 <router-link to="/settings/roles" class="">Roles</router-link>
              </span>

            </div>
          </div>
        </div>

        <div class="row">

          <div class="col-md-5">
            <div class="card custom-card-wrapper">
              <div class="card-header line-height-1">
                <span>Add Approver</span>
              </div>
              <div class="card-body">
                <form class="custom-form">

                  <div class="input-wrapper mb-2">

                    <div class="mb-3">
                      <label for="user-list" class="form-label">Select User</label>
                      <select class="form-select" id="user-list" v-model="user_id">
                        <option v-for="(u,index) in users" v-bind:key="getKey(index)" v-bind:value="u.id"><span v-text="u.first_name"></span> <span v-text="u.last_name"></span></option>
                      </select>
                    </div>

                    <div class="mb-3">
                      <label for="approval-level" class="form-label">Select Approval Level</label>
                      <select class="form-select" id="approval-level" v-model="approval_level">
                        <option value="1">First Approver</option>
                        <option value="2">Second Approver</option>
                        <option value="3">Third Approver</option>
                      </select>
                    </div>

                  </div>

                  <div class="modal-footer-copy">
                    <button type="button" class="btn btn-primary btn-sm shadow mr-2" @click="createApprover">Add</button>
                    <button type="button" class="btn btn-outline-secondary btn-sm " data-bs-dismiss="modal">Cancel</button>
                  </div>

                </form>
              </div>
            </div>
          </div>

          <div class="col-md-7">
            <div class="card custom-card-wrapper">
              <div class="card-header line-height-1">
                Approvers List
              </div>
              <div class="card-body p-0">

                <table class="table table-striped">
                  <thead class=" ">
                  <tr>
                    <th>
                      Full Name
                    </th>
                    <th>
                      Email
                    </th>
                    <th>
                      Phone
                    </th>
                    <th>
                      Approval Level
                    </th>
                    <th class="text-right">
                      Actions
                    </th>
                  </tr>

                  </thead>
                  <tbody>
                  <tr v-for="(a,index) in approvers" v-bind:key="getKey(index)" class="">
                    <td>
                      <span v-text="a.first_name"></span> <span v-text="a.last_name"></span>
                    </td>
                    <td v-text="a.email"></td>
                    <td v-text="a.msisdn"></td>
                    <td>Approval Level <span v-text="a.approval_level"></span></td>
                    <td class="text-right">
                      <div class="btn-group">
                      <button type="button" class="btn btn-primary btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        More
                      </button>
                      <ul class="dropdown-menu">

                        <li>
                          <a class="dropdown-item" @click="updateApprover(a)">Update</a>
                        </li>

                        <li>
                          <a class="dropdown-item" @click="deleteApprover(a)">Delete</a>
                        </li>

                      </ul>
                    </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>

</template>

<script>
import axios from "@/services/api";

export default {
  name: 'settingsapprovers',
  components: {

  },
  data: function (){
    return {
      mqttClient:'',
      active_tab: 'account',
      approvers: [],
      id: 0,
      user_id: 0,
      approval_level: 0,
      users: []
    }
  },
  mounted() {

    this.$store.dispatch("setCurrentPage","settings");
    this.getApprovers();
    this.getUsers();

  },
  computed: {

  },
  methods: {

    getUsers: function(){

      var vm = this;

      var path = process.env.VUE_APP_URL_USERS_LIST;

      axios.post(path, JSON.stringify({}),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            var msg = res.data.data;
            vm.users = msg;

          })
          .catch(err => {

            vm.loading = '';

            console.log('FAILED response '+JSON.stringify(err.response.data.data))

            if (err.response) {

              vm.setError("Failed",err.response.data.data)

            }
            else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")

            }
            else {

              vm.setError("Network Error", "Check your network connection and try again")

            }

          })

    },

    getApprovers: function(){

      var vm = this;

      var path = process.env.VUE_APP_URL_APPROVERS_LIST;

      axios.post(path, JSON.stringify({}),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            var msg = res.data.data;
            vm.approvers = msg;
          })
          .catch(err => {

            vm.loading = '';

            console.log('FAILED response '+JSON.stringify(err.response.data.data))

            if (err.response) {

              vm.setError("Failed",err.response.data.data)

            }
            else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")

            }
            else {

              vm.setError("Network Error", "Check your network connection and try again")

            }

          })

    },

    showTab: function(tabName) {

      this.active_tab = tabName;
    },

    isActiveTab: function(tabName) {

      return this.active_tab === tabName;
    },

    getTabClass: function(tabName) {

      return this.active_tab === tabName ? 'active-tab' : '';
    },

    getTabTitleClass: function(tabName) {

      return this.active_tab === tabName ? 'active' : '';

    },

    geSubPageActiveClass: function (subpage) {

      return subpage === this.sub_page ? 'active' : "";

    },

    deleteApprover: function(data) {

      var vm = this;

      vm.$swal.fire({
        title: "Do you want to delete. "+data.first_name+' as an approver?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete'

      }).then((result) => {

        if (result.isConfirmed) {

          var path = process.env.VUE_APP_URL_APPROVERS_DELETE.replace(":id", data.id);
          vm.loading = 'loading';

          axios.post(path, JSON.stringify({}),{
            headers: {
              'api-key': vm.getAuth()
            },
          })
              .then(res => {

                vm.loading = '';
                vm.getApprovers();

                var msg = res.data.data.message;

                vm.$swal.fire(
                    'Submitted!',
                    msg,
                    'success'
                )

              })
              .catch(err => {

                vm.loading = '';

                vm.$swal.fire(
                    'Failed!',
                    err.response.data.data,
                    'error'
                )

                if (err.response) {

                  var message = "";

                  if(parseInt(err.response.status) === 428 ) {

                    message = err.response.data.message;
                    vm.setError("Failed", message)
                    return;
                  }

                  if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                    vm.setError("Session Expired", "Your session on this device has expired");
                    vm.logout();
                    return;

                  } else {

                    message =  err.response.data.message;
                    vm.setError("Failed", message)

                  }

                } else if (err.request) {

                  vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err.request));

                } else {

                  //vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err));

                }
              })
        } else {

          vm.cashoutLoading = '';

        }

      })

    },

    createApprover: function (){

      var vm = this;

      if (!this.user_id) {

        this.setError("Invalid name", "Please select user");
        vm.$swal.fire(
            'Error!',
            "Select a user to proceed",
            'error'
        )
        return;
      }

      if (parseInt(this.approval_level) === 0 ) {

        this.setError("Invalid approval level", "Please select approval level");
        vm.$swal.fire(
            'Error!',
            "Please select approval level",
            'error'
        )
        return;
      }

      var path = process.env.VUE_APP_URL_APPROVERS_CREATE;

      this.loading = 'loading';

      var data = {
        approval_level: parseInt(this.approval_level),
        user_id: parseInt(this.user_id),
      };

      axios.post(path, JSON.stringify(data),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            var msg = res.data.data.message;
            vm.setSuccess("Success", msg);
            vm.getApprovers();

            vm.$swal.fire(
                'Submitted!',
                msg,
                'success'
            )
            vm.getJobs();
          })
          .catch(err => {

            vm.loading = '';

            console.log('FAILED response '+JSON.stringify(err.response.data.data))

            if (err.response) {
              vm.setError("Failed",err.response.data.data)
            }
            else if (err.request) {
              vm.setError("Network Error", "Check your network connection and try again")
            }
            else {
              vm.setError("Network Error", "Check your network connection and try again")
            }
          })

    },

    updateApprover: async function(data){

      var vm = this;

      const { value: approval_level } = await vm.$swal.fire({
        title: 'Select new approval level for '+data.first_name,
        input: 'select',
        inputOptions: {
          '1': '1st Approver',
          '2': '2nd Approver',
          '3': '3rd Approver',
        },
        inputPlaceholder: 'Select Approval Level',
        showCancelButton: true,
      })

      if (approval_level) {

        var path = process.env.VUE_APP_URL_APPROVERS_UPDATE.replace(":id", data.id);
        vm.loading = 'loading';

        axios.post(path, JSON.stringify({approval_level: parseInt(approval_level)}),{
          headers: {
            'api-key': vm.getAuth()
          },
        })
            .then(res => {

              vm.loading = '';
              vm.getApprovers();

              var msg = res.data.data.message;

              vm.$swal.fire(
                  'Submitted!',
                  msg,
                  'success'
              )

            })
            .catch(err => {

              vm.loading = '';

              vm.$swal.fire(
                  'Failed!',
                  err.response.data.data,
                  'error'
              )

              if (err.response) {

                var message = "";

                if(parseInt(err.response.status) === 428 ) {

                  message = err.response.data.message;
                  vm.setError("Failed", message)
                  return;
                }

                if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                  vm.setError("Session Expired", "Your session on this device has expired");
                  vm.logout();
                  return;

                } else {

                  message =  err.response.data.message;
                  vm.setError("Failed", message)

                }

              } else if (err.request) {

                vm.setError("Failed", "Please check your network")
                console.log(JSON.stringify(err.request));

              } else {

                //vm.setError("Failed", "Please check your network")
                console.log(JSON.stringify(err));

              }
            })

      }

    },

  },
}

</script>