<template>

    <div class="">

      <div class="container-fluid">

        <div class="card mb-3">
          <div class="card-header card-body-header-lg p-2 pb-0 d-flex justify-content-between shadow-none bg-light ">

            <div class=" d-flex justify-content-left ">

              <span class="font-weight-500 men-item underline-item mr-2 px-3 ">
                  <router-link to="/settings" class="">Title</router-link>
              </span>

              <span class="font-weight-500 men-item underline-item px-3 mr-2 active">
                 <router-link to="/settings/payment" class="">Rate</router-link>
              </span>

              <span class="font-weight-500 men-item underline-item px-3 mr-2">
                 <router-link to="/settings/roles" class="">Roles</router-link>
              </span>

            </div>

          </div>
        </div>

        <div class="row">
          <div class="col-md-5">
            <div class="card custom-card-wrapper">
              <div class="card-header line-height-1">
                Set Payment Rate
              </div>
              <div class="card-body">
                <form class="custom-form">
                  <div class="input-wrapper mb-2">

                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">Name</label>
                      <input type="text" class="form-control" v-model="name">
                    </div>

                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">Keyword</label>
                      <input type="text" class="form-control" v-model="keyword">
                    </div>

                    <div class="mb-3">
                      <label for="exampleInputPassword1" class="form-label">Payment rate</label>
                      <select class="form-select" aria-label="Default select example" v-model="wage_type">
                        <option value="1" selected>Daily with Overtime</option>
                        <option value="2">Daily</option>
                      </select>
                    </div>
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">Daily Amount</label>
                      <input type="text" class="form-control" v-model="daily">
                    </div>
                    <div class="mb-3" v-show="parseInt(wage_type) === 1">
                      <label for="exampleInputEmail1" class="form-label">Overtime Amount</label>
                      <input type="text" class="form-control" v-model="overtime">
                    </div>

                  </div>
                  <div class="modal-footer-copy">
                    <button type="button" class="btn btn-primary btn-sm shadow mr-2" @click="createJob">Add</button>
                    <button type="button" class="btn btn-outline-secondary btn-sm " data-bs-dismiss="modal">Cancel</button>

                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="col-md-7">
            <div class="card custom-card-wrapper">
              <div class="card-header line-height-1">
                Payment Rate List
              </div>
              <div class="card-body p-0">

                <div class="card-body-subheader p-2 d-flex justify-content-between border-bottom">
                  <div class="font-weight-500 pt-1">
                    <a class="btn btn-link btn-sm" href="">Delete</a>
                    <a class="btn btn-link btn-sm" href="">Print</a>
                    <a class="btn btn-link btn-sm" href="">Export</a>
                  </div>

                </div>
                <table class="table table-striped">
                  <thead class=" ">
                  <tr>
                    <th>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDisabled" >

                      </div>
                    </th>
                    <th>
                      Payment Rate
                    </th>
                    <th>
                      Daily Amount
                    </th>
                    <th>
                      Overtime Amount(per hour)
                    </th>

                    <th class="text-right">
                      Actions
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="">
                    <td>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDisabled" >

                      </div>
                    </td>
                    <td>
                      Daily With Overtime
                    </td>
                    <td>
                      500
                    </td>

                    <td>
                      150
                    </td>
                    <td class="text-right">
                      <a class="btn btn-warning btn-sm p-0" href=""><i class="bi bi-pen-fill"></i></a>
                      <a class="btn btn-danger btn-sm p-0" href=""><i class="bi bi-x-lg"></i></a>
                    </td>
                  </tr>


                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

</template>

<script>

import axios from "@/services/api";

export default {
  name: 'payments',
  components: {

  },
  data: function (){
    return {
      highlights: true,
      upcoming: false,
      today: false,
      sub_page: '',
      mqttClient:'',
      active_tab: 'account',
      wage_type: 1,
      name: '',
      overtime: 0,
      daily: 0,
      keyword: ''
    }
  },
  mounted() {

    this.$store.dispatch("setCurrentPage","home");
    // reset game filter
    //this.$store.dispatch("resetAllGames");
    //this.reloadProfile();

  },
  computed: {
    betslip_count: function (){

      return this.$store.state.betslip.total

    },
    current_sub_page: function () {

      return this.$store.state.current_sub_page;
    },
    sport_id: function () {

      return this.$store.state.sport_id;

    }
  },
  methods: {

    showTab: function(tabName) {

      this.active_tab = tabName;
    },

    isActiveTab: function(tabName) {

      return this.active_tab === tabName;
    },

    getTabClass: function(tabName) {

      return this.active_tab === tabName ? 'active-tab' : '';
    },

    getTabTitleClass: function(tabName) {

      return this.active_tab === tabName ? 'active' : '';

    },

    setSubPage: function (page){

      this.$store.dispatch("setCurrentSubPage",page);
      this.sub_page = page;

    },
    createJob: function (){

      var vm = this;

      if (!this.name) {

        this.setError("Invalid name", "Please enter a valid job title name");
        vm.$swal.fire(
            'Error!',
            "Please enter a valid job valid name",
            'error'
        )
        return;
      }

      if (parseInt(this.daily) === 0 ) {

        this.setError("Invalid daily rate", "Please enter a daily rate");
        vm.$swal.fire(
            'Error!',
            "Please enter a worksheet valid start date",
            'error'
        )
        return;
      }

      var path = process.env.VUE_APP_URL_RATECARD_CREATE;

      this.loading = 'loading';

      var data = {
        name: this.name,
        keyword: this.keyword,
        daily: parseFloat(this.daily),
        overtime: parseFloat(this.overtime),
      };

      axios.post(path, JSON.stringify(data),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            var msg = res.data.data.message;
            vm.setSuccess("Success", msg);

            vm.$swal.fire(
                'Submitted!',
                msg,
                'success'
            )
            vm.getJobs();
          })
          .catch(err => {

            vm.loading = '';

            console.log('FAILED response '+JSON.stringify(err.response.data.data))

            if (err.response) {
              vm.setError("Failed",err.response.data.data)
            }
            else if (err.request) {
              vm.setError("Network Error", "Check your network connection and try again")
            }
            else {
              vm.setError("Network Error", "Check your network connection and try again")
            }
          })

    },

    updateJob: function (){

      var vm = this;

      if (!this.currentjob.name) {

        this.setError("Invalid name", "Please enter a valid job title name");
        vm.$swal.fire(
            'Error!',
            "Please enter a valid job valid name",
            'error'
        )
        return;
      }

      if (parseInt(this.currentjob.daily) === 0 ) {

        this.setError("Invalid daily rate", "Please enter a daily rate");
        vm.$swal.fire(
            'Error!',
            "Please enter a worksheet valid start date",
            'error'
        )
        return;
      }

      var path = process.env.VUE_APP_URL_RATECARD_UPDATE.replace(":id", this.currentjob.id);

      this.loading = 'loading';

      var data = {
        name: this.currentjob.name,
        keyword: this.currentjob.job_keyword,
        daily: parseFloat(this.currentjob.daily),
        overtime: parseFloat(this.currentjob.overtime),
      };

      axios.post(path, JSON.stringify(data),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            var msg = res.data.data.message;
            vm.setSuccess("Success", msg);
            vm.$swal.fire(
                'Updated!',
                msg,
                'success'
            )
            vm.getJobs();
            vm.closeModal('create-job-title');

          })
          .catch(err => {

            vm.loading = '';

            console.log('FAILED response '+JSON.stringify(err.response.data.data))

            if (err.response) {
              vm.setError("Failed",err.response.data.data)
            }
            else if (err.request) {
              vm.setError("Network Error", "Check your network connection and try again")
            }
            else {
              vm.setError("Network Error", "Check your network connection and try again")
            }
          })

    },

  },
}

</script>