<template>

    <div class="">

      <div class="container-fluid">

        <div class="row">

          <div class="col-md-12">
            <div class="card custom-card-wrapper shadow-sm">

              <div class="card-body" v-bind:class="loading">

                <div class="font-weight-500" style="margin-bottom: 20px">
                  Add Members to <span v-text="name"></span>
                </div>
                <!-- Tab links -->

                <ul class="nav nav-tabs" style="margin-bottom: 20px">

                  <li class="nav-item">
                    <a class="nav-link tablinks text-dark" v-bind:class="getTabTitleClass('form')" @click="showTab('form')" aria-current="page" >Quick Add</a>
                  </li>

                  <li class="nav-item">
                    <a id="js-upload-file" class="nav-link tablinks text-dark" v-bind:class="getTabTitleClass('upload')" @click="showTab('upload')" >Upload Members</a>
                  </li>

                </ul>

                <!-- Tab content -->
                <div v-bind:class="getTabClass('form')" class="tabcontent p-0" style="padding-bottom: 50px !important;">

                  <div v-for="(member,idex) in members" v-bind:key="getKey(idex)">

                    <div class="row">
                      <div class="col-12">
                        <div class="float-end"><button @click="remove(idex)" class="btn btn-outline-danger btn-sm"><i class="bi bi-x"></i> </button></div>
                      </div>
                    </div>

                    <div class="font-weight-500">
                      Personal Details
                    </div>

                    <div class="row">

                      <div class="col-md-3">
                        <div class="mb-3">
                          <input type="text" class="form-control" placeholder="First Name" v-model="member.first_name">
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="mb-3">
                          <input type="text" class="form-control" placeholder="Middle Name" v-model="member.middle_name">
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="mb-3">
                          <input type="text" class="form-control" placeholder="Last Name" v-model="member.last_name">
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="mb-3">
                          <input type="text" class="form-control" placeholder=" Id Number" v-model="member.id_number">
                        </div>
                      </div>

                    </div>

                    <div class="font-weight-500">
                      Payment Details
                    </div>

                    <div class="row">

                      <div class="col-md-3">
                        <div class="mb-3">
                          <label>Select Payment Method</label>
                          <select class="form-select form-select-sm" aria-label=".form-select-sm example" v-model="member.payment_type">
                            <option value="1">M-PESA</option>
                            <option value="2">BANK</option>
                            <option value="3">PayBill</option>
                            <option value="4">Till Number</option>
                          </select>
                        </div>
                      </div>

                      <div v-show="parseInt(member.payment_type) === 1" class="col-md-3">
                        <div class="mb-3">
                          <label>M-PESA Number</label>
                          <input type="text" class="form-control" placeholder="M-Pesa No" v-model="member.account_number">
                        </div>
                      </div>

                      <div v-show="parseInt(member.payment_type) === 2" class="col-md-3">
                        <div class="mb-3">
                          <label>Select Bank Name</label>
                          <select class="form-select form-select-sm" aria-label=".form-select-sm example" v-model="bank_paybill">
                            <option value="247247">Equity Bank</option>
                            <option value="522522">KCB Bank</option>
                          </select>
                        </div>
                      </div>

                      <div v-show="parseInt(member.payment_type) === 2" class="col-md-3">
                        <div class="mb-3">
                          <label>Bank Account</label>
                          <input type="text" class="form-control" placeholder="Bank Acc No" v-model="member.account_number">
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="mb-3">
                          <label>Amount</label>
                          <input type="number" min="100" max="70000" class="form-control" placeholder="Enter any amount from 100" v-model="member.amount">
                        </div>
                      </div>
                    </div>

                    <hr class="bg-success border-2 border-top border-success">

                  </div>

                  <div>

                    <div class="font-weight-500">
                      Personal Details
                    </div>

                    <div class="row">

                      <div class="col-md-3">
                        <div class="mb-3">
                          <input type="text" class="form-control" placeholder="First Name" v-model="first_name">
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="mb-3">
                          <input type="text" class="form-control" placeholder="Middle Name" v-model="middle_name">
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="mb-3">
                          <input type="text" class="form-control" placeholder="Last Name" v-model="last_name">
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="mb-3">
                          <input type="text" class="form-control" placeholder=" Id Number" v-model="id_number">
                        </div>
                      </div>

                    </div>

                    <div class="font-weight-500">
                      Payment Details
                    </div>

                    <div class="row">

                      <div class="col-md-3">
                        <div class="mb-3">
                          <label>Select Payment Method</label>
                          <select class="form-select form-select-sm" aria-label=".form-select-sm example" v-model="payment_method">
                            <option value="1">M-PESA</option>
                            <option value="2">BANK</option>
                            <option value="3">Paybill Number</option>
                            <option value="4">Till Number</option>
                          </select>
                        </div>
                      </div>

                      <div v-show="parseInt(payment_method) === 1" class="col-md-3">
                        <div class="mb-3">
                          <label>M-PESA Number</label>
                          <input type="text" class="form-control" placeholder="M-Pesa No" v-model="account">
                        </div>
                      </div>

                      <div v-show="parseInt(payment_method) === 3" class="col-md-3">
                        <div class="mb-3">
                          <label>PayBill Number</label>
                          <input type="text" class="form-control" placeholder="Till No" v-model="bank_paybill">
                        </div>
                      </div>

                      <div v-show="parseInt(payment_method) === 4" class="col-md-3">
                        <div class="mb-3">
                          <label>Till Number</label>
                          <input type="text" class="form-control" placeholder="Till No" v-model="bank_paybill">
                        </div>
                      </div>

                      <div v-show="parseInt(payment_method) === 2" class="col-md-3">
                        <div class="mb-3">
                          <label>Select Bank Name</label>
                          <select class="form-select form-select-sm" aria-label=".form-select-sm example" v-model="bank_paybill">
                            <option v-for="(b,i) in banks" v-bind:key="getKey(i)" v-bind:value="b.bank_code" v-text="b.bank_name"></option>
                          </select>
                        </div>
                      </div>

                      <div v-show="parseInt(payment_method) > 1" class="col-md-3">
                        <div class="mb-3">
                          <label>Account Number</label>
                          <input type="text" class="form-control" placeholder="Acc No" v-model="account">
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="mb-3">
                          <label>Amount</label>
                          <input type="number" min="100" max="70000" class="form-control" placeholder="Enter any amount from 100" v-model="amount">
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col">
                        <button class="btn btn-secondary btn-sm" @click="addMember" v-text="buttonName"></button>
                      </div>
                    </div>

                  </div>

                  <hr class="bg-green border-2 border-top ">

                  <div class="row">
                    <div class="col-12">
                      <div class="float-end">
                        <button class="btn btn-success" @click="createMember">Submit</button>
                      </div>
                    </div>
                  </div>

                </div>

                <div v-bind:class="getTabClass('upload')" class="tabcontent pt-3">

                  <form class="dropzone dz-clickable" id="my-dropzone" style="background-color: var(--dialog-color) !important; border: 2px dotted rgba(0,0,0,.3); border-radius: 5px">
                    <div class="dz-message d-flex flex-column">
                      <div class="file-content">
                        <div class="file-infos">
                          <p class="file-icon">
                            <i class="bi bi-upload" style="font-size: 64px; font-weight: bold"></i>
                            <span class="icon-shadow"></span>
                          </p>
                        </div>
                      </div>
                      Drag &amp; Drop here or click to upload file
                    </div>
                  </form>

                  <div>
                    <strong>Instructions</strong> Format your file exactly as the attached template. If you are using excel. make sure you import your data as CSV and confirm the formatting<br>
                    <a href="/payroll.csv" class="font-weight-500 text-danger"> Click here to download template</a>
                  </div>

                  <hr class="bg-green border-2 border-top ">

                  <div class="row">
                    <div class="col-12">
                      <div class="float-end">
                        <button class="btn btn-success" id="upload-members">Upload File</button>
                      </div>
                    </div>
                  </div>

                </div>

              </div>

            </div>
          </div>
        </div>
      </div>

    </div>

</template>

<script>

import axios from "@/services/api";
import Dropzone from "dropzone";

export default {
  name: 'create',
  components: {

  },
  data: function (){
    return {
      highlights: true,
      upcoming: false,
      today: false,
      sub_page: '',
      mqttClient:'',
      active_tab: 'upload',
      payment_group_id: '',
      name: '',
      payment_method: 1,
      bank_paybill: '',
      account: '',
      amount: 0,
      first_name: '',
      middle_name: '',
      last_name: '',
      id_number: '',
      loading: '',
      members: [],
      banks: [
        {
          "bank_name": "Absa Bank",
          "bank_code": 101
        },
        {
          "bank_name": "Co-operative Bank",
          "bank_code": 102
        },
        {
          "bank_name": "Equity Bank(K)",
          "bank_code": 103
        },
        {
          "bank_name": "Family Bank",
          "bank_code": 104
        },
        {
          "bank_name": "KCB Bank Kenya (K)",
          "bank_code": 105
        },
        {
          "bank_name": "Standard Chartered Bank (K)",
          "bank_code": 106
        },
        {
          "bank_name": "ABC Bank",
          "bank_code": 107
        },
        {
          "bank_name": "I & M Bank (K)",
          "bank_code": 108
        },
        {
          "bank_name": "HF Group",
          "bank_code": 109
        },
        {
          "bank_name": "Ecobank (K)",
          "bank_code": 110
        },
        {
          "bank_name": "Habib Bank A.G. Zurich",
          "bank_code": 111
        },
        {
          "bank_name": "NCBA Bank(K)",
          "bank_code": 112
        },
        {
          "bank_name": "Stanbic Bank",
          "bank_code": 113
        },
        {
          "bank_name": "Sidian Bank",
          "bank_code": 114
        },
        {
          "bank_name": "Rafiki Microfinance Bank",
          "bank_code": 115
        },
        {
          "bank_name": "Spire Bank",
          "bank_code": 116
        },
        {
          "bank_name": "First Community Bank",
          "bank_code": 117
        },
        {
          "bank_name": "Access Bank (K)",
          "bank_code": 118
        },
        {
          "bank_name": "Diamond Trust Bank (K)",
          "bank_code": 119
        },
        {
          "bank_name": "DIB Kenya Bank",
          "bank_code": 120
        },
        {
          "bank_name": "Development Bank",
          "bank_code": 121
        },
        {
          "bank_name": "Citibank N.A.",
          "bank_code": 122
        },
        {
          "bank_name": "Bank of Baroda (K)",
          "bank_code": 123
        },
        {
          "bank_name": "Bank of Africa (K)",
          "bank_code": 124
        },
        {
          "bank_name": "Faulu Micro-Finance Bank",
          "bank_code": 125
        },
        {
          "bank_name": "Credit Bank",
          "bank_code": 126
        },
        {
          "bank_name": "Choice Microfinance Bank",
          "bank_code": 127
        },
        {
          "bank_name": "Consolidated Bank",
          "bank_code": 128
        },
        {
          "bank_name": "Caritas Microfinance Bank",
          "bank_code": 129
        },
        {
          "bank_name": "Kenya Women Microfinance Bank",
          "bank_code": 130
        },
        {
          "bank_name": "Mayfair Bank",
          "bank_code": 131
        },
        {
          "bank_name": "Kingdom Bank",
          "bank_code": 132
        },
        {
          "bank_name": "National Bank of Kenya",
          "bank_code": 133
        },
        {
          "bank_name": "Middle East Bank (K)",
          "bank_code": 134
        },
        {
          "bank_name": "Paramount Universal Bank",
          "bank_code": 135
        },
        {
          "bank_name": "Prime Bank",
          "bank_code": 136
        },
        {
          "bank_name": "Postbank",
          "bank_code": 137
        },
        {
          "bank_name": "SBM Bank (K)",
          "bank_code": 138
        },
        {
          "bank_name": "Victoria Commercial bank",
          "bank_code": 139
        },
        {
          "bank_name": "UBA Kenya Bank",
          "bank_code": 140
        },
        {
          "bank_name": "Salaam Microfinance Bank",
          "bank_code": 141
        },
        {
          "bank_name": "M Oriental Bank (K)",
          "bank_code": 142
        },
        {
          "bank_name": "Gulf African Bank",
          "bank_code": 143
        },
        {
          "bank_name": "Guaranty Trust Bank (K)",
          "bank_code": 144
        },
        {
          "bank_name": "Guardian Bank",
          "bank_code": 145
        },
        {
          "bank_name": "Bank of India(K)",
          "bank_code": 146
        }
      ]
    }
  },
  mounted: function() {

    this.$store.dispatch("setCurrentPage","groups");
    this.$store.dispatch("setCurrentPageName","Create Payment Group");
    this.$store.dispatch("setPreviousPage","groups");
    this.initFileUpload();

  },
  computed: {

    buttonName: function (){

      return this.members.length === 0 ? 'Add Member' : 'Add Another Member';

    },

  },
  methods: {

    showTab: function(tabName) {

      console.log('showTab ==> '+tabName);
      this.active_tab = tabName;

    },

    isActiveTab: function(tabName) {

      console.log('isActiveTab ==> '+tabName);
      return this.active_tab === tabName;
    },

    getTabClass: function(tabName) {

      return this.active_tab === tabName ? 'active-tab' : 'inactive-tab';
    },

    getTabTitleClass: function(tabName) {

      return this.active_tab === tabName ? 'active' : '';

    },

    resetForm: function (){

      this.payment_method = 1;
      this.bank_paybill = '';
      this.account='';
      this.amount=0;
      this.first_name='';
      this.middle_name='';
      this.last_name='';
      this.id_number=0;
      this.loading='';
    },

    addMember: function (){

      if (!this.account) {

        this.setError("Invalid Account", "Please enter a account");
        return;
      }

      if (parseInt(this.amount) < 100 ) {

        this.setError("Invalid Amount", "Please enter a amount atleast 100");
        return;
      }

      if (!this.first_name) {

        this.setError("Invalid First Name", "Please enter First Name");
        return;
      }

      if (!this.middle_name) {

        this.setError("Invalid Middle Name", "Please enter Middle Name");
        return;
      }

      if (!this.last_name) {

        this.setError("Invalid Last Name", "Please enter Last Name");
        return;
      }

      if (parseInt(this.id_number) < 1000000 ) {

        //this.setError("Invalid ID Number", "Please enter a valid ID Number");
        //return;
      }

      var paybill = 3016903;

      if (this.payment_method > 1 ) {

        paybill = this.bank_paybill;

      }
      this.members.push({
        id_number: parseInt(this.id_number),
        payment_type: parseInt(this.payment_method),
        first_name: this.first_name,
        middle_name: this.middle_name,
        last_name: this.last_name,
        amount: parseInt(this.amount),
        paybill: parseInt(paybill),
        account_number: this.account
      });

      this.resetForm();

    },

    createMember: function (){

      var hasError = false

      if (!this.account) {

        this.setError("Invalid Account", "Please enter a account");
        hasError = true;
      }

      if (parseInt(this.amount) < 100 ) {

        this.setError("Invalid Amount", "Please enter a amount atleast 100");
        hasError = true;
      }

      if (!this.first_name) {

        this.setError("Invalid First Name", "Please enter First Name");
        hasError = true;
      }

      if (!this.middle_name) {

        this.setError("Invalid Middle Name", "Please enter Middle Name");
        hasError = true;
      }

      if (!this.last_name) {

        this.setError("Invalid Last Name", "Please enter Last Name");
        hasError = true;
      }

      if (parseInt(this.id_number) < 1000000 ) {

        this.setError("Invalid ID Number", "Please enter a valid ID Number");
        hasError = true;
      }

      var paybill = 3016903;

      if (parseInt(this.payment_method) > 1 ) {

        paybill = this.bank_paybill;

      }

      if(!hasError) {

        this.members.push({
          id_number: parseInt(this.id_number),
          payment_type: parseInt(this.payment_method),
          first_name: this.first_name,
          middle_name: this.middle_name,
          last_name: this.last_name,
          amount: parseInt(this.amount),
          paybill: parseInt(paybill),
          account_number: this.account
        });

      }

      var vm = this;
      var endpoint = process.env.VUE_APP_URL_PAYMENT_GROUP_ADD_MEMBER;
      var path = endpoint.replace(":id",this.payment_group_id);

      this.loading = 'loading';

      axios.post(path, JSON.stringify(this.members),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            var msg = res.data.data.message;
            vm.setSuccess("Success", msg);
            vm.members = [];
            vm.resetForm();
          })
          .catch(err => {

            vm.loading = '';

            console.log('FAILED response '+JSON.stringify(err.response.data.data))

            if (err.response) {
              vm.setError("Failed",err.response.data.data)
            }
            else if (err.request) {
              vm.setError("Network Error", "Check your network connection and try again")
            }
            else {
              vm.setError("Network Error", "Check your network connection and try again")
            }
          })
    },

    remove: function (index){

      this.members.splice(index, 1);

    },

    uploadMembers: function (){

      var formData = new FormData();
      var imagefile = document.querySelector('#js-file-input');
      formData.append("file", imagefile.files[0]);

      var vm = this;
      var endpoint = process.env.VUE_APP_URL_PAYMENT_GROUP_UPLOAD_MEMBERS;
      var path = endpoint.replace(":id",this.payment_group_id);

      this.loading = 'loading';

      axios.post(path, formData,{
        headers: {
          'api-key': vm.getAuth(),
          'Content-Type': 'multipart/form-data'
        },
      })
          .then(res => {

            vm.loading = '';
            var msg = res.data.data.message;
            vm.setSuccess("Success", msg);
            vm.members = [];
            vm.resetForm();

          })
          .catch(err => {

            vm.loading = '';

            console.log('FAILED response '+JSON.stringify(err.response.data.data))

            if (err.response) {
              vm.setError("Failed",err.response.data.data)
            }
            else if (err.request) {
              vm.setError("Network Error", "Check your network connection and try again")
            }
            else {
              vm.setError("Network Error", "Check your network connection and try again")
            }
          })
    },

    initFileUpload: function (){

      var vm = this;

      var endpoint = process.env.VUE_APP_BASE_URL + process.env.VUE_APP_URL_PAYMENT_GROUP_UPLOAD_MEMBERS;
      var path = endpoint.replace(":id",this.payment_group_id);

      new Dropzone("#my-dropzone",{
        url: path,
        autoProcessQueue: false,
        uploadMultiple: true,
        parallelUploads: 5,
        maxFiles: 1,
        maxFilesize: 1000,
        headers: {
          'api-key': vm.getAuth(),
        },
        acceptedFiles: 'text/csv',
        addRemoveLinks: true,
        init: function () {

          var dzClosure = this; // Makes sure that 'this' is understood inside the functions below.

          // for Dropzone to process the queue (instead of default form behavior):
          document.getElementById("upload-members").addEventListener("click", function (e) {
            // Make sure that the form isn't actually being sent.
            e.preventDefault();
            e.stopPropagation();
            dzClosure.processQueue();

          });

          //send all the form data along with the files:
          this.on("sendingmultiple", function (data, xhr, formData) {

            console.log(data);
            formData.append("file", data);

          });

          //send all the form data along with the files:
          this.on("sending", function (data, xhr, formData) {

            console.log(data);
            formData.append("file", data);

          });


          //send all the form data along with the files:
          this.on("success", function (res1, response) {

            var msg = "Contact uploaded successful. ";

            if (response.message) {

              msg = response.message
            }

            vm.setSuccess("Upload successful",msg)

          });

          //send all the form data along with the files:
          this.on("error", function (res1, response) {

            var error = response;

            if (response.message) {

              error = response.message
            }

            vm.setError("Upload Failed",error)

          });
        }

      });

    },

  },
  watch: {

    $route: {

      immediate: true,
      handler(to) {
        // react to route changes...
        this.payment_group_id = to.params.id;
        this.name = to.params.name;
      }
    },
  },
}

</script>

<style>
/*Image upload*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --overlay-color: rgba(0, 0, 0, .7);
  --dialog-color: #e9ecef;
  --dialog-border-radius: 5px;
  --icon-color: rgba(73, 80, 87, .6);
  --dialog-padding: 5px;
  --drag-over-background: #e3e5e8;
}

.file-container {
  font-family: sans-serif;
  font-size: 13pt;
  color: #4d4d4d;
}
.file-container .file-overlay {
  /*position: fixed;*/
  /*width: 100vw;*/
  /*height: 100vh;*/
  /*top: 0;*/
  /*right: 0;*/
  /*bottom: 0;*/
  /*left: 0;*/
  /*z-index: 10;*/
  /*background-color: var(--overlay-color);*/
}
.file-container .file-wrapper {
  /*position: fixed;*/
  display: block;
  width: 70vw;
  height: 300px;
  max-height: 500px;
  min-height: 300px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-color: var(--dialog-color);
  z-index: 20;
  border-radius: var(--dialog-border-radius);
  padding: var(--dialog-padding);
}
.file-container .file-wrapper .file-input {
  position: absolute;
  /*width: 100%;*/
  /*height: 100%;*/
  /*top: 0;*/
  /*right: 0;*/
  /*bottom: 0;*/
  /*left: 0;*/
  /*background-color: #000;*/
  /*z-index: 10;*/
  /*cursor: pointer;*/
  opacity: 0;
}
.file-container .file-wrapper .file-input--active + .file-content {
  background: var(--drag-over-background);
}
.file-container .file-wrapper .file-input--active + .file-content .file-icon {
  opacity: 0.5;
}
.file-container .file-wrapper .file-input--active + .file-content .file-icon i {
  animation-name: bounce;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
}
.file-container .file-wrapper .file-input--active + .file-content .file-icon .icon-shadow {
  animation-name: shrink;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
}
.file-container .file-wrapper .file-content {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  border: 2px dashed var(--icon-color);
  border-radius: var(--dialog-border-radius);
  transition: 0.2s;
}
.file-container .file-wrapper .file-content .file-infos {
  position: absolute;
  display: flex;
  width: 50%;
  height: 50%;
  min-width: 400px;
  min-height: 202px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  flex-direction: column;
  justify-content: center;
}
.file-container .file-wrapper .file-content .file-infos .file-icon {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  color: var(--icon-color);
  /*background-color: #f1f1f1;*/
  border-radius: var(--dialog-border-radius);
  padding: var(--dialog-padding);
  /*box-shadow: inset 0px 0px 6px 0px #ccc;*/
  transition: 0.2s;
}
.file-container .file-wrapper .file-content .file-infos .file-icon i {
  margin-bottom: 20px;
  width: 100%;
}
.file-container .file-wrapper .file-content .file-infos .file-icon .icon-shadow {
  position: relative;
  display: block;
  width: 95px;
  height: 7px;
  border-radius: 100%;
  background-color: var(--drag-over-background);
  top: -17px;
  margin-left: auto;
  margin-right: auto;
}
.file-container .file-wrapper .file-content .file-infos .file-icon > span {
  position: absolute;
  bottom: var(--dialog-padding);
  width: calc(100% - var(--dialog-padding) * 2);
}
.file-container .file-wrapper .file-content .file-infos .file-icon > span span {
  display: none;
}
.file-container .file-wrapper .file-content .file-infos .file-icon > span .has-drag {
  display: inline;
}
.file-container .file-wrapper .file-content .file-infos .file-icon i, .file-container .file-wrapper .file-content .file-infos .file-icon span {
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}
.file-container .file-wrapper .file-content .file-name {
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0;
  bottom: var(--dialog-padding);
  right: 0;
  font-weight: bold;
  font-size: 15pt;
  margin: auto;
  text-align: center;
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes shrink {
  0% {
    width: 95px;
  }
  50% {
    width: 75px;
  }
  100% {
    width: 95px;
  }
}
</style>