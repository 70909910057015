<template>

    <div class="">

      <div class="container-fluid" v-bind:class="loading">

        <div class="row">
          <div class="col-md-8">
            <div class="card custom-card-wrapper">
              <div class="card-header line-height-1">
                USERS LIST
              </div>
              <div class="card-body p-0">

                <table class="table table-striped">
                  <thead class=" ">
                  <tr>
                    <th>
                      Full Name
                    </th>
                    <th>
                      Email
                    </th>
                    <th>
                      Phone No
                    </th>
                    <th>
                      Role
                    </th>
                    <th>
                      Status
                    </th>
                    <th class="text-right">
                      Actions
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(u,index) in users" class="" v-bind:key="getKey(index)">

                    <td>
                      <span v-text="u.first_name"> </span> <span v-text="u.last_name"></span>
                    </td>

                    <td v-text="u.email"></td>
                    <td v-text="u.msisdn"></td>
                    <td v-text="u.role_name"></td>
                    <td v-html="getStatus(u.status)"></td>

                    <td class="text-right">
                      <div class="btn-group">

                        <button type="button" class="btn btn-primary btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                          More
                        </button>
                        <ul class="dropdown-menu">

                          <li>
                            <a class="dropdown-item" @click="deleteUser(u)">Delete</a>
                          </li>

                          <li v-show="parseInt(u.status) === 0">
                            <a class="dropdown-item" @click="activateUser(u)">Activate</a>
                          </li>

                          <li v-show="parseInt(u.status) === 1">
                            <a class="dropdown-item" @click="deactivateUser(u)">Activate</a>
                          </li>

                          <li>
                            <a class="dropdown-item" @click="resetPassword(u)">Reset Password</a>
                          </li>

                          <li>
                            <a class="dropdown-item" @click="editMember(u)" data-bs-toggle="modal" data-bs-target="#update-user">Update Details</a>
                          </li>

                        </ul>

                      </div>
                    </td>

                  </tr>


                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card">
              <div class="card-header">
                Add User
              </div>
              <div class="card-body">
                <form class="custom-form">
                  <div class="input-wrapper mb-2">

                    <div class="mb-3">
                      <label for="first-name" class="form-label">First Name</label>
                      <input type="text" class="form-control" id="first-name" v-model="first_name">
                    </div>

                    <div class="mb-3">
                      <label for="last-name" class="form-label">Last Name</label>
                      <input type="text" class="form-control" id="last-name" v-model="last_name">
                    </div>

                    <div class="mb-3">
                      <label for="email" class="form-label">Email </label>
                      <input type="text" class="form-control" id="email" v-model="email">
                    </div>

                    <div class="mb-3">
                      <label for="msisdn" class="form-label">Phone Number </label>
                      <input type="text" class="form-control" id="msisdn" v-model="msisdn">
                    </div>

                    <div class="mb-3">
                      <label for="role-id" class="form-label">User Role </label>
                      <select class="form-select" aria-label="Default select example" id="role-id" v-model="role_id">
                        <option value="1">Business Administrator</option>
                        <option value="2">User</option>
                        <option value="3">Accountant</option>
                      </select>
                    </div>

                  </div>


                  <div class="modal-footer-copy">
                    <button type="button" class="btn btn-primary btn-sm shadow mr-2" @click="createUser">Add</button>
                    <button type="button" class="btn btn-outline-secondary btn-sm " data-bs-dismiss="modal">Cancel</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="modal fade" v-bind:class="loading" id="update-user" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-md modal-dialog-centered">
          <div class="modal-content">

            <div class="modal-header">
              <h5 class="modal-title">Update <span v-text="user.first_name"></span> Details</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

            <div class="modal-body" v-bind:class="loading">

              <form class="custom-form">

                <div class="input-wrapper mb-2">

                  <div class="row">

                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="update-first-name" class="form-label">First Name</label>
                        <input type="text" class="form-control" id="update-first-name" v-model="user.first_name">
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="update-last-name" class="form-label">Last Name</label>
                        <input type="text" class="form-control" id="update-last-name" v-model="user.last_name">
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="update-email" class="form-label">Email </label>
                        <input type="text" class="form-control" id="update-email" v-model="user.email">
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="update-msisdn" class="form-label">Phone Number </label>
                        <input type="number" class="form-control" id="update-msisdn" v-model="user.msisdn">
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="update-role-id" class="form-label">User Role </label>
                        <select class="form-select" aria-label="Default select example" id="update-role-id" v-model="user.role_id">
                          <option value="1">Business Administrator</option>
                          <option value="2">User</option>
                          <option value="3">Accountant</option>
                        </select>
                      </div>
                    </div>
                  </div>

                </div>


                <div class="modal-footer-copy">
                  <button type="button" class="btn btn-primary btn-sm shadow mr-2" @click="updateUser">Update</button>
                  <button type="button" class="btn btn-outline-secondary btn-sm" id="close-update-user" data-bs-dismiss="modal">Cancel</button>
                </div>
              </form>

            </div>

          </div>
        </div>
      </div>

    </div>

</template>

<script>
import axios from "@/services/api";

export default {
  name: 'Home',
  components: {

  },
  data: function (){
    return {
      users: [],
      first_name: '',
      last_name: '',
      role_id: '',
      email: '',
      msisdn: '',
      user: {
        first_name: '',
        last_name: '',
        role_id: '',
        email: '',
        msisdn: '',
        id: 0
      },
      mqttClient:'',
      active_tab: 'send-message',
      loading: ''
    }
  },
  mounted() {

    this.$store.dispatch("setCurrentPage","users");
    this.$store.dispatch("setCurrentPageName","Users");
    this.$store.dispatch("setPreviousPage","dashboard");
    this.getUsers()

  },
  computed: {

  },
  methods: {

    getUsers: function(){

      var vm = this;

      var path = process.env.VUE_APP_URL_USERS_LIST;

      axios.post(path, JSON.stringify({}),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            var msg = res.data.data;
            vm.users = msg;

          })
          .catch(err => {

            vm.loading = '';

            console.log('FAILED response '+JSON.stringify(err.response.data.data))

            if (err.response) {

              vm.setError("Failed",err.response.data.data)

            }
            else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")

            }
            else {

              vm.setError("Network Error", "Check your network connection and try again")

            }

          })

    },

    showTab: function(tabName) {

      this.active_tab = tabName;
    },

    isActiveTab: function(tabName) {

      return this.active_tab === tabName;
    },

    getTabClass: function(tabName) {

      return this.active_tab === tabName ? 'active-tab' : '';
    },

    getTabTitleClass: function(tabName) {

      return this.active_tab === tabName ? 'active' : '';

    },

    getStatus:function (status){

      if(parseInt(status) === 1) {

        return  '<span class="btn btn-sm btn-success py-0">Active</span>'

      }

      if(parseInt(status) === 0) {

        return  '<span class="btn btn-sm btn-secondary py-0">Inactive</span>'

      }

      return  '<span class="btn btn-sm btn-warning py-0">Suspended</span>'

    },

    deleteUser: function(data) {

      var vm = this;

      vm.$swal.fire({
        title: "Do you want to delete. "+data.first_name+' from the user?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete User'

      }).then((result) => {

        if (result.isConfirmed) {

          var path = process.env.VUE_APP_URL_USER_DELETE.replace(":id", data.id);
          vm.loading = 'loading';

          axios.post(path, JSON.stringify({}),{
            headers: {
              'api-key': vm.getAuth()
            },
          })
              .then(res => {

                vm.loading = '';
                vm.getUsers();

                var msg = res.data.data.message;

                vm.$swal.fire(
                    'Submitted!',
                    msg,
                    'success'
                )

              })
              .catch(err => {

                vm.loading = '';

                vm.$swal.fire(
                    'Failed!',
                    err.response.data.data,
                    'error'
                )

                if (err.response) {

                  var message = "";

                  if(parseInt(err.response.status) === 428 ) {

                    message = err.response.data.message;
                    vm.setError("Failed", message)
                    return;
                  }

                  if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                    vm.setError("Session Expired", "Your session on this device has expired");
                    vm.logout();
                    return;

                  } else {

                    message =  err.response.data.message;
                    vm.setError("Failed", message)

                  }

                } else if (err.request) {

                  vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err.request));

                } else {

                  //vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err));

                }
              })
        } else {

          vm.cashoutLoading = '';

        }

      })

    },

    activateUser: function(data) {

      var vm = this;

      vm.$swal.fire({
        title: "Do you want to active. "+data.first_name+'?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Activate User'

      }).then((result) => {

        if (result.isConfirmed) {

          var path = process.env.VUE_APP_URL_USER_ACTIVATE.replace(":id", data.id);
          vm.loading = 'loading';

          axios.post(path, JSON.stringify({}),{
            headers: {
              'api-key': vm.getAuth()
            },
          })
              .then(res => {

                vm.loading = '';
                vm.getUsers();

                var msg = res.data.data.message;

                vm.$swal.fire(
                    'Submitted!',
                    msg,
                    'success'
                )

              })
              .catch(err => {

                vm.loading = '';

                vm.$swal.fire(
                    'Failed!',
                    err.response.data.data,
                    'error'
                )

                if (err.response) {

                  var message = "";

                  if(parseInt(err.response.status) === 428 ) {

                    message = err.response.data.message;
                    vm.setError("Failed", message)
                    return;
                  }

                  if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                    vm.setError("Session Expired", "Your session on this device has expired");
                    vm.logout();
                    return;

                  } else {

                    message =  err.response.data.message;
                    vm.setError("Failed", message)

                  }

                } else if (err.request) {

                  vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err.request));

                } else {

                  //vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err));

                }
              })
        } else {

          vm.cashoutLoading = '';

        }

      })

    },

    deactivateUser: function(data) {

      var vm = this;

      vm.$swal.fire({
        title: "Do you want to deactive. "+data.first_name+'?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Deactivate User'

      }).then((result) => {

        if (result.isConfirmed) {

          var path = process.env.VUE_APP_URL_USER_DEACTIVATE.replace(":id", data.id);
          vm.loading = 'loading';

          axios.post(path, JSON.stringify({}),{
            headers: {
              'api-key': vm.getAuth()
            },
          })
              .then(res => {

                vm.loading = '';
                vm.getUsers();

                var msg = res.data.data.message;

                vm.$swal.fire(
                    'Submitted!',
                    msg,
                    'success'
                )

              })
              .catch(err => {

                vm.loading = '';

                vm.$swal.fire(
                    'Failed!',
                    err.response.data.data,
                    'error'
                )

                if (err.response) {

                  var message = "";

                  if(parseInt(err.response.status) === 428 ) {

                    message = err.response.data.message;
                    vm.setError("Failed", message)
                    return;
                  }

                  if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                    vm.setError("Session Expired", "Your session on this device has expired");
                    vm.logout();
                    return;

                  } else {

                    message =  err.response.data.message;
                    vm.setError("Failed", message)

                  }

                } else if (err.request) {

                  vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err.request));

                } else {

                  //vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err));

                }
              })
        } else {

          vm.cashoutLoading = '';

        }

      })

    },

    resetPassword: function(data) {

      var vm = this;

      vm.$swal.fire({
        title: "Do you want to reset ppassword for. "+data.first_name+'?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Reset Password'

      }).then((result) => {

        if (result.isConfirmed) {

          var path = process.env.VUE_APP_URL_USER_RESET_PASSWORD.replace(":id", data.id);
          vm.loading = 'loading';

          axios.post(path, JSON.stringify({}),{
            headers: {
              'api-key': vm.getAuth()
            },
          })
              .then(res => {

                vm.loading = '';
                vm.getUsers();

                var msg = res.data.data.message;

                vm.$swal.fire(
                    'Submitted!',
                    msg,
                    'success'
                )

              })
              .catch(err => {

                vm.loading = '';

                vm.$swal.fire(
                    'Failed!',
                    err.response.data.data,
                    'error'
                )

                if (err.response) {

                  var message = "";

                  if(parseInt(err.response.status) === 428 ) {

                    message = err.response.data.message;
                    vm.setError("Failed", message)
                    return;
                  }

                  if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                    vm.setError("Session Expired", "Your session on this device has expired");
                    vm.logout();
                    return;

                  } else {

                    message =  err.response.data.message;
                    vm.setError("Failed", message)

                  }

                } else if (err.request) {

                  vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err.request));

                } else {

                  //vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err));

                }
              })
        } else {

          vm.cashoutLoading = '';

        }

      })

    },

    createUser: function (){

      if (!this.first_name) {
        this.setError("Invalid name", "Please enter a valid first name");
        return;
      }

      if (!this.last_name) {
        this.setError("Invalid name", "Please enter a valid last name");
        return;
      }

      if (!this.validEmail(this.email)) {
        this.setError("Invalid name", "Please enter a valid email");
        return;
      }

      if (!this.msisdn) {

        this.setError("Invalid phone", "Please enter a valid phone number");
        return;
      }

      if (!this.role_id) {

        this.setError("Invalid name", "Please select a valid role");
        return;
      }

      var vm = this;

      var path = process.env.VUE_APP_URL_USERS_CREATE;
      this.loading = 'loading';

      var dt = {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        role_id: parseInt(this.role_id),
        msisdn: parseInt(this.msisdn),
      }

      axios.post(path, JSON.stringify(dt),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            vm.getUsers();

            var msg = res.data.data.message;

            vm.$swal.fire(
                'Submitted!',
                msg,
                'success'
            )
          })
          .catch(err => {

            vm.loading = '';

            console.log('FAILED response '+JSON.stringify(err.response.data.data))

            if (err.response) {
              vm.setError("Failed",err.response.data.data)
            }
            else if (err.request) {
              vm.setError("Network Error", "Check your network connection and try again")
            }
            else {
              vm.setError("Network Error", "Check your network connection and try again")
            }
          })

    },

    updateUser: function (){

      if (!this.user.first_name) {
        this.setError("Invalid name", "Please enter a valid first name");
        return;
      }

      if (!this.user.last_name) {
        this.setError("Invalid name", "Please enter a valid last name");
        return;
      }

      if (!this.validEmail(this.user.email)) {
        this.setError("Invalid name", "Please enter a valid email");
        return;
      }

      if (!this.user.msisdn) {
        this.setError("Invalid phone", "Please enter a valid phone number");
        return;
      }

      if (!this.user.role_id) {

        this.setError("Invalid name", "Please select a valid role");
        return;
      }

      var vm = this;

      var path = process.env.VUE_APP_URL_USER_UPDATE.replace(":id",this.user.id);
      this.loading = 'loading';

      var dt = {
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        email: this.user.email,
        role_id: parseInt(this.user.role_id),
        msisdn: parseInt(this.user.msisdn),
      }

      axios.post(path, JSON.stringify(dt),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            vm.getUsers();
            vm.closeModal('update-user');
            console.log(JSON.stringify(res.data,undefined,2));

            var msg = res.data.data.message;

            vm.$swal.fire(
                'Submitted!',
                msg,
                'success'
            )
          })
          .catch(err => {

            vm.loading = '';

            console.log('FAILED response '+JSON.stringify(err.response.data.data))

            if (err.response) {
              vm.setError("Failed",err.response.data.data)
            }
            else if (err.request) {
              vm.setError("Network Error", "Check your network connection and try again")
            }
            else {
              vm.setError("Network Error", "Check your network connection and try again")
            }
          })

    },

    editMember: function (data) {

      this.user = data;

    },

  },
}

</script>

<style>
/* Style the tab */
.tab {
  overflow: hidden;
  background-color: #fff;
}
.nav-tabs .nav-link{
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
/* Style the buttons that are used to open the tab content */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  border-top: 2px solid transparent;

  border-bottom:1px solid  rgba(0,0,0,.125);
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #fff;
  border-top: 2px solid #3C9D48;
  border-bottom:1px solid  transparent;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #fff;
  border-top: 2px solid #3C9D48;
  border-left: 1px solid rgba(0,0,0,.125);
  border-right: 1px solid rgba(0,0,0,.125);
  border-bottom:1px solid  transparent;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border-top: none;
}
</style>
