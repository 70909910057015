<template>

    <div class="">

      <div class="container-fluid">

        <div class="d-flex justify-content-between bg-light mb-3">

          <ul class="nav nav-tabs nav-header">

            <li class="nav-item">
              <a class="nav-link tablinks text-dark" v-bind:class="getTabTitleClass('send-message')" @click="showTab('send-message')" aria-current="page" >Send Message</a>
            </li>

            <li class="nav-item">
              <a id="js-upload-file" class="nav-link tablinks text-dark" v-bind:class="getTabTitleClass('sent-message')" @click="showTab('sent-message')" >Sent Messages</a>
            </li>

            <li class="nav-item">
              <a id="js-upload-file" class="nav-link tablinks text-dark" v-bind:class="getTabTitleClass('contact-group')" @click="showTab('contact-group')" >Contact Groups</a>
            </li>

          </ul>

          <div class="py-1">
            <a  class="primary-btn-comms d-block" data-bs-toggle="modal" data-bs-target="#exampleModal"> <i class="bi bi-cart"></i> Buy SMS Units</a>
          </div>

        </div>

        <!-- Tab content -->
        <div v-show="isActiveTab('send-message')" v-bind:class="getTabClass('send-message')" class="tabcontent p-0">
          <div class="row mb-3">
            <div class="col-md-5">
              <div class="card">
                <div class="card-body">
                  <form class="custom-form">
                    <div class="input-wrapper mb-2">
                      <div class="mb-3">
                        <label for="exampleInputPassword1" class="form-label">From</label>
                        <select class="form-select" aria-label="Default select example">
                          <option selected>Open this select menu</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                      <div class="mb-3">
                        <label for="exampleInputPassword1" class="form-label">Select Template</label>
                        <select class="form-select" aria-label="Default select example">
                          <option selected>Open this select menu</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>

                      <div class="mb-3">

                        <input type="text" class="form-control" placeholder="Select Contact Group or separate multiple contacts with comma">
                      </div>
                      <div class="mb-3">
                        <label for="exampleInputPassword1" class="form-label">Message To Send</label>
                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                        <small>0 character(s), 1 Page(s)</small>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">
                          Save This Message to Template
                        </label>
                      </div>
                      <div class="form-check">
                        <div class="row">
                          <div class="col-md-6">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                            <label class="form-check-label" for="flexCheckChecked">
                              Schedule Message
                            </label>
                          </div>
                          <div class="col-md-6">
                            <input type="date" class="form-control py-0" placeholder="">
                          </div>
                        </div>

                      </div>
                      <div class="sample-file mb-2">
                        Download Sample File
                      </div>

                      <div class="d-flex justify-content-between">
                        <div class="modal-footer-copy">
                          <button type="button" class="btn btn-secondary btn-sm mr-2" data-bs-dismiss="modal">Upload Contacts</button>

                        </div>
                        <div class="modal-footer-copy">
                          <button type="button" class="btn btn-secondary btn-sm mr-2" data-bs-dismiss="modal">Cancel</button>
                          <button type="button" class="btn btn-primary btn-sm shadow">Send</button>
                        </div>
                      </div>

                    </div>



                  </form>
                </div>
              </div>
            </div>
            <div class="col-md-7">
              <div class="row m-0 p-0 mb-3 bg-light py-2">
                <div class="col-md-3 text-center border-right">
                  <strong class="text-light-blue">100</strong><br>
                  <span class="font-size-12">Sent Messages</span>
                </div>
                <div class="col-md-3 text-center border-right">
                  <strong class="text-light-blue">200</strong><br>
                  <span class="font-size-12">SMS Units Used</span>
                </div>
                <div class="col-md-3 text-center border-right">
                  <strong class="text-light-blue">1200</strong><br>
                  <span class="font-size-12">SMS Balance</span>
                </div>
                <div class="col-md-3 text-center">
                  <strong class="text-light-blue">4</strong><br>
                  <span class="font-size-12">Contact groups</span>
                </div>
              </div>

              <div class="card">
                <div class="card-header">
                  Message Template
                </div>
                <div class="card-body">
                  <div class="d-flex justify-content-between mb-2">
                    <form>
                      <div class="mb-0">
                        <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Search...">
                      </div>
                    </form>
                    <a  class="primary-btn-comms d-block" data-bs-toggle="modal" data-bs-target="#templateModal">  Create Template</a>
                  </div>

                  <table class="table table-striped">
                    <thead class="bg-green text-light">
                    <th>
                      Title
                    </th>
                    <th>
                      Message
                    </th>
                    <th>
                      Actions
                    </th>
                    </thead>
                    <tbody>
                    <td>
                      Form 1 Parents
                    </td>
                    <td>
                      Hello Lorem Ipsum
                    </td>
                    <td>
                      <a class="btn btn-link btn-sm p-0" href=""><i class="bi bi-pen-fill"></i></a>
                      <a class="btn btn-link btn-sm p-0" href=""><i class="bi bi-x-lg"></i></a>
                    </td>
                    </tbody>
                  </table>

                </div>
              </div>


            </div>
          </div>



        </div>

        <div v-show="isActiveTab('sent-message')" v-bind:class="getTabClass('sent-message')" class="tabcontent pt-0 p-0">
          <div class="card">
            <div class="card-header d-flex">
              <div class="mb-3 mr-3">
                <label for="exampleInputPassword1" class="form-label">Search By:</label>
                <input type="text" class="form-control" placeholder="search">
              </div>
              <div>
                <label for="exampleInputPassword1" class="form-label text-light">Search By:</label>
                <div id="reportrange" style="">
                  <i class="bi bi-calendar"></i>&nbsp;
                  <span></span> <i class="bi bi-chevron-down"></i>
                </div>
              </div>

            </div>
            <div class="card-body-header cbh2">
              RESULTS(2)
            </div>
            <div class="card-body p-0">

              <table class="table table-striped">
                <thead class="">
                <th>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDisabled" >

                  </div>
                </th>
                <th>
                  Phone No
                </th>
                <th>
                  Message
                </th>

                </thead>
                <tbody>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDisabled" >

                  </div>
                </td>
                <td>
                  0712345678
                </td>
                <td>
                  Hello Lorem Ipsum
                </td>

                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div v-show="isActiveTab('contact-group')" v-bind:class="getTabClass('contact-group')" class="tabcontent pt-0 p-0">
          <div class="row">
            <div class="col-md-4">
              <div class="card">
                <div class="card-header">
                  Contact Groups
                </div>
                <div class="card-body">
                  <ul class="listles">
                    <li class="py-1 active">
                      <a>
                        <div class="d-flex">
                          <span>ECMS Worksheet</span>
                          <span>12</span>
                        </div>
                      </a>
                    </li>
                    <li class="py-1">
                      <a>
                        <div class="d-flex">
                            <span>Group 1</span><span>12</span>
                        </div>
                      </a>
                    </li>
                    <li class="py-1">
                      <a>
                        <div class="d-flex">
                                        <span>
                                            Group 1
                                        </span>
                          <span>
                                            12
                                        </span>
                        </div>

                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="card">
                <div class="card-header">
                  ECMS Worksheet
                </div>
                <div class="card-body">
                  <div class="d-flex justify-content-between mb-2">
                    <form>
                      <div class="mb-0">
                        <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Search...">
                      </div>
                    </form>

                  </div>

                  <table class="table table-striped">
                    <thead class="bg-green text-light">
                    <th>
                      Mobile No
                    </th>
                    <th>
                      First Name
                    </th>
                    <th>
                      Last Name
                    </th>
                    </thead>
                    <tbody>
                    <td>
                      0701234567
                    </td>
                    <td>
                      Lorem Ipmsum
                    </td>
                    <td>
                      Dolor Ipsum
                    </td>
                    </tbody>
                  </table>

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <!-- Modal -->
      <div class="modal fade" id="templateModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Add Message Template</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form class="custom-form">
                <div class="input-wrapper mb-2">
                  <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">Title</label>
                    <input type="text" class="form-control" >
                  </div>
                  <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">Template</label>
                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                    <small>0 character(s), 1 Page(s)</small>
                  </div>


                </div>


                <div class="modal-footer-copy">
                  <button type="button" class="btn btn-outline-secondary btn-sm mr-2" data-bs-dismiss="modal">Cancel</button>
                  <button type="button" class="btn btn-primary btn-sm shadow">Create</button>
                </div>
              </form>
            </div>

          </div>
        </div>
      </div>

      <!-- Modal -->
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Top Up Via MPESA</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form class="custom-form">
                <div class="input-wrapper mb-2">
                  <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">Amount to Top Up</label>
                    <input type="text" class="form-control" >
                  </div>
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">Mpesa Number </label>
                    <input type="text" class="form-control" >
                  </div>


                </div>


                <div class="modal-footer-copy">
                  <button type="button" class="btn btn-outline-secondary btn-sm mr-2" data-bs-dismiss="modal">Cancel</button>
                  <button type="button" class="btn btn-primary btn-sm shadow">Top Up</button>
                </div>
              </form>
            </div>

          </div>
        </div>
      </div>

    </div>

</template>

<script>

export default {
  name: 'Home',
  components: {

  },
  data: function (){
    return {
      highlights: true,
      upcoming: false,
      today: false,
      sub_page: '',
      mqttClient:'',
      active_tab: 'send-message'
    }
  },
  mounted() {

    this.$store.dispatch("setCurrentPage","communications");
    this.$store.dispatch("setCurrentPageName","Communications");
    this.$store.dispatch("setPreviousPage","dashboard");

  },
  computed: {
    betslip_count: function (){

      return this.$store.state.betslip.total

    },
    current_sub_page: function () {

      return this.$store.state.current_sub_page;
    },
    sport_id: function () {

      return this.$store.state.sport_id;

    }
  },
  methods: {

    showTab: function(tabName) {

      this.active_tab = tabName;
    },

    isActiveTab: function(tabName) {

      return this.active_tab === tabName;
    },

    getTabClass: function(tabName) {

      return this.active_tab === tabName ? 'active-tab' : '';
    },

    getTabTitleClass: function(tabName) {

      return this.active_tab === tabName ? 'active' : '';

    },

    setUpcoming: function(){

      this.highlights = false;
      this.today = false;
      this.upcoming = true;
    },
    setToday: function(){

      this.highlights = false;
      this.today = true;
      this.upcoming = false;
    },
    setHighlight: function(){

      this.highlights = true;
      this.today = false;
      this.upcoming = false;
    },
    setSubPage: function (page){

      this.$store.dispatch("setCurrentSubPage",page);
      this.sub_page = page;

    },
    isHighlight: function(){

      return this.highlights
    },
    isToday: function(){

      return this.today
    },
    isUpcoming: function(){

      return this.upcoming
    },

    geSubPageActiveClass: function (subpage) {

      return subpage === this.sub_page ? 'active' : "";

    },
    setSport: function(sportID) {

      this.$store.dispatch("setSportID",sportID);

    }
  },
}

</script>

<style>
/* Style the tab */
.tab {
  overflow: hidden;
  background-color: #fff;
}
.nav-tabs .nav-link{
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
/* Style the buttons that are used to open the tab content */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  border-top: 2px solid transparent;

  border-bottom:1px solid  rgba(0,0,0,.125);
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #fff;
  border-top: 2px solid #3C9D48;
  border-bottom:1px solid  transparent;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #fff;
  border-top: 2px solid #3C9D48;
  border-left: 1px solid rgba(0,0,0,.125);
  border-right: 1px solid rgba(0,0,0,.125);
  border-bottom:1px solid  transparent;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border-top: none;
}
</style>
