<template>

    <div class="">

      <div class="container-fluid">
        <div class="card mb-3">
          <div class="card-header card-body-header-lg p-2 pb-0 d-flex justify-content-between shadow-none bg-light ">
            <div class=" d-flex justify-content-left ">
            <span class="font-weight-500 men-item underline-item mr-2 px-3 ">
                <router-link to="/settings" class="">Title</router-link>
            </span>
              <span class="font-weight-500 men-item underline-item px-3 mr-2 ">
                 <router-link to="/settings/payment" class="">Rate</router-link>
            </span>
              <span class="font-weight-500 men-item underline-item px-3 mr-2 active">
                 <router-link to="/settings/roles" class="">Roles</router-link>
            </span>


            </div>

          </div>
        </div>
        <div class="row">
          <div class="col-md-7">
            <div class="card custom-card-wrapper">
              <div class="card-header line-height-1">
                User Roles List
              </div>
              <div class="card-body p-0">

                <div class="card-body-subheader p-2 d-flex justify-content-between border-bottom">
                  <div class="font-weight-500 pt-1">
                    <a class="btn btn-link btn-sm" href="">Delete</a>
                    <a class="btn btn-link btn-sm" href="">Print</a>
                    <a class="btn btn-link btn-sm" href="">Export</a>
                  </div>

                </div>
                <table class="table table-striped">
                  <thead class=" ">
                  <tr>
                    <th>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDisabled" >

                      </div>
                    </th>
                    <th>
                      Role Name
                    </th>

                    <th class="text-right">
                      Actions
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="">
                    <td>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDisabled" >

                      </div>
                    </td>
                    <td>
                      Administrator
                    </td>

                    <td class="text-right">
                      <a class="btn btn-warning btn-sm p-0" href=""><i class="bi bi-pen-fill"></i></a>
                      <a class="btn btn-danger btn-sm p-0" href=""><i class="bi bi-x-lg"></i></a>
                    </td>
                  </tr>


                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="card custom-card-wrapper">
              <div class="card-header line-height-1">
                Add User Role
              </div>
              <div class="card-body">
                <form class="custom-form">
                  <div class="input-wrapper mb-2">
                    <div class="mb-3">
                      <label for="exampleInputPassword1" class="form-label">Role Name</label>
                      <input type="text" class="form-control" >
                    </div>
                  </div>
                  <div class="modal-footer-copy">
                    <button type="button" class="btn btn-primary btn-sm shadow mr-2">Add</button>
                    <button type="button" class="btn btn-outline-secondary btn-sm " data-bs-dismiss="modal">Cancel</button>

                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

</template>

<script>

export default {
  name: 'payments',
  components: {

  },
  data: function (){
    return {
      highlights: true,
      upcoming: false,
      today: false,
      sub_page: '',
      mqttClient:'',
      active_tab: 'account'
    }
  },
  mounted() {

    this.$store.dispatch("setCurrentPage","home");
    // reset game filter
    //this.$store.dispatch("resetAllGames");
    //this.reloadProfile();

  },
  computed: {
    betslip_count: function (){

      return this.$store.state.betslip.total

    },
    current_sub_page: function () {

      return this.$store.state.current_sub_page;
    },
    sport_id: function () {

      return this.$store.state.sport_id;

    }
  },
  methods: {

    showTab: function(tabName) {

      this.active_tab = tabName;
    },

    isActiveTab: function(tabName) {

      return this.active_tab === tabName;
    },

    getTabClass: function(tabName) {

      return this.active_tab === tabName ? 'active-tab' : '';
    },

    getTabTitleClass: function(tabName) {

      return this.active_tab === tabName ? 'active' : '';

    },

    setUpcoming: function(){

      this.highlights = false;
      this.today = false;
      this.upcoming = true;
    },
    setToday: function(){

      this.highlights = false;
      this.today = true;
      this.upcoming = false;
    },
    setHighlight: function(){

      this.highlights = true;
      this.today = false;
      this.upcoming = false;
    },
    setSubPage: function (page){

      this.$store.dispatch("setCurrentSubPage",page);
      this.sub_page = page;

    },
    isHighlight: function(){

      return this.highlights
    },
    isToday: function(){

      return this.today
    },
    isUpcoming: function(){

      return this.upcoming
    },

    geSubPageActiveClass: function (subpage) {

      return subpage === this.sub_page ? 'active' : "";

    },
    setSport: function(sportID) {

      this.$store.dispatch("setSportID",sportID);

    }
  },
}

</script>