<template>

    <div class="">

      <div class="container-fluid">

        <div class="card custom-card-wrapper">

          <div class="card-header card-body-header-lg p-2 pb-0 d-flex justify-content-between shadow-sm">

            <div class=" d-flex justify-content-left ">

            <span class="font-weight-500 men-item underline-item mr-2 active">
                 <router-link to="/worksheets" class="">Timesheet</router-link>
            </span>

              <span class="font-weight-500 men-item underline-item mr-2">
                 Timesheet Payment
              </span>
            </div>

            <div class="input-group blue-input-group mb-2">
              <button class="btn blue-input-group-btn-left btn-outline-primary dropdown-toggle font-size-12" type="button" data-bs-toggle="dropdown" aria-expanded="false">Filter By Status</button>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">Action before</a></li>
                <li><a class="dropdown-item" href="#">Another action before</a></li>
                <li><a class="dropdown-item" href="#">Something else here</a></li>
                <li><hr class="dropdown-divider"></li>
                <li><a class="dropdown-item" href="#">Separated link</a></li>
              </ul>
              <input type="text" class="form-control" aria-label="Text input with 2 dropdown buttons">
              <button class="btn blue-input-group-btn-right btn-outline-primary  font-size-12" type="button" ><i class="bi bi-search"></i></button>
            </div>
          </div>

          <div class="card-body p-0">

            <div class="card-body-subheader p-2 d-flex justify-content-between border-bottom">
              <span class="text-success"> </span>
              <div class="font-weight-500 pt-1">
                <button data-bs-toggle="modal" data-bs-target="#create-worksheet" class="primary-btn font-size-12">
                  Create Payment
                </button>
              </div>
            </div>

            <vuetable
                ref="vuetable"
                api-url="request/table"
                :api-mode="apiMode"
                :fields="fields"
                :sort-order="sortOrder"
                :css="css.table"
                pagination-path=""
                :per-page="perPage"
                :append-params="moreParams"
                :http-fetch="fetchMembers"
                :load-on-start="loadOnStart"
                @vuetable:pagination-data="onPaginationData"
                @vuetable:loading="onLoading"
                @vuetable:loaded="onLoaded">

              <div slot="actions-slot" slot-scope="props">
                <div class="btn-group">

                  <button type="button" class="btn bg-green btn-sm" @click="viewMembers(props.rowData)">
                    View Breakdown
                  </button>

                  <button type="button" class="btn btn-primary btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    More
                  </button>

                  <ul class="dropdown-menu">

                    <li><a class="dropdown-item" @click="updateWorksheetPayment(props.rowData)" data-bs-toggle="modal" data-bs-target="#create-worksheet" >Update Payment Schedule</a></li>

                    <li><a class="dropdown-item" @click="deleteWorksheetPayment(props.rowData)">Delete Payment Schedule</a></li>

                  </ul>

                </div>
              </div>

              <div slot="sequence-slot" slot-scope="props">
                {{ props.rowIndex + 1}}
              </div>

              <div slot="format-start-date-slot" slot-scope="props">
                {{ formatDate(props.rowData.start_date) }}
              </div>

              <div slot="format-end-date-slot" slot-scope="props">
                {{ formatDate(props.rowData.end_date) }}
              </div>

              <div slot="total-amount-slot" slot-scope="props">
                {{ formatCurrency(props.rowData.total_amount) }} Ksh
              </div>

              <div slot="created-slot" slot-scope="props">
                {{ formatDateTime(props.rowData.created) }}
              </div>

              <div slot="status-slot" slot-scope="props">
                <span v-html="getGroupStatus(props.rowData.status)"></span>
              </div>

            </vuetable>

            <vuetable-pagination
                ref="pagination"
                :css="css.pagination"
                @vuetable-pagination:change-page="onChangePage">
            </vuetable-pagination>

          </div>

        </div>

      </div>

      <!-- Modal -->
      <div class="modal fade" id="create-worksheet" tabindex="-1" aria-labelledby="create-worksheet" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">

            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel"><span v-text="action"></span> Timesheet Payment</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

            <div class="modal-body">
              <form class="custom-form">
                <div class="input-wrapper mb-2">

                  <div class="row">

                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="start-date" class="form-label">Start Time</label>
                        <input type="date" class="form-control" id="start-date" v-model="start_date">
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="end-date" class="form-label">End Time</label>
                        <input type="date" class="form-control" id="end-date" v-model="end_date">
                      </div>
                    </div>

                  </div>

                </div>

                <div class="modal-footer-copy">
                  <button type="button" class="btn btn-outline-secondary btn-sm mr-2" data-bs-dismiss="modal" id="close-create-worksheet">Close</button>
                  <button type="button" class="btn btn-primary btn-sm shadow" @click="createWorksheetPayment"><span v-text="action"></span> Payment</button>
                </div>
              </form>
            </div>

          </div>
        </div>
      </div>

    </div>

</template>

<script>

import Vue from 'vue'
import axios from "@/services/api";
import Vuetable from 'vuetable-2'
import VuetableFieldSequence from "vuetable-2/src/components/VuetableFieldSequence";

import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import moment from "moment";
Vue.use(Vuetable);

var moreParams = {};

export default {
  name: 'worksheetpayment',
  components: {
    Vuetable,
    'vuetable-pagination': VuetablePagination
  },
  data: function (){
    return {
      mqttClient:'',
      active_tab: 'account',
      name: '',
      start_date: '',
      end_date: '',
      action: 'Create',
      payment: {},

      // vuetable
      search: '',
      fields: [
        {
          name: VuetableFieldSequence,
          title: '#',
        },
        {
          name: "format-start-date-slot",
          title: 'Start Date',
          sortField: 'worksheet_payment_schedule.start_date',
        },
        {
          name: "format-end-date-slot",
          title: 'End Date',
          sortField: 'worksheet_payment_schedule.end_date',
        },
        {
          name: 'total_members',
          title: 'Members',
          sortField: 'worksheet_payment_schedule.total_members',
        },
        {
          name: 'total-amount-slot',
          title: 'Amount',
          sortField: 'worksheet_payment_schedule.total_amount'
        },
        {
          name: "initiated_by",
          title: 'Initiator',
          sortField: 'user.fullName',
        },
        {
          name: "status-slot",
          title: 'Status',
          sortField: 'worksheet_payment_schedule.status',
        },
        {
          name: "created-slot",
          title: 'Created',
          sortField: 'worksheet_payment_schedule.created',
        },
        {
          name: "actions-slot",
          title: 'Actions',
          titleClass: "center aligned",
          dataClass: "text-right"
        }
      ],
      moreParams: moreParams,
      css: {
        table: {
          tableClass: 'table table-striped table-bordered table-hovered',
          loadingClass: 'loading',
          ascendingIcon: 'glyphicon glyphicon-chevron-up',
          descendingIcon: 'glyphicon glyphicon-chevron-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger',
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination pull-right',
          activeClass: 'bg-green',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: '',
          },
        }
      },
      sortOrder: [
        { field: 'worksheet_payment_schedule.id', direction: 'desc' }
      ],
      perPage: parseInt(5),
      loadOnStart: true,
      apiMode: true,
      loading: ''
    }
  },
  mounted() {

    this.$store.dispatch("setCurrentPage","worksheetpayments");
    this.$store.dispatch("setCurrentPageName","Timesheet Payments");
    this.$store.dispatch("setPreviousPage","worksheet");

  },
  computed: {

    worksheet: function () {

      return this.$store.state.worksheet;

    },

  },
  methods: {

    getGroupStatus: function (status){

      if(parseInt(status) === 0) {

        return '<span class="fw-bold text-secondary">Pending</span>'
      }

      if(parseInt(status) === 1) {

        return '<span class="fw-bold text-secondary">Pending Approval</span>'
      }

      if(parseInt(status) === 2) {

        return '<span class="fw-bold text-warning">Payment Initiated</span>'
      }

      if(parseInt(status) === -1) {

        return '<span class="fw-bold text-danger">Payment Failed</span>'
      }

      if(parseInt(status) === -2) {

        return '<span class="fw-bold text-danger">Insufficient Balance</span>'
      }

      return '<span class="fw-bold text-success">Payment Completed</span>'

    },

    dateFormat: function (date){

      return this.dateFormat(date)

    },

    searchMember: function (){

      if(this.search.length < 2 ) {

        this.setError("invalid fields","please type atleast 3 characters to search")
        return
      }

      this.moreParams.search = this.search;
      this.$refs.vuetable.refresh();

    },

    reload: function() {

      this.moreParams.search = '';
      this.$refs.vuetable.refresh();
    },

    onPaginationData: function(paginationData) {

      this.$refs.pagination.setPaginationData(paginationData)
    },

    onChangePage: function(page) {

      this.$refs.vuetable.changePage(page)

    },

    delete: function(rowData) {

      console.log('TO delete '+JSON.stringify(rowData))

    },

    editRow: function(rowData){

      alert("You clicked edit on"+ JSON.stringify(rowData))
    },

    deleteRow: function(rowData){

      alert("You clicked delete on"+ JSON.stringify(rowData))

    },

    onLoading: function() {

      this.loading = 'loading'
      console.log('loading... show your spinner here')

    },

    onLoaded: function() {

      this.loading = ''
      console.log('loaded! .. hide your spinner here')

    },

    fetchMembers: function(apiUrl, httpOptions){

      var vm = this;
      var path = process.env.VUE_APP_URL_WORKSHEET_PAYMENT_VIEW_TABLE.replace(":id",vm.worksheet.id);

      return axios.post(path, JSON.stringify(httpOptions.params), {
        headers: {
          'api-key': vm.getAuth()
        },
      })
    },

    getPaymentMethod: function (paymentMethod){

      if(parseInt(paymentMethod) === 1 ) {

        return 'MPESA';

      }
      return  'BANK';
    },

    formatAmount: function (amount){

      return this.formatStake(amount);

    },

    updateWorksheetPayment: function(payment){

      this.payment = payment;

      var fm = 'YYYY-MM-DD';
      this.start_date = moment(payment.start_date,fm).format('YYYY-MM-DD');
      this.end_date = moment(payment.end_date,fm).format('YYYY-MM-DD');
      this.action = 'Update';

    },

    createWorksheetPayment: function (){

      var vm = this;

      if (!this.start_date) {

        this.setError("Invalid start date", "Please enter a Timesheet start date");
        vm.$swal.fire(
            'Error!',
            "Please enter a Timesheet valid start date",
            'error'
        )
        return;
      }

      if (!this.end_date) {

        this.setError("Invalid end date", "Please enter a Timesheet end date");
        vm.$swal.fire(
            'Error!',
            "Please enter a Timesheet valid end date",
            'error'
        )
        return;
      }

      var path = process.env.VUE_APP_URL_WORKSHEET_PAYMENT_CREATE.replace(":id",this.worksheet.id);

      if(this.action ==='Update') {

        path = process.env.VUE_APP_URL_WORKSHEET_PAYMENT_UPDATE.replace(":id", this.worksheet.id).replace(":payment_id",vm.payment.id);

      }

      this.loading = 'loading';

      var data = {
        start: this.start_date,
        end: this.end_date,
      };

      axios.post(path, JSON.stringify(data),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            vm.closeModal('create-worksheet');
            var msg = res.data.data.message;
            vm.setSuccess("Success", msg);
            vm.action = 'Create';

            vm.$swal.fire(
                'Submitted!',
                msg,
                'success'
            )
            vm.reload();
          })
          .catch(err => {

            vm.loading = '';
            vm.closeModal('create-worksheet');

            var status = parseInt(err.response.status);

            //FAILED response {"attendance":null,"message":"You have un marked attendance"}

            if(status === 412) {

              var msg = err.response.data.data.message;
              var attendance = err.response.data.data.attendance;
              console.log('FAILED err.response.status ' + JSON.stringify(err.response.status))

              if (attendance && attendance.length > 0) {

                var trs = [];

                vm.jQuery.each(attendance, function (k, v) {

                  var tr = '<tr><td>' + vm.formatDate(v.date) + '</td><td>' + v.total + '</td>';
                  trs.push(tr);

                })

                var tbl = '<h3>You have unmarked attendance</h3><table class="table table-striped"><thead><tr><th>Date</th><th>Un Marked</th></tr></thead><tbody>' + trs.join('') + '</tbody></table>';

                vm.$swal.fire({
                  icon: 'error',
                  html: tbl,
                  showCloseButton: true,
                  showCancelButton: true,
                  focusConfirm: false,
                })

              } else {

                vm.$swal.fire('Failed!', msg, 'error')

              }

            }
            else {

              console.log(JSON.stringify(err.response.data,undefined,2))

              vm.$swal.fire(
                  'Failed!',
                  err.response.data.data,
                  'error'
              )
            }

          })

    },

    viewMembers: function (worksheetpaymentschedule){

      var vm = this;
      this.$store.dispatch("setWorksheetPaymentSchedule",worksheetpaymentschedule);
      this.$router.push({ name: 'worksheetpaymentschedulemembers', params: {id: vm.worksheet.id, payment_id:worksheetpaymentschedule.id} });
    },

    deleteWorksheetPayment: function(data) {

      var vm = this;

      vm.$swal.fire({
        title: "Do you want to delete this payment schedule from the list?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete'

      }).then((result) => {

        if (result.isConfirmed) {

          var path = process.env.VUE_APP_URL_WORKSHEET_PAYMENT_DELETE.replace(":id", vm.worksheet.id).replace(":payment_id",data.id);
          vm.loading = 'loading';

          axios.post(path, JSON.stringify({}),{
            headers: {
              'api-key': vm.getAuth()
            },
          })
              .then(res => {

                vm.loading = '';
                vm.reload();

                var msg = res.data.data.message;

                vm.$swal.fire(
                    'Submitted!',
                    msg,
                    'success'
                )

              })
              .catch(err => {

                vm.loading = '';

                vm.$swal.fire(
                    'Failed!',
                    err.response.data.data,
                    'error'
                )

                if (err.response) {

                  var message = "";

                  if(parseInt(err.response.status) === 428 ) {

                    message = err.response.data.message;
                    vm.setError("Failed", message)
                    return;
                  }

                  if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                    vm.setError("Session Expired", "Your session on this device has expired");
                    vm.logout();
                    return;

                  } else {

                    message =  err.response.data.message;
                    vm.setError("Failed", message)

                  }

                } else if (err.request) {

                  vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err.request));

                } else {

                  //vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err));

                }
              })
        } else {

          vm.cashoutLoading = '';

        }

      })

    },

    makePayment: function(data) {

      var vm = this;

      vm.$swal.fire({
        title: 'Confirm',
        text: "Do you want to approve this payment? Total amount "+vm.formatCurrency(data.total_amount)+' Ksh?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Approve Payment'

      }).then((result) => {

        if (result.isConfirmed) {

          var path = process.env.VUE_APP_URL_WORKSHEET_PAYMENT_APPROVE.replace(":payment_id",data.id).replace(":id",vm.worksheet.id);
          vm.loading = 'loading';

          axios.post(path, JSON.stringify({description: 'payment approved'}),{
            headers: {
              'api-key': vm.getAuth()
            },
          })
              .then(res => {

                vm.loading = '';
                vm.reload();

                var msg = res.data.data.message;

                vm.$swal.fire(
                    'Submitted!',
                    msg,
                    'success'
                )

              })
              .catch(err => {

                vm.loading = '';

                vm.$swal.fire(
                    'Failed!',
                    err.response.data.data,
                    'error'
                )

                if (err.response) {

                  var message = "";

                  if(parseInt(err.response.status) === 428 ) {

                    message = err.response.data.message;
                    vm.setError("Failed", message)
                    return;
                  }

                  if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                    vm.setError("Session Expired", "Your session on this device has expired");
                    vm.logout();
                    return;

                  } else {

                    message =  err.response.data.message;
                    vm.setError("Failed", message)

                  }

                } else if (err.request) {

                  vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err.request));

                } else {

                  //vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err));

                }
              })
        } else {

          vm.cashoutLoading = '';

        }

      })

    },

  },
}

</script>