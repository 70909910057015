<template>

    <div class="">

      <div class="container-fluid">
        <div class="card custom-card-wrapper">
          <div class="card-header card-body-header-lg p-2 pb-0 d-flex justify-content-between shadow-sm">
            <div class=" d-flex justify-content-left ">
            <span class="font-weight-500 men-item underline-item mr-2 active">
                Timesheet
            </span>
              <span class="font-weight-500 men-item underline-item mr-2" style="display: none">
                 <router-link to="/worksheets/attendance" class="">Mark Attendance</router-link>
              </span>

            </div>

            <div class="input-group blue-input-group mb-2">
              <button class="btn blue-input-group-btn-left btn-outline-primary dropdown-toggle font-size-12" type="button" data-bs-toggle="dropdown" aria-expanded="false">Filter By Status</button>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" @click="searchStatus(1)">Open Worksheet</a></li>
                <li><a class="dropdown-item"  @click="searchStatus(4)">Clossed Worksheet</a></li>
              </ul>
              <input type="text" class="form-control" aria-label="Text input with 2 dropdown buttons" v-model="search" v-on:keyup="searchMember">
              <button class="btn blue-input-group-btn-right btn-outline-primary  font-size-12" type="button" @click="searchMember"><i class="bi bi-search"></i></button>
            </div>
          </div>
          <div class="card-body p-0">

            <div class="card-body-subheader p-2 d-flex justify-content-between border-bottom">
              <span class="text-success"> </span>
              <div class="font-weight-500 pt-1">
                <button data-bs-toggle="modal" data-bs-target="#create-worksheet" class="primary-btn font-size-12">
                  CREATE TIMESHEET
                </button>
              </div>
            </div>

            <vuetable
                ref="vuetable"
                api-url="request/table"
                :api-mode="apiMode"
                :fields="fields"
                :sort-order="sortOrder"
                :css="css.table"
                pagination-path=""
                :per-page="perPage"
                :append-params="moreParams"
                :http-fetch="fetchMembers"
                :load-on-start="loadOnStart"
                @vuetable:pagination-data="onPaginationData"
                @vuetable:loading="onLoading"
                @vuetable:loaded="onLoaded">

              <div slot="actions-slot" slot-scope="props">
                <div class="btn-group">

                  <button type="button" class="btn bg-green btn-sm" @click="viewPayment(props.rowData)">
                    Payment
                  </button>

                  <button type="button" class="btn bg-success btn-sm" @click="viewPayments(props.rowData)">
                    Payments
                  </button>

                  <button type="button" class="btn btn-primary btn-sm" @click="viewMembersAttendance(props.rowData)">
                    Attendance
                  </button>

                  <button type="button" class="btn btn-secondary btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    More
                  </button>


                  <ul class="dropdown-menu">

                    <li><a class="dropdown-item" @click="createMember(props.rowData)">Add Members</a></li>

                    <li><a class="dropdown-item" @click="viewMembers(props.rowData)">View</a></li>

                    <li><a class="dropdown-item" @click="updateWorksheet(props.rowData)" data-bs-toggle="modal" data-bs-target="#create-worksheet" >Update</a></li>

                    <li><a class="dropdown-item" @click="deleteSheet(props.rowData)">Delete</a></li>

                  </ul>

                </div>
              </div>

              <div slot="sequence-slot" slot-scope="props">
                {{ props.rowIndex + 1}}
              </div>

              <div slot="format-start-date-slot" slot-scope="props">
                {{ formatDate(props.rowData.start_date) }}
              </div>

              <div slot="format-end-date-slot" slot-scope="props">
                {{ formatDate(props.rowData.end_date) }}
              </div>

              <div slot="format-currency-slot" slot-scope="props">
                {{ formatCurrency(props.rowData.amount) }} Ksh
              </div>

            </vuetable>

            <vuetable-pagination
                ref="pagination"
                :css="css.pagination"
                @vuetable-pagination:change-page="onChangePage">
            </vuetable-pagination>

          </div>
        </div>
      </div>

      <!-- Modal -->
      <div class="modal fade" id="create-worksheet" tabindex="-1" aria-labelledby="create-worksheet" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel"><span v-text="action"></span> Timesheet</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form class="custom-form">
                <div class="input-wrapper mb-2">

                  <div class="mb-3">
                    <label for="worksheet-name" class="form-label">Timesheet Name</label>
                    <input type="text" class="form-control" id="worksheet-name" v-model="name">
                  </div>

                  <div class="mb-3" style="display: none">
                    <label for="exampleInputEmail1" class="form-label">Payment Group </label>
                    <select class="form-select" aria-label="Default select example">
                      <option value="1">ABC Bank</option>
                    </select>
                  </div>

                  <div class="row">

                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="start-date" class="form-label">Start Time</label>
                        <input type="date" class="form-control" id="start-date" v-model="start_date">
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="end-date" class="form-label">End Time</label>
                        <input type="date" class="form-control" id="end-date" v-model="end_date">
                      </div>
                    </div>

                  </div>

                </div>


                <div class="modal-footer-copy">
                  <button type="button" class="btn btn-outline-secondary btn-sm mr-2" data-bs-dismiss="modal" id="close-create-worksheet">Close</button>
                  <button type="button" class="btn btn-primary btn-sm shadow" @click="createWorksheet">Save</button>
                </div>
              </form>
            </div>

          </div>
        </div>
      </div>

    </div>

</template>

<script>

import Vue from 'vue'
import axios from "@/services/api";
import Vuetable from 'vuetable-2'
import VuetableFieldSequence from "vuetable-2/src/components/VuetableFieldSequence";

import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import moment from "moment";
Vue.use(Vuetable);

var moreParams = {};
moreParams.route = 'worksheet/table';

export default {
  name: 'worksheet',
  components: {
    Vuetable,
    'vuetable-pagination': VuetablePagination
  },
  data: function (){
    return {
      mqttClient:'',
      active_tab: 'account',
      name: '',
      start_date: '',
      end_date: '',
      action: 'Create',
      worksheet: {},

      // vuetable
      search: '',
      fields: [
        {
          name: VuetableFieldSequence,
          title: '#',
        },
        {
          name: 'name',
          title: '<span class="orange glyphicon glyphicon-credit-card"></span> Name',
          sortField: 'worksheet.name',
        },
        {
          name: 'members',
          title: '<span class="orange glyphicon glyphicon-bookmark"></span> Members',
          sortField: 'members'
        },
        {
          name: "format-start-date-slot",
          title: '<span class="orange glyphicon glyphicon-calendar"></span> Start Date',
          sortField: 'worksheet.start_date',
        },
        {
          name: "format-end-date-slot",
          title: '<span class="orange glyphicon glyphicon-calendar"></span> End Date',
          sortField: 'worksheet.end_date',
        },
        {
          name: 'format-currency-slot',
          title: 'Total Payment Due',
          sortField: 'total',
        },
        {
          name: "actions-slot",
          title: 'Actions',
          titleClass: "center aligned",
          dataClass: "text-right"
        }
      ],
      moreParams: moreParams,
      css: {
        table: {
          tableClass: 'table table-striped table-bordered table-hovered',
          loadingClass: 'loading',
          ascendingIcon: 'glyphicon glyphicon-chevron-up',
          descendingIcon: 'glyphicon glyphicon-chevron-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger',
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination pull-right',
          activeClass: 'bg-green',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: '',
          },
        }
      },
      sortOrder: [
        { field: 'worksheet.id', direction: 'desc' }
      ],
      perPage: parseInt(5),
      loadOnStart: true,
      apiMode: true,
      loading: '',
      status: -1,
    }
  },
  mounted() {

    this.$store.dispatch("setCurrentPage","worksheets");
    this.$store.dispatch("setCurrentPageName","Timesheet");
    this.$store.dispatch("setPreviousPage","dashboard");

  },
  computed: {

  },
  methods: {

    dateFormat: function (date){

      return this.dateFormat(date)

    },

    searchMember: function (){

      if(this.search.length < 2 && this.search.length !== 0 ) {

        this.setError("invalid fields","please type atleast 3 characters to search")
        return
      }

      this.moreParams.search = this.search;
      this.$refs.vuetable.refresh();

    },

    searchStatus: function (status){

      if(parseInt(status) > 0 ) {

        this.setError("invalid fields","please select status to filter")
        return
      }

      this.status = parseInt(status);

      this.moreParams.status = this.parseInt(this.status);
      this.$refs.vuetable.refresh();

    },

    reload: function() {

      this.moreParams.search = '';
      this.moreParams.status = '-1';
      this.$refs.vuetable.refresh();
    },

    onPaginationData: function(paginationData) {

      this.$refs.pagination.setPaginationData(paginationData)
    },

    onChangePage: function(page) {

      this.$refs.vuetable.changePage(page)

    },

    delete: function(rowData) {

      console.log('TO delete '+JSON.stringify(rowData))

    },

    editRow: function(rowData){

      alert("You clicked edit on"+ JSON.stringify(rowData))
    },

    deleteRow: function(rowData){

      alert("You clicked delete on"+ JSON.stringify(rowData))

    },

    onLoading: function() {

      console.log('loading... show your spinner here')

    },

    onLoaded: function() {

      console.log('loaded! .. hide your spinner here')

    },

    fetchMembers: function(apiUrl, httpOptions){

      var vm = this;
      var path = process.env.VUE_APP_URL_WORKSHEET_VIEW_TABLE;

      return axios.post(path, JSON.stringify(httpOptions.params), {
        headers: {
          'api-key': vm.getAuth()
        },
      })
    },

    getPaymentMethod: function (paymentMethod){

      if(parseInt(paymentMethod) === 1 ) {

        return 'MPESA';

      }
      return  'BANK';
    },

    formatAmount: function (amount){

      return this.formatStake(amount);

    },

    updateWorksheet: function(worksheet){

      this.worksheet = worksheet;
      this.name = worksheet.name;

      var fm = 'YYYY-MM-DD';
      this.start_date = moment(worksheet.start_date,fm).format('YYYY-MM-DD');
      this.end_date = moment(worksheet.end_date,fm).format('YYYY-MM-DD');
      this.action = 'Update';

    },

    createWorksheet: function (){

      var vm = this;

      if (!this.name) {

        this.setError("Invalid name", "Please enter a Timesheet valid name");
        vm.$swal.fire(
            'Error!',
            "Please enter a Timesheet valid name",
            'error'
        )
        return;
      }

      if (!this.start_date) {

        this.setError("Invalid start date", "Please enter a Timesheet start date");
        vm.$swal.fire(
            'Error!',
            "Please enter a Timesheet valid start date",
            'error'
        )
        return;
      }

      if (!this.end_date) {

        this.setError("Invalid end date", "Please enter a Timesheet end date");
        vm.$swal.fire(
            'Error!',
            "Please enter a Timesheet valid end date",
            'error'
        )
        return;
      }

      var path = process.env.VUE_APP_URL_WORKSHEET_CREATE;

      if(this.action ==='Update') {

        path = process.env.VUE_APP_URL_WORKSHEET_UPDATE.replace(":id", this.worksheet.id);

      }

      this.loading = 'loading';

      var data = {
        name: this.name,
        start: this.start_date,
        end: this.end_date,
      };

      axios.post(path, JSON.stringify(data),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            vm.closeModal('create-worksheet');
            var msg = res.data.data.message;
            vm.setSuccess("Success", msg);
            vm.action = 'Create';

            vm.$swal.fire(
                'Submitted!',
                msg,
                'success'
            )
            vm.reload();
          })
          .catch(err => {

            vm.loading = '';

            console.log('FAILED response '+JSON.stringify(err.response.data.data))

            if (err.response) {
              vm.setError("Failed",err.response.data.data)
            }
            else if (err.request) {
              vm.setError("Network Error", "Check your network connection and try again")
            }
            else {
              vm.setError("Network Error", "Check your network connection and try again")
            }
          })

    },

    viewMembersAttendance: function (worksheet){

      this.$store.dispatch("setWorksheet",worksheet);
      this.$router.push({ name: 'attendance', params: {id: worksheet.id, name:worksheet.name} });

    },

    viewPayment: function (worksheet){

      this.$store.dispatch("setWorksheet",worksheet);
      this.$router.push({ name: 'worksheetpayment', params: {id: worksheet.id} });

    },

    viewPayments: function (worksheet){

      this.$store.dispatch("setWorksheet",worksheet);
      this.$router.push({ name: 'worksheetpayments', params: {id: worksheet.id} });

    },

    createMember: function (worksheet){

      this.$store.dispatch("setWorksheet",worksheet);
      this.$router.push({ name: 'worksheetcreatemembers', params: {id: worksheet.id, name:worksheet.name} });

    },

    viewMembers: function (worksheet){

      this.$store.dispatch("setWorksheet",worksheet);
      this.$router.push({ name: 'worksheetviewmembers', params: {id: worksheet.id, name:worksheet.name} });

    },

    deleteMember: function(data) {

      var vm = this;

      vm.$swal.fire({
        title: "Do you want to delete. "+data.recipient_name+' from the list?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete'

      }).then((result) => {

        if (result.isConfirmed) {

          var path = process.env.VUE_APP_URL_PAYMENT_GROUP_MEMBER_DELETE.replace(":id", data.id);
          vm.loading = 'loading';

          axios.post(path, JSON.stringify({}),{
            headers: {
              'api-key': vm.getAuth()
            },
          })
              .then(res => {

                vm.loading = '';
                vm.reload();

                var msg = res.data.data.message;

                vm.$swal.fire(
                    'Submitted!',
                    msg,
                    'success'
                )

              })
              .catch(err => {

                vm.loading = '';

                vm.$swal.fire(
                    'Failed!',
                    err.response.data.data,
                    'error'
                )

                if (err.response) {

                  var message = "";

                  if(parseInt(err.response.status) === 428 ) {

                    message = err.response.data.message;
                    vm.setError("Failed", message)
                    return;
                  }

                  if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                    vm.setError("Session Expired", "Your session on this device has expired");
                    vm.logout();
                    return;

                  } else {

                    message =  err.response.data.message;
                    vm.setError("Failed", message)

                  }

                } else if (err.request) {

                  vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err.request));

                } else {

                  //vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err));

                }
              })
        } else {

          vm.cashoutLoading = '';

        }

      })

    },

    changeAmount: async function(data){

      var vm = this;

      const { value: amount } = await vm.$swal.fire({
        title: 'Enter new amount for '+data.recipient_name,
        input: 'number',
        inputLabel: 'Amount',
        inputPlaceholder: 'Enter new amount',
        inputAttributes: {
          min: 100,
          max: 70000
        }
      })

      if (amount) {

        var path = process.env.VUE_APP_URL_PAYMENT_GROUP_MEMBER_UPDATE_AMOUNT.replace(":id", data.id);
        vm.loading = 'loading';

        axios.post(path, JSON.stringify({amount: parseInt(amount)}),{
          headers: {
            'api-key': vm.getAuth()
          },
        })
            .then(res => {

              vm.loading = '';
              vm.reload();

              var msg = res.data.data.message;

              vm.$swal.fire(
                  'Submitted!',
                  msg,
                  'success'
              )

            })
            .catch(err => {

              vm.loading = '';

              vm.$swal.fire(
                  'Failed!',
                  err.response.data.data,
                  'error'
              )

              if (err.response) {

                var message = "";

                if(parseInt(err.response.status) === 428 ) {

                  message = err.response.data.message;
                  vm.setError("Failed", message)
                  return;
                }

                if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                  vm.setError("Session Expired", "Your session on this device has expired");
                  vm.logout();
                  return;

                } else {

                  message =  err.response.data.message;
                  vm.setError("Failed", message)

                }

              } else if (err.request) {

                vm.setError("Failed", "Please check your network")
                console.log(JSON.stringify(err.request));

              } else {

                //vm.setError("Failed", "Please check your network")
                console.log(JSON.stringify(err));

              }
            })

      }

    },

    editMember: function (data) {

      this.member = data;

      var names = [];

      if(data.recipient_name){

        names = data.recipient_name.split(' ');

      }

      if(names.length > 0) {

        this.member.first_name = names[0];
      }

      if(names.length > 1) {

        this.member.middle_name = names[1];
      }

      if(names.length > 2) {

        this.member.last_name = names.slice(2).join(" ");
      }

    },

    updateMember: function (){

      if (!this.member.account) {

        this.setError("Invalid Account", "Please enter a account");
        return;
      }

      if (parseInt(this.member.amount) < 100 ) {

        this.setError("Invalid Amount", "Please enter a amount atleast 100");
        return;
      }

      if (!this.member.first_name) {

        this.setError("Invalid First Name", "Please enter First Name");
        return;
      }

      if (!this.member.middle_name) {

        this.setError("Invalid Middle Name", "Please enter Middle Name");
        return;
      }

      if (!this.member.last_name) {

        this.setError("Invalid Last Name", "Please enter Last Name");
        return;
      }

      if (parseInt(this.member.id_number) < 1000000 ) {

        this.setError("Invalid ID Number", "Please enter a valid ID Number");
        return;
      }

      var paybill = 3016903;

      if (this.member.recipient_type === 2 ) {

        paybill = this.bank_paybill;

      }

      var vm = this;

      var data = {
        id_number: parseInt(this.member.id_number),
        payment_type: parseInt(this.member.payment_method),
        first_name: this.member.first_name,
        middle_name: this.member.middle_name,
        last_name: this.member.last_name,
        amount: parseInt(this.member.amount),
        paybill: parseInt(paybill),
        account_number: this.member.account
      };

      var path = process.env.VUE_APP_URL_PAYMENT_GROUP_MEMBER_UPDATE.replace(":id", this.member.id);
      vm.loading = 'loading';

      axios.post(path, JSON.stringify(data),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            vm.reload();
            vm.closeModal('update-member-details');

            var msg = res.data.data.message;

            vm.$swal.fire(
                'Submitted!',
                msg,
                'success'
            )

          })
          .catch(err => {

            vm.loading = '';

            vm.$swal.fire(
                'Failed!',
                err.response.data.data,
                'error'
            )

            if (err.response) {

              var message = "";

              if(parseInt(err.response.status) === 428 ) {

                message = err.response.data.message;
                vm.setError("Failed", message)
                return;
              }

              if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();
                return;

              } else {

                message =  err.response.data.message;
                vm.setError("Failed", message)

              }

            } else if (err.request) {

              vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err.request));

            } else {

              //vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err));

            }
          })
    },

    deleteSheet: function(data) {

      var vm = this;

      vm.$swal.fire({
        text: "Do you want to delete. "+data.name+'?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete'

      }).then((result) => {

        if (result.isConfirmed) {

          var endpoint = process.env.VUE_APP_URL_WORKSHEET_DELETE;
          console.log('endpoint ==> '+endpoint);

          var path = endpoint.replace(":id", data.id);
          vm.loading = 'loading';

          axios.post(path, JSON.stringify({}),{
            headers: {
              'api-key': vm.getAuth()
            },
          })
              .then(res => {

                vm.loading = '';
                vm.reload();

                var msg = res.data.data.message;

                vm.$swal.fire(
                    'Submitted!',
                    msg,
                    'success'
                )

              })
              .catch(err => {

                vm.loading = '';

                vm.$swal.fire(
                    'Failed!',
                    err.response.data.data,
                    'error'
                )

                if (err.response) {

                  var message = "";

                  if(parseInt(err.response.status) === 428 ) {

                    message = err.response.data.message;
                    vm.setError("Failed", message)
                    return;
                  }

                  if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                    vm.setError("Session Expired", "Your session on this device has expired");
                    vm.logout();
                    return;

                  } else {

                    message =  err.response.data.message;
                    vm.setError("Failed", message)

                  }

                } else if (err.request) {

                  vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err.request));

                } else {

                  //vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err));

                }
              })
        } else {

          vm.cashoutLoading = '';

        }

      })

    },

    updatePaymentGroup: function (){

      if (!this.paymentgroup.name) {

        this.setError("Invalid name", "Please enter a valid name");
        return;
      }

      var vm = this;

      var path = process.env.VUE_APP_URL_PAYMENT_GROUP_UPDATE.replace(":id", this.paymentgroup.id);
      vm.loading = 'loading';

      axios.post(path, JSON.stringify(this.paymentgroup),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            vm.closeModal('update-payment-group');
            var msg = res.data.data.message;
            vm.$swal.fire(
                'Submitted!',
                msg,
                'success'
            )

            vm.getPaymentGroup();

          })
          .catch(err => {

            vm.loading = '';

            vm.$swal.fire(
                'Failed!',
                err.response.data.data,
                'error'
            )

            if (err.response) {

              var message = "";

              if(parseInt(err.response.status) === 428 ) {

                message = err.response.data.message;
                vm.setError("Failed", message)
                return;
              }

              if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();
                return;

              } else {

                message =  err.response.data.message;
                vm.setError("Failed", message)

              }

            } else if (err.request) {

              vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err.request));

            } else {

              //vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err));

            }

          })
    },

    makePayment: function(data) {

      var vm = this;

      vm.$swal.fire({
        title: 'Confirm',
        text: "Do you want to make payment for "+data.name+' a total of '+vm.formatCurrency(data.amount)+' Ksh?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Make Payment'

      }).then((result) => {

        if (result.isConfirmed) {

          var path = process.env.VUE_APP_URL_WORKSHEET_APPROVE.replace(":id",data.id);
          vm.loading = 'loading';

          axios.post(path, JSON.stringify({description: 'payment approved'}),{
            headers: {
              'api-key': vm.getAuth()
            },
          })
              .then(res => {

                vm.loading = '';
                vm.reload();

                var msg = res.data.data.message;

                vm.$swal.fire(
                    'Submitted!',
                    msg,
                    'success'
                )

              })
              .catch(err => {

                vm.loading = '';

                vm.$swal.fire(
                    'Failed!',
                    err.response.data.data,
                    'error'
                )

                if (err.response) {

                  var message = "";

                  if(parseInt(err.response.status) === 428 ) {

                    message = err.response.data.message;
                    vm.setError("Failed", message)
                    return;
                  }

                  if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                    vm.setError("Session Expired", "Your session on this device has expired");
                    vm.logout();
                    return;

                  } else {

                    message =  err.response.data.message;
                    vm.setError("Failed", message)

                  }

                } else if (err.request) {

                  vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err.request));

                } else {

                  //vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err));

                }
              })
        } else {

          vm.cashoutLoading = '';

        }

      })

    },

  },
}

</script>