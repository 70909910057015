<template>

    <div class="">

      <div class="container-fluid" v-bind:class="loading">

        <div class="row">

          <div class="col-md-4">

            <div  class="card custom-card-wrapper" style="margin-bottom: 10px;">

              <div class="card-header shadow-sm p-2 d-flex justify-content-between shadow-sm">

                <div>
                  <button type="button" class="btn btn-secondary btn-sm" @click="goBack">
                    <i class="bi bi-arrow-left"></i> Go Back
                  </button>
                </div>

                <div>
                  <a data-bs-toggle="modal" data-bs-target="#create-worksheet" class="primary-btn " ><i class="bi bi-plus-circle-fill"></i> Create Payment</a>
                </div>

              </div>

              <div v-if="active_payroll > 0" class="card-header">
                Active Approvals <span class="badge rounded-pill bg-primary" v-text="active_payroll"></span>
              </div>

              <div v-if="active_payroll > 0" class="card-body p-0">

                <table class="table table-striped">
                  <thead class=" ">
                    <tr>
                      <th>Date</th>
                      <th>Members</th>
                      <th>Status</th>
                      <th class="text-right">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(group,index) in activegroups" v-bind:key="getKey(index)"  @click="setPaymentGroup(group,index)">
                      <td v-text="getDates(group)"></td>
                      <td v-text="group.total_members"></td>
                      <td v-html="getGroupStatus(group.status)"></td>
                      <td class="text-right">
                        <div class="btn-group">
                          <button type="button" class="btn btn-primary btn-sm" @click="updateWorksheetPayment(group)" data-bs-toggle="modal" data-bs-target="#create-worksheet" style="color: white">
                            <i class="bi-pencil"></i>
                          </button>
                          <button type="button" class="btn btn-danger btn-sm" @click="deleteWorksheetPayment(group)" style="color: white">
                            <i class="bi-x"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>

            </div>

            <div v-if="pending_payroll > 0" class="card custom-card-wrapper"  style="margin-bottom: 10px;">

              <div class="card-header">
                Pending Approvals <span class="badge rounded-pill bg-secondary" v-text="pending_payroll"></span>
              </div>

              <div class="card-body p-0">

                <table class="table table-striped">
                  <thead class=" ">
                  <tr>
                    <th>Date</th>
                    <th>Members</th>
                    <th>Status</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(group,index) in pendinggroups" v-bind:key="getKey(index)"  @click="setPaymentGroup(group,index)">
                    <td v-text="getDates(group)"></td>
                    <td v-text="group.total_members"></td>
                    <td v-html="getGroupStatus(group.status)"></td>
                  </tr>
                  </tbody>
                </table>

              </div>
            </div>

            <div v-if="pending_payment > 0" class="card custom-card-wrapper"  style="margin-bottom: 10px;">

              <div class="card-header">
                Pending Payment <span class="badge rounded-pill bg-warning" v-text="pending_payment"></span>
              </div>

              <div class="card-body p-0">

                <table class="table table-striped">
                  <thead class=" ">
                  <tr>
                    <th>Date</th>
                    <th>Members</th>
                    <th>Status</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(group,index) in pendingpaymentgroups" v-bind:key="getKey(index)"  @click="setPaymentGroup(group,index)">
                    <td v-text="getDates(group)"></td>
                    <td v-text="group.total_members"></td>
                    <td v-html="getGroupStatus(group.status)"></td>
                  </tr>
                  </tbody>
                </table>

              </div>

            </div>

            <div v-if="close_payroll > 0" class="card custom-card-wrapper"  style="margin-bottom: 10px;">

              <div class="card-header">
                Completed Payment <span class="badge rounded-pill bg-success" v-text="close_payroll"></span>
              </div>

              <div class="card-body p-0">

                <table class="table table-striped">
                  <thead class=" ">
                  <tr>
                    <th>Date</th>
                    <th>Members</th>
                    <th>Status</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(group,index) in closedgroups" v-bind:key="getKey(index)"  @click="setPaymentGroup(group,index)">
                    <td v-text="getDates(group)"></td>
                    <td v-text="group.total_members"></td>
                    <td v-html="getGroupStatus(group.status)"></td>
                  </tr>
                  </tbody>
                </table>

              </div>

            </div>

          </div>

          <div class="col-md-8">

            <div class="card custom-card-wrapper">

              <div class="card-header line-height-1">
                <span v-text="getName(paymentgroup)"></span> <br> <small class="text-success text-small font-weight-500"> members</small>
              </div>

              <div class="card-body p-0">

                <div class="card-body-header-lg p-2 d-flex justify-content-between border-bottom">

                  <div class="font-weight-500 pt-1">

                    <button v-if="parseInt(paymentgroup.status) === 0" type="button" class="btn btn-primary btn-sm m-1" data-bs-toggle="modal" data-bs-target="#update-payment-group">
                      <i class="bi bi-pencil"></i> Edit
                    </button>

                    <button v-if="parseInt(paymentgroup.status) === 0" @click="deleteGroup(paymentgroup)" type="button" class="btn btn-danger btn-sm m-1">
                      <i class="bi bi-x"></i> Delete
                    </button>

                    <button v-if="parseInt(paymentgroup.status) === -2" type="button" class="btn btn-warning btn-sm m-1">
                      <i class="bi bi-recycle"></i> Resend
                    </button>

                    <button type="button" class="btn btn-secondary btn-sm m-1">
                      <i class="bi bi-download"></i> Export
                    </button>

                  </div>

                  <span class="text-success"> Total Amount: <span class="font-weight-500 text-success">KES. <span v-text="formatStake(paymentgroup.total_amount)"></span> </span></span>
                </div>

                <div class="card-body-subheader p-2 d-flex justify-content-between shadow-sm">
                  <div class="input-group mb-0">
                    <input type="text" class="form-control" placeholder="Search" aria-label="Recipient's Name" aria-describedby="button-addon2" v-model="search">
                    <button @click="searchMember"  class="btn btn-outline-secondary btn-sm" type="button" id="button-addon2"><i class="bi bi-search"></i></button>
                  </div>
                </div>

                <vuetable
                    ref="vuetable"
                    api-url="request/table"
                    :api-mode="apiMode"
                    :fields="fields"
                    :sort-order="sortOrder"
                    :css="css.table"
                    pagination-path=""
                    :per-page="perPage"
                    :append-params="moreParams"
                    :http-fetch="fetchMembers"
                    :load-on-start="loadOnStart"
                    @vuetable:row-clicked="onRowClicked"
                    @vuetable:pagination-data="onPaginationData"
                    @vuetable:loading="onLoading"
                    @vuetable:loaded="onLoaded">

                  <div slot="actions-slot" slot-scope="props">

                    <div class="btn-group" v-if="parseInt(paymentgroup.status) === 0 ">
                      <button type="button" class="btn btn-primary btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        More
                      </button>

                      <ul class="dropdown-menu">

                        <li>
                          <a class="dropdown-item" @click="deleteMember(props.rowData)">Remove Member</a>
                        </li>
                        <li>
                          <a class="dropdown-item" @click="changeAmount(props.rowData)">Update Amount</a>
                        </li>

                        <li>
                          <a class="dropdown-item" @click="editMember(props.rowData)" data-bs-toggle="modal" data-bs-target="#update-member-details">Update Details</a>
                        </li>

                      </ul>

                    </div>
                    <div class="btn-group" v-if="parseInt(paymentgroup.status) === -2 ">
                      <button type="button" class="btn btn-primary btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        More
                      </button>

                      <ul class="dropdown-menu">

                        <li>
                          <a class="dropdown-item" @click="deleteMember(props.rowData)">Resend</a>
                        </li>
                        <li>
                          <a class="dropdown-item" @click="deleteMember(props.rowData)">Remove Member</a>
                        </li>
                        <li>
                          <a class="dropdown-item" @click="changeAmount(props.rowData)">Update Amount</a>
                        </li>

                        <li>
                          <a class="dropdown-item" @click="editMember(props.rowData)" data-bs-toggle="modal" data-bs-target="#update-member-details">Update Details</a>
                        </li>

                      </ul>

                    </div>

                  </div>

                  <div slot="sequence-slot" slot-scope="props">
                    {{ props.rowIndex + 1}}
                  </div>

                  <div slot="payment-method-slot" slot-scope="props">
                    <span v-show="parseInt(props.rowData.payment_method) === 1">MPESA</span>
                    <span v-show="parseInt(props.rowData.payment_method) === 2">BANK</span>
                  </div>

                  <div slot="status-slot" slot-scope="props">
                    <span v-html="getStatus(props.rowData.status)"></span>
                  </div>

                  <div slot="amount-slot" slot-scope="props">
                    {{ formatCurrency(props.rowData.amount) }} Ksh
                  </div>

                  <div slot="payment-reference-slot" slot-scope="props">

                    <div v-if="parseInt(props.rowData.status) === -1">
                      <button type="button" class="btn btn-warning btn-sm">
                        <i class="bi bi-recycle"></i> Resend
                      </button>
                    </div>

                    <div v-else>
                      <span v-show="parseInt(props.rowData.payment_method) === 1">MPESA <span v-text="props.rowData.payment_reference"></span></span>
                      <span v-show="parseInt(props.rowData.payment_method) === 2">BANK <span v-text="props.rowData.payment_reference"></span></span>
                    </div>

                  </div>

                </vuetable>

                <vuetable-pagination
                    ref="pagination"
                    :css="css.pagination"
                    @vuetable-pagination:change-page="onChangePage">
                </vuetable-pagination>

              </div>

            </div>

          </div>

        </div>

      </div>

      <div class="modal fade" id="create-worksheet" tabindex="-1" aria-labelledby="create-worksheet" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">

            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel1"><span v-text="action"></span> Timesheet Payment</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

            <div class="modal-body">
              <form class="custom-form">
                <div class="input-wrapper mb-2">

                  <div class="row">
                    <div class="col-12">
                      <label>Select Payment Duration</label>
                      <select class="form-select form-select-sm" aria-label=".form-select-sm example" v-model="duration">
                        <option value="1">Pay all Days</option>
                        <option value="2">Pay Specific Days</option>
                      </select>
                    </div>
                  </div>

                  <div class="row" v-show="parseInt(duration) === 2">

                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="start-date" class="form-label">Start Date</label>
                        <input type="date" class="form-control" id="start-date" v-model="start_date">
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="end-date" class="form-label">End Date</label>
                        <input type="date" class="form-control" id="end-date" v-model="end_date">
                      </div>
                    </div>

                  </div>

                  <div class="row">
                    <div class="col-12">
                      <label>Select Payment Method</label>
                      <select class="form-select form-select-sm" aria-label=".form-select-sm example" v-model="payment_type">
                        <option value="1">M-PESA</option>
                        <option value="2">BANK</option>
                      </select>
                    </div>
                  </div>

                </div>

                <div class="modal-footer-copy">
                  <button type="button" class="btn btn-outline-secondary btn-sm mr-2" data-bs-dismiss="modal" id="close-create-worksheet">Close</button>
                  <button type="button" class="btn btn-primary btn-sm shadow" @click="createWorksheetPayment"><span v-text="action"></span> Payment</button>
                </div>
              </form>
            </div>

          </div>
        </div>
      </div>

    </div>

</template>

<script>

import Vue from 'vue'
import axios from "@/services/api";
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldSequence from "vuetable-2/src/components/VuetableFieldSequence";
import moment from "moment";
Vue.use(Vuetable);

var moreParams = {};
moreParams.status = -2

var activeGroupfields = [
      {
        name: VuetableFieldSequence,
        title: '#',
      },
      {
        name: 'recipient_name',
        title: '<span class="orange glyphicon glyphicon-credit-card"></span> Name',
        sortField: 'worksheet_member.recipient_name',
      },
      {
        name: 'id_number',
        title: '<span class="orange glyphicon glyphicon-bookmark"></span> ID No.',
        sortField: 'worksheet_member.id_number'
      },
      {
        name: "payment-method-slot",
        title: 'Method',
        sortField: 'worksheet_member.recipient_type',
      },
      {
        name: 'account',
        title: 'Acc No.',
        sortField: 'worksheet_member.account'
      },
      {
        name: 'amount-slot',
        title: 'Amount',
        sortField: 'worksheet_payment_schedule_member.amount',
      },
      {
        name: "status-slot",
        title: 'Status',
        sortField: 'worksheet_payment_schedule_member.status',
      },
      {
        name: "actions-slot",
        title: 'Actions',
        titleClass: "center aligned",
        dataClass: "text-right"
      }
    ];

var pendingApprovalGroupfields = [
  {
    name: VuetableFieldSequence,
    title: '#',
  },
  {
    name: 'recipient_name',
    title: '<span class="orange glyphicon glyphicon-credit-card"></span> Name',
    sortField: 'worksheet_member.recipient_name',
  },
  {
    name: 'id_number',
    title: '<span class="orange glyphicon glyphicon-bookmark"></span> ID No.',
    sortField: 'worksheet_member.id_number'
  },
  {
    name: "payment-method-slot",
    title: 'Method',
    sortField: 'worksheet_member.recipient_type',
  },
  {
    name: 'account',
    title: 'Acc No.',
    sortField: 'worksheet_member.account'
  },
  {
    name: 'amount-slot',
    title: 'Amount',
    sortField: 'worksheet_payment_schedule_member.amount',
  },
  {
    name: "status-slot",
    title: 'Status',
    sortField: 'worksheet_payment_schedule_member.status',
  }
];

var paymentGroupfields = [
  {
    name: VuetableFieldSequence,
    title: '#',
  },
  {
    name: 'recipient_name',
    title: '<span class="orange glyphicon glyphicon-credit-card"></span> Name',
    sortField: 'worksheet_member.recipient_name',
  },
  {
    name: 'id_number',
    title: '<span class="orange glyphicon glyphicon-bookmark"></span> ID No.',
    sortField: 'worksheet_member.id_number'
  },
  {
    name: 'account',
    title: 'Acc No.',
    sortField: 'worksheet_member.account'
  },
  {
    name: 'amount-slot',
    title: 'Amount',
    sortField: 'worksheet_payment_schedule_member.amount',
  },
  {
    name: "status-slot",
    title: 'Status',
    sortField: 'worksheet_payment_schedule_member.status',
  },
  {
    name: "payment-reference-slot",
    title: 'Reference',
  }
];

  export default {
  name: 'worksheetpayments',
  components: {
    Vuetable,
    'vuetable-pagination': VuetablePagination
  },
  data: function (){
    return {
      row_index: 0,
      active_tab: 'account',
      paymentgroups: [],
      paymentgroup: {
        end_date:"",
        start_date:"",
        total_amount:0,
        total_members:0
      },
      start_date: '',
      end_date: '',
      action: 'Create',
      payment: {},
      duration: 2,
      payment_type: 1,

      // vuetable
      search: '',
      fields: [
        {
          name: 'sequence-slot',
          title: '#',
        },
        {
          name: 'recipient_name',
          title: '<span class="orange glyphicon glyphicon-credit-card"></span> Name',
          sortField: 'worksheet_member.recipient_name',
        },
        {
          name: 'id_number',
          title: '<span class="orange glyphicon glyphicon-bookmark"></span> ID No.',
          sortField: 'worksheet_member.id_number'
        },
        {
          name: "payment-method-slot",
          title: 'Method',
          sortField: 'worksheet_member.recipient_type',
        },
        {
          name: 'account',
          title: 'Acc No.',
          sortField: 'worksheet_member.account'
        },
        {
          name: 'amount-slot',
          title: 'Amount',
          sortField: 'worksheet_payment_schedule_member.amount',
        },
        {
          name: "status-slot",
          title: 'Status',
          sortField: 'worksheet_payment_schedule_member.status',
        },
        {
          name: "actions-slot",
          title: 'Actions',
          titleClass: "center aligned",
          dataClass: "text-right"
        }
      ],
      moreParams: moreParams,
      css: {
        table: {
          tableClass: 'table table-striped table-bordered table-hovered',
          loadingClass: 'loading',
          ascendingIcon: 'glyphicon glyphicon-chevron-up',
          descendingIcon: 'glyphicon glyphicon-chevron-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger',
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination pull-right',
          activeClass: 'bg-green',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: '',
          },
        }
      },
      sortOrder: [
        { field: 'worksheet_payment_schedule_member.id', direction: 'asc' }
      ],
      perPage: parseInt(5),
      loadOnStart: false,
      apiMode: true,
      name: '',
      loading: ''
    }
  },
  mounted: function() {

    this.$store.dispatch("setCurrentPage","worksheets");
    this.$store.dispatch("setCurrentPageName","Worksheet");
    this.$store.dispatch("setPreviousPage","worksheet");
    this.getPaymentGroup();

  },
  computed: {

    active_payroll: function (){

      var count = 0;

      this.jQuery.each(this.paymentgroups,function (k,v){

        if(parseInt(v.payment_status) === 0 ) {

          count++
        }

      })

      return count;

    },

    pending_payroll: function (){

      var count = 0;

      this.jQuery.each(this.paymentgroups,function (k,v){

        if(parseInt(v.payment_status) === 1 ) {

          count++
        }
      })

      return count;

    },

    pending_payment: function (){

      var count = 0;

      this.jQuery.each(this.paymentgroups,function (k,v){

        if(parseInt(v.payment_status) === 2 ) {

          count++
        }
      })

      return count;

    },

    close_payroll: function (){

      var count = 0;

      this.jQuery.each(this.paymentgroups,function (k,v){

        if(parseInt(v.payment_status) > 2 ) {

          count++
        }
      })

      return count;

    },

    activegroups: function (){

      var count = [];

      this.jQuery.each(this.paymentgroups,function (k,v){

        if(parseInt(v.payment_status) === 0 ) {

          count.push(v)
        }
      })

      return count;

    },

    pendinggroups: function (){

      var count = [];

      this.jQuery.each(this.paymentgroups,function (k,v){

        if(parseInt(v.payment_status) === 1 ) {

          count.push(v)
        }
      })

      return count;

    },

    pendingpaymentgroups: function (){

      var count = [];

      this.jQuery.each(this.paymentgroups,function (k,v){

        if(parseInt(v.payment_status) === 2 ) {

          count.push(v)
        }
      })

      return count;

    },

    closedgroups: function (){

      var count = [];

      this.jQuery.each(this.paymentgroups,function (k,v){

        if(parseInt(v.payment_status) > 2 ) {

          count.push(v)
        }
      })

      return count;

    },

    worksheet: function () {

      return this.$store.state.worksheet;

    },

  },
  methods: {

    getDates:function (group){

      if(!group || group === undefined ) {

        return  ''
      }

      if(!group.start_date) {

        return  ''
      }

      if(!group.end_date) {

        return  ''
      }

      return this.formatDateWithoutYear(group.start_date) + ' - '+ this.formatDateWithoutYear(group.end_date);

    },

    getName: function (group){

      if(!group || group === undefined ) {

        return  ''
      }

      if(!group.start_date) {

        return  ''
      }

      if(!group.end_date) {

        return  ''
      }

      return this.formatDateShort(group.start_date) + ' To '+ this.formatDateShort(group.end_date);

    },

    getGroupStatus: function (status){

      if(parseInt(status) === 0) {

        return '<span class="fw-bold text-secondary">Pending</span>'
      }

      if(parseInt(status) === 1) {

        return '<span class="fw-bold text-secondary">Pending Approval</span>'
      }

      if(parseInt(status) === 2) {

        return '<span class="fw-bold text-warning">Payment Initiated</span>'
      }

      if(parseInt(status) === -1) {

        return '<span class="fw-bold text-danger">Payment Failed</span>'
      }

      if(parseInt(status) === -2) {

        return '<span class="fw-bold text-danger">Insufficient Balance</span>'

      }

      return '<span class="fw-bold text-success">Payment Completed</span>'

    },

    getStatus: function (status){

      if(parseInt(status) === 0) {

        return '<span class="fw-bold text-secondary">Waiting Approval</span>'
      }

      if(parseInt(status) === 1) {

        return '<span class="fw-bold text-warning">Payment Initiated</span>'
      }

      if(parseInt(status) === -1) {

        return '<span class="fw-bold text-danger">Payment Failed</span>'

      }

      return '<span class="fw-bold text-success">Payment Successful</span>'

    },

    searchMember: function (){

      if(this.search.length < 2 ) {

        this.setError("invalid fields","please type atleast 3 characters to search")
        return
      }

      this.moreParams.search = this.search;
      this.$refs.vuetable.refresh();

    },

    reload: function() {

      this.moreParams.search = '';
      this.$refs.vuetable.refresh();
    },

    onPaginationData: function(paginationData) {

      this.$refs.pagination.setPaginationData(paginationData)
    },

    onChangePage: function(page) {

      this.$refs.vuetable.changePage(page)

    },

    onRowClicked: function (rowData){

      console.log('event onRowClicked ==> '+JSON.stringify(rowData))

    },

    delete: function(rowData) {

      console.log('TO delete '+JSON.stringify(rowData))

    },

    editRow: function(rowData){

      alert("You clicked edit on"+ JSON.stringify(rowData))
    },

    deleteRow: function(rowData){

      alert("You clicked delete on"+ JSON.stringify(rowData))

    },

    onLoading: function() {

      console.log('loading... show your spinner here')

    },

    onLoaded: function() {

      this.loading = '';
      console.log('loaded! .. hide your spinner here')

    },

    fetchMembers: function(apiUrl, httpOptions){

      var vm = this;
      var endpoint = process.env.VUE_APP_URL_WORKSHEET_PAYMENT_SCHEDULE_MEMBERS_TABLE_VIEW;
      var path = endpoint.replace(":payment_id",parseInt(this.paymentgroup.id)).replace(":id",parseInt(this.worksheet.id));
      console.log('got fetchMembers paths '+path)

      vm.loading = 'loading';

      return axios.post(path, JSON.stringify(httpOptions.params), {
        headers: {
          'api-key': vm.getAuth()
        },
      })
    },

    getPaymentMethod: function (paymentMethod){

      if(parseInt(paymentMethod) === 1 ) {

        return 'MPESA';

      }
      return  'BANK';
    },

    formatAmount: function (amount){

      return this.formatStake(amount);

    },

    setPaymentGroup: function(group,index){

      this.paymentgroup = group;

      this.row_index = index;

      if(parseInt(this.paymentgroup.status) === 0) {

        this.fields = activeGroupfields

      }
      else if(parseInt(this.paymentgroup.status) === 1) {

        this.fields = pendingApprovalGroupfields

      }
      else if(parseInt(this.paymentgroup.status) === 2) {

        this.fields = paymentGroupfields

      }
      else if(parseInt(this.paymentgroup.status) === 3) {

        this.fields = paymentGroupfields

      }
      else if(parseInt(this.paymentgroup.status) === 4) {

        this.fields = paymentGroupfields

      }

      this.reload();

    },

    isCurrentRow: function(index) {

      return parseInt(index) === this.row_index ? 'bg-green text-light' : '';

    },

    getPaymentGroup: function(){

      var vm = this;

      var path = process.env.VUE_APP_URL_WORKSHEET_PAYMENT_VIEW_LIST.replace(":id",this.worksheet.id);

      axios.post(path, JSON.stringify({}),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            var msg = res.data.data;
            vm.paymentgroups = msg;
            vm.setPaymentGroup(vm.paymentgroups[0],0);

            console.log(' active_payroll => '+vm.active_payroll);
            //console.log(' pending_payment => '+vm.pending_payment);
            //console.log(' close_payroll => '+vm.close_payroll);
           // console.log(' pending_payroll => '+vm.pending_payroll);

          })
          .catch(err => {

            vm.loading = '';

            console.log('FAILED response '+JSON.stringify(err.response.data.data))

            if (err.response) {

              vm.setError("Failed",err.response.data.data)

            }
            else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")

            }
            else {

              vm.setError("Network Error", "Check your network connection and try again")

            }

          })

    },

    createPaymentGroup: function (){

      if (!this.name) {
        this.setError("Invalid name", "Please enter a valid name");
        return;
      }

      var vm = this;

      var path = process.env.VUE_APP_URL_PAYMENT_GROUP_CREATE;
      this.loading = 'loading';

      axios.post(path, JSON.stringify({name: this.name}),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            vm.closeModal('create-payment-group');
            var msg = res.data.data.message;
            vm.setSuccess("Success", msg);
            vm.getPaymentGroup();

          })
          .catch(err => {

            vm.loading = '';

            console.log('FAILED response '+JSON.stringify(err.response.data.data))

            if (err.response) {
              vm.setError("Failed",err.response.data.data)
            }
            else if (err.request) {
              vm.setError("Network Error", "Check your network connection and try again")
            }
            else {
              vm.setError("Network Error", "Check your network connection and try again")
            }
          })

    },

    createMember: function (){

      this.$router.push({ name: 'creategroup', params: {id: this.paymentgroup.id, name:this.paymentgroup.name} });

    },

    deleteMember: function(data) {

      var vm = this;

      vm.$swal.fire({
        title: "Do you want to delete. "+data.recipient_name+' from the list?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete'

      }).then((result) => {

        if (result.isConfirmed) {

          var path = process.env.VUE_APP_URL_PAYMENT_GROUP_MEMBER_DELETE.replace(":id", data.id);
          vm.loading = 'loading';

          axios.post(path, JSON.stringify({}),{
            headers: {
              'api-key': vm.getAuth()
            },
          })
              .then(res => {

                vm.loading = '';
                vm.reload();

                var msg = res.data.data.message;

                vm.$swal.fire(
                    'Submitted!',
                    msg,
                    'success'
                )

              })
              .catch(err => {

                vm.loading = '';

                vm.$swal.fire(
                    'Failed!',
                    err.response.data.data,
                    'error'
                )

                if (err.response) {

                  var message = "";

                  if(parseInt(err.response.status) === 428 ) {

                    message = err.response.data.message;
                    vm.setError("Failed", message)
                    return;
                  }

                  if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                    vm.setError("Session Expired", "Your session on this device has expired");
                    vm.logout();
                    return;

                  } else {

                    message =  err.response.data.message;
                    vm.setError("Failed", message)

                  }

                } else if (err.request) {

                  vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err.request));

                } else {

                  //vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err));

                }
              })
        } else {

          vm.cashoutLoading = '';

        }

      })

    },

    changeAmount: async function(data){

      var vm = this;

      const { value: amount } = await vm.$swal.fire({
        title: 'Enter new amount for '+data.recipient_name,
        input: 'number',
        inputLabel: 'Amount',
        inputPlaceholder: 'Enter new amount',
        inputAttributes: {
          min: 100,
          max: 70000
        }
      })

      if (amount) {

        var path = process.env.VUE_APP_URL_PAYMENT_GROUP_MEMBER_UPDATE_AMOUNT.replace(":id", data.id);
        vm.loading = 'loading';

        axios.post(path, JSON.stringify({amount: parseInt(amount)}),{
          headers: {
            'api-key': vm.getAuth()
          },
        })
            .then(res => {

              vm.loading = '';
              vm.reload();

              var msg = res.data.data.message;

              vm.$swal.fire(
                  'Submitted!',
                  msg,
                  'success'
              )

            })
            .catch(err => {

              vm.loading = '';

              vm.$swal.fire(
                  'Failed!',
                  err.response.data.data,
                  'error'
              )

              if (err.response) {

                var message = "";

                if(parseInt(err.response.status) === 428 ) {

                  message = err.response.data.message;
                  vm.setError("Failed", message)
                  return;
                }

                if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                  vm.setError("Session Expired", "Your session on this device has expired");
                  vm.logout();
                  return;

                } else {

                  message =  err.response.data.message;
                  vm.setError("Failed", message)

                }

              } else if (err.request) {

                vm.setError("Failed", "Please check your network")
                console.log(JSON.stringify(err.request));

              } else {

                //vm.setError("Failed", "Please check your network")
                console.log(JSON.stringify(err));

              }
            })

      }

    },

    editMember: function (data) {

      this.member = data;

      var names = [];

      if(data.recipient_name){

        names = data.recipient_name.split(' ');

      }

      if(names.length > 0) {

        this.member.first_name = names[0];
      }

      if(names.length > 1) {

        this.member.middle_name = names[1];
      }

      if(names.length > 2) {

        this.member.last_name = names.slice(2).join(" ");
      }

    },

    updateMember: function (){

      if (!this.member.account) {

        this.setError("Invalid Account", "Please enter a account");
        return;
      }

      if (parseInt(this.member.amount) < 100 ) {

        this.setError("Invalid Amount", "Please enter a amount atleast 100");
        return;
      }

      if (!this.member.first_name) {

        this.setError("Invalid First Name", "Please enter First Name");
        return;
      }

      if (!this.member.middle_name) {

        this.setError("Invalid Middle Name", "Please enter Middle Name");
        return;
      }

      if (!this.member.last_name) {

        this.setError("Invalid Last Name", "Please enter Last Name");
        return;
      }

      if (parseInt(this.member.id_number) < 1000000 ) {

        this.setError("Invalid ID Number", "Please enter a valid ID Number");
        return;
      }

      var paybill = 3016903;

      if (this.member.recipient_type === 2 ) {

        paybill = this.bank_paybill;

      }

      var vm = this;

      var data = {
        id_number: parseInt(this.member.id_number),
        payment_type: parseInt(this.member.payment_method),
        first_name: this.member.first_name,
        middle_name: this.member.middle_name,
        last_name: this.member.last_name,
        amount: parseInt(this.member.amount),
        paybill: parseInt(paybill),
        account_number: this.member.account
      };

      var path = process.env.VUE_APP_URL_PAYMENT_GROUP_MEMBER_UPDATE.replace(":id", this.member.id);
      vm.loading = 'loading';

      axios.post(path, JSON.stringify(data),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            vm.reload();
            vm.closeModal('update-member-details');

            var msg = res.data.data.message;

            vm.$swal.fire(
                'Submitted!',
                msg,
                'success'
            )

          })
          .catch(err => {

            vm.loading = '';

            vm.$swal.fire(
                'Failed!',
                err.response.data.data,
                'error'
            )

            if (err.response) {

              var message = "";

              if(parseInt(err.response.status) === 428 ) {

                message = err.response.data.message;
                vm.setError("Failed", message)
                return;
              }

              if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();
                return;

              } else {

                message =  err.response.data.message;
                vm.setError("Failed", message)

              }

            } else if (err.request) {

              vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err.request));

            } else {

              //vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err));

            }
          })
    },

    deleteGroup: function(data) {

      var vm = this;

      vm.$swal.fire({
        title: "Do you want to delete. "+data.name+'?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete'

      }).then((result) => {

        if (result.isConfirmed) {

          var path = process.env.VUE_APP_URL_PAYMENT_GROUP_DELETE.replace(":id", data.id);
          vm.loading = 'loading';

          axios.post(path, JSON.stringify({}),{
            headers: {
              'api-key': vm.getAuth()
            },
          })
              .then(res => {

                vm.loading = '';
                vm.getPaymentGroup();

                var msg = res.data.data.message;

                vm.$swal.fire(
                    'Submitted!',
                    msg,
                    'success'
                )

              })
              .catch(err => {

                vm.loading = '';

                vm.$swal.fire(
                    'Failed!',
                    err.response.data.data,
                    'error'
                )

                if (err.response) {

                  var message = "";

                  if(parseInt(err.response.status) === 428 ) {

                    message = err.response.data.message;
                    vm.setError("Failed", message)
                    return;
                  }

                  if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                    vm.setError("Session Expired", "Your session on this device has expired");
                    vm.logout();
                    return;

                  } else {

                    message =  err.response.data.message;
                    vm.setError("Failed", message)

                  }

                } else if (err.request) {

                  vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err.request));

                } else {

                  //vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err));

                }
              })
        } else {

          vm.cashoutLoading = '';

        }

      })

    },

    updatePaymentGroup: function (){

      if (!this.paymentgroup.name) {

        this.setError("Invalid name", "Please enter a valid name");
        return;
      }

      var vm = this;

      var path = process.env.VUE_APP_URL_PAYMENT_GROUP_UPDATE.replace(":id", this.paymentgroup.id);
      vm.loading = 'loading';

      axios.post(path, JSON.stringify(this.paymentgroup),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            vm.closeModal('update-payment-group');
            var msg = res.data.data.message;
            vm.$swal.fire(
                'Submitted!',
                msg,
                'success'
            )

            vm.getPaymentGroup();

          })
          .catch(err => {

            vm.loading = '';

            vm.$swal.fire(
                'Failed!',
                err.response.data.data,
                'error'
            )

            if (err.response) {

              var message = "";

              if(parseInt(err.response.status) === 428 ) {

                message = err.response.data.message;
                vm.setError("Failed", message)
                return;
              }

              if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();
                return;

              } else {

                message =  err.response.data.message;
                vm.setError("Failed", message)

              }

            } else if (err.request) {

              vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err.request));

            } else {

              //vm.setError("Failed", "Please check your network")
              console.log(JSON.stringify(err));

            }

          })
    },

    makePayment: function(data) {

      var vm = this;

      vm.$swal.fire({
        title: 'Confirm',
        text: "Do you want to initiate payment for "+data.name+' a total of '+vm.formatCurrency(data.amount)+' Ksh?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Initiate Payment'

      }).then((result) => {

        if (result.isConfirmed) {

          var path = process.env.VUE_APP_URL_PAYMENT_GROUP_APPROVAL_INITIATE.replace(":id",data.id);
          vm.loading = 'loading';

          axios.post(path, JSON.stringify({}),{
            headers: {
              'api-key': vm.getAuth()
            },
          })
              .then(res => {

                vm.loading = '';
                vm.reload();

                var msg = res.data.data.message;

                vm.$swal.fire(
                    'Submitted!',
                    msg,
                    'success'
                )

              })
              .catch(err => {

                vm.loading = '';

                vm.$swal.fire(
                    'Failed!',
                    err.response.data.data,
                    'error'
                )

                if (err.response) {

                  var message = "";

                  if(parseInt(err.response.status) === 428 ) {

                    message = err.response.data.message;
                    vm.setError("Failed", message)
                    return;
                  }

                  if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                    vm.setError("Session Expired", "Your session on this device has expired");
                    vm.logout();
                    return;

                  } else {

                    message =  err.response.data.message;
                    vm.setError("Failed", message)

                  }

                } else if (err.request) {

                  vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err.request));

                } else {

                  //vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err));

                }
              })
        } else {

          vm.cashoutLoading = '';

        }

      })

    },





    updateWorksheetPayment: function(payment){

      this.payment = payment;

      var fm = 'YYYY-MM-DD';
      this.start_date = moment(payment.start_date,fm).format('YYYY-MM-DD');
      this.end_date = moment(payment.end_date,fm).format('YYYY-MM-DD');
      this.action = 'Update';

    },

    createWorksheetPayment: function (){

      var vm = this;

      if (!this.start_date) {

        this.setError("Invalid start date", "Please enter a Timesheet start date");
        vm.$swal.fire(
            'Error!',
            "Please enter a Timesheet valid start date",
            'error'
        )
        return;
      }

      if (!this.end_date) {

        this.setError("Invalid end date", "Please enter a Timesheet end date");
        vm.$swal.fire(
            'Error!',
            "Please enter a Timesheet valid end date",
            'error'
        )
        return;
      }

      var path = process.env.VUE_APP_URL_WORKSHEET_PAYMENT_CREATE.replace(":id",this.worksheet.id);

      if(this.action ==='Update') {

        path = process.env.VUE_APP_URL_WORKSHEET_PAYMENT_UPDATE.replace(":id", this.worksheet.id).replace(":payment_id",vm.payment.id);

      }

      this.loading = 'loading';

      var data = {
        start: this.start_date,
        end: this.end_date,
        payment_type: this.payment_type
      };

      axios.post(path, JSON.stringify(data),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            vm.closeModal('create-worksheet');
            var msg = res.data.data.message;
            vm.setSuccess("Success", msg);
            vm.action = 'Create';

            vm.$swal.fire(
                'Submitted!',
                msg,
                'success'
            )
            vm.getPaymentGroup();

          })
          .catch(err => {

            vm.loading = '';
            vm.closeModal('create-worksheet');

            var status = parseInt(err.response.status);

            if(status === 412) {

              var msg = err.response.data.data.message;
              var attendance = err.response.data.data.attendance;
              console.log('FAILED err.response.status ' + JSON.stringify(err.response.status))

              if (attendance && attendance.length > 0) {

                var trs = [];

                vm.jQuery.each(attendance, function (k, v) {

                  var tr = '<tr><td>' + vm.formatDateShort(v.date) + '</td><td>' + v.total + '</td>';
                  trs.push(tr);

                })

                var tbl = '<h3>You have unmarked attendance</h3><table class="table table-striped"><thead><tr><th>Date</th><th>Un Marked</th></tr></thead><tbody>' + trs.join('') + '</tbody></table>';

                vm.$swal.fire({
                  icon: 'error',
                  html: tbl,
                  showCloseButton: true,
                  showCancelButton: true,
                  focusConfirm: false,
                })

              } else {

                vm.$swal.fire('Failed!', msg, 'error')

              }

            }
            else {

              console.log(JSON.stringify(err.response.data,undefined,2))

              vm.$swal.fire(
                  'Failed!',
                  err.response.data.data,
                  'error'
              )
            }

          })

    },

    deleteWorksheetPayment: function(data) {

      var vm = this;

      vm.$swal.fire({
        title: "Do you want to delete this payment schedule from the list?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete'

      }).then((result) => {

        if (result.isConfirmed) {

          var path = process.env.VUE_APP_URL_WORKSHEET_PAYMENT_DELETE.replace(":id", vm.worksheet.id).replace(":payment_id",data.id);
          vm.loading = 'loading';

          axios.post(path, JSON.stringify({}),{
            headers: {
              'api-key': vm.getAuth()
            },
          })
              .then(res => {

                vm.loading = '';
                vm.reload();

                var msg = res.data.data.message;

                vm.$swal.fire(
                    'Submitted!',
                    msg,
                    'success'
                )

              })
              .catch(err => {

                vm.loading = '';

                vm.$swal.fire(
                    'Failed!',
                    err.response.data.data,
                    'error'
                )

                if (err.response) {

                  var message = "";

                  if(parseInt(err.response.status) === 428 ) {

                    message = err.response.data.message;
                    vm.setError("Failed", message)
                    return;
                  }

                  if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 ) {

                    vm.setError("Session Expired", "Your session on this device has expired");
                    vm.logout();
                    return;

                  } else {

                    message =  err.response.data.message;
                    vm.setError("Failed", message)

                  }

                } else if (err.request) {

                  vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err.request));

                } else {

                  //vm.setError("Failed", "Please check your network")
                  console.log(JSON.stringify(err));

                }
              })
        } else {

          vm.cashoutLoading = '';

        }

      })

    },


  },
  watch: {

    duration: function (newVal) {

      var fm = 'YYYY-MM-DD';
      if(parseInt(newVal) === 1) {

        this.start_date = moment(this.worksheet.start_date, fm).format('YYYY-MM-DD');
        this.end_date = moment(this.worksheet.end_date, fm).format('YYYY-MM-DD');
      }

    }
  }

}

</script>