<template>

    <div class="">

      <div class="container-fluid">
        <div class="card custom-card-wrapper">
          <div class="card-header card-body-header-lg p-2 pb-0 d-flex justify-content-left shadow-sm">

            <span class="font-weight-500 men-item underline-item mr-2 "  @click="goTo('payments')">
                Payments
            </span>

            <span class="font-weight-500 men-item underline-item mr-2 " @click="goTo('paymentsdue')">
                Due Payments
            </span>

            <span class="font-weight-500 men-item underline-item mr-2 " @click="goTo('paymentscompleted')">
                Completed Payments
            </span>

            <span class="font-weight-500 men-item underline-item mr-2 active">
                Reversed Payments
            </span>

          </div>
          <div class="card-body p-0">

            <div class="card-body-header p-2 d-flex justify-content-between border-bottom">
              <div id="reportrange" class="" style="">
                <i class="bi bi-calendar"></i>&nbsp;
                <span></span> <i class="bi bi-chevron-down"></i>
              </div>


            </div>
            <table class="table table-striped">
              <thead class=" ">
              <tr>
                <th>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDisabled" >

                  </div>
                </th>
                <th>
                  Recepients Name
                </th>
                <th>
                  Amount
                </th>
                <th>
                  Bank/Mpesa
                </th>
                <th>
                  Purpose
                </th>
                <th>
                  Date
                </th>

                <th class="text-right">
                  Actions
                </th>
              </tr>
              </thead>
              <tbody>
              <tr class="">
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDisabled" >

                  </div>
                </td>
                <td>
                  John Doe
                </td>
                <td>
                  12,017.00
                </td>
                <td>
                  0700123456
                </td>
                <td>
                  Lorem Ipsum
                </td>
                <td class="">
                  02/12/2016
                </td>

                <td  class="text-right">
                  <div class="btn-group">
                    <button type="button" class="btn btn-primary btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                      More
                    </button>
                    <ul class="dropdown-menu">
                      <li><a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#exampleModal">Correct</a></li>
                      <li><router-link class="dropdown-item" to="/payments/make">Resend</router-link></li>
                    </ul>
                  </div>
                </td>
              </tr>


              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- Modal -->
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Correct Details</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form class="custom-form">
                <div class="input-wrapper mb-2">
                  <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">Recepient Name</label>
                    <input type="text" class="form-control" >
                  </div>
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">Account Number </label>
                    <input type="text" class="form-control" >
                  </div>


                </div>


                <div class="modal-footer-copy">
                  <button type="button" class="btn btn-outline-secondary btn-sm mr-2" data-bs-dismiss="modal">Cancel</button>
                  <button type="button" class="btn btn-primary btn-sm shadow">Save</button>
                </div>
              </form>
            </div>

          </div>
        </div>
      </div>

    </div>

</template>

<script>

export default {
  name: 'payments',
  components: {

  },
  data: function (){
    return {
      highlights: true,
      upcoming: false,
      today: false,
      sub_page: '',
      mqttClient:'',
      active_tab: 'account'
    }
  },
  mounted() {

    this.$store.dispatch("setCurrentPage","home");
    // reset game filter
    //this.$store.dispatch("resetAllGames");
    //this.reloadProfile();

  },
  computed: {
    betslip_count: function (){

      return this.$store.state.betslip.total

    },
    current_sub_page: function () {

      return this.$store.state.current_sub_page;
    },
    sport_id: function () {

      return this.$store.state.sport_id;

    }
  },
  methods: {

    showTab: function(tabName) {

      this.active_tab = tabName;
    },

    isActiveTab: function(tabName) {

      return this.active_tab === tabName;
    },

    getTabClass: function(tabName) {

      return this.active_tab === tabName ? 'active-tab' : '';
    },

    getTabTitleClass: function(tabName) {

      return this.active_tab === tabName ? 'active' : '';

    },

    setUpcoming: function(){

      this.highlights = false;
      this.today = false;
      this.upcoming = true;
    },
    setToday: function(){

      this.highlights = false;
      this.today = true;
      this.upcoming = false;
    },
    setHighlight: function(){

      this.highlights = true;
      this.today = false;
      this.upcoming = false;
    },
    setSubPage: function (page){

      this.$store.dispatch("setCurrentSubPage",page);
      this.sub_page = page;

    },
    isHighlight: function(){

      return this.highlights
    },
    isToday: function(){

      return this.today
    },
    isUpcoming: function(){

      return this.upcoming
    },

    geSubPageActiveClass: function (subpage) {

      return subpage === this.sub_page ? 'active' : "";

    },
    setSport: function(sportID) {

      this.$store.dispatch("setSportID",sportID);

    }
  },
}

</script>